import Layout from "@/layout/index.vue";
import type { RouteRecordRaw } from "vue-router";
import Login from "../views/login.vue";
import Home from "../views/home.vue";
import Collect from "../views/collect.vue";
import Cabinet from "../views/cabinet.vue";
import EnterpriseEdit from "../views/enterpriseEdit.vue";
import ManagerCenterView from "../views/managementCenterView.vue";
import EditCard from "../views/editCard.vue";
import { NavigatorRoute } from "@/utils/const";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    component: Layout,
    redirect: NavigatorRoute.LOGIN_PAGE,
    children: [
      {
        path: NavigatorRoute.LOGIN_PAGE,
        name: NavigatorRoute.LOGIN_PAGE,
        component: Login,
        meta: {
          title: NavigatorRoute.LOGIN_PAGE,
        },
      },
      {
        path: NavigatorRoute.HOME_PAGE,
        name: NavigatorRoute.HOME_PAGE,
        component: Home,
        meta: {
          title: NavigatorRoute.HOME_PAGE,
        },
      },
      {
        path: NavigatorRoute.EDIT_CARD,
        name: NavigatorRoute.EDIT_CARD,
        component: EditCard,
        meta: {
          title: NavigatorRoute.EDIT_CARD,
        },
      },
      {
        path: NavigatorRoute.COLLECT,
        name: NavigatorRoute.COLLECT,
        component: Collect,
        meta: {
          title: NavigatorRoute.COLLECT,
        },
      },
      {
        path: NavigatorRoute.CABINET,
        name: NavigatorRoute.CABINET,
        component: Cabinet,
        meta: {
          title: NavigatorRoute.CABINET,
        },
      },
      {
        path: NavigatorRoute.ENTER_PRISE_EDIT,
        name: NavigatorRoute.ENTER_PRISE_EDIT,
        component: EnterpriseEdit,
        meta: {
          title: NavigatorRoute.ENTER_PRISE_EDIT,
        },
      },
      {
        path: NavigatorRoute.MANAGER_CENTER,
        name: NavigatorRoute.MANAGER_CENTER,
        component: ManagerCenterView,
        meta: {
          title: NavigatorRoute.MANAGER_CENTER,
        },
      },
    ],
  },
];

export default routes;
