<template>
  <div class="expire_card_list_bg">
    <div class="top_content">
      <div class="left_content">
        <span class="enterprise_title">已过期</span>
        <span class="card_number_tips">共{{ linkardTotal }}张</span>
      </div>
      <button class="right_expire_card_button" @click="backButtonClick">
        返回
      </button>
    </div>
    <div class="card_list_bg">
      <ul class="card-list">
        <li v-for="(linkInfo, index) in expireCardListItem.linkardList" :key="index">
          <LinkardInfoItem :linkardInfo="linkInfo">
            <div :class="[
          'click_layer',
          currentSelCard == linkInfo
            ? 'click_layer_sel'
            : 'click_layer_normal',
        ]" @mouseover="linkardInfoMouseOver(linkInfo)" @mouseleave="linkardInfoMouseLeave(linkInfo)">
              <div :class="[
          isActivatable ? 'activatable_card_tips' : 'expire_card_tips',
        ]">
                <span>{{ isActivatable ? "可激活" : "已过期" }}</span>
              </div>
              <div v-if="currentSelCard == linkInfo" class="handle_bg_view">
                <button v-if="isActivatable" class="handle_button active_card_button" @click="activeButtonClick(index)">
                  <img src="../assets/images/linkard_active_icon.svg" fit="cover" />
                  <span>{{ "激活" }}</span>
                </button>

                <button class="handle_button delete_card_button" @click="deleteButtonClick(index)">
                  <img src="../assets/images/expire_card_del_icon.svg" fit="cover" />
                  <span>{{ "删除" }}</span>
                </button>
              </div>
            </div>
          </LinkardInfoItem>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { EnterpriseCardItem, linkardNet } from "@/net/linkardNet";
import { ElMessage } from "element-plus";
import { Ref, defineComponent, onMounted, ref } from "vue";
import LinkardInfoItem from "../components/linkardInfoItem_Small.vue";
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';

export default defineComponent({
  name: "ExpireCardListView",
  components: {
    LinkardInfoItem,
  },
  props: {
    expireCardListItem: {
      type: Object as () => any,
      default: {
        linkardTotal: 0,
        linkardNum: 0,
        linkardList: [] as EnterpriseCardItem[],
      },
    },
    isActivatable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    let currentSelCard: Ref<any> = ref();
    let linkardTotal = ref(0);

    const backButtonClick = () => {
      emit("expireCardListViewClose");
    };
    const linkardInfoMouseOver = (linkardInfo: EnterpriseCardItem) => {
      currentSelCard.value = linkardInfo;
    };
    const linkardInfoMouseLeave = (linkardInfo: EnterpriseCardItem) => {
      currentSelCard.value = undefined;
    };
    const activeButtonClick = (index: number) => {
      OverAllDialogView.showOverAllDialogView("激活后不可撤销，请谨慎操作", "确认激活", "返回", "", false, (dialogIndex: number) => {
        if (dialogIndex == 1) {
          enterpriseActivateLinkardRequest(props.expireCardListItem.linkardList[index].id, index);
        }
        OverAllDialogView.hideOverAllDialogView();
      });
    };
    const deleteButtonClick = (index: number) => {
      OverAllDialogView.showOverAllDialogView("删除后不可恢复，请谨慎操作", "确认删除", "返回", "", false, (dialogIndex: number) => {
        if (dialogIndex == 1) {
          enterpriseDeleteLinkardRequest(props.expireCardListItem.linkardList[index].id, index);
        }
        OverAllDialogView.hideOverAllDialogView();
      });
    };

    const enterpriseDeleteLinkardRequest = async (
      linkardId: string,
      index: number
    ) => {
      linkardNet.enterpriseDeleteLinkard(linkardId).then(
        (response) => {
          ElMessage.success("删除成功");
          linkardTotal.value -= 1;
          props.expireCardListItem.linkardList.splice(index, 1);
        },
        (ex) => {
          ElMessage.error("删除失败,请稍后重试");
        }
      );
    };
    const enterpriseActivateLinkardRequest = async (
      linkardId: string,
      index: number
    ) => {
      linkardNet.enterpriseActivateLinkard(linkardId).then(
        (response) => {
          ElMessage.success("激活成功");
          linkardTotal.value -= 1;
          props.expireCardListItem.linkardList.splice(index, 1);
        },
        (ex) => {
          ElMessage.error("激活失败,请稍后重试");
        }
      );
    };

    onMounted(() => {
      linkardTotal.value = props.expireCardListItem.linkardTotal;
    });

    return {
      currentSelCard,
      linkardTotal,
      backButtonClick,
      activeButtonClick,
      deleteButtonClick,
      linkardInfoMouseOver,
      linkardInfoMouseLeave,
    };
  },
});
</script>

<style lang="less" scoped>
.expire_card_list_bg {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 944px;
  height: 704px;
  border-radius: 40px;
  background: #f0f0f0;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

  .top_content {
    width: 810px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 88px;

    .left_content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .enterprise_title {
        color: #242424;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        position: sticky;
      }

      .card_number_tips {
        margin-left: 10px;
        color: #242424;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        position: sticky;
        margin-bottom: 2px;
      }
    }

    .right_expire_card_button {
      width: 180px;
      height: 64px;
      border-radius: 32px;
      color: #242424;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      border: 2px solid #242424;
      padding: 0 32px 0 32px;
      cursor: pointer;
    }

    .right_expire_card_button:hover {
      opacity: 0.7;
    }

    .right_expire_card_button:active {
      opacity: 0.5;
    }
  }

  .card_list_bg {
    width: 850px;
    height: 466px;
    margin-top: 48px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .card-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px 20px 20px;

      li {
        margin: 0 10px 40px 0;
        position: relative;

        .post-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;

          #posterHtml {
            position: relative;
            background: url("../assets/images/share-my.svg") no-repeat;
            width: 150px;
            height: 200px;
            background-size: cover;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
          }

          .post-erweima {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 6px;
            left: 10px;
            bottom: 20px;
          }

          .post-link-logo {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            margin-top: 34px;
            margin-left: 20px;
            object-fit: cover;
          }

          .post-link-name {
            font-size: 8px;
            color: #fff;
            margin-left: 18px;
            font-weight: 600;
            margin-top: 6px;
            text-align: center;
          }

          .post-link-tags {
            margin-left: 14px;
            text-align: center;
            justify-content: center;

            span {
              color: #f0f0f0;
              border-radius: 16px;
              border: 0.5px solid #f0f0f0;
              font-size: 5px;
              padding: 2px 4px;
              margin-left: 6px;
            }
          }

          .post-link-avatar {
            width: 100px;
            height: 50px;
            border-radius: 10px 0px 0px 10px;
            background: #f0f0f0;
            position: absolute;
            right: 0;
            bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            :deep(.el-image) {
              height: 100%;
            }
          }

          .post-link-path {
            position: absolute;
            color: #f0f0f0;
            font-size: 6px;
            bottom: 20px;
            left: 50px;
          }
        }
      }

      li:nth-child(5n) {
        margin-right: 0;
      }

      li:hover .mask {
        opacity: 1;
      }
    }
  }

  .card_list_bg::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 30px 0;
  }

  .card_list_bg::-webkit-scrollbar {
    width: 4px;
  }

  .card_list_bg::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #d3d4d6;
    border-radius: 4px;
    margin: 30px 0;
  }

  .click_layer {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .handle_bg_view {
      margin-top: 54px;

      .handle_button {
        width: 80px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-width: 0px;
        cursor: pointer;

        span {
          color: #242424;
          text-align: center;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .active_card_button {
        background-color: #f3ec3f;
      }

      .active_card_button:hover {
        opacity: 0.8;
      }

      .active_card_button:active {
        opacity: 0.5;
      }

      .delete_card_button {
        margin-top: 10px;
        background-color: #f0f0f0;
      }

      .delete_card_button:hover {
        opacity: 0.8;
      }

      .delete_card_button:active {
        opacity: 0.5;
      }
    }
  }

  .click_layer_normal {
    background: rgba(36, 36, 36, 0.5);
  }

  .click_layer_sel {
    background: rgba(36, 36, 36, 0.9);
  }

  .expire_card_tips {
    margin-top: 24px;
    background-color: #b3b3b3;
    width: 128px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      color: #242424;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .activatable_card_tips {
    margin-top: 24px;
    background-color: #f3ec3f;
    width: 128px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      color: #242424;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
</style>
