import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-711edccd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "theme_style_bg" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['space_model_item_bg', _ctx.specialHeight ? 'space_model_item_bg_special' : '']),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.itemClick && _ctx.itemClick(...args))),
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
    style: _normalizeStyle(_ctx.boxStyle)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['content_view', _ctx.isSelected ? 'content_view_selected' : ''])
    }, [
      _createElementVNode("img", {
        class: "space_model_item",
        src: _ctx.coverImg
      }, null, 8, _hoisted_1)
    ], 2),
    _createElementVNode("span", {
      class: "item_name",
      textContent: _toDisplayString(_ctx.itemInfo.name)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.styleList, (item, index) => {
        return (_openBlock(), _createBlock(_component_van_button, {
          class: _normalizeClass(['theme_style_button', _ctx.styleTabIndex === index ? 'theme_style_button_selected' : '']),
          key: index,
          onClick: _withModifiers(($event: any) => (_ctx.themeStyleButtonClick(item, index)), ["stop"])
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "theme_style_img",
              src: item.button
            }, null, 8, _hoisted_4)
          ]),
          _: 2
        }, 1032, ["class", "onClick"]))
      }), 128))
    ])
  ], 38))
}