<template>
    <div class="digital_corpus_setting">
        <div class="top_view">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
            <button class="submit_button" @click="submitClick">确认</button>
        </div>
        <div class="yl-wrapper">
            <div class="yl-left">{{ realTimeTraining.length }}/5</div>
            <div class="yl-right">
                <div class="yl-list-item" v-for="(item, index) in realTimeTraining" :key="index">
                    <div class="input-content">
                        <el-input v-model="item.text"></el-input>
                    </div>
                    <img src="../assets/images/brand-close.png" alt="" @click="deleteYl(index)" />
                </div>
                <button class="click_add_button" @click="addRealTimeTrainingClick">
                    点击添加
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref } from 'vue';
import BackButtonItem from "./backButtonItem.vue";

export default defineComponent({
    name: 'RealTimeTraining',
    props: {
        tempRealTimeTraining: {
            type: Array as () => Array<any>,
            default: () => []
        },
    },
    components: {
        BackButtonItem
    },
    setup(props, { emit }) {

        let realTimeTraining: Ref<any[]> = ref([]);
        const backButtonClick = () => {
            emit("dismissRealTimeTraining", null);
        };

        const submitClick = () => {
            emit("dismissRealTimeTraining", realTimeTraining.value);
        };

        const deleteYl = (index: number) => {
            realTimeTraining.value = realTimeTraining.value.filter(
                (item, i) => index !== i
            );
        };

        const addRealTimeTrainingClick = () => {
            const item = {
                text: ""
            };
            realTimeTraining.value.push(item);
        };

        onMounted(() => {
            realTimeTraining.value = props.tempRealTimeTraining;
        });

        return {
            realTimeTraining,
            addRealTimeTrainingClick,
            backButtonClick,
            submitClick,
            deleteYl,
        };
    },
});

</script>

<style lang="less" scoped>
.digital_corpus_setting {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {}

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
            margin-left: 60px;
        }
    }

    .yl-wrapper {
        width: 100%;
        flex-shrink: 0;
        border-radius: 40px;
        background: #f0f0f0;
        padding: 40px 158px 64px 97px;
        display: flex;
        justify-content: center;
        position: relative;

        .yl-left {
            color: #242424;
            font-size: 28px;
            font-weight: 700;
            width: 80px;
            margin-right: 24px;
            line-height: 48px;
        }

        .yl-right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .yl-list-item {
                display: flex;
                align-items: center;
                margin-bottom: 22px;
                cursor: pointer;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 400px;
                    height: 48px;
                    border-radius: 36px;
                    border: 2px solid #242424;
                    background: #f0f0f0;
                    color: #242424;
                    font-weight: 600;
                }

                img {
                    width: 18px;
                    height: 18px;
                    margin-left: 44px;
                    cursor: pointer;
                }

                .input-content {
                    :deep(input) {
                        border-radius: 36px;
                        border: 2px solid #242424;
                        background: #f0f0f0;
                        width: 400px;
                        height: 48px;
                    }

                    :deep(.el-textarea) {
                        border-radius: 36px;
                        border: 2px solid #242424;
                        overflow: hidden;
                    }

                    :deep(.textarea) {
                        border: none;
                        background: #f0f0f0;
                        width: 556px;
                        height: 138px;
                    }
                }
            }

            .click_add_button {
                cursor: pointer;
                width: 400px;
                height: 48px;
                border-radius: 36px;
                border-width: 0;
                background: #242424;
                color: #F0F0F0;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
}
</style>
