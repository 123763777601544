<template>
    <div class="linkard_item_bg" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" :style="boxStyle">
        <img :src="linkardInfo.avatar" alt="" class="linkard_logo">
        <div class="linkard_name">{{ linkardInfo.name }}</div>
        <div class="linkard_tags">
            <div class="linkard_tags_bg" v-for="(item, index) in linkardInfo.tags">
                <span v-if="item" :key="index">{{ item }}</span>
            </div>
        </div>
        <div class="linkard_avatar">
            <el-image :src="linkardInfo.npcAvatar" fit="cover" />
        </div>
        <span class="linkard_path">{{ linkardQRImgUrl }}</span>
        <div class="qr_code_image_bg">
            <vue-qrcode :value="linkardQRImgUrl" :color="{}" type="image/png" :width="66" :height="66" :margin="2"
                errorCorrectionLevel="L"></vue-qrcode>
        </div>
    </div>
</template>

<script lang="ts">
import { LinkardItemInfo } from '@/net/linkardNet';
import { GenerateQRCodeLink } from '@/utils/serviceUtils';
import { computed, defineComponent, onMounted, ref } from 'vue';
import VueQrcode from 'vue-qrcode';

export default defineComponent({
    name: 'LinkardInfoItem',
    props: {
        linkardInfo: {
            type: Object as () => LinkardItemInfo,
            default: () => ({
                id: '',
                url: '',
                name: '',
                avatar: '',
                npcAvatar: '',
                tags: []
            })
        },
    },
    components: {
        VueQrcode
    },
    setup(props, { emit }) {

        let linkardQRImgUrl = ref('');
        const scale = ref(1);

        const handleMouseEnter = () => {
            scale.value = 1.02;
        };

        const handleMouseLeave = () => {
            scale.value = 1;
        };

        const boxStyle = computed(() => ({
            transform: `scale(${scale.value})`,
            transition: 'transform 0.3s ease',
        }));
        const generateQRCodeLink = (path: string) => {
            linkardQRImgUrl.value = GenerateQRCodeLink(path);
        };

        generateQRCodeLink(props.linkardInfo.url);
        onMounted(() => {
        });
        return {
            generateQRCodeLink,
            handleMouseEnter,
            handleMouseLeave,
            boxStyle,
            linkardQRImgUrl,
        };
    },
});

</script>

<style lang="less" scoped>
.linkard_item_bg {
    position: relative;
    background: url('../assets/images/linkard_item_bg.svg') no-repeat;
    width: 253px;
    height: 336px;
    background-size: cover;
    border-radius: 20px;

    .linkard_path {
        position: absolute;
        color: #f0f0f0;
        font-size: 10px;
        bottom: 28.8px;
        left: 96px;
    }

    .qr_code_image_bg {
        position: absolute;
        border-radius: 8px;
        left: 16px;
        bottom: 32px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            border-radius: 8px;
        }
    }

    .linkard_logo {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        margin-top: 52px;
        margin-left: 30px;
        object-fit: cover;
    }

    .linkard_name {
        font-size: 19px;
        color: #fff;
        margin-left: 26px;
        font-weight: 600;
        margin-top: 8px;
        text-align: center;
    }

    .linkard_tags {
        // display: flex;
        // flex: 1;
        margin-left: 18px;
        margin-top: 8px;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .linkard_tags_bg {
            span {
                color: #f0f0f0;
                border-radius: 16px;
                border: 1px solid #F0F0F0;
                font-size: 8px;
                padding: 2px 8px;
                margin-left: 10px;
            }
        }
    }

    .linkard_avatar {
        width: 157px;
        height: 80px;
        border-radius: 16px 0px 0px 16px;
        background: #f0f0f0;
        position: absolute;
        right: 0;
        bottom: 46px;
        display: flex;
        align-items: center;
        justify-content: center;

        :deep(.el-image) {
            height: 100%;
        }
    }
}
</style>