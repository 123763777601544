<template>
    <div class="special_subject_add_bg">
        <div class="special_subject_info_bg">
            <el-input class="special_subject_name_input" placeholder="请输入专题名称" v-model="specialTopicInfo.name"
                maxlength="12"></el-input>
            <button class="back_button" @click="backButtonClick">返回
            </button>
            <button class="submit_button" @click="submitClick">确认
            </button>
        </div>
        <div class="special_subject_content">
            <div class="special_subject_model_bg">
                <span class="special_subject_model_title">
                    专题模板
                </span>
                <ul class="model_list">
                    <li v-for="(modelItem, index) in tempModelList" :key="index" @mouseenter="handleMouseEnter(index)"
                        @mouseleave="handleMouseLeave(index)" :style="itemStyle(index)">
                        <div v-if="!modelItem.isPlaceHoder"
                            :class="['model_item_bg', selectedModelItem == modelItem ? 'model_item_bg_select' : '']"
                            @click="modelItemClick(modelItem)">
                            <img :src="modelItem.url" alt="" />
                            <span v-if="selectedModelItem == modelItem" class="using_tips">使用中</span>
                        </div>
                        <div v-else class="model_placeHoder">
                            <span>更多模板敬请期待</span>
                        </div>
                    </li>
                </ul>
                <div class="model_list_bottom_view">
                    <el-button :disabled="currentModelListPage <= 0" class="page_button" @click="pageLeft">
                        <img src="../assets/images/page_turning_left.svg" alt="" />
                    </el-button>
                    <span class="page_number">{{ currentModelListPage + 1 }}</span>
                    <el-button :disabled="(currentModelListPage + 1) * 4 >= modelList.length" class="page_button"
                        @click="pageRight">
                        <img src="../assets/images/page_turning_right.svg" alt="" />
                    </el-button>
                </div>
            </div>
            <div class="card_list_bg">
                <span class="card_list_title">添加联卡</span>
                <ul class="card-list">
                    <li v-for="(linkInfo, index) in specialTopicInfo.linkardList" :key="index"
                        @click="subjectLinkardItemClick(linkInfo)">
                        <div class="post-wrapper">
                            <div id="posterHtml">
                                <img :src="linkInfo.avatar" alt="" class="post-link-logo">
                                <div class="post-link-name">{{ linkInfo.name }}</div>
                            </div>
                            <div class="custom_linkard_name">{{ linkInfo.showName ? linkInfo.showName : linkInfo.name }}
                            </div>
                            <button class="handle_button delete_card_button" @click.stop="deleteButtonClick(index)">
                                <img src="../assets/images/expire_card_del_icon.svg" fit="cover" />
                            </button>
                        </div>
                    </li>
                    <li>
                        <button class="add_button" @click="addLinkardButtonClick">
                            <img src="../assets/images/special_subject_add_icon.svg" alt="" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="add_linkard_edit_view" v-if="showAddLinkardEditView">
            <div class="add_linkard_info_bg">
                <div class="add_linkard_navi_bar">
                    <BackButtonItem @backBtnClick="closeAddLinkardEdit" class="back_button"></BackButtonItem>
                    <button class="submit_button" @click="addLinkardSureButtonClick">确认</button>
                </div>
                <div class="add_linkard_content">
                    <div class="left">
                        <div v-if="selectedAddLinkardItem" class="selected_linkard_bg">
                            <img :src="selectedAddLinkardItem.avatar" alt="" class="selected_linkard_logo">
                            <div class="selected_linkard_name">{{ selectedAddLinkardItem.name }}</div>
                        </div>
                    </div>
                    <div class="right">
                        <span class="linkard_title">联卡链接</span>
                        <div class="top-site-info">
                            <span>{{ baseLinkardUrl }}/</span>
                            <el-select class="el-select-option-info" v-model="selLinkardUrl" placeholder="请选择"
                                @change="linkardSelectedChange">
                                <el-option v-for="item, index in addLinkardList" :key="index" :label="item.name + `(${item.url})`"
                                    :value="item.url">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="input-wrapper add_linkard_name_bg">
                            <span class="title_style">联卡名称</span>
                            <el-input class="add_linkard_name" v-model="addLinkardName"
                                placeholder="请输入联卡名称"></el-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { SpecialTopicLinkardItem, TemplateListResponse, LinkardItemInfo, SpecialTopicAddArgs, SpecialTopicDetailResponse, SpecialTopicUpdateArgs, linkardNet } from '../net/linkardNet';
import BackButtonItem from './backButtonItem.vue';
import { ElMessage } from 'element-plus';

export default defineComponent({
    name: 'DigitalHumanView',
    props: {
        currentSpecialTopicId: {
            type: String,
            default: '',
        },

        cabinetId: {
            type: String,
            default: '',
        },
    },
    components: {
        BackButtonItem
    },
    setup(props, { emit }) {

        let baseLinkardUrl = process.env.VUE_APP_MOBILE_URL;
        let specialTopicInfo: Ref<SpecialTopicDetailResponse> = ref({
            id: "",
            name: "",
            url: "",
            linkardList: [
            ]
        });
        let showAddLinkardEditView = ref(false);
        let linkInputFocus = false;
        let addLinkardName = ref('');
        let selLinkardUrl = ref('');
        let currentModelListPage = ref(0);
        let modelList: Ref<TemplateListResponse[]> = ref([{ url: '', isPlaceHoder: true }]);
        let tempModelList: Ref<TemplateListResponse[]> = ref([]);
        let addLinkardList: Ref<LinkardItemInfo[]> = ref([]);
        let selectedModelItem: Ref<TemplateListResponse | undefined> = ref(undefined);
        let selectedAddLinkardItem: Ref<LinkardItemInfo | null> = ref(null);
        let isSubmitting = false;

        const scales = ref(new Array(tempModelList.value.length).fill(1));

        const handleMouseEnter = (index: number) => {
            scales.value[index] = 1.05;
        };

        const handleMouseLeave = (index: number) => {
            scales.value[index] = 1;
        };

        const itemStyle = (index: number) => {
            return {
                transform: `scale(${scales.value[index]})`,
                transition: 'transform 0.3s ease',
            };
        };

        const backButtonClick = () => {
            emit("dismissSpecialSubject", 0,props.cabinetId);
        };
        const submitClick = () => {
            specialTopicAddOrUpdateRequest();
        };
        const subjectLinkardItemClick = (linkardInfo: any) => {
            for (var i = 0; i < addLinkardList.value.length; i++) {
                let item = addLinkardList.value[i];
                if (item.id == linkardInfo.id) {
                    selectedAddLinkardItem.value = item;
                    selLinkardUrl.value = selectedAddLinkardItem.value.url;
                    addLinkardName.value = selectedAddLinkardItem.value.name;
                    break;
                }
            }
            addLinkardName.value = linkardInfo.showName;
            if (selectedAddLinkardItem.value != null) {
                showAddLinkardEditView.value = true;
            }
        };

        const linkardSelectedChange = () => {
            for (var i = 0; i < addLinkardList.value.length; i++) {
                let item = addLinkardList.value[i];
                if (item.url == selLinkardUrl.value) {
                    selectedAddLinkardItem.value = item;
                    addLinkardName.value = item.name;
                }
            }
        };
        const addLinkardSureButtonClick = () => {
            if (!selLinkardUrl.value) {
                ElMessage.info('请选择一个联卡链接');
                return;
            }
            if (!addLinkardName.value) {
                ElMessage.info('请输入联卡名称');
                return;
            }
            for (var i = 0; i < specialTopicInfo.value.linkardList.length; i++) {
                let item = specialTopicInfo.value.linkardList[i];
                if (item.url == selLinkardUrl.value) {
                    specialTopicInfo.value.linkardList.splice(i, 1);
                }
            }

            if (selectedAddLinkardItem.value != null) {
                selectedAddLinkardItem.value.showName = addLinkardName.value;
                specialTopicInfo.value.linkardList.push(selectedAddLinkardItem.value);

            }
            addLinkardName.value = "";
            showAddLinkardEditView.value = false;
            selectedAddLinkardItem.value = null;
            selLinkardUrl.value = '';
        };

        const addLinkardButtonClick = () => {
            showAddLinkardEditView.value = true;
        };

        const closeAddLinkardEdit = () => {
            selectedAddLinkardItem.value = null;
            selLinkardUrl.value = '';
            showAddLinkardEditView.value = false;
        };

        const deleteButtonClick = (index: number) => {
            specialTopicInfo.value.linkardList.splice(index, 1);
        };

        const specialTopicDetailRequest = async () => {
            linkardNet.specialTopicDetail(props.currentSpecialTopicId).then(
                (response) => {
                    specialTopicInfo.value = response;
                    for (var i = 0; i < tempModelList.value.length; i++) {
                        if (specialTopicInfo.value.url == tempModelList.value[i].url) {
                            selectedModelItem.value = tempModelList.value[i];
                        }
                    }
                },
                (ex) => {

                },
            );
        };

        const specialTopicTemplateListRequest = async () => {

            linkardNet.specialTopicTemplateList(props.currentSpecialTopicId).then(
                (response) => {
                    modelList.value.unshift(...response);
                    if (modelList.value.length > 4) {
                        tempModelList.value = modelList.value.slice(currentModelListPage.value * 4, 4);
                    }
                    else {
                        tempModelList.value = modelList.value;
                    }
                    scales.value = Array(tempModelList.value.length).fill(1);
                    if (props.currentSpecialTopicId) {
                        specialTopicDetailRequest();
                    }
                },
                (ex) => {

                },
            );
        };

        const specialTopicAddOrUpdateRequest = async () => {
            if (selectedModelItem.value == undefined) {
                ElMessage.error('请选择模板');
                return;
            };

            if (specialTopicInfo.value.linkardList.length <= 0) {
                ElMessage.error('联卡列表不能为空');
                return;
            };

            const tempLinkardList: SpecialTopicLinkardItem[] = [];
            for (const item of specialTopicInfo.value.linkardList) {
                const linkardItem: SpecialTopicLinkardItem = {
                    linkardId: '',
                    linkardName: ''
                };
                linkardItem.linkardId = item.id;
                linkardItem.linkardName = item.showName ? item.showName : item.name;
                tempLinkardList.push(linkardItem);
            }

            if (selectedModelItem.value != null) {
                specialTopicInfo.value.url = selectedModelItem.value.url;
            }

            isSubmitting = true;
            if (props.currentSpecialTopicId) {
                const params: SpecialTopicUpdateArgs = {
                    id: props.currentSpecialTopicId,
                    name: specialTopicInfo.value.name,
                    url: specialTopicInfo.value.url,
                    linkardList: tempLinkardList
                };

                await linkardNet.specialTopicUpdate(params).then(
                    (response) => {
                        ElMessage.success('提交成功');
                    },
                    (ex) => {
                        ElMessage.error('提交失败');
                    },
                );

                emit("dismissSpecialSubject", 1,props.cabinetId);
                isSubmitting = false;

            } else {
                const params: SpecialTopicAddArgs = {
                    cabinetId:props.cabinetId,
                    name: specialTopicInfo.value.name,
                    url: specialTopicInfo.value.url,
                    linkardList: tempLinkardList
                };

                await linkardNet.specialTopicAdd(params).then(
                    (response) => {
                        ElMessage.success('提交成功');
                    },
                    (ex) => {
                        ElMessage.error('提交失败');
                    },
                );
                emit("dismissSpecialSubject", 1,props.cabinetId);
                isSubmitting = false;
            }
        };

        const specialTopicLinkardListRequest = async () => {
            linkardNet.specialTopicLinkardList().then(
                (response) => {
                    addLinkardList.value = response;
                },
                (ex) => {
                },
            );
        };

        const pageLeft = () => {
            currentModelListPage.value -= 1;
            tempModelList.value = modelList.value.slice(currentModelListPage.value * 4, currentModelListPage.value * 4 + 4);
            scales.value = Array(tempModelList.value.length).fill(1);
        };

        const pageRight = () => {
            currentModelListPage.value += 1;
            tempModelList.value = modelList.value.slice(currentModelListPage.value * 4, currentModelListPage.value * 4 + 4);
            scales.value = Array(tempModelList.value.length).fill(1);
        };

        const modelItemClick = (item: TemplateListResponse) => {
            selectedModelItem.value = item;
        };

        onMounted(() => {
            specialTopicTemplateListRequest();
            specialTopicLinkardListRequest();
        });

        return {
            specialTopicInfo,
            modelList,
            showAddLinkardEditView,
            selectedAddLinkardItem,
            baseLinkardUrl,
            selLinkardUrl,
            addLinkardList,
            addLinkardName,
            tempModelList,
            selectedModelItem,
            currentModelListPage,
            deleteButtonClick,
            backButtonClick,
            submitClick,
            subjectLinkardItemClick,
            addLinkardButtonClick,
            modelItemClick,
            pageLeft,
            pageRight,
            closeAddLinkardEdit,
            addLinkardSureButtonClick,
            linkardSelectedChange,
            handleMouseEnter,
            handleMouseLeave,
            itemStyle,
        };
    },
});

</script>

<style lang="less" scoped>
.special_subject_add_bg {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .special_subject_info_bg {
        width: 100%;
        padding: 64px 48px 0 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .special_subject_name_input {
            flex-grow: 1;

            :deep(.el-input__wrapper) {
                background: transparent;
                border: 2px solid #242424;
                height: 64px;
                border-radius: 32px;

                .el-input__inner {
                    font-size: 18px;
                    font-weight: 600;
                    color: #242424;
                    margin: 0 16px 0 16px;
                    background: transparent;
                }
            }

            :deep(.el-input__wrapper.is-focus) {
                box-shadow: none;
            }
        }

        .back_button {
            width: 180px;
            height: 64px;
            border-radius: 36px;
            border: 2px solid #242424;
            background: #F0F0F0;
            margin-left: 24px;

            color: #242424;
            text-align: center;
            font-family: LXGW 975 HazyGo SC;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
        }

        .back_button:hover {
            opacity: 0.6;
        }

        .back_button:active {
            opacity: 0.8;
        }

        .submit_button {
            width: 180px;
            height: 64px;
            border-radius: 36px;
            border-width: 0;
            background: #242424;
            margin-left: 24px;

            color: #F0F0F0;
            text-align: center;
            font-family: LXGW 975 HazyGo SC;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
        }

        .submit_button:hover {
            opacity: 0.6;
        }

        .submit_button:active {
            opacity: 0.8;
        }
    }

    .input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        .title_style {
            color: #242424;
            font-weight: 600;
            flex-shrink: 0;
            line-height: 48px;
            margin-right: 4px;
            position: relative;
        }

        :deep(.el-input__wrapper) {
            background: transparent;
            border: 2px solid #242424;
            width: 386px;
            height: 48px;
            border-radius: 24px;

            .el-input__inner {
                font-size: 18px;
                font-weight: 600;
                color: #242424;
                margin: 0 16px 0 16px;
                background: transparent;
                text-align: center;
            }
        }

        :deep(.el-input__wrapper.is-focus) {
            box-shadow: none;
        }
    }

    .special_subject_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        width: 100%;
        padding: 0 48px 0 48px;
        margin-top: 44px;

        .card_list_bg {
            width: 530px;
            height: 468px;
            border-radius: 28px;
            border: 2px solid #242424;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .card_list_title {
                color: #242424;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                top: 0px;
                width: 100%;
                height: 96px;
                line-height: 96px;
                position: sticky;
                background-color: #F0F0F0;
                z-index: 10;
            }

            .card-list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 0 40px 20px 40px;

                li {
                    margin: 0 14px 16px 0;
                    position: relative;
                    cursor: pointer;

                    .post-wrapper {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        #posterHtml {
                            position: relative;
                            background: url('../assets/images/share-my.svg') no-repeat;
                            width: 100px;
                            height: 132px;
                            background-size: cover;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;

                        }

                        .post-link-logo {
                            width: 48px;
                            height: 48px;
                            border-radius: 24px;
                            margin-left: 16px;
                            object-fit: cover;
                        }

                        .post-link-name {
                            font-size: 8px;
                            color: #fff;
                            margin-left: 16px;
                            font-weight: 600;
                            margin-top: 8px;
                            text-align: center;
                        }

                        .custom_linkard_name {
                            width: 100%;
                            height: 20px;
                            margin-top: 4px;
                            border-radius: 10px;
                            background: #F0F0F0;
                            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                            color: #242424;
                            text-align: center;
                            font-size: 10px;
                            font-weight: 600;
                            line-height: 20px;
                        }

                        .handle_button {
                            width: 24px;
                            height: 24px;
                            border-radius: 16px;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            border-width: 0px;
                            background-color: #f0f0f0;
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            cursor: pointer;

                            span {
                                color: #242424;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }

                        .delete_card_button:hover {
                            opacity: 0.8;
                        }

                        .delete_card_button:active {
                            opacity: 0.5;
                        }
                    }
                }

                li:nth-child(4n) {
                    margin-right: 0;
                }

                li:hover .mask {
                    opacity: 1;
                }

                .add_button {
                    width: 100px;
                    height: 132px;
                    border-radius: 8px;
                    background: rgba(36, 36, 36, 0.20);
                    border-width: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img {
                        width: 64px;
                        height: 64px;
                    }
                }

                .add_button:hover {
                    opacity: 0.8;
                }

                .add_button:active {
                    opacity: 0.6;
                }
            }
        }

        .special_subject_model_bg {
            width: 360px;
            height: 468px;
            border-radius: 28px;
            border: 2px solid #242424;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            .special_subject_model_title {
                color: #242424;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                top: 0px;
                width: 100%;
                height: 96px;
                line-height: 96px;
                position: sticky;
                background-color: #F0F0F0;
                z-index: 10;
            }

            .model_list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 6px 20px 0 20px;

                li {
                    margin: 0 12px 20px 0;
                    position: relative;

                    .model_item_bg {
                        position: relative;
                        width: 150px;
                        height: 116px;
                        background-size: cover;
                        border-radius: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        flex-direction: column;
                        cursor: pointer;

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 20px;
                        }

                        .using_tips {
                            font-size: 12px;
                            color: #242424;
                            background-color: #F3EC3F;
                            font-weight: 500;
                            margin-top: 8px;
                            text-align: center;
                            width: 80px;
                            height: 20px;
                            line-height: 18px;
                            border-radius: 10px;
                            border: 2px solid #242424;
                            z-index: 6;
                            margin-bottom: 12px;
                        }
                    }

                    .model_item_bg_select {
                        border: 6px solid #F3EC3F;
                    }

                    .model_placeHoder {
                        width: 150px;
                        height: 116px;
                        border-radius: 20px;
                        background: #242424;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        span {
                            width: 88px;
                            height: 44px;
                            color: #F0F0F0;
                            font-size: 18px;
                            font-weight: 600;
                        }
                    }

                    .model_using_label {
                        font-size: 8px;
                        color: #fff;
                        margin-left: 16px;
                        font-weight: 600;
                        margin-top: 8px;
                        text-align: center;
                    }
                }

                li:nth-child(2n) {
                    margin-right: 0;
                }
            }

            .model_list_bottom_view {
                height: 96px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .page_button {
                    width: 64px;
                    height: 44px;
                    border-width: 0;
                    border-radius: 22px;
                    background-color: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                .page_button:hover {
                    opacity: 0.7;
                }

                .page_button:active {
                    opacity: 0.6;
                }

                .el-button.is-disabled {
                    opacity: 0.6;
                }

                .page_number {
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    width: 50%;
                    background-color: #F0F0F0;
                }
            }
        }

        .special_subject_model_bg::-webkit-scrollbar-track {
            display: none;
        }

        .special_subject_model_bg::-webkit-scrollbar {
            display: none;
        }

        .special_subject_model_bg::-webkit-scrollbar-thumb {
            display: none;
        }
    }

    .card_list_bg::-webkit-scrollbar-track {
        background-color: transparent;
        margin: 30px 0;
    }

    .card_list_bg::-webkit-scrollbar {
        width: 4px;
    }

    .card_list_bg::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: #242424;
        border-radius: 4px;
        margin: 30px 0;
    }

    .add_linkard_edit_view {
        background: rgba(230, 230, 230, 0.95);
        backdrop-filter: blur(8px);
        position: fixed;
        width: 100%;
        height: 100%;
        inset: 0;
        left: 0;
        top: 0;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .add_linkard_info_bg {
            width: 890px;
            height: 640px;
            border-radius: 40px;
            background: #F0F0F0;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .add_linkard_navi_bar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 32px;
                position: relative;

                .back_button {}

                span {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    color: #242424;
                    margin-left: 60px;
                }
            }

            .add_linkard_content {
                width: 794px;
                height: 394px;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: start;

                .left {
                    width: 298px;
                    height: 394px;
                    border-radius: 20px;
                    background-color: rgba(36, 36, 36, 0.20);

                    .selected_linkard_bg {
                        width: 298px;
                        height: 394px;
                        background-size: cover;
                        border-radius: 20px;
                        background: url('../assets/images/share-my.svg') no-repeat;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .selected_linkard_logo {
                            width: 120px;
                            height: 120px;
                            border-radius: 60px;
                            margin: 0 0 40px 26px;
                            object-fit: cover;
                        }

                        .selected_linkard_name {
                            color: #FFF;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                            margin-left: 20px;
                        }
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: start;
                    width: 448px;

                    .linkard_title {
                        color: #242424;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .top-site-info {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 700;
                        align-items: center;

                        span {
                            color: rgba(36, 36, 36, 0.50);
                            font-size: 18px;
                            font-weight: 600;
                        }

                        :deep(.el-select-option-info) {
                            margin-left: 8px;

                            .el-tooltip__trigger {
                                border-radius: 36px;
                                border: 2px solid #242424;
                                background: #f0f0f0;
                                width: 250px;
                                height: 48px;
                                margin: 0;


                                .el-select__selected-item span {
                                    font-size: 16px;
                                    color: #242424;
                                }
                            }
                        }
                    }

                    .add_linkard_name {
                        width: 348px;
                        margin-left: 24px;
                    }

                    .add_linkard_name_bg {
                        margin-top: 50px;
                    }
                }
            }
        }
    }
}
</style>