<template>
    <div class="home-wrapper">
        <div class="ent-content-wrapper">
            <div class="left_content_bg">
                <div class="top_content">
                    <div class="left_content">
                        <span class="enterprise_title">我的联卡</span>
                        <span class="card_number_tips">已用{{ entLinkardNum }}张/共{{ entLinkardTotal }}张</span>
                    </div>
                    <div class="top_right_bg">
                        <button
                            :class="['right_expire_card_button', expireEntLinkardTotal <= 0 ? 'right_expire_card_button_disable' : '']"
                            @click="expireCardButtonClick">
                            <span>已过期卡片</span>
                            <span>共{{ expireEntLinkardTotal }}张</span>
                        </button>
                        <button class="add_card_button" @click="addLinkardButtonClick(1)">
                            <img src="../assets/images/add_card_icon.svg" alt="" />
                            <span class="creat_tips_text">新建联卡</span>
                        </button>
                    </div>
                </div>
                <div class="card_list_bg">
                    <ul class="card-list">
                        <li v-for="(linkInfo, index) in tempEntCardList" :key="index">
                            <LinkardInfoItem :linkardInfo="linkInfo" @click="linkardItemClick(1, linkInfo)">
                                <div v-if="currentHoverLinkard == linkInfo" class="controller_manager_bg">
                                    <div class="controller_manager_info_bg">
                                        <span class="manager_name">{{ linkInfo.adminName }}</span>
                                        <span class="manager_phone">{{ linkInfo.userPhone ? linkInfo.userPhone :
                            '' }}</span>
                                    </div>
                                </div>
                                <button v-if="userLevel == 4 && isEnterpriseAdmin" class="manage_card_button"
                                    @click.stop="manageCardButtonClick(linkInfo)"
                                    @mouseover="manageCardButtonMouseOver(linkInfo)"
                                    @mouseleave="manageCardButtonMouseLeave(linkInfo)">
                                    <img class="manage_icon" src="../assets/images/linkard_manager_icon.svg" alt="" />
                                    <span v-if="currentHoverLinkard == linkInfo">转移</span>
                                </button>
                                <div v-if="linkInfo.remainingCnt < 100" class="dialogue_limit_alarm">
                                    <span>对话额度不足100次</span>
                                </div>
                            </LinkardInfoItem>
                        </li>
                    </ul>
                </div>
                <div class="linkcard_list_bottom_view">
                    <el-button :disabled="currentCardListPage <= 0" class="page_button" @click="pageLeft">
                        <img src="../assets/images/page_turning_left.svg" alt="" />
                    </el-button>
                    <span class="page_number">{{ currentCardListPage + 1 }}</span>
                    <el-button :disabled="(currentCardListPage + 1) * 4 >= linkardList.length" class="page_button"
                        @click="pageRight">
                        <img src="../assets/images/page_turning_right.svg" alt="" />
                    </el-button>
                </div>
            </div>
            <div class="right_content_bg">
                <div class="free_top_content">
                    <span class="free_experience_title_tips">免费体验联卡</span>
                    <span class="free_experience_count">已用{{ freeExperienceNum }}张/共{{ freeExperienceTotal }}张</span>
                </div>
                <button v-if="freeExperienceTotal - freeExperienceNum > 0" class="add_button"
                    @click="addLinkardButtonClick(0)">
                    <img src="../assets/images/circle_add_icon_black.svg" alt="" />
                    <span class="creat_tips_text">新建联卡</span>
                    <span class="residue_opportunity">剩余可用{{ freeExperienceTotal - freeExperienceNum }}张</span>
                </button>
                <LinkardInfoItem v-else :linkardInfo="freeExperienceCard"
                    @click="linkardItemClick(0, freeExperienceCard)">
                    <div v-if="freeExperienceCard.remainingCnt < 100" class="dialogue_limit_alarm">
                        <span>对话额度不足100次</span>
                    </div>
                </LinkardInfoItem>
            </div>
        </div>
        <AuthorityTransferView v-show="showAuthorityTransferView" :transferLinkardInfo="transferLinkardInfo"
            @dismissAuthorityTransferView="dismissAuthorityTransferView"></AuthorityTransferView>

        <el-dialog v-model="showExpireCardListView" :show-close="false" align-center center>
            <template #header>
                <ExpireCardListView :expireCardListItem="expireCardListItem" :isActivatable="isActivatable"
                    @expireCardListViewClose="expireCardListViewClose">
                </ExpireCardListView>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref, watch } from 'vue';
import { EnterpriseCardItem, linkardNet } from '@/net/linkardNet';
import { GenerateQRCodeLink } from '@/utils/serviceUtils';
import { useRouter } from 'vue-router';
import { NavigatorRoute } from '@/utils/const';
import { encryptPhoneNumber } from '@/utils/commonUtils';
import LinkardInfoItem from '../components/linkardInfoItem_Ent.vue';
import ExpireCardListView from '../components/expireCardListView.vue';
import AuthorityTransferView from '../components/authorityTransferView.vue';
import { useUserStore } from '@/stores/user';

export default defineComponent({
    name: 'Home',
    components: {
        LinkardInfoItem,
        ExpireCardListView,
        AuthorityTransferView
    },
    setup(props, { emit }) {

        let linkardList: Ref<EnterpriseCardItem[]> = ref([]);
        let tempEntCardList: Ref<EnterpriseCardItem[]> = ref([]);
        let currentCardListPage = ref(0);
        let expireCardListItem: Ref<any> = ref();
        let showExpireCardListView = ref(false);
        let showAuthorityTransferView = ref(false);
        let transferLinkardInfo: Ref<EnterpriseCardItem | null> = ref(null);
        let entLinkardNum = ref(0);
        let entLinkardTotal = ref(0);
        let expireEntLinkardTotal = ref(0);
        let freeExperienceNum = ref(0);
        let freeExperienceTotal = ref(0);
        let freeExperienceCard: Ref<EnterpriseCardItem> = ref({
            avatar: "",
            id: "",
            name: "",
            npcAvatar: "",
            tags: [],
            url: "",
            usedCnt: 0,
            remainingCnt: 1000,
            totalCnt: 0,
            adminName: '',
            adminPhone: '',
            userPhone: ''
        });
        const userStore = useUserStore();
        const isEnterpriseAdmin = ref(userStore.privilegeLevel.isEnterpriseAdmin);
        let currentHoverLinkard: Ref<EnterpriseCardItem | null> = ref(null);;
        let isActivatable = ref(false);
        const router = useRouter();
        const userLevel = ref(userStore.privilegeLevel.level);

        const getQrLink = (path: string) => {
            return GenerateQRCodeLink(path);
        };
        const expireCardButtonClick = () => {
            if (expireEntLinkardTotal.value <= 0) return;
            showExpireCardListView.value = true;
        };
        const expireCardListViewClose = () => {
            showExpireCardListView.value = false;
            enterpriseMyLinkardListRequest();
        };

        // creatType:1新建
        const addLinkardButtonClick = (type: number) => {
            userStore.currentPath = '';
            router.push({ path: NavigatorRoute.EDIT_CARD, query: { editType: 0, cardType: type } });
        };

        const linkardItemClick = (type: number, info: EnterpriseCardItem) => {
            router.push({ path: NavigatorRoute.EDIT_CARD, query: { editType: 1, cardType: type, linkardId: info.id } });
        };

        const manageCardButtonClick = (linkardInfo: EnterpriseCardItem) => {
            transferLinkardInfo.value = linkardInfo;
            showAuthorityTransferView.value = true;
        };

        const manageCardButtonMouseOver = (linkardInfo: EnterpriseCardItem) => {
            currentHoverLinkard.value = linkardInfo;
        };

        const manageCardButtonMouseLeave = (linkardInfo: EnterpriseCardItem) => {
            currentHoverLinkard.value = null;
        };

        const dismissAuthorityTransferView = (type: number) => {
            showAuthorityTransferView.value = false;
            if (type == 1) {
                enterpriseMyLinkardListRequest();
            }
        };

        const enterpriseMyLinkardListRequest = async () => {

            linkardNet.enterpriseMyLinkardList("1,2,3").then(
                (response) => {
                    response.forEach(element => {
                        if (element.type == 1) {
                            freeExperienceNum.value = element.linkardNum;
                            freeExperienceTotal.value = element.linkardTotal;
                            if (element.linkardList) {
                                element.linkardList.forEach(item => {
                                    if (item) {
                                        freeExperienceCard.value = item;
                                    }
                                });
                            }
                        }
                        else if (element.type == 2) {
                            linkardList.value = [];
                            entLinkardNum.value = element.linkardNum;
                            entLinkardTotal.value = element.linkardTotal;
                            isActivatable.value = !element.full;
                            if (element.linkardList) {
                                element.linkardList.forEach(item => {
                                    if (item) {
                                        if (item.adminPhone) {
                                            item.userPhone = encryptPhoneNumber(item.adminPhone);
                                        }
                                        linkardList.value.push(item);
                                    }
                                });
                            }

                            if (linkardList.value.length > 4) {
                                tempEntCardList.value = linkardList.value.slice(currentCardListPage.value * 4, 4);
                            }
                            else {
                                tempEntCardList.value = linkardList.value;
                            }
                        } else if (element.type == 3) {
                            expireCardListItem.value = element;
                            expireEntLinkardTotal.value = element.linkardTotal;
                        }
                    });
                },
                (ex) => {
                    // ElMessage.error('获取卡柜信息失败');
                },
            );
        };

        const pageLeft = () => {
            currentCardListPage.value -= 1;
            tempEntCardList.value = linkardList.value.slice(currentCardListPage.value * 4, currentCardListPage.value * 4 + 4);
        };

        const pageRight = () => {
            currentCardListPage.value += 1;
            tempEntCardList.value = linkardList.value.slice(currentCardListPage.value * 4, currentCardListPage.value * 4 + 4);
        };

        watch(() => userStore.privilegeLevel, (newValue, oldValue) => {
            isEnterpriseAdmin.value = newValue.isEnterpriseAdmin;
            userLevel.value = userStore.privilegeLevel.level;
        });

        onMounted(() => {
            enterpriseMyLinkardListRequest();
        });
        return {
            getQrLink,
            expireEntLinkardTotal,
            entLinkardNum,
            entLinkardTotal,
            linkardList,
            freeExperienceTotal,
            freeExperienceCard,
            isActivatable,
            showAuthorityTransferView,
            showExpireCardListView,
            transferLinkardInfo,
            currentHoverLinkard,
            freeExperienceNum,
            isEnterpriseAdmin,
            expireCardListItem,
            currentCardListPage,
            tempEntCardList,
            userLevel,
            pageLeft,
            pageRight,
            manageCardButtonMouseLeave,
            manageCardButtonMouseOver,
            manageCardButtonClick,
            dismissAuthorityTransferView,
            addLinkardButtonClick,
            expireCardButtonClick,
            linkardItemClick,
            expireCardListViewClose
        };
    },
});

</script>

<style lang="less" scoped>
.home-wrapper {
    width: 100%;
    height: calc(100vh - 168px);
    padding: 0px 80px 0 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .ent-content-wrapper {
        top: 40px;
        left: 0;
        right: 0;
        bottom: 60px;
        display: flex;
        position: relative;
        overflow: hidden;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        .left_content_bg {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            position: relative;

            .top_content {
                width: 100%;
                padding: 0 22px 0 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .left_content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;

                    .enterprise_title {
                        color: #242424;
                        text-align: center;
                        font-size: 24px;
                        font-weight: 600;
                        position: sticky;
                    }

                    .card_number_tips {
                        margin: 0 0 2px 10px;
                        color: #242424;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                        position: sticky;
                    }

                }

                .top_right_bg {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .right_expire_card_button {
                        width: 320px;
                        height: 48px;
                        border-radius: 24px;
                        color: #242424;
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                        position: sticky;
                        border: 2px solid #242424;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 32px 0 32px;
                        cursor: pointer;
                    }

                    .right_expire_card_button_disable {
                        color: #c0c4cc !important;
                        border: 2px solid #c0c4cc !important;
                    }

                    .right_expire_card_button:hover {
                        opacity: 0.7;
                    }

                    .right_expire_card_button:active {
                        opacity: 0.5;
                    }

                    .add_card_button {
                        width: 160px;
                        height: 48px;
                        border-radius: 24px;
                        border: 3px solid #242424;
                        background: #F3EC3F;
                        color: #242424;
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 22px 0 22px;
                        margin-left: 36px;
                        cursor: pointer;

                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    .add_card_button:hover {
                        opacity: 0.7;
                    }

                    .add_card_button:active {
                        opacity: 0.5;
                    }
                }
            }

            .card_list_bg {
                width: 1160px;
                margin-top: 48px;
                overflow: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                overflow-x: hidden;

                .card-list {
                    width: 100%;
                    height: 390px;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 10px;

                    li {
                        margin-right: 12px;
                        position: relative;
                    }

                    li:nth-child(5n) {
                        margin-right: 0;
                    }

                    li:hover .mask {
                        opacity: 1;
                    }
                }
            }

            .linkcard_list_bottom_view {
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 40px;

                .page_button {
                    width: 64px;
                    height: 44px;
                    border-width: 0;
                    border-radius: 22px;
                    background-color: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                .page_button:hover {
                    opacity: 0.7;
                }

                .page_button:active {
                    opacity: 0.6;
                }

                .el-button.is-disabled {
                    opacity: 0.6;
                }

                .page_number {
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    width: 140px;
                }
            }

            .card_list_bg::-webkit-scrollbar-track {
                background-color: transparent;
                margin: 30px 0;
            }

            .card_list_bg::-webkit-scrollbar {
                width: 4px;
            }

            .card_list_bg::-webkit-scrollbar-thumb {
                width: 4px;
                background-color: #242424;
                border-radius: 4px;
                margin: 30px 0;
            }
        }

        .right_content_bg {
            width: 400px;
            flex-shrink: 0;
            text-align: center;
            border-radius: 40px;
            background: transparent;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .free_top_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0px 0 55px 0;

                .free_experience_title_tips {
                    color: #242424;
                    text-align: center;
                    font-size: 24px;
                    font-weight: 600;
                    position: sticky;
                }

                .free_experience_count {
                    color: #242424;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 600;
                    position: sticky;
                }

            }

        }

        .add_button {
            width: 270px;
            height: 360px;
            border-radius: 40px;
            background-color: #F0F0F0;
            border-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 64px;
                height: 64px;
            }

            .creat_tips_text {
                color: #242424;
                font-size: 18px;
                font-weight: 600;
                margin-top: 12px;
            }

            .residue_opportunity {
                color: #242424;
                font-size: 12px;
                font-weight: 600;
            }
        }

        .add_button:hover {
            opacity: 0.8;
        }

        .add_button:active {
            opacity: 0.6;
        }
    }

    .controller_manager_bg {
        border-radius: 40px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(36, 36, 36, 0.90);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;

        .controller_manager_info_bg {
            width: 180px;
            height: 60px;
            border-radius: 18px;
            background-color: #F0F0F0;
            font-weight: 600;
            color: #242424;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;


            .manager_name {
                font-size: 14px;
            }

            .manager_phone {
                font-size: 12px;
            }

        }

    }

    .manage_card_button {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 36px;
        height: 36px;
        background-color: #F0F0F0;
        border-width: 0;
        border-radius: 18px;

        transition-property: width;
        transition-duration: 0.2s;
        transition-timing-function: linear;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: center;
        cursor: pointer;

        .manage_icon {
            width: 22px;
            height: 22px;
        }

        span {
            left: 20px;
            position: absolute;
            color: #242424;
            font-size: 12px;
            font-weight: 600;
            width: 40px;
        }
    }

    .manage_card_button:hover {
        width: 60px;
        justify-content: flex-start;
    }

    .dialogue_limit_alarm {
        position: absolute;
        bottom: 30px;
        background-color: #E70000;
        width: 128px;
        height: 24px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span {
            color: #f0f0f0;
            font-size: 12px;
            font-weight: 600;
        }
    }

    :deep(.el-overlay) {
        background: rgba(230, 230, 230, 0.95);
        backdrop-filter: blur(6px);

        .el-dialog {
            width: auto;
            border-radius: 8px;
            border-width: 0px !important;
            background-color: transparent !important;
            box-shadow: none;

            .el-dialog__header.show-close {
                padding: 0;
            }

            .el-dialog__headerbtn {
                top: 6px;
                right: 6px;

                .el-dialog__close {
                    font-size: 24px;
                }
            }
        }
    }
}
</style>
