<template>
    <div class="sound_info_setting">
        <div class="top_view">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
            <button class="submit_button" @click="submitClick">确认</button>
        </div>
        <div class="sound-add-wrapper">
            <div class="default_sound_setting_bg">
                <button
                    :class="['default_sound_button', currentSelVoiceLevel == item.type ? 'selected_sound_button' : '']"
                    v-for="item, index in voiceTypeList" :key="index" @click="voiceTypeButtonClick(item)">{{ item.name
                    }}</button>
            </div>

            <div class="sound_list_bg">
                <ul class="sound_list">
                    <li v-for="(soundItem, index) in selectedVoiceList" :key="index">
                        <div :class="['sound_item', selectedItem == soundItem ? 'sound_item_selected' : 'sound_item_normal', !soundItem.allow ? 'sound_item_disable' : '']"
                            @click="soundItemClick(soundItem)">
                            <span class="sound_name">{{ soundItem.name }}</span>
                            <button @click.stop="soundPlayingClick(soundItem)" class="play_icon_button">
                                <img :src="currentPlayingItem == soundItem ? require('../assets/images/sound_setting_item_pause.svg') : require('../assets/images/sound_setting_item_play.svg')"
                                    alt="">
                            </button>
                        </div>
                    </li>
                </ul>
                <audio ref="audioPlayer" @ended="audioEnded">
                </audio>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { VoiceItem } from '@/net/linkardNet';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import BackButtonItem from "./backButtonItem.vue";

export default defineComponent({
    name: 'BaseInfoView',
    props: {
        voiceList: {
            type: Array as () => Array<VoiceItem>,
            default: []
        },
        voiceId: {
            default: ""
        }
    },
    components: {
        BackButtonItem
    },
    setup(props, { emit }) {

        let voiceTypeList = ref([
            {
                name: "女声",
                type: 2,
            }, {
                name: "男声",
                type: 1,
            }, {
                name: "童声",
                type: 3,
            }
        ]);
        let currentSelVoiceLevel = ref(2);
        let showSoundRecordingView = ref(false);
        let selectedItem = ref({ url: '' });
        let currentPlayingItem: Ref<VoiceItem | null> = ref(null);
        let selectedVoiceList: Ref<VoiceItem[]> = ref([]);
        let audioPlayer = ref();
        let isPlaying = ref(false);

        const retrievalVoiceTypeList = () => {
            selectedVoiceList.value = [];
            props.voiceList.forEach(element => {
                if (element.type == currentSelVoiceLevel.value) {
                    selectedVoiceList.value.push(element);
                }
            });
        };

        const backButtonClick = () => {
            emit("dismisSoundSetting", null);
        };
        const submitClick = () => {
            emit("dismisSoundSetting", selectedItem.value);
        };
        const voiceTypeButtonClick = (item: any) => {
            currentSelVoiceLevel.value = item.type;
            retrievalVoiceTypeList();
        };

        const soundItemClick = (item: VoiceItem) => {
            if (!item.allow) return;
            selectedItem.value = item;
        };

        const soundPlayingClick = (item: VoiceItem) => {
            if (isPlaying.value) {
                audioPlayer.value.pause();
                isPlaying.value = false;
                currentPlayingItem.value = null;
                audioPlayer.value.currentTime = 0;
            } else {
                currentPlayingItem.value = item;
                audioPlayer.value.src = currentPlayingItem.value.url;
                audioPlayer.value.play();
                isPlaying.value = true;
            }
        };
        const audioEnded = () => {
            audioPlayer.value.pause();
            currentPlayingItem.value = null;
            isPlaying.value = false;
        };


        onMounted(() => {
            for (var i = 0; i < props.voiceList.length; i++) {
                const item = props.voiceList[i];
                if (props.voiceId && props.voiceId == item.id) {
                    selectedItem.value = item;
                    currentSelVoiceLevel.value = item.type;
                    break;
                }
            }
            retrievalVoiceTypeList();
        });

        return {
            voiceTypeList,
            currentSelVoiceLevel,
            showSoundRecordingView,
            selectedItem,
            currentPlayingItem,
            selectedVoiceList,
            audioPlayer,
            isPlaying,
            backButtonClick,
            submitClick,
            voiceTypeButtonClick,
            soundItemClick,
            soundPlayingClick,
            audioEnded
        };
    },
});

</script>

<style lang="less" scoped>
.sound_info_setting {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {}

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
            margin-left: 60px;
        }
    }

    .sound-add-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        .default_sound_setting_bg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .default_sound_button {
                height: 64px;
                width: 120px;
                border-radius: 32px;
                border: 2px solid #242424;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 24px;
                cursor: pointer;
            }

            .selected_sound_button {
                background-color: #F3EC3F;
            }
        }

        .sound_list_bg {
            width: 584px;
            height: 466px;
            margin-left: 40px;
            border: 2px solid #242424;
            border-radius: 28px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 30px 0;

            .sound_list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 0 20px 20px 40px;

                li {
                    margin: 0 30px 24px 0;
                    position: relative;

                    .sound_item {
                        width: 144px;
                        height: 64px;
                        border-radius: 32px;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: center;

                        font-size: 12px;
                        color: #242424;
                        font-weight: 500;
                        cursor: pointer;

                        .sound_name {}

                        .play_icon_button {
                            width: 36px;
                            height: 36px;
                            background-color: transparent;
                            border-radius: 18px;
                            border-width: 0;
                            display: flex;
                            margin-left: 10px;
                            align-items: center;

                            img {
                                width: 36px;
                                height: 36px;
                            }
                        }
                    }

                    .sound_item_normal {
                        border: 2px solid #242424;
                        background-color: #F0F0F0;
                        transition: color 0.3s, background 0.3s;
                    }

                    .sound_item_normal:hover {
                        color: #f0f0f0;
                        background-color: #242424;
                    }

                    .sound_item_selected {
                        border: 4px solid #242424;
                        background: #F3EC3F;
                    }

                    .sound_item_disable {
                        color: #c0c4cc !important;
                        border: 2px solid #c0c4cc !important;

                        span {
                            color: #c0c4cc !important;
                        }
                    }

                    .sound_item_disable:hover {
                        background: transparent;
                    }
                }

                li:nth-child(3n) {
                    margin-right: 0;
                }

                li:hover .mask {
                    opacity: 1;
                }
            }
        }

        .sound_list_bg::-webkit-scrollbar-track {
            background-color: transparent;
            margin: 30px 0;
        }

        .sound_list_bg::-webkit-scrollbar {
            width: 4px;
        }

        .sound_list_bg::-webkit-scrollbar-thumb {
            width: 4px;
            background-color: #242424;
            border-radius: 4px;
            margin: 30px 0;
        }
    }
}
</style>
