<template>
    <div class="home-wrapper">
        <div class="management_center_bg">
            <div class="top_manager_view">
                <div class="manager_center_view_bg">
                    <span class="manager_center_tips">管理中心</span>
                    <div :class="['version_type_default', userLevel == 4 ? 'version_type_ent' : 'version_type_other']">
                        {{ userLevelTips }}</div>
                </div>
                <button class="edit_button_bg" @click="enterpriseEditUserInfoClick">
                    <span class="edit_info_tips">编辑账号信息</span>
                    <div v-if="userLevel == 4"
                        :class="['authority_type', isEnterpriseAdmin ? 'authority_type_admin' : '']">{{
                        isEnterpriseAdmin ? '超管' : '普管' }} </div>
                </button>
            </div>
            <div class="center_content_view">
                <div class="left_view">
                    <div class="linkard_limit_title_bg">
                        <div class="linkard_limit_title">联卡额度</div>
                        <div class="linkard_limit_title_right_bg">
                            <div class="count_tips">已建{{ linkardLimitInfo.linkardNum }}张 / 剩余可用{{
                        linkardLimitInfo.linkardTotal - linkardLimitInfo.linkardNum }}张</div>
                        </div>
                    </div>
                    <div class="linkard_limit_list_bg">
                        <div class="linkard_limit_list_title_bg">
                            <span class="linkard_name_title">联卡名称</span>
                            <span class="link_url_title">联卡链接</span>
                            <span class="dialogue_use_title">对话消耗</span>
                            <span class="manager_user_name_title">管理员</span>
                        </div>
                        <ul class="linkard_limit_list">
                            <li v-for="(limitItem, index) in tempLinkardLimitList" :key="index">
                                <div class="linkard_limit_item">
                                    <span class="linkard_name">{{ limitItem.name }}</span>
                                    <span class="link_url">{{ baseUrl + '/' + limitItem.link }}</span>
                                    <span class="dialogue_use">{{ limitItem.usedCnt + "/" + (limitItem.remainingCnt +
                        limitItem.usedCnt) }}</span>
                                    <span class="manager_user_name">{{ limitItem.adminName }}</span>
                                </div>
                            </li>
                        </ul>
                        <div class="linkard_limit_list_bottom_view">
                            <el-button :disabled="currentLinkardLimitPage <= 0" class="page_button" @click="pageLeft">
                                <img src="../assets/images/page_turning_left_white.svg" alt="" />
                            </el-button>
                            <span class="page_number">{{ currentLinkardLimitPage + 1 }}</span>
                            <el-button
                                :disabled="(currentLinkardLimitPage + 1) * 5 >= linkardLimitInfo.linkardLimitList.length"
                                class="page_button" @click="pageRight">
                                <img src="../assets/images/page_turning_right_white.svg" alt="" />
                            </el-button>
                        </div>
                    </div>
                </div>
                <div ref="rightContentView" class="right_view">
                    <div class="manager_list_title_bg">
                        <div class="manager_list_title">权限管理</div>
                        <el-button v-if="isEnterpriseAdmin" class="add_manager_user_button"
                            @click="addManagerUserButtonClick">
                            <img class="add_icon" src="../assets/images/add_manager_icon.svg" alt="">
                            <span>添加</span>
                        </el-button>
                        <div v-else></div>
                    </div>
                    <div class="manager_list_bg">
                        <ul class="manager_list">
                            <li v-for="(managerItem, index) in tempManagerList" :key="index">
                                <div class="manager_list_item">
                                    <span class="user_name">{{ managerItem.userName }}</span>
                                    <span v-if="userLevel == 4"
                                        :class="['authority_type', managerItem.isEnterpriseAdmin ? 'authority_type_admin' : '']">{{
                        managerItem.isEnterpriseAdmin ? '超管' : '普管' }} </span>
                                    <span class="user_phone">{{ managerItem.encryptUserPhone }}</span>
                                    <el-button :disabled="!isEnterpriseAdmin" v-if="!managerItem.isEnterpriseAdmin"
                                        class="delete_button"
                                        @click="deleteManagerUserButtonClick(managerItem)">删除</el-button>
                                    <div v-else class="delete_button_placehoder"></div>
                                </div>
                            </li>
                        </ul>
                        <div class="manager_list_bottom_view">
                            <el-button :disabled="managerListPage <= 0" class="page_button" @click="managerPageLeft">
                                <img src="../assets/images/page_turning_left_white.svg" alt="" />
                            </el-button>
                            <span class="page_number">{{ managerListPage + 1 }}</span>
                            <el-button :disabled="(managerListPage + 1) * 5 >= managerList.length" class="page_button"
                                @click="managerPageRight">
                                <img src="../assets/images/page_turning_right_white.svg" alt="" />
                            </el-button>
                        </div>
                    </div>
                    <span v-if="!isEnterpriseAdmin" class="regular_administrator_tips">普通管理员无法修改权限</span>
                </div>
            </div>
        </div>

        <el-dialog v-model="showAddManagerView" :show-close="false" align-center center>
            <template #header>
                <AddManagerView dialogText="添加普通管理员" @dismissAddManagerView="dismissAddManagerView">
                </AddManagerView>
            </template>
        </el-dialog>


        <el-dialog v-model="showDeleteManagerView" :show-close="false" align-center center>
            <template #header>
                <DeleteManagerView @dismissDeleteManagerView="dismissDeleteManagerView">
                </DeleteManagerView>
            </template>
        </el-dialog>

        <el-dialog v-model="showEnterpriseEditUserInfoView" :show-close="false" align-center center>
            <template #header>
                <EnterpriseEditUserInfoView @dismissEnterpriseEditUserInfoView="dismissEnterpriseEditUserInfoView">
                </EnterpriseEditUserInfoView>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts">
import { linkardNet } from '@/net/linkardNet';
import { encryptPhoneNumber } from '@/utils/commonUtils';
import { ElMessage } from 'element-plus';
import { Ref, defineComponent, onMounted, ref, watch } from 'vue';
import AddManagerView from "../components/addManagerView.vue";
import DeleteManagerView from "../components/deleteManagerView.vue";
import EnterpriseEditUserInfoView from "../components/enterpriseEditUserInfoView.vue";
import { useUserStore } from '@/stores/user';
import { UserLevelType } from '@/utils/const';
import { HttpResponse } from '@/net/http';

export default defineComponent({
    name: 'BaseInfoView',
    props: {

    },
    components: {
        AddManagerView,
        DeleteManagerView,
        EnterpriseEditUserInfoView
    },
    setup(props, { emit }) {

        const userStore = useUserStore();

        let linkardLimitInfo: Ref<any> = ref({
            linkardTotal: 0,
            linkardNum: 0,
            linkardLimitList: [

            ],
        });
        let managerList: Ref<any[]> = ref([]);
        let currentLinkardLimitPage = ref(0);
        let tempLinkardLimitList: Ref<any[]> = ref([]);
        let managerListPage = ref(0);
        let tempManagerList: Ref<any[]> = ref([]);
        let currentDeleteManager: any = null;
        let showAddManagerView = ref(false);
        let showDeleteManagerView = ref(false);
        let showEnterpriseEditUserInfoView = ref(false);
        let isEnterpriseAdmin = ref(false);
        let userLevel = ref(-1);
        let userLevelTips = ref('');
        let rightContentView = ref();
        let baseUrl = process.env.VUE_APP_MOBILE_URL;

        const pageLeft = () => {
            currentLinkardLimitPage.value -= 1;
            tempLinkardLimitList.value = linkardLimitInfo.value.linkardLimitList.slice(currentLinkardLimitPage.value * 5, currentLinkardLimitPage.value * 5 + 5);
        };

        const pageRight = () => {
            currentLinkardLimitPage.value += 1;
            tempLinkardLimitList.value = linkardLimitInfo.value.linkardLimitList.slice(currentLinkardLimitPage.value * 5, currentLinkardLimitPage.value * 5 + 5);
        };

        const managerPageLeft = () => {
            managerListPage.value -= 1;
            tempManagerList.value = managerList.value.slice(managerListPage.value * 5, managerListPage.value * 5 + 5);
        };

        const managerPageRight = () => {
            managerListPage.value += 1;
            tempManagerList.value = managerList.value.slice(managerListPage.value * 5, managerListPage.value * 5 + 5);
        };

        const addManagerUserButtonClick = () => {
            showAddManagerView.value = true;
        };

        const dismissAddManagerView = (type: number, userName: string, userPhone: string) => {
            showAddManagerView.value = false;
            if (type == 0) return;
            enterpriseOrdAdminAdd(userName, userPhone);
        };

        const deleteManagerUserButtonClick = (item: any) => {
            currentDeleteManager = item;
            showDeleteManagerView.value = true;
        };

        const dismissDeleteManagerView = (type: number) => {
            showDeleteManagerView.value = false;
            if (type == 0) return;
            enterpriseOrdAdminDelete();
        };

        const enterpriseEditUserInfoClick = () => {
            showEnterpriseEditUserInfoView.value = true;
        };


        const dismissEnterpriseEditUserInfoView = () => {
            showEnterpriseEditUserInfoView.value = false;
        };

        const enterpriseConsumptionList = async () => {
            linkardNet.enterpriseConsumptionList().then(
                (response) => {
                    linkardLimitInfo.value.linkardNum = response.linkardNum;
                    linkardLimitInfo.value.linkardTotal = response.linkardTotal;
                    linkardLimitInfo.value.linkardLimitList = response.linkardList;
                    if (linkardLimitInfo.value.linkardLimitList.length > 5) {
                        tempLinkardLimitList.value = linkardLimitInfo.value.linkardLimitList.slice(currentLinkardLimitPage.value * 5, 5);
                    }
                    else {
                        tempLinkardLimitList.value = linkardLimitInfo.value.linkardLimitList;
                    }
                },
                (ex) => {

                },
            );
        };

        const enterpriseAdminList = async () => {
            linkardNet.enterpriseAdminList().then(
                (response) => {
                    managerList.value = response;
                    managerList.value.forEach(item => {
                        item.encryptUserPhone = encryptPhoneNumber(item.userPhone);
                    });
                    if (managerList.value.length > 5) {
                        tempManagerList.value = managerList.value.slice(currentLinkardLimitPage.value * 5, 5);
                    }
                    else {
                        tempManagerList.value = managerList.value;
                    }
                },
                (ex) => {

                },
            );
        };

        const enterpriseOrdAdminAdd = async (userName: string, userPhone: string) => {
            const param = {
                userName: userName,
                userPhone: "+86," + userPhone
            };

            linkardNet.enterpriseOrdAdminAdd(param).then(
                (response) => {
                    ElMessage.success('添加成功');
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(ex.message);
                },
            );
        };

        const enterpriseOrdAdminDelete = async () => {
            const param = {
                userName: currentDeleteManager.userName,
                userPhone: currentDeleteManager.userPhone
            };

            linkardNet.enterpriseOrdAdminDelete(param).then(
                (response) => {
                    ElMessage.success('删除成功');
                    enterpriseAdminList();
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(ex.message);
                },
            );
        };

        const setUserPrivilegeLevel = (privilegeLevel: any) => {
            userLevel.value = privilegeLevel.level;
            if (privilegeLevel.isEnterpriseAdmin) {
                isEnterpriseAdmin.value = true;
            }
            switch (userLevel.value) {
                case UserLevelType.TRIAL:
                    userLevelTips.value = "体验版";
                    break;
                case UserLevelType.ADVANCED:
                    userLevelTips.value = "进阶版";
                    break;
                case UserLevelType.PROFESSIONAL:
                    userLevelTips.value = "专业版";
                    break;
                case UserLevelType.ENT:
                    userLevelTips.value = "企业版";
                    break;
            }

            if (userLevel.value != UserLevelType.ENT) {
                rightContentView.value.style.display = 'none';
            }
        };

        watch(() => userStore.privilegeLevel, (newValue, oldValue) => {
            if (Object.keys(newValue).length != 0) {
                setUserPrivilegeLevel(newValue);
            }
        });

        onMounted(() => {
            if (Object.keys(userStore.privilegeLevel).length != 0) {
                setUserPrivilegeLevel(userStore.privilegeLevel);
            }
            enterpriseConsumptionList();
            enterpriseAdminList();
        });
        return {
            linkardLimitInfo,
            managerList,
            currentLinkardLimitPage,
            tempLinkardLimitList,
            managerListPage,
            tempManagerList,
            showAddManagerView,
            showDeleteManagerView,
            showEnterpriseEditUserInfoView,
            isEnterpriseAdmin,
            userLevel,
            userLevelTips,
            rightContentView,
            baseUrl,
            enterpriseEditUserInfoClick,
            pageLeft,
            pageRight,
            addManagerUserButtonClick,
            deleteManagerUserButtonClick,
            managerPageLeft,
            managerPageRight,
            dismissAddManagerView,
            dismissDeleteManagerView,
            dismissEnterpriseEditUserInfoView,
        };
    },
});

</script>

<style lang="less" scoped>
.home-wrapper {
    width: 100%;
    height: calc(100vh - 168px);
    padding: 0 80px 0 80px;

    .edit-header-wrapper {
        flex-grow: 1;
    }

    .management_center_bg {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .top_manager_view {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .manager_center_view_bg {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                width: 440px;
                height: 64px;
                border-radius: 36px;
                background-color: #F0F0F0;

                .manager_center_tips {
                    color: #242424;
                    font-size: 32px;
                    font-weight: 600;
                }

                .version_type_default {
                    width: 80px;
                    height: 32px;
                    border-radius: 20px;
                    color: #F0F0F0;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 600;
                    margin-left: 30px;
                    line-height: 32px;
                }

                .version_type_ent {
                    background-color: #0D55FF;
                }

                .version_type_other {
                    background-color: #242424;
                }
            }

            .edit_button_bg {

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                width: 318px;
                height: 64px;
                border-radius: 32px;
                border-width: 0;
                background-color: #242424;
                cursor: pointer;

                .edit_info_tips {
                    color: #f0f0f0;
                    font-size: 18px;
                    font-weight: 600;
                }

                .authority_type {
                    padding: 0 20px;
                    height: 24px;
                    border-radius: 20px;
                    background: #FF900D;
                    color: #F0F0F0;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 600;
                    margin-left: 40px;
                    line-height: 24px;
                }

                .authority_type_admin {
                    background: #0D55FF;
                }
            }
        }

        .center_content_view {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 32px;

            .left_view {
                height: 634px;
                width: 1100px;
                border-radius: 40px;
                background: #F0F0F0;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .linkard_limit_title_bg {
                    margin-top: 40px;
                    width: 810px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    color: #242424;
                    font-size: 24px;
                    font-weight: 600;

                    .linkard_limit_title {}

                    .linkard_limit_title_right_bg {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-left: 8px;
                        }

                        .count_tips {
                            width: 210px;
                            height: 40px;
                            border-radius: 32px;
                            border: 2px solid #242424;
                            font-size: 12px;
                            text-align: center;
                            margin-left: 16px;
                            line-height: 40px;
                        }
                    }

                }

                .linkard_limit_list_bg {
                    width: 810px;
                    height: 472px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    margin-top: 28px;
                    border-radius: 40px;
                    border: 2px solid #242424;

                    .linkard_limit_list_title_bg {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        justify-content: space-between;
                        color: #242424;
                        font-size: 18px;
                        font-weight: 600;
                        padding: 48px 0 48px 40px;

                        .linkard_name_title {
                            width: 120px;
                            text-align: left;
                        }

                        .link_url_title {
                            margin-left: 10px;
                            width: 320px;
                            text-align: left;
                        }

                        .dialogue_use_title {
                            margin-left: 10px;
                            width: 80px;
                            text-align: center;
                        }

                        .manager_user_name_title {
                            margin-left: 10px;
                            width: 100px;
                            text-align: left;
                        }
                    }

                    .linkard_limit_list {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;

                        li {
                            width: 100%;
                            padding: 0 0 16px 40px;
                            position: relative;

                            .linkard_limit_item {
                                width: 100%;
                                height: 40px;
                                font-size: 18px;
                                font-weight: 600;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;

                                .linkard_name {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 120px;
                                    text-align: left;
                                }

                                .link_url {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    margin-left: 10px;
                                    width: 320px;
                                    text-align: left;
                                }

                                .dialogue_use {
                                    margin-left: 10px;
                                    width: 80px;
                                    text-align: center;
                                }

                                .manager_user_name {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    margin-left: 10px;
                                    width: 100px;
                                    text-align: left;
                                }
                            }

                            .linkard_limit_item_normal {
                                background: #F0F0F0;

                                span {
                                    color: #242424;
                                }
                            }
                        }

                        li:nth-child(1n) {
                            margin-right: 0;
                        }
                    }

                    .linkard_limit_list_bottom_view {
                        height: 60px;
                        width: 100%;
                        position: absolute;
                        bottom: 20px;
                        left: 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        .page_button {
                            width: 64px;
                            height: 44px;
                            border-width: 0;
                            border-radius: 22px;
                            background-color: #242424;
                            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }

                        .page_button:hover {
                            opacity: 0.7;
                        }

                        .page_button:active {
                            opacity: 0.6;
                        }

                        .el-button.is-disabled {
                            opacity: 0.6;
                        }

                        .page_number {
                            color: #242424;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                            width: 50%;
                            background-color: #F0F0F0;
                        }
                    }
                }

                .linkard_limit_list_bg::-webkit-scrollbar-track {
                    display: none;
                }

                .linkard_limit_list_bg::-webkit-scrollbar {
                    display: none;
                }

                .linkard_limit_list_bg::-webkit-scrollbar-thumb {
                    display: none;
                }
            }

            .right_view {
                margin-left: 36px;
                flex-grow: 1;
                height: 634px;
                border-radius: 40px;
                background: #F0F0F0;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .manager_list_title_bg {
                    margin-top: 40px;
                    width: 480px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    color: #242424;
                    font-size: 24px;
                    font-weight: 600;

                    .manager_list_title {}

                    .add_manager_user_button {
                        width: 140px;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-radius: 32px;
                        background-color: #242424;
                        color: #f0f0f0;
                        font-weight: 600;
                        font-size: 18px;
                        padding: 0;
                        .add_icon {
                            width: 18px;
                            height: 18px;
                            margin-right: 16px;
                        }
                    }

                }

                .manager_list_bg {
                    width: 480px;
                    height: 472px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    margin-top: 28px;
                    border-radius: 40px;
                    border: 2px solid #242424;

                    .manager_list {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 30px;

                        li {
                            width: 100%;
                            padding: 0 36px 30px 36px;
                            position: relative;

                            .manager_list_item {
                                width: 100%;
                                height: 40px;
                                font-size: 18px;
                                font-weight: 600;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                color: #242424;

                                .el-button:hover {
                                    color: #242424;
                                }

                                .user_name {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: 60px;
                                }

                                .authority_type {
                                    width: 60px;
                                    height: 24px;
                                    line-height: 24px;
                                    border-radius: 20px;
                                    background: #FF900D;
                                    color: #f0f0f0;
                                    text-align: center;
                                    font-size: 12px;
                                }

                                .authority_type_admin {
                                    background: #0D55FF;
                                }

                                .user_phone {}

                                .delete_button {
                                    width: 88px;
                                    height: 40px;
                                    border-radius: 20px;
                                    font-size: 18px;
                                    border: 2px solid #242424;
                                    background-color: #F0F0F0;
                                    padding: 0;
                                }

                                .delete_button:hover {
                                    opacity: 0.8;
                                }

                                .delete_button:active {
                                    opacity: 0.5;
                                }

                                .delete_button:hover {
                                    opacity: 0.8;
                                }

                                .delete_button:active {
                                    opacity: 0.5;
                                }

                                .delete_button_placehoder {
                                    width: 88px;
                                    height: 40px;
                                }
                            }
                        }

                        li:nth-child(1n) {
                            margin-right: 0;
                        }
                    }

                    .manager_list_bottom_view {
                        height: 60px;
                        width: 100%;
                        position: absolute;
                        bottom: 20px;
                        left: 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        .page_button {
                            width: 64px;
                            height: 44px;
                            border-width: 0;
                            border-radius: 22px;
                            background-color: #242424;
                            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }

                        .page_button:hover {
                            opacity: 0.7;
                        }

                        .page_button:active {
                            opacity: 0.6;
                        }

                        .el-button.is-disabled {
                            opacity: 0.6;
                        }

                        .page_number {
                            color: #242424;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                            width: 50%;
                            background-color: #F0F0F0;
                        }
                    }
                }

                .manager_list_bg::-webkit-scrollbar-track {
                    display: none;
                }

                .manager_list_bg::-webkit-scrollbar {
                    display: none;
                }

                .manager_list_bg::-webkit-scrollbar-thumb {
                    display: none;
                }

                .regular_administrator_tips {
                    margin-top: 10px;
                    color: #E70000;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }

    :deep(.el-overlay) {
        background: rgba(230, 230, 230, 0.95);
        backdrop-filter: blur(6px);

        .el-dialog {
            border-radius: 8px;
            border-width: 0px !important;
            background-color: transparent !important;
            box-shadow: none;
            width: auto;

            .el-dialog__header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .el-dialog__header.show-close {
                padding: 0;
            }

            .el-dialog__headerbtn {
                top: 6px;
                right: 6px;

                .el-dialog__close {
                    font-size: 24px;
                }
            }
        }
    }
}
</style>
