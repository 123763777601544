<template>
    <div class="home-wrapper">
        <div class="collect-content-wrapper">
            <div class="left">
                <div class="search-wrapper">
                    <img src="../assets/images/brand-search.png" alt="" />
                    <el-input placeholder="搜索" v-model="searchKey" @input="cardSearchInput"></el-input>
                </div>
                <img src="../assets/images/collect-bg.png" alt="" class="collect-bg" />
            </div>
            <div class="right">
                <ul class="card-list" v-infinite-scroll="getCollectListRequest"
                    :infinite-scroll-disabled="noMoreCollectListData">
                    <li v-for="(linkInfo, index) in tempCollectList" :key="index">
                        <LinkardInfoItem :linkardInfo="linkInfo"></LinkardInfoItem>
                    </li>
                </ul>
                <div class="collect-no"
                    v-if="collectList != null && (collectList.length == 0 || (collectList.length != 0 && tempCollectList.length == 0))">
                    <img src="../assets/images/collect-no.svg" alt="" />
                    <div v-if="collectList.length == 0">您暂未收藏联卡</div>
                    <div v-else>暂无内容</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { LinkardItemInfo, linkardNet } from '@/net/linkardNet';
import LinkardInfoItem from '../components/linkardInfoItem_Large.vue';

export default defineComponent({
    name: 'Collect',
    components: {
        LinkardInfoItem
    },
    setup(props, { emit }) {

        let pageNumber = 1;
        let pageSize = 12;
        let noMoreCollectListData = ref(false);
        let collectList: Ref<LinkardItemInfo[]> = ref([]);
        let tempCollectList: Ref<LinkardItemInfo[]> = ref([]);
        let searchKey = ref('');

        const cardSearchInput = (text: string) => {
            // 将输入文本转换为小写
            const searchText = text.toLowerCase();
            tempCollectList.value = [];
            collectList.value.forEach(element => {
                const elementName = element.name.toLowerCase();
                if (searchText == '') {
                    tempCollectList.value = collectList.value;
                }
                else if (elementName.includes(searchText)) {
                    tempCollectList.value.push(element);
                }
            });
        }

        const getCollectListRequest = () => {
            linkardNet.getCollectList(pageNumber, pageSize).then(
                (response) => {
                    if (response.list.length < pageSize) {
                        noMoreCollectListData.value = true;
                    } else {
                        pageNumber += 1;
                    }

                    collectList.value = [...tempCollectList.value, ...response.list];
                    tempCollectList.value = collectList.value;
                },
                (ex) => {

                },
            );
        }

        onMounted(() => {

        });
        return {
            searchKey,
            collectList,
            tempCollectList,
            noMoreCollectListData,
            getCollectListRequest,
            cardSearchInput
        };
    },
});

</script>

<style lang="less" scoped>
.home-wrapper {
    width: 100%;
    height: calc(100vh - 168px);
    padding: 0px 80px 0 80px;
    display: flex;
    flex-direction: column;

    .collect-header-wrapper {
        padding-left: 88px;
    }

    .collect-content-wrapper {
        padding: 108px 0 0 0px;
        height: 85vh;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .left {
            margin-right: 100px;

            .search-wrapper {
                width: 280px;
                height: 64px;
                border-radius: 36px;
                border: 2px solid #242424;
                display: flex;
                align-items: center;
                padding: 0 32px;

                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 24px;
                }

                :deep(.el-input__wrapper) {
                    height: 64px;
                    border-radius: 32px;
                    background: transparent;
                    border-width: 0;
                    box-shadow: none;

                    input {
                        background: transparent;
                        height: 64px;
                        border: none;
                        font-size: 18px;
                        padding: 0;
                        color: rgba(36, 36, 36, 0.5);
                        font-weight: 600;
                    }
                }

                :deep(.el-input__wrapper.is-focus) {
                    box-shadow: none;
                }
            }

            .collect-bg {
                margin-top: 80px;
                width: 320px;
            }
        }

        .right {
            flex-grow: 1;
            overflow: auto;

            .card-list {
                display: flex;
                flex-wrap: wrap;
                padding: 10px 0 60px 10px;

                li {
                    margin: 0 25px 22px 0;
                    position: relative;
                }

                li:nth-child(4n) {
                    margin-right: 0;
                }

                li:hover .mask {
                    opacity: 1;
                }
            }

            .data_count_tips {
                width: 100%;
                height: 40px;
                line-height: 60px;
                color: #242424;
                font-size: 16px;
                font-weight: 600;
                text-align: center;
            }

            .collect-no {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 1200px;
                color: #242424;
                font-size: 24px;
                font-weight: 600;

                img {
                    width: 390px;
                    height: 390px;
                }
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .collect-list {
                margin-bottom: 40px;
                margin-right: 28px;

                img {
                    width: 280px;
                    height: 392px;
                    border-radius: 28px;
                    background: #f0f0f0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                }

                div {
                    color: #242424;
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 10px;
                    text-align: center;
                }
            }

            .collect-list:nth-child(4n) {
                margin-right: 0;
            }
        }

        .right::-webkit-scrollbar-track {
            background-color: transparent;
        }

        .right::-webkit-scrollbar {
            width: 10px;
        }

        .right::-webkit-scrollbar-thumb {
            width: 4px;
            background-color: #242424;
            border-radius: 10px;
        }
    }
}
</style>