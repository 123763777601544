export const ACCESS_TOKEN = "token";

export const BG_DELETE_ALERT = "bg_delete_alter";

export const LK_PATH_PERSONAL = "personal/";

export const LK_PATH_PRO = "pro/";

export enum QueryParam {
  //微信code
  CODE = "code",

  //联卡类型
  CARD_TYPE = "cardType",

  //编辑类型
  EDIT_TYPE = "editType",

  //联卡路径
  CARD_PATH = "cardPath",

  //联卡ID
  CARD_ID = "cardId",
}

export enum UserLevelType {
  //体验版
  TRIAL = 1,
  //进阶版
  ADVANCED = 2,
  //专业版
  PROFESSIONAL = 3,
  //企业版
  ENT = 4,
}

/***********路由名称***********/
export enum NavigatorRoute {
  //登录
  LOGIN_PAGE = "login",

  //首页
  HOME_PAGE = "home",

  //联卡编辑页
  EDIT_CARD = "editCard",

  //卡柜
  CABINET = "cabinet",

  //卡包
  COLLECT = "collect",

  // 企业版联卡
  ENTER_PRISE_EDIT = "enterpriseEdit",

  // 企业版管理中心
  MANAGER_CENTER = "managementCenterView",
}

export const GetPreviewUrl = () => {
  return `${process.env.VUE_APP_MOBILE_URL}/?preview=true`;
};

export type TableRowClassNameItem = {
  row: number;
  rowIndex: number;
};

export type TableCellClassNameItem = {
  row: number;
  rowIndex: number;
  column: number;
  columnIndex: number;
};
