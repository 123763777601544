<template>
    <div class="cabinet_base_info">
        <button v-if="canbinetViewType == 0" class="add_cabinet_button" @click="addCabinetClick">
            <img src="../assets/images/special_subject_add_icon.svg" alt="" />
            <span class="add_tips">点击创建卡柜</span>
        </button>
        <div class="cabinet_info_list" v-if="canbinetViewType == 1">
            <div v-for="(item, index) in cabinetInfoList" class="cabinet_info_save_bg"
                :style="{ backgroundImage: `url(${cabinet_info_bg})` }">
                <div class="top_action_bg">
                    <el-button class="action_button" @click="previewCabinetClick(index)">
                        <img :src="cabinet_share_icon" alt="">
                        <span class="action_title">预览</span>
                    </el-button>
                    <el-button class="action_button" @click="editCabinetClick(index)">
                        <img :src="cabinet_edit_icon" alt="">
                        <span class="action_title">编辑</span>
                    </el-button>
                    <el-button class="action_button" @click="deleteCabinetClick(index)">
                        <img :src="cabinet_delete_icon" alt="">
                        <span class="action_title">删除</span>
                    </el-button>
                </div>
                <div class="bottom_content_view">
                    <div class="cabinet_name_bg">
                        <el-input placeholder="请输入卡柜名称" v-model="item.cabinetName" maxlength="12"
                            @change="cabinetNameChange(index)"></el-input>
                        <!-- <el-button class="edit_cabinet_name_button">
                            <img :src="edit_card_name_icon" alt="">
                        </el-button> -->
                    </div>
                    <div class="show_switch">
                        <span>是否在H5展示</span>
                        <el-switch width="80" @change="cabinetOpenSwitchChange(index)" class="public_switch"
                            v-model="item.isOpen" active-color="rgb(0,0,0,0)" inactive-color="rgb(0,0,0,0)">
                        </el-switch>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="canbinetViewType == 2" class="special_subject_bg">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
            <button v-for="(specialItem, index) in specialTopicList" :key="index" class="special_subject_info">
                <img class="special_subject_img" :src="specialItem.url" alt="" />
                <!-- <button class="delete_special_subject_button" @click.stop="deleteSpecialSubjectButton(index)">
                    <img src="../assets/images/delete_button_icon.svg" alt="" />
                </button> -->
                <div class="top_action_bg">
                    <el-button class="action_button" @click="specialSubjectInfoClick(index)">
                        <img :src="cabinet_edit_icon" alt="">
                        <span class="action_title">编辑</span>
                    </el-button>
                    <el-button class="action_button" @click="deleteSpecialSubjectButton(index)">
                        <img :src="cabinet_delete_icon" alt="">
                        <span class="action_title">删除</span>
                    </el-button>
                </div>
                <div class="special_subject_name_bg">
                    <el-input placeholder="请输入专题名称" v-model="specialItem.name" maxlength="12"
                        @change="specialSubjectNameChange(index)"></el-input>
                    <!-- <el-button class="edit_special_subject_name_button">
                        <img :src="edit_card_name_icon" alt="">
                    </el-button> -->
                </div>
                <!-- <span class="special_subject_name">{{ specialItem.name }}</span> -->
            </button>

            <button v-if="specialTopicList.length < 2" class="add_button" @click="addSpecialSubjectClick">
                <img src="../assets/images/special_subject_add_icon.svg" alt="" />
                <span class="add_tips">点击添加专题</span>
            </button>
        </div>
        <SpecialSubjectAddView v-if="showSpecialSubjectView" :currentSpecialTopicId="currentSpecialTopicId"
            :cabinetId="currentCabinetId" @dismissSpecialSubject="dismissSpecialSubjectView">
        </SpecialSubjectAddView>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { linkardNet } from '../net/linkardNet';
import SpecialSubjectAddView from './specialSubjectAddView.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { HttpResponse } from '@/net/http';
import { Loading } from '../components/loadingView/customLoading';
import { useUserStore } from '@/stores/user';
import BackButtonItem from "./backButtonItem.vue";
import useClipboard from 'vue-clipboard3';

import { useRouter } from 'vue-router';
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';

export default defineComponent({
    name: 'CabinetBaseView',
    props: {
        specialTopicId: {
            type: String,
            default: '',
        },
    },
    components: {
        SpecialSubjectAddView,
        BackButtonItem
    },
    setup(props, { emit }) {

        const userStore = useUserStore();
        const router = useRouter();

        const cabinetInfoList: Ref<any[]> = ref([]);
        const specialTopicList: Ref<any[]> = ref([]);
        const currentCabinetId = ref("");

        const showSpecialSubjectView = ref(false);
        const currentSpecialTopicId = ref('');

        // 0: 显示‘点击创建卡柜’页面，1:显示卡柜页面 2:专题页面
        const canbinetViewType = ref(0);

        const { toClipboard } = useClipboard();

        const dismissSpecialSubjectView = (index: number, cabinetId: string) => {
            showSpecialSubjectView.value = false;
            if (index == 1) {
                emit("refreshSpecialSubjectList");
                specialTopicInfoListRequest(cabinetId);
            }
        };

        const addSpecialSubjectClick = () => {
            currentSpecialTopicId.value = '';
            showSpecialSubjectView.value = true;
        };

        const addCabinetClick = () => {
            createCabinetRequest();
        };

        const previewCabinetClick = async (index: number) => {
            const item = cabinetInfoList.value[index];
            try {
                await toClipboard(process.env.VUE_APP_MOBILE_URL + '/cabinet?cabinetId=' + item.id);
                ElMessage.success('卡柜链接已复制');
            } catch (e) {
                ElMessage.error('复制失败');
                console.error(e);
            }
        };

        const editCabinetClick = (index: number) => {
            const item = cabinetInfoList.value[index];
            currentCabinetId.value = item.id;
            specialTopicList.value = [];
            specialTopicInfoListRequest(currentCabinetId.value);
            canbinetViewType.value = 2;
        };

        const deleteCabinetClick = (index: number) => {
            OverAllDialogView.showOverAllDialogView("删除后不可撤销，请谨慎操作！", "确认删除", "取消", "", false, (dialogIndex: number) => {
                if (dialogIndex == 1) {
                    const item = cabinetInfoList.value[index];
                    cabinetDeleteRequest(item.id);
                }
                OverAllDialogView.hideOverAllDialogView();
            });
        };

        const specialSubjectInfoClick = (index: number) => {
            currentSpecialTopicId.value = specialTopicList.value[index].id;
            showSpecialSubjectView.value = true;
        };

        const deleteSpecialSubjectButton = (index: number) => {
            OverAllDialogView.showOverAllDialogView("删除后不可撤销，请谨慎操作！", "确认删除", "取消", "", false, (dialogIndex: number) => {
                if (dialogIndex == 1) {
                    specialTopicDeleteRequest(index);
                }
                OverAllDialogView.hideOverAllDialogView();
            });
        };

        const cabinetOpenSwitchChange = (index: number) => {
            const item = cabinetInfoList.value[index];
            cabinetSaveRequst(item);
        };

        const backButtonClick = () => {
            canbinetViewType.value = 1;
            // if (userStore.privilegeLevel.level == UserLevelType.PROFESSIONAL || userStore.privilegeLevel.level == UserLevelType.ENT) {
            //     router.push({ path: NavigatorRoute.ENTER_PRISE_EDIT });
            // } else {
            //     router.push({ path: NavigatorRoute.HOME_PAGE });
            // }
        };

        const cabinetDeleteRequest = async (id: string) => {
            linkardNet.cabinetDelete(id).then(
                (response) => {
                    cabinetInfoList.value = [];
                    cabinetInfoListRequest();
                    emit("refreshSpecialSubjectList");
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const createCabinetRequest = async () => {
            linkardNet.createCabinet().then(
                (response) => {
                    cabinetInfoListRequest();
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const cabinetInfoListRequest = async () => {
            linkardNet.cabinetInfoList().then(
                (response) => {
                    if (response.list.length > 0) {
                        canbinetViewType.value = 1;
                        cabinetInfoList.value = response.list;
                    } else {
                        canbinetViewType.value = 0;
                    }
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const specialTopicInfoListRequest = async (cabinetId: string) => {
            linkardNet.specialTopicInfoList(cabinetId).then(
                (response) => {
                    specialTopicList.value = response.list;
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const cabinetSaveRequst = async (item: any) => {
            // if (!item.cabinetName) {
            //     ElMessage.error('请输入卡柜名称');
            //     return;
            // };
            const args = {
                id: item.id,
                cabinetName: item.cabinetName,
                openType: item.isOpen ? 1 : 0
            };
            // Loading.show('正在保存...');
            linkardNet.cabinetSave(args).then(
                (response) => {
                    // ElMessage.success('保存成功');
                    emit("refreshSpecialSubjectList");
                    Loading.hide();
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                    // Loading.hide();
                },
            );
        };

        const specialTopicDeleteRequest = async (index: number) => {
            linkardNet.specialTopicDelete(specialTopicList.value[index].id).then(
                (response) => {
                    specialTopicList.value.splice(index, 1);
                    ElMessage.success('删除成功');
                    emit("refreshSpecialSubjectList");
                },
                (ex) => {
                    ElMessage.error('删除失败');
                },
            );
        };

        const specialTopicSaveNameRequest = async (index: number) => {
            const args = {
                id: specialTopicList.value[index].id,
                name: specialTopicList.value[index].name
            };
            linkardNet.specialTopicSaveName(args).then(
                (response) => {
                    ElMessage.success('保存成功');
                    emit("refreshSpecialSubjectList");
                },
                (ex) => {
                    ElMessage.error('保存失败');
                },
            );
        };

        const cabinetNameChange = (index: number) => {
            const item = cabinetInfoList.value[index];
            cabinetSaveRequst(item);
        };

        const specialSubjectNameChange = (index: number) => {
            specialTopicSaveNameRequest(index);
        };

        onMounted(() => {
            currentSpecialTopicId.value = props.specialTopicId;
            cabinetInfoListRequest();
        });

        return {
            showSpecialSubjectView,
            currentSpecialTopicId,
            canbinetViewType,
            cabinetInfoList,
            specialTopicList,
            currentCabinetId,
            addSpecialSubjectClick,
            backButtonClick,
            specialTopicDeleteRequest,
            cabinetOpenSwitchChange,
            specialSubjectInfoClick,
            deleteSpecialSubjectButton,
            dismissSpecialSubjectView,
            addCabinetClick,
            editCabinetClick,
            deleteCabinetClick,
            cabinetNameChange,
            specialSubjectNameChange,
            previewCabinetClick,
            cabinet_info_bg: require('../assets/images/cabinet_info_bg.svg'),
            // edit_card_name_icon: require('../assets/images/edit_card_name_icon.svg'),
            cabinet_delete_icon: require('../assets/images/cabinet_delete_icon.svg'),
            cabinet_edit_icon: require('../assets/images/cabinet_edit_icon.svg'),
            cabinet_share_icon: require('../assets/images/cabinet_share_icon.svg'),
        };
    },
});

</script>

<style lang="less" scoped>
.cabinet_base_info {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .add_cabinet_button {
        width: 798px;
        height: 210px;
        border-radius: 40px;
        background: rgba(36, 36, 36, 0.20);
        border-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            width: 64px;
            height: 64px;
            margin-bottom: 18px;
        }

        .add_tips {
            color: #242424;
            text-align: center;
            font-family: LXGW 975 HazyGo SC;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .add_cabinet_button:hover {
        opacity: 0.8;
    }

    .add_cabinet_button:active {
        opacity: 0.6;
    }

    .cabinet_info_list {
        margin: 20px 0;
        overflow-y: auto;

        .cabinet_info_save_bg {
            width: 798px;
            height: 210px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-size: cover;
            border-radius: 40px;

            margin: 0 6px 20px 0;

            .cabinet_name_input {
                flex-grow: 1;
            }

            .top_action_bg {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding-right: 16px;
                margin-top: 16px;

                .action_button {
                    width: 88px;
                    height: 36px;
                    border-radius: 40px;
                    background-color: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                    color: #242424;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                    border: none;
                    margin-left: 20px;

                    // img {
                    //     width: 20px;
                    //     height: 20px;
                    // }

                    .action_title {
                        margin-left: 8px;
                    }
                }

                .action_button:hover {
                    opacity: 0.8;
                }

                .action_button:active {
                    opacity: 0.6;
                }
            }

            .bottom_content_view {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 24px;

                .cabinet_name_bg {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-left: 48px;

                    .edit_cabinet_name_button {
                        width: 48px;
                        height: 48px;
                        border-radius: 40px;
                        background-color: #F0F0F0;
                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                        border: none;
                        margin-left: 20px;

                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .edit_cabinet_name_button:hover {
                        opacity: 0.8;
                    }

                    .edit_cabinet_name_button:active {
                        opacity: 0.6;
                    }

                    :deep(.el-input__wrapper) {
                        background-color: #F0F0F0;
                        width: 300px;
                        height: 48px;
                        border-radius: 32px;

                        .el-input__inner {
                            font-size: 18px;
                            font-weight: 600;
                            color: #242424;
                            margin: 0 16px 0 16px;
                            background: transparent;
                        }
                    }

                    :deep(.el-input__wrapper.is-focus) {
                        box-shadow: none;
                    }
                }

                .show_switch {
                    margin-right: 16px;
                    width: 232px;
                    height: 48px;
                    border-radius: 30px;
                    background-color: #F0F0F0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 20px;

                    span {
                        color: #242424;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                    }

                    .public_switch {}

                    :deep(.el-switch) {
                        .el-switch__core {
                            width: 80px !important;
                            height: 36px;
                            border-radius: 18px;
                            border: 2px solid #242424 !important;
                            background-color: transparent;
                        }

                        .el-switch__action {
                            width: 24px;
                            height: 24px;
                            margin-left: 4px;
                            background: #242424;
                            border: 2px solid transparent
                        }
                    }

                    :deep(.el-switch.is-checked .el-switch__action) {
                        margin-left: -14px;
                        background: #F3EC3F;
                        border: 2px solid #242424
                    }
                }
            }

            .save_button {
                width: 180px;
                height: 64px;
                border-radius: 36px;
                border: 4px solid #242424;
                background: #F3EC3F;
                margin-left: 24px;

                color: #242424;
                text-align: center;
                font-family: LXGW 975 HazyGo SC;
                font-size: 18px;
                font-weight: 600;
            }

            .save_button:hover {
                opacity: 0.6;
            }

            .save_button:active {
                opacity: 0.8;
            }
        }
    }

    .cabinet_info_list::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .cabinet_info_list::-webkit-scrollbar {
        width: 4px;
    }

    .cabinet_info_list::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: #242424;
        border-radius: 10px;
    }

    .special_subject_bg {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 114px;
        padding: 0 64px 0 64px;
        width: 100%;

        .back_button {
            left: 48px;
            top: 64px;
            position: absolute;
        }

        .special_subject_info {
            width: 400px;
            height: 300px;
            border-radius: 20px;
            background: rgba(36, 36, 36, 0.20);
            border-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-left: 20px;
            position: relative;

            .special_subject_img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }

            .top_action_bg {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding-right: 16px;
                margin-top: 16px;
                z-index: 0;

                .action_button {
                    width: 88px;
                    height: 36px;
                    border-radius: 40px;
                    background-color: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                    color: #242424;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                    border: none;
                    margin-left: 20px;

                    // img {
                    //     width: 20px;
                    //     height: 20px;
                    // }

                    .action_title {
                        margin-left: 8px;
                    }
                }

                .action_button:hover {
                    opacity: 0.8;
                }

                .action_button:active {
                    opacity: 0.6;
                }
            }

            .delete_special_subject_button {
                position: absolute;
                top: 16px;
                right: 16px;
                width: 88px;
                height: 36px;
                border-width: 0;
                border-radius: 18px;
                background-color: rgba(240, 240, 240, 1);
                display: flex;
                flex-direction: row;
                justify-content: end;
                align-items: center;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 18px;
                }
            }

            .delete_special_subject_button:hover {
                opacity: 0.8;
            }

            .delete_special_subject_button:active {
                opacity: 0.6;
            }

            .special_subject_name_bg {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 0 0 40px 26px;
                z-index: 0;

                .edit_special_subject_name_button {
                    width: 48px;
                    height: 48px;
                    border-radius: 40px;
                    background-color: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                    border: none;
                    margin-left: 20px;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                .edit_special_subject_button:hover {
                    opacity: 0.8;
                }

                .edit_special_subject_button:active {
                    opacity: 0.6;
                }

                :deep(.el-input) {
                    width: 280px;
                    height: 48px;

                    .el-input__wrapper {
                        background-color: #F0F0F0;
                        border-radius: 32px;

                        .el-input__inner {
                            font-size: 18px;
                            font-weight: 600;
                            color: #242424;
                            margin: 0 16px 0 16px;
                            background: transparent;
                        }
                    }

                    .el-input__wrapper.is-focus {
                        box-shadow: none;
                    }
                }
            }

        }

        .add_button {
            width: 400px;
            height: 300px;
            border-radius: 20px;
            background: rgba(36, 36, 36, 0.20);
            border-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;

            img {
                width: 64px;
                height: 64px;
                margin-bottom: 18px;
            }

            .add_tips {
                color: #F0F0F0;
                text-align: center;
                font-family: LXGW 975 HazyGo SC;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .add_button:hover {
            opacity: 0.8;
        }

        .add_button:active {
            opacity: 0.6;
        }
    }
}
</style>