import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import router from "../router/index";

import { uuid } from "vue3-uuid";
import { ElMessage } from "element-plus";
import { ACCESS_TOKEN, NavigatorRoute } from "@/utils/const";

enum CustomNetWorkStatus {
  ethernetOrWiFi = 0,
  cellular = 1,
  notReachable = 2,
}

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

export enum NetErrorCode {
  SUCCESS = 2000000, // 服务器返回成功
  TOKENERROE = 4010103, // token失效
  VERIFICATION_CODE_EXPIRED = 6150005, // 验证码已过期
  USERHASEXISTS = 6130001, // 用户已存在
  USERDOESNOTEXIST = 6130000, // 用户不存在
  PASSWORLDMISMATCH = 6170000, //用户密码登录
}

export interface HttpResponse<T> {
  code: NetErrorCode;
  message: string;
  res?: T;
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Credentials": false,
  "X-Requested-With": "XMLHttpRequest",
};

const injectToken = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  try {
    const token = sessionStorage.getItem(ACCESS_TOKEN);
    config.headers['T-Validate-Token'] = config.headers['T-Validate-Token'] || token ||'';
    config.headers["T-Post-ID"] = uuid.v4();
    config.headers["T-Trace-ID"] = uuid.v4();
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
  return config;
};

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.VUE_APP_SERVER_URL,
      headers,
      timeout: 30000,
      withCredentials: false,
    });

    http.interceptors.request.use(injectToken, (error) => {
      return Promise.reject(error);
    });

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = T>(
    url: string,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    const promise = this.http.get<T, AxiosResponse<HttpResponse<R>>>(
      url,
      config
    );
    return this.handleData(promise);
  }

  post<T = any, R = any>(
    url: string,
    data?: T,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    const promise = this.http.post<T, AxiosResponse<HttpResponse<R>>>(
      url,
      data,
      config
    );
    return this.handleData(promise);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  private handleData<T = any>(
    promise: Promise<AxiosResponse<HttpResponse<T>>>
  ) {
    return promise.then(
      (response) => {
        const { data } = response;
        const httpResponse = data as HttpResponse<any>;
        if (httpResponse.code === NetErrorCode.SUCCESS) {
          return Promise.resolve(httpResponse.res);
        }
        if (httpResponse.code === NetErrorCode.TOKENERROE) {
          sessionStorage.removeItem(ACCESS_TOKEN);

          if (
            !router.currentRoute.value.fullPath.includes(
              `/${NavigatorRoute.LOGIN_PAGE}`
            )
          ) {
            router.replace({path: '/' + NavigatorRoute.LOGIN_PAGE});
            ElMessage.error("登录已失效，请重新登录");
          }
          return;
        }
        return Promise.reject(httpResponse);
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  }

  private handleError(error: any) {
    console.error(`handleError: ${error}`);
    const { status } = error;
    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      default: {
        // TODO
        break;
      }
    }
    let httpResponse: HttpResponse<never>;
    httpResponse = { code: status, message: "http request failed." };
    return Promise.reject(httpResponse);
  }
}

export const http = new Http();
