import { PrivilegeLevelInfo, UserLoginInfo } from "@/net/linkardNet";
import { defineStore } from "pinia";
/**
 * 用户数据仓库
 */
export const useUserStore = defineStore("userInfo", {
  state: () => {
    return {
      userInfo: {} as UserLoginInfo,
      privilegeLevel: {} as PrivilegeLevelInfo,
      currentPath:''
    };
  },

  // 开启数据缓存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, // 表示存储在localStorage
        paths: ["userInfo","currentPath"], // 指定要长久化的字段
      },
    ],
  },

  actions: {
    init() {
      const info = "{}";
      this.userInfo = JSON.parse(info);

      const levelInfo = "{}";
      this.privilegeLevel = JSON.parse(levelInfo);
    },

    /**
     * 更新当前用户信息
     */
    async updateCurrentUserInfo(response: UserLoginInfo) {
      this.userInfo = response;
    },

    /**
     * 获取当前用户信息
     */
    getCurrentUserInfo(): UserLoginInfo | undefined {
      return this.userInfo;
    },

    /**
     * 更新当前用户权益
     */
    async updatePrivilegeLevelInfo(response: PrivilegeLevelInfo) {
      this.privilegeLevel = response;
    },

    /**
     * 获取当前用户权益
     */
    getPrivilegeLevelInfo(): PrivilegeLevelInfo | undefined {
      return this.privilegeLevel;
    },
  },
});
