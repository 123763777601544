<template>
    <div v-if="msg.show" class="loading_bg">
        <div class="loading-spinner">
            <svg v-if="!msg.isShowSuccess" viewBox="25 25 50 50" class="circular">
                <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
            </svg>
            <img v-else src="../../assets/images/request_success.svg" alt="">
            <span>{{ msg.text }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'SubViewLoadingView',
    props: {
        msg: {
            type: Object as () => any,
            default: {
                show: false,
                text: '',
                isShowSuccess: false
            }
        }
    },
    components: {

    },
    setup(props, { emit }) {
        onMounted(() => {
        });

        return {
        };
    },
});

</script>

<style scoped lang="less">
.loading_bg {
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
    z-index: 4000;
    width: 100%;
    height: 100%;
    background: rgba(230, 230, 230, 0.95);
    backdrop-filter: blur(8px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;

    .loading-spinner {
        top: 50%;
        margin-top: -21px;
        width: 120px;
        height: 120px;
        border-radius: 20px;
        background: #F0F0F0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .circular {
            height: 45px;
            width: 45px;
            margin-top: 16px;
            animation: loading-rotate 2s linear infinite;

            .path {
                animation: loading-dash 1.5s ease-in-out infinite;
                stroke-dasharray: 90, 150;
                stroke-dashoffset: 0;
                stroke-width: 4;
                stroke: rgba(36, 36, 36, 1);
                stroke-linecap: round;
            }
        }

        img {
            width: 40px;
            height: 40px;
        }

        span {
            margin-top: 16px;
            color: #242424;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

    }

    .loading-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            margin-top: 10px;
            color: #666;
            line-height: 20px;
            font-size: 16px;
        }
    }
}
</style>