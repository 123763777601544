import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-788af00d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog_bg_view" }
const _hoisted_2 = {
  key: 0,
  class: "dialog_content_view"
}
const _hoisted_3 = { class: "dialog_text" }
const _hoisted_4 = { class: "dialog_action_bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "dialog" }, {
      default: _withCtx(() => [
        (_ctx.showDialog)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dialogText), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "dialog_button dialog_sure",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sureButtonClick && _ctx.sureButtonClick(...args)))
                }, _toDisplayString(_ctx.sureButton), 1),
                _createElementVNode("button", {
                  class: "dialog_button dialog_cancel",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelButtonClick && _ctx.cancelButtonClick(...args)))
                }, _toDisplayString(_ctx.cancelButton), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}