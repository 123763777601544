<template>
  <router-view />
</template>

<script lang="ts">
import { useUserStore } from '@/stores/user';

export default {
  setup() {
    const userStore = useUserStore();
    userStore.init();
    return {};
  }
};
</script>
<style></style>
