<template>
    <div class="digital_corpus_setting">
        <div class="top_view">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
            <el-upload class="upload_file" accept=".txt, .xls, .xlsx, .pdf, .doc, .docx" drag :on-error="handleError"
                :on-success="handleSuccess" :http-request="(item: any) => uploadFile(item.file)"
                :before-upload="beforeUPload" :show-file-list="false">
                <div class="yl-add-btn"
                    v-if="(knowledgeList.length < 5 && knowledgeUsed <= knowledgeTotal) || (linkardType == 1 && knowledgeUsed <= knowledgeTotal)">
                    <span>点击上传</span>
                </div>
            </el-upload>
        </div>
        <div class="yl-wrapper">
            <div v-if="knowledgeList.length > 0" class="yl-right">
                <div class="yl-list-item" v-for="(item, index) in knowledgeList" :key="index">
                    <div class="item_count">{{ linkardType != 1 ? index + 1 + '/5' : '' }}</div>
                    <div
                        :class="['knowledge_item_bg', item.status == 2 ? 'knowledge_item_bg_success' : '', item.status == 1 ? 'knowledge_item_bg_process' : '']">
                        <span>{{ item.filename }}</span>
                        <img class="status_icon" :src="item.statusImage" alt="" />
                    </div>
                    <span class="status_label_black" :style="{ color: item.statusColor }">{{ item.statusTips }}</span>
                    <img class="delete_icon" src="../assets/images/brand-close.png" alt=""
                        @click="deleteYl(item, index)" />
                </div>
            </div>
            <div v-else class="knowledge_list_empty">
                <img src="../assets/images/collect-no.svg" alt="" />
                <span>暂无内容</span>
            </div>
        </div>
        <div class="used_word_count_bg">
            <span>已使用</span>
            <span class="used_word_count">{{ knowledgeUsed }}/{{ knowledgeTotal }}字</span>
        </div>
        <SubViewLoadingView :msg="subLoadingInfo"></SubViewLoadingView>
    </div>
</template>

<script lang="ts">
import { LKKnowledgeItem, linkardNet } from '@/net/linkardNet';
import { HttpResponse } from '@/net/http';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import BackButtonItem from "./backButtonItem.vue";
import SubViewLoadingView from "./loadingView/subViewLoadingView.vue";
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';

export default defineComponent({
    name: 'KnowledgeListView',
    props: {
        linkardId: {
            type: String,
            default: ''
        },
        linkardType: {
            type: Number,
            default: 0
        }
    },
    components: {
        BackButtonItem,
        SubViewLoadingView
    },
    setup(props, { emit }) {

        let isUploading = ref(false);
        let knowledgeTotal = ref(0);
        let knowledgeUsed = ref(0);
        let knowledgeList: Ref<any[]> = ref([]);

        const subLoadingInfo = ref({
            show: false,
            text: '',
            isShowSuccess: false
        });

        const backButtonClick = () => {
            emit("dismissKnowledgeListView", null);
        };

        const submitClick = () => {
            // emit("dismissDigtalCorpus", props.knowledge);
        };

        const beforeUPload = (file: any) => {

            if (!props.linkardId || props.linkardId == 'undefined') {
                ElMessage.error('请保存联卡后，再进行上传');
                return false;
            }

            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件大小不能超过 20MB!',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件大小不能超过 20MB!", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });
                
            return isLt2M;
        };

        // 上传错误提示
        const handleError = () => {
            ElMessage.error('上传失败，请您重新上传！');
        };

        //上传成功提示
        const handleSuccess = () => {

        };
        // 图片上传
        const uploadFile = async (file: any) => {
            isUploading.value = true;
            linkardNet.uploadKnowledge({ linkardId: props.linkardId, file: file }).then(
                (response) => {
                    isUploading.value = false;
                    ElMessage.success('上传成功！');
                    // let item: LKKnowledgeItem = {
                    //     filename: response.filename,
                    //     url: response.url,
                    //     id: response.id,
                    //     words: 0,
                    //     wordsStr: '',
                    //     status: 0,
                    //     statusTips: '',
                    //     statusColor: '',
                    //     createTime: 0
                    // };
                    // setStatusView(item);
                    // knowledgeList.value.push(item);
                    getKnowledgeListRequest();
                },
                (ex) => {
                    isUploading.value = false;
                    ElMessage.error('上传失败，请重试！');
                },
            );
        }

        const setStatusView = (item: LKKnowledgeItem) => {
            switch (item.status) {
                case 0:
                    item.statusTips = "未保存";
                    item.statusColor = "#242424";
                    item.statusImage = require('../assets/images/knowledge_status_processing.svg');
                    break;
                case 1:
                    item.statusTips = "正在处理";
                    item.statusColor = "#242424";
                    item.statusImage = require('../assets/images/knowledge_status_processing.svg');
                    break;
                case 2:
                    item.statusTips = "";
                    item.statusColor = "#242424";
                    item.statusImage = require('../assets/images/knowledge_status_success.svg');
                    break;
                case 3:
                    item.statusTips = "处理失败";
                    item.statusColor = "#F5222D";
                    item.statusImage = require('../assets/images/knowledge_status_faild.svg');
                    break;
                case 4:
                    item.statusTips = "超出限制";
                    item.statusColor = "#F5222D";
                    item.statusImage = require('../assets/images/knowledge_status_faild.svg');
                    break;
                default:
                    item.statusTips = "未知状态";
                    item.statusColor = "#F5222D";
                    item.statusImage = require('../assets/images/knowledge_status_faild.svg');
                    break;
            }
        };

        const deleteYl = (file: any, index: number) => {
            deleteKnowledgeRequest(index);
        };

        const getKnowledgeListRequest = () => {
            if (!props.linkardId || props.linkardId == 'undefined') return;

            subLoadingInfo.value.show = true;
            linkardNet.getKnowledgeList(props.linkardId).then(
                (response) => {

                    response.knowledge.forEach((item: any) => {
                        setStatusView(item);
                    });
                    knowledgeList.value = response.knowledge;
                    knowledgeTotal.value = response.knowledgeTotal;
                    knowledgeUsed.value = response.knowledgeUsed;
                    subLoadingInfo.value.show = false;
                },
                (ex) => {
                    subLoadingInfo.value.show = false;
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const deleteKnowledgeRequest = (index: number) => {
            const tempItem = knowledgeList.value[index];
            linkardNet.deleteKnowledge(props.linkardId, tempItem.id).then(
                (response) => {
                    knowledgeList.value.splice(index, 1);
                    ElMessage.success('删除成功');
                    getKnowledgeListRequest();
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        onMounted(() => {
            getKnowledgeListRequest();
        });

        return {
            isUploading,
            knowledgeList,
            knowledgeUsed,
            knowledgeTotal,
            subLoadingInfo,
            knowledge_status_faild: require('../assets/images/knowledge_status_faild.svg'),
            knowledge_status_processing: require('../assets/images/knowledge_status_processing.svg'),
            knowledge_status_success: require('../assets/images/knowledge_status_success.svg'),
            uploadFile,
            beforeUPload,
            handleSuccess,
            handleError,
            deleteYl,
            backButtonClick,
            submitClick,
        };
    },
});

</script>

<style lang="less" scoped>
.digital_corpus_setting {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 64px);
        position: absolute;
        top: 32px;
        left: 32px;
        z-index: 1;

        .back_button {}

        .upload_file {
            :deep(.el-upload-dragger) {
                padding: 0;
                border-width: 0;
                background-color: transparent;
            }
        }

        .upload_file:hover {
            opacity: 0.8;
        }

        .upload_file:active {
            opacity: 0.6;
        }

        .yl-add-btn {
            width: 120px;
            height: 48px;
            border-radius: 36px;
            background-color: #242424;
            margin-top: 10px;

            span {
                height: 48px;
                line-height: 48px;
                color: #F0F0F0;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;

            }
        }
    }

    .yl-wrapper {
        flex-shrink: 0;
        border-radius: 40px;
        background: #f0f0f0;
        padding: 60px 58px 0px 58px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;


        .item_count {
            color: #242424;
            font-size: 20px;
            font-weight: 700;
            width: 90px;
            margin-right: 24px;
            line-height: 48px;
        }

        .yl-right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 750px;
            max-height: 400px;
            overflow-y: auto;

            .yl-list-item {
                display: flex;
                align-items: center;
                margin-bottom: 22px;

                .knowledge_item_bg {
                    width: 400px;
                    height: 48px;
                    padding: 0 12px;
                    border-radius: 36px;
                    border: 2px solid #242424;
                    background: #f0f0f0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        width: 350px;
                        height: 48px;
                        line-height: 48px;
                        color: #242424;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .status_icon {
                        width: 20px;
                        height: 20px;
                    }
                }

                .knowledge_item_bg_success {
                    background-color: #F3EC3F;
                }

                .knowledge_item_bg_process {
                    background-color: rgba(36, 36, 36, 0.20);
                }

                .status_label_black {
                    width: 80px;
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0 14px;
                }

                .status_label_red {
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0 14px;
                }

                .delete_icon {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }
        }

        .knowledge_list_empty {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 80px;
                height: 80px;
            }

            span {
                color: #242424;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
            }
        }

        .yl-right::-webkit-scrollbar-track {
            background-color: transparent;
            margin: 30px 0;
        }

        .yl-right::-webkit-scrollbar {
            width: 4px;
        }

        .yl-right::-webkit-scrollbar-thumb {
            width: 4px;
            background-color: #d3d4d6;
            border-radius: 4px;
            margin: 30px 0;
        }
    }

    .used_word_count_bg {
        position: absolute;
        bottom: 40px;

        span {
            color: #242424;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .used_word_count {
            margin-left: 40px;
        }
    }
}
</style>
