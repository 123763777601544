import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31bbf9a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "back_button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backButtonClick && _ctx.backButtonClick(...args)))
  }, [
    _createElementVNode("img", {
      class: "back_button_icon",
      src: require('../assets/images/back_icon.svg'),
      alt: ""
    }, null, 8, _hoisted_1)
  ]))
}