<template>
    <div class="user_info_dialog">
        <div class="manager_center_tips_bg">
            <span class="title_tips">管理中心</span>
            <div class="version_type">{{ userLevel == 2 ? '进阶版' : '体验版' }}</div>
        </div>
        <div class="account-wrapper">
            <div class="account-base">
                <el-upload class="left" accept="image/png,image/jpeg" :on-error="handleError"
                    :on-success="handleSuccess" :http-request="(item: any) => uploadImg(item.file)"
                    :before-upload="beforeUPload" :show-file-list="false">
                    <img :src="userInfo.headImg" alt="" v-if="userInfo.headImg" />
                    <img src="../assets/images/upload-ava.png" alt="" v-else class="upload-ava" />
                </el-upload>
                <div class="right">
                    <div class="line">
                        <label>姓名</label>
                        <el-input v-model="userInfo.name" type="text" placeholder="请输入姓名"></el-input>
                    </div>
                    <div class="line">
                        <label>电话</label>
                        <el-input class="disable_phone_number" v-model="userInfo.phone" placeholder="请输入手机号"
                            disabled="true"></el-input>
                    </div>
                </div>
            </div>
            <div class="site-info">
                <span>{{ baseUrl + '/personal/' }}</span>
                <el-input v-model="userInfo.path" :disabled="!userInfo.pathAllowChanges"></el-input>
            </div>
            <div class="site-tips">
                一旦修改链接，旧链接将失效并有可能被他人注册，请您尽快在分享的各处替换为新链接。
            </div>
            <div class="divider_line"></div>
            <div class="dialogue_limits_bg">
                <span class="dialogue_limits_title">对话消耗</span>
                <div class="limits_content_bg">
                    <span class="has_used_dialogue_count">已用{{ userInfo.usedCnt }}轮次</span>
                    <div class="dialogue_count_divider">/</div>
                    <span>共{{ userInfo.totalCnt }}轮次</span>
                </div>
            </div>
            <div class="action_button_bg">
                <div class="btn" @click="closeUserView">取消</div>
                <div :class="['btn btn-confirm']" @click="updateUserInfo">确认更改</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { HttpResponse } from '@/net/http';
import { UserLoginInfo, linkardNet } from '@/net/linkardNet';
import { useUserStore } from '@/stores/user';
import { phoneWithOutReginPrefix } from '@/utils/commonUtils';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';

export default defineComponent({
    name: 'UserinfoView',
    components: {

    },
    setup(props, { emit }) {

        const userStore = useUserStore();
        let userInfo: Ref<UserLoginInfo> = ref(userStore.userInfo);
        let userLevel = userStore.privilegeLevel.level;
        let userShowPhone = phoneWithOutReginPrefix(userInfo.value.phone);
        let baseUrl = process.env.VUE_APP_MOBILE_URL;

        const closeUserView = () => {
            emit("closeUserView");
        };

        const beforeUPload = (file: any) => {
            const isImage =
                file.type === 'image/png' ||
                file.type ===
                'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isImage)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件只能是 png / jpg 格式！',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件只能是 png / jpg 格式！", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });

            if (!isLt2M)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件大小不能超过 20MB!',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件大小不能超过 20MB!", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });

            return isImage && isLt2M;
        };

        // 上传错误提示
        const handleError = () => {
            ElMessage.error('上传失败，请您重新上传！');
        };

        //上传成功提示
        const handleSuccess = () => {

        };
        // 图片上传
        const uploadImg = async (file: any) => {
            linkardNet.uploadHeaderImageMedia({ file }).then(
                (response) => {
                    ElMessage.success('上传成功！');
                    userInfo.value.headImg = response;
                },
                (ex) => {
                    ElMessage.error('图片上传失败，请重试！');
                },
            );
        };

        const updateUserInfo = async () => {
            linkardNet.userUpdate(userInfo.value).then(
                (response) => {
                    ElMessage.success('修改成功');
                    userStore.currentPath = userInfo.value.path;
                    userStore.userInfo.pathAllowChanges = false;
                    userStore.updateCurrentUserInfo(userInfo.value);
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        onMounted(() => {

        });

        return {
            beforeUPload,
            handleError,
            handleSuccess,
            uploadImg,
            updateUserInfo,
            closeUserView,
            baseUrl,
            userInfo,
            userShowPhone,
            userLevel,

        };
    },
});

</script>

<style lang="less" scoped>
.user_info_dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2001;

    .manager_center_tips_bg {
        pointer-events: none;
        width: 440px;
        height: 64px;
        border-radius: 36px;
        background: #F0F0F0;

        margin: 56px 0 32px 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .title_tips {
            color: #242424;
            font-size: 32px;
            font-weight: 600;
        }

        .version_type {
            width: 80px;
            height: 32px;
            line-height: 32px;
            flex-shrink: 0;
            border-radius: 20px;
            background-color: #39D92B;
            margin-left: 20px;

            color: #F0F0F0;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
        }
    }

    .account-wrapper {
        width: 780px;
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 58px 38px 48px 38px;
        background: #f0f0f0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        .account-base {
            display: flex;
            align-items: center;

            .left {
                width: 164px;
                height: 164px;
                margin-right: 32px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }

                .upload-ava {
                    border: 1px solid #000;
                }
            }

            .right {
                .line {
                    display: flex;
                    align-items: center;

                    label {
                        width: 54px;
                        margin-right: 6px;
                        flex-shrink: 0;
                        color: #242424;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    :deep(.el-input__wrapper) {
                        width: 320px;
                        height: 48px;
                        border-radius: 36px;
                        border: 2px solid #242424;
                        background: #f0f0f0;



                        .el-input__inner {
                            color: #242424;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;

                            margin: 0 16px 0 16px;
                            background: transparent;
                        }
                    }

                    .disable_phone_number {
                        input {
                            border: 1px solid #c0c4cc;
                            color: #c0c4cc;
                        }
                    }

                }

                .line:first-child {
                    margin-bottom: 20px;
                }
            }
        }

        .site-info {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            margin-top: 40px;
            align-items: center;

            color: #242424;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            :deep(.el-input__wrapper) {
                width: 206px;
                height: 48px;
                flex-shrink: 0;
                background-color: #f0f0f0 !important;
                border-radius: 36px;
                border: 2px solid #242424;

                .el-input__inner {
                    color: #242424;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0 16px 0 10px;
                    background: transparent;
                }
            }
        }

        .site-tips {
            color: rgba(36, 36, 36, 0.6);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            margin: 20px 0 45px 0;
        }

        .divider_line {
            width: 478px;
            height: 2px;
            background-color: #242424;
        }

        .dialogue_limits_bg {
            margin: 32px 0 40px 0;
            width: 478px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #242424;
            text-align: center;
            font-size: 18px;
            font-weight: 600;

            .dialogue_limits_title {}

            .limits_content_bg {
                width: 320px;
                height: 40px;

                border-radius: 20px;
                border: 2px solid #242424;
                background: #F0F0F0;

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .has_used_dialogue_count {}

                .dialogue_count_divider {
                    margin: 0 30px;
                }
            }
        }

        .action_button_bg {
            width: 478px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .btn-confirm {
                margin: 0 0 0 50px;
            }
        }
    }

    :deep(.el-input__wrapper.is-focus) {
        box-shadow: none;
    }
}
</style>
