<template>
    <div class="authority_transfer_bg">
        <div class="center_content">
            <div class="post-wrapper">
                <div id="posterHtml">
                    <img :src="linkardInfo.avatar" alt="" class="post-link-logo">
                    <div class="post-link-name">{{ linkardInfo.name }}</div>
                    <div class="linkard_tags">
                        <div class="linkard_tags_bg" v-for="(item, index) in linkardInfo.tags">
                            <span v-if="item" :key="index">{{ item }}</span>
                        </div>
                    </div>
                    <div class="post-link-avatar">
                        <el-image :src="linkardInfo.npcAvatar" fit="cover" />
                    </div>
                    <div class="qr_code_image_bg">
                        <vue-qrcode :value="linkardQRImgUrl" :color="{}" type="image/png" :width="32" :height="32"
                            :margin="2" errorCorrectionLevel="L"></vue-qrcode>
                    </div>
                </div>
            </div>
            <span class="card_name">{{ linkardInfo.name }}</span>
            <div class="manager_list_bg">
                <ul v-if="tempManagerList.length > 0" class="manager_list">
                    <li v-for="(managerItem, index) in tempManagerList" :key="index">
                        <div :class="['manager_item', !managerItem.transferable ? 'manager_item_disable' : currentSelManagerItem == managerItem ? 'manager_item_selected' : 'manager_item_normal']"
                            @click="managerItemClick(managerItem)">
                            <span class="manager_user_name">{{ managerItem.userName }}</span>
                            <span class="phone_number">{{ managerItem.tempUserPhone }}</span>
                            <span class="linkard_cnt">{{ managerItem.transferable ? '已有' + managerItem.linkardCnt + '张'
                        : '未注册' }}</span>
                        </div>
                    </li>
                </ul>
                <span v-else class="empty_tips">暂未添加管理员</span>
                <div class="manager_list_bottom_view">
                    <el-button :disabled="currentManagerPage <= 0" class="page_button" @click="pageLeft">
                        <img src="../assets/images/page_turning_left.svg" alt="" />
                    </el-button>
                    <span class="page_number">{{ currentManagerPage + 1 }}</span>
                    <el-button :disabled="(currentManagerPage + 1) * 5 >= managerList.length" class="page_button"
                        @click="pageRight">
                        <img src="../assets/images/page_turning_right.svg" alt="" />
                    </el-button>
                </div>
            </div>
            <div class="bottom_action_bg">
                <button class="cancel_button action_button" @click="cancleButtonClick">取消</button>
                <button class="sure_button action_button" @click="sureButtonClick">确认转移</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { HttpResponse } from '@/net/http';
import { EnterpriseCardItem, linkardNet } from '@/net/linkardNet';
import { encryptPhoneNumber } from '@/utils/commonUtils';
import { GenerateQRCodeLink } from '@/utils/serviceUtils';
import { ElMessage } from 'element-plus';
import { Ref, defineComponent, onMounted, ref, watch } from 'vue';
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';
import VueQrcode from 'vue-qrcode';

export default defineComponent({
    name: 'AuthorityTransferView',
    components: {
        VueQrcode
    },
    props: {
        transferLinkardInfo: {
            type: Object as () => EnterpriseCardItem | null,
            default: {}
        },
    },
    setup(props, { emit }) {

        let linkardInfo: Ref<any> = ref(
            {
                id: "",
                url: "",
                name: "",
                avatar: "",
                npcAvatar: "",
                tags: [
                ],
                usedCnt: 0,
                remainingCnt: 0
            }
        );
        let managerList: Ref<any[]> = ref([]);
        let currentManagerPage = ref(0);
        let selectedItem: Ref<any> = ref();
        let tempManagerList: Ref<any[]> = ref([]);
        let currentSelManagerItem: Ref<any> = ref();

        let linkardQRImgUrl = ref('');

        const pageLeft = () => {
            currentManagerPage.value -= 1;
            tempManagerList.value = managerList.value.slice(currentManagerPage.value * 5, currentManagerPage.value * 5 + 5);
        };

        const pageRight = () => {
            currentManagerPage.value += 1;
            tempManagerList.value = managerList.value.slice(currentManagerPage.value * 5, currentManagerPage.value * 5 + 5);
        };

        const managerItemClick = (item: any) => {
            currentSelManagerItem.value = item;
        };

        const cancleButtonClick = () => {
            emit("dismissAuthorityTransferView", 0);
            currentSelManagerItem.value = null;
        };

        const sureButtonClick = () => {
            if (!currentSelManagerItem) {
                ElMessage.info("请选择一个管理员");
                return;
            }
            enterpriseTransferLinkardCheck(linkardInfo.value.id);
        };

        const enterpriseTransferableUserList = async (linkardId: string) => {
            linkardNet.enterpriseTransferableUserList(linkardId).then(
                (response) => {
                    managerList.value = response;
                    managerList.value.sort((a, b) => {
                        return b.transferable - a.transferable;
                    });
                    managerList.value.forEach(item => {
                        item.tempUserPhone = encryptPhoneNumber(item.userPhone);
                    });
                    if (managerList.value.length > 5) {
                        tempManagerList.value = managerList.value.slice(currentManagerPage.value * 5, 5);
                    }
                    else {
                        tempManagerList.value = managerList.value;
                    }
                    currentSelManagerItem.value = null;
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const enterpriseTransferLinkardCheck = async (linkardId: string) => {
            const param = {
                linkardId: linkardId,
                transferName: currentSelManagerItem.value.userName,
                transferPhone: currentSelManagerItem.value.userPhone
            };

            linkardNet.enterpriseTransferLinkardCheck(param).then(
                (response) => {
                    if (response.bindSkyseaSpace) {
                        OverAllDialogView.showOverAllDialogView("联卡「" + response.linkardUser + "」的数字空间绑定的" + response.linkardPhone + "在天海现场创建的空间，需解绑后才可转移该卡片。", "解绑空间", "取消", "该操作不可撤销，且解绑后需重新绑定", true, (dialogIndex: number) => {
                            if (dialogIndex == 1) {
                                enterpriseUnbindSpaceRequest(linkardId, response.linkardUser);
                            }
                            OverAllDialogView.hideOverAllDialogView();
                        });
                    } else {
                        enterpriseTransferLinkard(linkardId);
                    }
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const enterpriseUnbindSpaceRequest = async (linkardId: string, linkardUser: string) => {
            const param = {
                linkardId: linkardId
            };

            linkardNet.enterpriseUnbindSpace(param).then(
                (response) => {
                    OverAllDialogView.showOverAllDialogView("联卡「" + linkardUser + "」的数字空间已解绑，可转移卡片的管理权。", "继续转移", "取消", "", true, (dialogIndex: number) => {
                        if (dialogIndex == 1) {
                            enterpriseTransferLinkard(linkardId);
                        }
                        OverAllDialogView.hideOverAllDialogView();
                    });
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const enterpriseTransferLinkard = async (linkardId: string) => {
            const param = {
                linkardId: linkardId,
                transferName: currentSelManagerItem.value.userName,
                transferPhone: currentSelManagerItem.value.userPhone
            };

            linkardNet.enterpriseTransferLinkard(param).then(
                (response) => {
                    ElMessage.success('转移成功');
                    emit("dismissAuthorityTransferView", 1);
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
            currentSelManagerItem.value = null;
        };


        watch(() => props.transferLinkardInfo, (newValue, oldValue) => {
            if (newValue) {
                linkardInfo.value = newValue;
                linkardQRImgUrl.value = GenerateQRCodeLink(newValue.url);
                enterpriseTransferableUserList(newValue.id)
            }
        });

        onMounted(() => {

        });

        return {
            managerList,
            currentManagerPage,
            selectedItem,
            tempManagerList,
            currentSelManagerItem,
            linkardInfo,
            linkardQRImgUrl,
            pageLeft,
            pageRight,
            managerItemClick,
            cancleButtonClick,
            sureButtonClick,
        };
    },
});

</script>

<style lang="less" scoped>
.authority_transfer_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(230, 230, 230, 0.95);
    backdrop-filter: blur(8px);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .center_content {
        width: 690px;
        height: 830px;
        border-radius: 40px;
        background: #F0F0F0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .post-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 36px;

            #posterHtml {
                position: relative;
                background: url('../assets/images/share-my.svg') no-repeat;
                width: 150px;
                height: 200px;
                background-size: cover;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;

            }

            .post-erweima {
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 6px;
                left: 10px;
                bottom: 20px;
            }

            .post-link-logo {
                width: 36px;
                height: 36px;
                border-radius: 18px;
                margin-top: 34px;
                margin-left: 20px;
                object-fit: cover;
            }

            .post-link-name {
                font-size: 8px;
                color: #fff;
                margin-left: 18px;
                font-weight: 600;
                margin-top: 6px;
                text-align: center;
            }


            .linkard_tags {
                margin-left: 10px;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 180px;

                .linkard_tags_bg {
                    margin-left: 6px;

                    span {
                        color: #f0f0f0;
                        border-radius: 16px;
                        border: 1px solid #F0F0F0;
                        font-size: 6px;
                        padding: 2px 6px;
                    }
                }
            }

            .post-link-avatar {
                width: 100px;
                height: 50px;
                border-radius: 10px 0px 0px 10px;
                background: #f0f0f0;
                position: absolute;
                right: 0;
                bottom: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .el-image {
                    height: 100%;
                }
            }

            .qr_code_image_bg {
                position: absolute;
                border-radius: 8px;
                left: 8px;
                bottom: 22px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    border-radius: 4px;
                }
            }

            .post-link-path {
                position: absolute;
                color: #f0f0f0;
                font-size: 6px;
                bottom: 20px;
                left: 50px;
            }

            .manage_card_button {
                position: absolute;
                top: 8px;
                right: 8px;
                width: 32px;
                height: 32px;
                background-color: #F0F0F0;
                border-width: 0;
                border-radius: 16px;
            }

            .dialogue_limit_alarm {
                position: absolute;
                bottom: 30px;
                background-color: #E70000;
                width: 128px;
                height: 24px;
                border-radius: 12px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                span {
                    color: #f0f0f0;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .card_name {
            margin-top: 16px;
            color: #242424;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
        }

        .manager_list_bg {
            width: 430px;
            height: 364px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            margin-top: 28px;

            .manager_list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                li {
                    margin: 0 0 24px 0;
                    position: relative;

                    .manager_item {
                        width: 430px;
                        height: 40px;
                        border-radius: 20px;
                        border: 2px solid #242424;
                        font-size: 18px;
                        font-weight: 600;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .manager_user_name {
                            width: 80px;
                            margin-left: 48px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .phone_number {
                            margin-left: 38px;
                            width: 200px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .linkard_cnt {
                            margin-left: 28px;
                            width: 180px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .manager_item_normal {
                        background: #F0F0F0;

                        span {
                            color: #242424;
                        }
                    }

                    .manager_item_selected {
                        background: #242424;

                        span {
                            color: #f0f0f0;
                        }
                    }

                    .manager_item_disable {
                        background: #F0F0F0;
                        border: 2px solid #c0c4cc;

                        span {
                            color: #c0c4cc;
                        }
                    }
                }

                li:nth-child(1n) {
                    margin-right: 0;
                }
            }

            .manager_list_bottom_view {
                height: 60px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .page_button {
                    width: 64px;
                    height: 44px;
                    border-width: 0;
                    border-radius: 22px;
                    background-color: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                .page_button:hover {
                    opacity: 0.7;
                }

                .page_button:active {
                    opacity: 0.6;
                }

                .el-button.is-disabled {
                    opacity: 0.6;
                }

                .page_number {
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    width: 50%;
                    background-color: #F0F0F0;
                }
            }
        }

        .empty_tips {
            color: #242424;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            width: 200px;
            margin-top: 25%;
        }

        .manager_list_bg::-webkit-scrollbar-track {
            display: none;
        }

        .manager_list_bg::-webkit-scrollbar {
            display: none;
        }

        .manager_list_bg::-webkit-scrollbar-thumb {
            display: none;
        }

        .bottom_action_bg {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 16px;

            .cancel_button {
                width: 180px;
                height: 64px;
                border-radius: 36px;
                border: 2px solid #242424;
                background: #F0F0F0;

                color: #242424;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
            }

            .sure_button {
                width: 180px;
                height: 64px;
                border-radius: 36px;
                border-width: 0;
                background: #242424;

                color: #F0F0F0;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                margin-left: 30px;
            }

            .action_button {
                cursor: pointer;
            }

            .action_button:hover {
                opacity: 0.8;
            }

            .action_button:active {
                opacity: 0.5;
            }
        }
    }
}
</style>