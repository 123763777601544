<template>
    <div class="linkard_item_bg" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" :style="boxStyle">
        <img :src="linkardInfo.avatar" alt="" class="linkard_logo">
        <div class="linkard_name">{{ linkardInfo.name }}</div>
        <div class="linkard_tags">
            <div class="linkard_tags_bg" v-for="(item, index) in linkardInfo.tags">
                <span v-if="item" :key="index">{{ item }}</span>
            </div>
        </div>
        <div class="linkard_avatar">
            <el-image v-if="linkardInfo.npcAvatar" :src="linkardInfo.npcAvatar" fit="cover" />
        </div>
        <span class="linkard_path">{{ linkardQRImgUrl }}</span>
        <div class="qr_code_image_bg">
            <vue-qrcode :value="linkardQRImgUrl" :color="{}" type="image/png" :width="60" :height="60" :margin="0"
                errorCorrectionLevel="L"></vue-qrcode>
        </div>
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { GenerateQRCodeLink } from '@/utils/serviceUtils';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import VueQrcode from 'vue-qrcode';

export default defineComponent({
    name: 'linkardInfoItem_Small',
    props: {
        linkardInfo: {
            type: Object as () => any,
            default: () => ({
                id: '',
                url: '',
                name: '',
                avatar: '',
                npcAvatar: '',
                tags: []
            })
        },
    },
    components: {
        VueQrcode
    },
    setup(props, { emit }) {

        let linkardQRImgUrl = ref('');
        const scale = ref(1);

        const handleMouseEnter = () => {
            scale.value = 1.02;
        };

        const handleMouseLeave = () => {
            scale.value = 1;
        };

        const boxStyle = computed(() => ({
            transform: `scale(${scale.value})`,
            transition: 'transform 0.3s ease',
        }));
        const generateQRCodeLink = (path: string) => {
            linkardQRImgUrl.value = GenerateQRCodeLink(path);
        };

        if (props.linkardInfo.url.length > 0) {
            generateQRCodeLink(props.linkardInfo.url);
        } else {
            watch(() => props.linkardInfo, (newValue, oldValue) => {
                generateQRCodeLink(newValue.url);
            });
        }

        onMounted(() => {
        });
        return {
            generateQRCodeLink,
            handleMouseEnter,
            handleMouseLeave,
            boxStyle,
            linkardQRImgUrl,
        };
    },
});

</script>

<style lang="less" scoped>
.linkard_item_bg {
    position: relative;
    background: url('../assets/images/linkard_item_bg.svg') no-repeat;
    width: 270px;
    height: 360px;
    background-size: cover;
    border-radius: 40px;
    cursor: pointer;

    .linkard_path {
        position: absolute;
        color: #f0f0f0;
        font-size: 10px;
        bottom: 36px;
        left: 90px;
    }

    .qr_code_image_bg {
        position: absolute;
        border-radius: 8px;
        left: 12px;
        bottom: 40px;
        display: flex;
        padding: 3px;
        background-color: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            // border-radius: 4px;
        }
    }

    .linkard_logo {
        width: 60px;
        height: 60px;
        border-radius: 32px;
        margin-top: 52px;
        margin-left: 26px;
        object-fit: cover;
    }

    .linkard_name {
        font-size: 13px;
        color: #fff;
        margin-left: 26px;
        font-weight: 600;
        margin-top: 4px;
        text-align: center;
    }

    .linkard_tags {
        margin-left: 14px;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .linkard_tags_bg {
            margin-top: 8px;

            span {
                color: #f0f0f0;
                border-radius: 16px;
                border: 0.2px solid #F0F0F0;
                font-size: 8px;
                padding: 2px 6px;
                margin-left: 6px;
            }
        }
    }

    .linkard_avatar {
        width: 180px;
        height: 90px;
        border-radius: 20px 0px 0px 20px;
        background: #f0f0f0;
        position: absolute;
        right: 0;
        bottom: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        :deep(.el-image) {
            height: 100%;
        }
    }
}
</style>