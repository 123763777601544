<template>
    <div class="login_content_bg">
        <div class="linkcard_tips_bg">
            <div class="left_text_bg">
                <img class="left_tips_img" src="../assets/images/lincard_left_tips_img.svg" alt="" />
                <span>轻松配置你的专属 AI 智能体</span>
            </div>
            <div class="right_tips_view">
                <div class="right_cover_card"></div>
                <img class="lincard_rigtht_text_img" src="../assets/images/lincard_rigtht_text.svg" alt="">
                <img class="right_line_tips_img" src="../assets/images/right_line_tips_img.svg" alt="">
            </div>
        </div>
        <div class="content-wrapper">
            <div class="card-wrapper">
                <div class="left-wrapper">
                    <div class="tab active">他们在用</div>
                </div>
                <div class="right-wrapper">
                    <ul class="card-list" v-infinite-scroll="getCollectListRequest"
                        :infinite-scroll-disabled="noMoreCollectListData">
                        <li v-for="(linkInfo, index) in collectList" :key="index">
                            <LinkardInfoItem :linkardInfo="linkInfo"></LinkardInfoItem>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { WxLogin } from '../utils/weixinLogin';
import { LinkardItemInfo, linkardNet } from '@/net/linkardNet';
import LinkardInfoItem from '../components/linkardInfoItem_Middle.vue';

const screenWidth = window.screen.width;
let wxQrCodeStyle = ''
if (screenWidth > 1280 && screenWidth <= 1440) {//240
    wxQrCodeStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI0MHB4O2hlaWdodDoyNDBweDttYXJnaW4tdG9wOjIwcHg7Ym9yZGVyLXJhZGl1czoyOHB4fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTAwJTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7bWFyZ2luOiAwIDAgNHB4IDA7fQ0KLnN0YXR1c19mYWlsIHtkaXNwbGF5OiBmbGV4O2ZsZXgtZGlyZWN0aW9uOiBjb2x1bW47YWxpZ24taXRlbXM6IGNlbnRlcjt9DQouc3RhdHVzX3N1Y2Mge2Rpc3BsYXk6IGZsZXg7ZmxleC1kaXJlY3Rpb246IGNvbHVtbjthbGlnbi1pdGVtczogY2VudGVyO30NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'
} else if (screenWidth > 1440 && screenWidth <= 1920) {//250
    wxQrCodeStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI1MHB4O2hlaWdodDoyNTBweDttYXJnaW4tdG9wOjIwcHg7Ym9yZGVyLXJhZGl1czoyOHB4fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTAwJTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7bWFyZ2luOiAwIDAgNHB4IDA7fQ0KLnN0YXR1c19mYWlsIHtkaXNwbGF5OiBmbGV4O2ZsZXgtZGlyZWN0aW9uOiBjb2x1bW47YWxpZ24taXRlbXM6IGNlbnRlcjt9DQouc3RhdHVzX3N1Y2Mge2Rpc3BsYXk6IGZsZXg7ZmxleC1kaXJlY3Rpb246IGNvbHVtbjthbGlnbi1pdGVtczogY2VudGVyO30NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'
} else if (screenWidth > 1920) {//260
    wxQrCodeStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI2MHB4O2hlaWdodDoyNjBweDttYXJnaW4tdG9wOjQwcHg7Ym9yZGVyLXJhZGl1czoyOHB4fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTAwJTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7bWFyZ2luOiAwIDAgNHB4IDA7fQ0KLnN0YXR1c19mYWlsIHtkaXNwbGF5OiBmbGV4O2ZsZXgtZGlyZWN0aW9uOiBjb2x1bW47YWxpZ24taXRlbXM6IGNlbnRlcjt9DQouc3RhdHVzX3N1Y2Mge2Rpc3BsYXk6IGZsZXg7ZmxleC1kaXJlY3Rpb246IGNvbHVtbjthbGlnbi1pdGVtczogY2VudGVyO30NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'
}

export default defineComponent({
    name: 'Login',
    components: {
        LinkardInfoItem
    },
    setup(props, { emit }) {
        let pageNumber = 1;
        let pageSize = 12;
        let noMoreCollectListData = ref(false);
        let collectList: Ref<LinkardItemInfo[]> = ref([]);
        let loginQRVisible = ref(false);
        const getCollectListRequest = () => {
            linkardNet.getCollectList(pageNumber, pageSize).then(
                (response) => {
                    if (response.list.length > 0) {
                        response.list.forEach(item => {
                            collectList.value.push(item);
                        });
                    }
                    if (response.list.length < pageSize) {
                        noMoreCollectListData.value = true;
                    } else {
                        pageNumber += 1;
                        noMoreCollectListData.value = false;
                    }
                },
                (ex) => {

                },
            );
        }

        const initWxLogin = (): void => {
            const obj: WxLogin = new WxLogin({
                id: 'qrcode-container',// 需要显示的容器id
                appid: process.env.VUE_APP_WX_APPID,
                scope: 'snsapi_login',
                redirect_uri: encodeURIComponent(process.env.VUE_APP_WX_URL),
                state: new Date().getTime() + '' + Math.ceil(Math.random() * 1000), //建议使用时间戳+随机数，具体看你
                // style: 'style',// 提供"black"、"white"可选。二维码的样式
                href: wxQrCodeStyle
            });
        };

        const loginButtonClick = () => {
            loginQRVisible.value = true;
            setTimeout(() => {
                initWxLogin();
            }, 50);
        };

        onMounted(() => {
        });
        return {
            collectList,
            noMoreCollectListData,
            loginQRVisible,
            getCollectListRequest,
            loginButtonClick
        };
    },
});

</script>

<style lang="less" scoped>
.login_content_bg {
    width: 100%;
    height: calc(100vh - 168px);
    padding: 0 80px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .header-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            

            .logo-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    width: 88px;
                    height: 88px;
                }

                .linkard_en_name {
                    margin: 0 20px 0 48px;
                }

                .linkard_name {
                    min-width: 50px;
                    color: #242424;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .line_div {
                width: 578px;
                height: 2px;
                margin-left: 136px;
                background-color: #242424;
            }
        }

        .login_btn {
            width: 200px;
            height: 64px;
            font-weight: 600;
            border-radius: 36px;
            font-size: 18px;
            border: 4px solid #242424;
            background-color: #F3EC3F;
            color: #242424;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        }

        .login_btn:hover {
            opacity: 0.7;
        }

        .login_btn:active {
            opacity: 0.5;
        }
    }

    .linkcard_tips_bg {
        position: absolute;
        margin: 10px 0 0 130px;
        width: 1550px;
        z-index: -1000;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .left_text_bg {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            color: #242424;

            .left_tips_img {
                width: 432px;
                height: 261px;
            }

            span {
                color: #242424;
                font-family: LXGW 975 HazyGo SC;
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-top: 10px;
            }
        }

        .right_tips_view {
            margin-left: -260px;
            z-index: -1000;

            .lincard_rigtht_text_img {
                margin-top: 60px;
            }

            .right_cover_card {
                position: absolute;
                top: 0;
                left: 230px;
                width: 460px;
                height: 300px;
                border-radius: 20px;
                background: rgba(243, 236, 63, 0.90);
                backdrop-filter: blur(4px);
                z-index: -1;
            }

            .right_line_tips_img {
                position: absolute;
                right: 0;
            }
        }
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        margin-top: 380px;
    }

    .card-wrapper {
        display: flex;

        .left-wrapper {
            margin-bottom: 22px;
            border-bottom: 1px solid #242424;

            .tab {
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 280px;
                height: 64px;
                background: #e6e6e6;
                color: #242424;
                border: 2px solid #242424;
                border-radius: 32px;
                margin-bottom: 20px;
            }

            .active {
                background: #242424;
                color: #e6e6e6;
            }
        }

        .right-wrapper {
            height: 40vh;
            margin-left: 180px;
            overflow: auto;

            .card-list {
                display: flex;
                flex-wrap: wrap;
                padding: 10px 0 0 10px;

                li {
                    margin: 0 30px 22px 0;
                    position: relative;

                    img {
                        width: 317px;
                        height: 400px;
                    }

                    .mask {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 28px;
                        background: rgba(243, 236, 63, 0.9);
                        backdrop-filter: blur(4px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0;
                        transition: all 0.2s ease-in-out;

                        div {
                            color: #242424;
                            text-align: center;
                            font-size: 24px;
                            font-weight: 600;
                        }
                    }
                    .post-wrapper {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .btn-post {
                            margin-top: 57px;
                        }
                    }
                }

                li:nth-child(4n) {
                    margin-right: 0;
                }

                li:hover .mask {
                    opacity: 1;
                }
            }

            .data_count_tips {
                width: 100%;
                height: 40px;
                line-height: 60px;
                color: #242424;
                font-size: 16px;
                font-weight: 600;
                text-align: center;
            }
        }

        .right-wrapper::-webkit-scrollbar-track {
            background-color: transparent;
        }

        .right-wrapper::-webkit-scrollbar {
            width: 10px;
        }

        .right-wrapper::-webkit-scrollbar-thumb {
            width: 4px;
            background-color: #242424;
            border-radius: 10px;
        }
    }
}
</style>