<template>
    <div class="space_content_bg">
        <div v-if="!hasSpaceInfo" class="space_empty">
            <img class="empty_img" :src="require('../assets/images/collect-no.svg')" />
            <span>暂无内容</span>

            <div class="bottom_creat_bg">
                <button class="bottom_button creat_space_button" @click="creatButtonClick">
                    <img class="space_button_icon" :src="require('../assets/images/creat_space_btn.svg')" alt="">
                    <span>创建空间</span>
                </button>
                <button class="bottom_button bind_space_button" @click="bindButtonClick">
                    <img class="space_button_icon" :src="require('../assets/images/bind_space_icon.svg')" alt="">
                    <span>绑定天海现场空间</span>
                </button>
            </div>
        </div>

        <div v-else class="space_info_bg">
            <div class="space_info">
                <div class="user_space">
                    <el-image class="space_img" :src="spaceInfo.coverImgUrl">
                    </el-image>
                    <div class="space_status_bg">
                        <div :class="[spaceInfo.status == 2 ? 'space_status_mark_live' : 'space_status_mark_end']">
                        </div>
                        <span class="space_status_text">{{ spaceInfo.status == 2 ? 'Live' : 'Ended' }}</span>
                    </div>
                    <button class="unbind_or_delete_btn" @click="unbindOrDeleteBtnClick">
                        <img class="unbind_or_delete_icon" :src="require('../assets/images/unbind_or_delete_btn.svg')"
                            alt="">
                    </button>
                    <button class="goto_space_btn" @click="gotoSpaceBtnClick">前往空间</button>
                </div>
                <span class="space_name" v-text="spaceInfo.activityName"></span>
                <span class="space_creator" v-text="spaceInfo.creatorName"></span>
            </div>
            <div class="bottom_creat_bg">
                <button class='bottom_button creat_can_not_click' @click="creatButtonClick">
                    <img class="space_button_icon" :src="require('../assets/images/creat_space_btn_disable.svg')"
                        alt="">
                    <span>创建空间</span>
                </button>
                <button class='bottom_button bind_can_not_click' @click="bindButtonClick">
                    <img class="space_button_icon" :src="require('../assets/images/bind_space_icon_disable.svg')"
                        alt="">
                    <span>绑定天海现场空间</span>
                </button>
            </div>
            <span v-if="tipsText" class="tips_bg">{{ tipsText }}</span>
        </div>
        <SpaceModelList v-if="showSpaceModelList" :linkardId="linkardId" :linkardType="linkardType"
            @dismissModelList="dismissModelList">
        </SpaceModelList>
        <BindSpaceView v-if="showBindSpaceView" :linkardId="linkardId" @dismissBindView="dismissBindView">
        </BindSpaceView>
        <DialogView v-if="showDialog" @sureBtnClick="sureButtonClick" @cancelBtnClick="cancelButtonClick"
            :showDialog="showDialog" :dialog-text="dialogText" sure-button="确认删除" cancel-button="返回"></DialogView>
    </div>
</template>

<script lang="ts">
import { linkardNet } from '@/net/linkardNet';
import { ElMessage } from 'element-plus';
import { Ref, defineComponent, onMounted, ref, watch } from 'vue';
import SpaceModelList from "./spaceModelList.vue";
import BindSpaceView from "./bindSpaceView.vue";
import DialogView from "./dialogView.vue";

export default defineComponent({
    name: 'CreatSpaceView',
    components: {
        SpaceModelList,
        BindSpaceView,
        DialogView,
    },
    props: {
        linkardType: {
            type: Number,
            default: 0,
        },
        linkardId: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {

        let hasSpaceInfo = ref(false);
        let showDialog = ref(false);
        let showSpaceModelList = ref(false);
        let showBindSpaceView = ref(false);
        let sureText = ref('确认删除');
        let dialogText = ref('删除后联卡将无法访问该空间， 且不可恢复；请确认是否删除？');
        let dialogType = ref(0);//1:删除，0:解绑
        let spaceInfo: Ref<any> = ref();
        let tipsText = ref('');

        const spaceModelItemClick = () => {

        };

        const creatButtonClick = () => {
            if (hasSpaceInfo.value) {
                tipsText.value = "联卡已创建或绑定空间，请先删除已有空间";
                controlTips();
            } else {
                showSpaceModelList.value = true;
            }
        };

        const bindButtonClick = () => {
            if (hasSpaceInfo.value) {
                tipsText.value = "联卡已创建或绑定空间，请先删除已有空间";
                controlTips();
            } else {
                showBindSpaceView.value = true;
            }
        };

        const controlTips = () => {
            setTimeout(() => {
                tipsText.value = '';
            }, 3000);
        };

        const dismissModelList = () => {
            showSpaceModelList.value = false;
            checkLinkardSpace();
        };

        const dismissBindView = () => {
            showBindSpaceView.value = false;
            checkLinkardSpace();
        };

        const unbindOrDeleteBtnClick = () => {
            showDialog.value = true;
        };

        const gotoSpaceBtnClick = () => {
            if (spaceInfo.value.status != 2) {
                ElMessage.error('空间已结束');
                return;
            }
            const token = sessionStorage.getItem('token') || '';
            const _href = `${process.env.VUE_APP_SERVER_URL}/v2/home/space/edit?T-Validate-Token=${token}&id=${props.linkardId}`;
            window.open(_href, "_blank");
        };

        const sureButtonClick = () => {
            showDialog.value = false;
            if (dialogType.value == 0) {
                bindSpace(0);
            } else {
                deleteSpace();
            }
        };

        const cancelButtonClick = () => {
            showDialog.value = false;
            if (dialogType.value == 0) {

            } else {

            }
        };

        const checkLinkardSpace = async () => {

            linkardNet.checkLinkardSpace(props.linkardId).then(
                (response) => {
                    hasSpaceInfo.value = true;
                    spaceInfo.value = response;
                    dialogType.value = spaceInfo.value.activitySource;
                    if (dialogType.value == 0) {
                        sureText.value = "确认解绑";
                        dialogText.value = "解绑后联卡将无法访问该空间； 请确认是否解绑？";
                    } else {
                        sureText.value = "确认删除";
                        dialogText.value = "删除后联卡将无法访问该空间， 且不可恢复；请确认是否删除？";
                    }
                },
                (ex) => {
                    hasSpaceInfo.value = false;
                    spaceInfo.value = null;
                },
            );
        };

        const deleteSpace = async () => {

            linkardNet.deleteSpace({
                linkardId: props.linkardId,
                spaceId: spaceInfo.value.activityId
            }).then(
                (response) => {
                    hasSpaceInfo.value = false;
                    spaceInfo.value = null;
                },
                (ex) => {

                },
            );
        };

        const bindSpace = async (bindType: number) => {
            linkardNet.bindSpace({
                linkardId: props.linkardId,
                spaceId: spaceInfo.value.activityId,
                bindType: bindType
            }).then(
                (response) => {
                    hasSpaceInfo.value = false;
                    spaceInfo.value = null;
                },
                (ex) => {

                },
            );
        };


        watch(() => props.linkardId, (newValue, oldValue) => {
            if (newValue) {
                checkLinkardSpace();
            }
        });

        onMounted(() => {
            checkLinkardSpace();
        });

        return {
            hasSpaceInfo,
            showDialog,
            showSpaceModelList,
            showBindSpaceView,
            sureText,
            dialogText,
            dialogType,
            spaceInfo,
            tipsText,
            creatButtonClick,
            bindButtonClick,
            dismissModelList,
            unbindOrDeleteBtnClick,
            gotoSpaceBtnClick,
            dismissBindView,
            sureButtonClick,
            cancelButtonClick
        };
    },
});

</script>

<style lang="less" scoped>
.space_content_bg {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;

    .space_empty {
        width: 100%;
        height: auto;
        margin: 0 0 100px 0;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        align-items: center;

        .empty_img {
            width: 100px;
        }
    }

    .space_info_bg {
        width: 100%;
        height: 100%;
        margin: auto;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 120px;

        .space_info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .user_space {
                width: 600px;
                height: 280px;
                border-radius: 26px;
                border: 8px solid #F3EC3F;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                position: relative;

                .space_img {
                    height: 100%;
                    width: 100%;
                    border-radius: 20px;
                }

                .unbind_or_delete_btn {
                    cursor: pointer;
                    width: 88px;
                    height: 36px;
                    background-color: rgb(240, 240, 240);
                    border-width: 0;
                    border-radius: 18px;
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    align-items: center;
                    display: flex;
                    flex-direction: row;


                    .unbind_or_delete_icon {
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        right: 18px;
                    }
                }

                .goto_space_btn {
                    cursor: pointer;
                    width: 88px;
                    height: 36px;
                    background-color: rgb(240, 240, 240);
                    border-width: 0;
                    border-radius: 18px;
                    position: absolute;
                    bottom: 12px;
                    right: 12px;

                    color: #242424;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px;
                }

                .unbind_or_delete_btn:active {
                    opacity: 0.5;
                }

                .space_status_bg {
                    width: auto;
                    height: 24px;
                    background: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                    border-radius: 12px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 18px 0 10px;
                    left: 16px;
                    bottom: 18px;
                    position: absolute;

                    .space_status_mark_live {
                        width: 12px;
                        height: 12px;
                        border-radius: 6px;
                        background-color: #E70000;
                    }

                    .space_status_mark_end {
                        width: 12px;
                        height: 12px;
                        border-radius: 6px;
                        background-color: rgb(127, 127, 127);
                    }

                    .space_status_text {
                        color: 242424;
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-left: 5px;
                    }
                }
            }

            .space_name {
                color: #242424;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-top: 20px;
            }

            .space_creator {
                color: #242424;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 6px;
            }
        }
    }

    .bottom_creat_bg {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 84px;

        .bottom_button {
            width: 320px;
            height: 64px;
            border-radius: 32px;
            font-weight: 600;
            font-size: 18px;
            background-color: #242424;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-width: 0;
            cursor:pointer;

            span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }

        .bottom_button:hover {
            opacity: 0.9;
        }

        .bottom_button:active {
            opacity: 0.5;
        }

        .creat_space_button {
            margin-right: 20px;
        }

        .bind_space_button {
            margin-left: 20px;
        }

        .creat_can_not_click {
            background-color: transparent;
            border: 2px solid rgba(36, 36, 36, 1);
            color: rgba(36, 36, 36, 1);
            opacity: 0.6;
            margin-right: 20px;
        }

        .bind_can_not_click {
            background-color: transparent;
            border: 2px solid rgba(36, 36, 36, 1);
            color: rgba(36, 36, 36, 1);
            opacity: 0.6;
            margin-left: 20px;
        }

        .space_button_icon {
            width: 24px;
            height: 24px;
            margin-right: 18px;
            background-size: cover;
        }
    }

    .tips_bg {
        padding: 0 30px;
        position: absolute;
        bottom: 24px;
        height: 28px;
        border-radius: 40px;
        background: #F3EC3F;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        color: #242424;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }
}
</style>
