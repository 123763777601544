<template>
    <div class="trigger_view_bg">
        <div class="trigger_view_content">
            <video-player class="video-js play_view_content" :src="showVideoUrl" @dispose="closeVideoButtonClick"
                :options="playerOptions" :volume="0.6" />
            <button class="close_button" @click="closeVideoButtonClick">
                <img :src="require('../assets/images/delete_button_icon.svg')" alt="">
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    components: {
    },
    props: {
        showVideoUrl: {
            type: String,
            default: '',
        }
    },
    setup(props, { emit }) {

        let playerOptions = ref({
            playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
            autoplay: true, // 如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: true, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controls: true,
            sources: [{
                type: "video/mp4", // 类型
                src: props.showVideoUrl, // url地址
            }],
            controlBar: {
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: false,
                fullscreenToggle: true // 全屏按钮
            }
        });

        onMounted(() => {
        });

        const closeVideoButtonClick = () => {
            emit("dismissVideoTriggerPlay");
        };

        return {
            playerOptions,
            closeVideoButtonClick
        }
    }
});
</script>

<style lang="less" scoped>
.trigger_view_bg {
    background: #242424;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;

    .trigger_view_content {
        width: 80%;
        height: 44.8vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        border-radius: 14px;
        border: 2px solid #f0f0f0;

        .play_view_content {
            width: 100%;
            height: 100%;
            border-radius: 14px;
            background-color: transparent !important;
            box-sizing: border-box;

            :deep(.vjs-tech) {
                border-radius: 14px;
            }

            :deep(.video-js) {
                background-color: transparent !important;
            }

            :deep(.vjs-control-bar) {
                background-color: transparent !important;
                background: linear-gradient(180deg, hsla(0, 0%, 90%, 0), #242424);
                border-bottom-left-radius: 14px;
                border-bottom-right-radius: 14px;
            }
        }

        .close_button {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 60px;
            height: 36px;
            border-radius: 18px;
            border-width: 0;
            background: rgba(240, 240, 240, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                width: 12px;
                height: 12px;
            }
        }
    }
}
</style>
