// 导入依赖
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
//邮箱正则验证
export function validateEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

//手机号正则验证
export function validatePhone(number: string): boolean {
  const emailRegex = /(^1\d{10}$)|(^[0-9]\d{7}$)/;
  return emailRegex.test(number);
}

//url 正则验证
export function isValidURL(url: string): boolean {
  var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
}

//手机号去掉区号
export function phoneWithOutReginPrefix(phoneNumber: string): string {
  let phoneNumberNoPrefix = "";
  if (phoneNumber.includes("+86,")) {
    phoneNumberNoPrefix = phoneNumber.replace("+86,", "");
  } else {
    phoneNumberNoPrefix = phoneNumber;
  }
  return phoneNumberNoPrefix;
}

//加密手机号
export function encryptPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) return "";
  let phoneNumberNoPrefix = "";
  if (phoneNumber.includes("+86,")) {
    phoneNumberNoPrefix = phoneNumber.replace("+86,", "");
  } else {
    phoneNumberNoPrefix = phoneNumber;
  }
  const digits = phoneNumberNoPrefix.match(/\d/g);
  if (digits && digits.length >= 5) {
    const encrypted =
      digits.slice(0, 3).join("") + "****" + digits.slice(-4).join("");
    return encrypted;
  } else {
    return "";
  }
}

// 删除节点
const removeNodeMethod = (selectors: HTMLElement | NodeList) => {
  const removeNode: { parent: HTMLElement; inner: HTMLElement }[] = [];
  if (selectors instanceof NodeList) {
    const len = selectors.length;
    for (let i = 0; i < len; i++) {
      const selector = selectors[i] as HTMLElement;
      console.log(selector);
      removeNode.push({
        parent: selector.parentNode as HTMLElement,
        inner: selector,
      });
    }
    for (let i = 0; i < len; i++) {
      (selectors[i] as HTMLElement).remove();
    }
  } else {
    removeNode.push({
      parent: (selectors as HTMLElement).parentNode as HTMLElement,
      inner: selectors as HTMLElement,
    });
    (selectors as HTMLElement).remove();
  }
  return removeNode;
};
// 恢复节点
const recoverNode = (
  selectors: { parent: HTMLElement; inner: HTMLElement }[]
) => {
  const len = selectors.length;
  for (let i = 0; i < len; i++) {
    const node = selectors[i];
    node.parent.appendChild(node.inner);
  }
};

/**
 * 导出Excel表格
 * @param name 生成excel的文件名，如：interestTable.xlsx
 * @param tableName 表格的id，如：#tableId
 * */
export const exportExcel = (
  name: string,
  tableName: string,
  hideColumns: number[]
) => {
  let sel = XLSX.utils.table_to_book(document.querySelector(tableName));
  // let tmpNodeArray: { parent: HTMLElement; inner: HTMLElement }[][] = [];
  // if (hideColumnClassName.length >= 0) {
  //   hideColumnClassName.forEach((item) => {
  //     let fix = document.querySelectorAll(item);
  //     // 调用删除节点方法
  //     const removedNodes = removeNodeMethod(fix);
  //     tmpNodeArray.push(removedNodes);
  //   });
  // }

  sel.Sheets["Sheet1"]["!cols"] = [];

  for (var i = 0; i <= hideColumns.length; i++) {
    sel.Sheets["Sheet1"]["!cols"][hideColumns[i]] = { hidden: true };
  }

  sel.Sheets["Sheet1"]["!cols"] = [{ wch: 30 }, { wch: 20 }, { wch: 20 }];

  let selIn = XLSX.write(sel, {
    bookType: "xlsx",
    bookSST: true,
    type: "array",
  });

  try {
    FileSaver.saveAs(
      new Blob([selIn], { type: "application/octet-stream" }),
      name
    );

    // if (tmpNodeArray.length > 0) {
    //   tmpNodeArray.forEach((item) => {
    //     // 调用恢复节点方法
    //     recoverNode(item);
    //   });
    // }
  } catch (e) {
    if (typeof console !== "undefined") console.log(e, selIn);
  }

  return selIn;
};
