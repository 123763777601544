<template>
    <div class="home-wrapper">
        <div class="edit-content-wrapper">
            <div class="left_content_bg">
                <div class="left">
                    <iframe id="iframe" ref="mobileH5Ref" @load="onPreviewLoad" :src="mobileH5Url" frameborder="0"
                        width="100%" height="100%" :key="refreshIframe"></iframe>
                </div>
                <div class="right">
                    <div class="action_btn_bg">
                        <div :class="[
                        item.index === tabIndex ? 'left-btn left-btn-active' : 'left-btn left-btn-hover-style',
                        item.uncomplate ? 'require' : '',
                    ]" v-for="item in tabList" :key="item.index" @click="changeTab(item.index)">
                            {{ item.text }}
                            <span class="require-tips" v-show="item.uncomplate">*请填入必选项</span>
                        </div>
                        <div :class="[
                        'left-btn',
                        'left-btn-3d',
                        (!linkardId || linkardId == 'undefined' || !isOwnLinakrd) ? 'left-btn-3d-disabled' : '', tabIndex === 4 ? 'left-btn-active' : 'left-btn-hover-style',
                    ]" @click="toSpace">
                            <span>{{ baseInfo.name }}的数字空间</span>
                            <img :src="tabIndex === 4 ? require('../assets/images/right_selected.png') : require('../assets/images/right.png')"
                                alt="" />
                        </div>
                    </div>
                    <div class="left-save-wrapper">
                        <button class="save-btn" @click="saveEdit">
                            完成配置
                        </button>
                        <button class="back-btn" @click="handleBack">返回</button>
                        <div class="has_change_tips">*请及时点击上方“完成配置”保存修改</div>
                    </div>
                </div>
            </div>
            <div class="right_content_bg">
                <transition name="subcomponents" mode="out-in">
                    <BaseInfoView v-show="tabIndex === 0" :baseInfo="baseInfo" :labelList="labelList"
                        :operation="currentLinkardInfo.operation" @updatePreview="updateBaseInfoPreviewInfo">
                    </BaseInfoView>
                </transition>

                <transition name="subcomponents" mode="out-in">
                    <SocialMediaView v-show="tabIndex === 1" :selectSocialLinkTop="selectSocialLinkTop"
                        :selectSocialLinkNormalList="selectSocialLinkNormalList"
                        :mediaCount="linkardConfigInfo?.mediaCount" @deleteTopLink="deleteTopLink">
                    </SocialMediaView>
                </transition>
                <transition name="subcomponents" mode="out-in">
                    <DigitalRoleView v-show="tabIndex === 2" @getDigitalRoleInfo="getDigitalRoleInfo"
                        @soundSettingUpdate="soundSettingUpdate" :tempCharacterId="currentLinkardInfo.npc.characterId"
                        :tempBackground="currentLinkardInfo.npc.background"
                        :tempBackgroundId="currentLinkardInfo.npc.backgroundId"
                        :personality="currentLinkardInfo.npc.personality" :careerList="linkardConfigInfo?.careers"
                        :toneList="linkardConfigInfo?.tones" :charactList="linkardConfigInfo?.characters"
                        :initialCapabilityList="linkardConfigInfo?.abilities" :voices="linkardConfigInfo?.voices"
                        :voiceId="currentLinkardInfo.npc.voice?.voiceId ? currentLinkardInfo.npc.voice.voiceId : ''"
                        :characterShow="currentLinkardInfo.npc.characterShow"
                        :backgroundShow="currentLinkardInfo.npc.backgroundShow"
                        @characterAndBgShowChange="characterAndBgShowChange">
                    </DigitalRoleView>
                </transition>
                <transition name="subcomponents" mode="out-in">
                    <DigitalChatSetting v-show="tabIndex === 3" ref="digitalChatSetting"
                        :npcDetail="currentLinkardInfo.npc" :modelLibraryList="linkardConfigInfo?.availableModels"
                        :linkardId="linkardId" :linkardType="currentLinkardInfo.type"
                        @largeModelUpdate="largeModelUpdate">
                    </DigitalChatSetting>
                </transition>
                <transition name="subcomponents" mode="out-in">
                    <CreatSpaceView :linkardId="linkardId" :linkardType="cardType" v-show="tabIndex === 4">
                    </CreatSpaceView>
                </transition>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { SocialLinkItem, LinkardBaseInfo, BackGroundImgItem, LKConfigResponse, linkardNet, CareersItem } from '../net/linkardNet';
import { ACCESS_TOKEN, GetPreviewUrl, NavigatorRoute, UserLevelType } from '@/utils/const';
import { ElMessage } from 'element-plus';
import { HttpResponse } from '@/net/http';

import CreatSpaceView from "../components/creatSpaceView.vue";
import BaseInfoView from "../components/baseInfoView.vue";
import SocialMediaView from "../components/socialMediaView.vue";
import DigitalChatSetting from "../components/digitalChatSetting.vue";
import DigitalRoleView from "../components/digitalRoleView.vue";
import { useUserStore } from '@/stores/user';
import { Loading } from '../components/loadingView/customLoading';

export default defineComponent({
    name: 'EditCard',
    components: {
        BaseInfoView,
        SocialMediaView,
        DigitalRoleView,
        DigitalChatSetting,
        CreatSpaceView
    },
    setup(props, { emit }) {

        const router = useRouter();
        const route = useRoute();

        let iframeLoaded = false;
        let mobileH5Url = GetPreviewUrl();
        let refreshIframe = Math.random();
        let renderIframeTimer = 0;
        let firstEditSetInfoTimer = 0;
        let iframeData: any;
        let digitalChatSetting = ref();
        const userStore = useUserStore();

        const editType = Number(route.query.editType);//editType:0:新建，1:编辑
        const cardType = ref(Number(route.query.cardType));//cardType:0:体验卡，1:正式卡

        let linkardConfigInfo: Ref<LKConfigResponse | undefined> = ref();
        let currentLinkardInfo: Ref<any> = ref({
            id: null,
            type: 0,
            name: '',
            style: 'card',
            pathAllowChanges: true,
            path: '',
            icon: '',
            tags: ['', '', ''],
            operation: {
                phoneOpen: false,
                phone: '',
                messageOpen: false,
            },
            isOwn: false,
            spaceValid: false,
            socialLinks: [],
            npc: {
                characterId: '',
                backgroundShow: true,
                characterShow: true,
                filepath: '',
                avatar: '',
                welcomeMsg: '',
                backgroundId: '',
                background: '',
                personality: {
                    npcName: '',
                    career: null,
                    tone: null,
                    ability: null,
                    introduction: ''
                },
                voice: {
                    voiceId: null
                },
                largeModel: ''
            },
            snapshot: '',
        });

        let linkardId = ref('');
        let mobileH5Ref = ref();
        let tabIndex = ref(0);
        let hasDataChange = ref(false);

        let isSubmit = ref(false);
        let isOwnLinakrd = ref(true);

        linkardId.value = String(route.query.linkardId);

        let labelList: Ref<string[]> = ref([]);
        let baseInfo: Ref<LinkardBaseInfo> = ref({
            name: "",
            labelName: "",
            labelValue1: "",
            labelValue2: "",
            icon: "",
            style: "card"
        });

        let selectSocialLinkTop: Ref<SocialLinkItem> = ref({
            icon: "",
            name: "",
            url: "",
            isTop: true,
        });


        let selectSocialLinkNormalList: Ref<SocialLinkItem[]> = ref([]);
        // const backImgList: Ref<BackGroundImgItem[]> = ref([]);


        const tabList: any = computed(() => {
            return [
                {
                    index: 0,
                    text: "基础资料",
                    uncomplate: isSubmit.value && !baseInfoFinish(),
                },
                {
                    index: 1,
                    text: "社媒链接",
                },
                {
                    index: 2,
                    text: "智能体角色",
                    uncomplate: isSubmit.value && !npcFinish(),
                },
                {
                    index: 3,
                    text: "智能体学习",
                },
            ];
        });

        const baseInfoFinish = () => {
            return (
                baseInfo.value.name &&
                baseInfo.value.icon
            );
        };

        const npcFinish = () => {
            return currentLinkardInfo.value.npc.personality.npcName;
        };

        if (!Number.isNaN(editType)) {
        }

        if (!Number.isNaN(cardType.value)) {
            currentLinkardInfo.value.type = cardType.value;
        }

        if (linkardId.value != 'undefined') {
        }

        const firstEditSetInfoTimerMethod = () => {
            if (currentLinkardInfo.value.npc.backgroundId && currentLinkardInfo.value.npc.characterId) {
                clearInterval(firstEditSetInfoTimer); // 停止定时器
                firstEditSetInfoTimer = 0;
                handlePreview(currentLinkardInfo.value);
            }
        };

        const getMyLinkardConfigRequest = () => {
            linkardNet.getLinkardConfigInfo(cardType.value).then(
                (response) => {
                    linkardConfigInfo.value = response;

                    linkardConfigInfo.value.careers.unshift({
                        id: null,
                        name: '(空)',
                        level: -1,
                        allow: true
                    });
                    if (editType == 0 && (linkardId.value == 'undefined' || !linkardId.value)) {
                        //新建 默认选中第一个NPC
                        if (linkardConfigInfo.value.characters.length > 0) {
                            currentLinkardInfo.value.npc.characterId = linkardConfigInfo.value.characters[0].characterId;
                            currentLinkardInfo.value.npc.filepath = linkardConfigInfo.value.characters[0].filepath;
                            currentLinkardInfo.value.npc.voice.voiceId = linkardConfigInfo.value.characters[0].voiceId;
                            if (!firstEditSetInfoTimer) {
                                firstEditSetInfoTimer = setInterval(firstEditSetInfoTimerMethod, 100);
                            }
                        }
                    }
                },
                (ex) => {

                },
            );
        }

        const getLinkardInfoRequest = () => {
            linkardNet.getLinkardInfo(linkardId.value).then(
                (response) => {
                    currentLinkardInfo.value = response;
                    if (currentLinkardInfo.value.operation == undefined || currentLinkardInfo.value.operation == 'undefined') {
                        currentLinkardInfo.value['operation'] = {
                            phoneOpen: false,
                            phone: '',
                            messageOpen: true,
                        };
                    }

                    if (currentLinkardInfo.value.npc.characterShow == undefined || currentLinkardInfo.value.npc.characterShow == 'undefined') {
                        currentLinkardInfo.value.npc['characterShow'] = true;
                    }
                    if (currentLinkardInfo.value.npc.backgroundShow == undefined || currentLinkardInfo.value.npc.backgroundShow == 'undefined') {
                        currentLinkardInfo.value.npc['backgroundShow'] = true;
                    }

                    if (currentLinkardInfo.value.npc.personality.career == undefined || currentLinkardInfo.value.npc.personality.career == 'undefined') {
                        currentLinkardInfo.value.npc.personality['career'] = null;
                    }

                    if (currentLinkardInfo.value.npc.personality.ability == undefined || currentLinkardInfo.value.npc.personality.ability == 'undefined') {
                        currentLinkardInfo.value.npc.personality['ability'] = null;
                    }

                    if (currentLinkardInfo.value.npc.personality.tone == undefined || currentLinkardInfo.value.npc.personality.tone == 'undefined') {
                        currentLinkardInfo.value.npc.personality['tone'] = null;
                    }

                    let tempLabelValue1 = "";
                    if (currentLinkardInfo.value.tags[1] == "") {
                        tempLabelValue1 = "(空)";
                    } else if (currentLinkardInfo.value.tags[1]) {
                        tempLabelValue1 = currentLinkardInfo.value.tags[1];
                    }

                    let tempLabelValue2 = "";
                    if (currentLinkardInfo.value.tags[2] == "") {
                        tempLabelValue2 = "(空)";
                    } else if (currentLinkardInfo.value.tags[2]) {
                        tempLabelValue2 = currentLinkardInfo.value.tags[2];
                    }

                    baseInfo.value = {
                        name: currentLinkardInfo.value.name,
                        icon: currentLinkardInfo.value.icon,
                        labelName: currentLinkardInfo.value.tags[0],
                        labelValue1: tempLabelValue1,
                        labelValue2: tempLabelValue2,
                        style: currentLinkardInfo.value.style,
                    };

                    userStore.currentPath = response.path;
                    selectSocialLinkTop.value = currentLinkardInfo.value.socialLinks.filter((item: any) => item.isTop)[0] || selectSocialLinkTop.value;
                    selectSocialLinkNormalList.value = currentLinkardInfo.value.socialLinks.filter((item: any) => !item.isTop) || [];

                    handlePreview(currentLinkardInfo.value);
                },
                (ex) => {

                },
            );
        }

        const getTagsListRequest = () => {
            linkardNet.getTagsList().then(
                (response) => {
                    labelList.value = response;
                    labelList.value.unshift("(空)");
                },
                (ex) => {

                },
            );
        }

        const onPreviewLoad = async () => {
            iframeLoaded = true;
        };

        const handlePreview = (data: any) => {
            if (mobileH5Ref.value) {
                iframeData = JSON.parse(JSON.stringify(data));

                if (!currentLinkardInfo.value.npc.characterShow) {
                    iframeData.npc.filepath = "";
                }

                if (!currentLinkardInfo.value.npc.backgroundShow) {
                    iframeData.npc.background = "";
                }

                iframeData.dataType = 'linkard';
                iframeData.linkardId = linkardId;
                iframeData.token = sessionStorage.getItem(ACCESS_TOKEN);

                iframeData.pathAllowChanges = false;
                iframeData.tags = data.tags;
                iframeData.operation = {
                    phoneOpen: false,
                    phone: '',
                    messageOpen: false
                };
                iframeData.socialLinks = [];
                iframeData.isOwn = false;

                iframeData.spaceValid = false;
                iframeData.mouthShape = 0;
                iframeData.sourceType = 0;

                iframeData.npc.welcomeMsg = '';
                if (!renderIframeTimer) {
                    renderIframeTimer = setInterval(postMessageToIframe, 100);
                }
            }
        };

        const postMessageToIframe = () => {
            if (iframeLoaded) {
                clearInterval(renderIframeTimer); // 停止定时器
                renderIframeTimer = 0;
                mobileH5Ref.value.contentWindow.postMessage(JSON.parse(JSON.stringify(iframeData)), mobileH5Url); // 将数据传递到iframe中
            }
        };

        const changeTab = (index: number) => {
            tabIndex.value = index;
        };

        const toSpace = async () => {
            if (!linkardId.value || linkardId.value == 'undefined' || !isOwnLinakrd.value) return;
            tabIndex.value = 4;
        };

        const handleBack = () => {
            if (userStore.privilegeLevel.level == UserLevelType.PROFESSIONAL || userStore.privilegeLevel.level == UserLevelType.ENT) {
                router.push({ path: NavigatorRoute.ENTER_PRISE_EDIT });
            } else {
                router.push({ path: NavigatorRoute.HOME_PAGE });
            }
        };

        const deleteTopLink = () => {
            selectSocialLinkTop.value = {
                icon: "",
                name: "",
                url: "",
                isTop: false,
            };
        };

        const getBackGroundImgListRequest = () => {
            linkardNet.getBackGroundImgList(1, 10, 0).then(
                (response) => {
                    if (editType == 0 && (linkardId.value == 'undefined' || !linkardId.value)) {
                        //新建 默认选中第一个NPC
                        if (response.list.length > 0) {
                            currentLinkardInfo.value.npc.backgroundId = response.list[0].id;
                            currentLinkardInfo.value.npc.background = response.list[0].url;
                        }
                    }
                },
                (ex) => {

                },
            );
        }

        const saveEdit = () => {
            isSubmit.value = true;
            if (!baseInfoFinish() || !npcFinish()) {
                return false;
            }

            if (!currentLinkardInfo.value.npc.largeModel) {
                if (linkardConfigInfo.value?.availableModels && linkardConfigInfo.value?.availableModels.length > 0) {
                    currentLinkardInfo.value.npc.largeModel = linkardConfigInfo.value?.availableModels[0].id;
                }
            }

            const tempSocialLinks = [...selectSocialLinkNormalList.value];
            selectSocialLinkTop.value.url && tempSocialLinks.unshift(selectSocialLinkTop.value);
            currentLinkardInfo.value.socialLinks = tempSocialLinks;

            // currentLinkardInfo.value.icon = baseInfo.value.icon;
            // currentLinkardInfo.value.name = baseInfo.value.name;
            // currentLinkardInfo.value.style = baseInfo.value.style;
            let tempLinkardId = '';
            if (linkardId.value && linkardId.value != 'undefined') {
                tempLinkardId = linkardId.value;
            }
            currentLinkardInfo.value.id = tempLinkardId;

            if (currentLinkardInfo.value.npc.voice.voiceId == null) {
                delete currentLinkardInfo.value.npc.voice;
            }

            // const tempTags = [];
            // tempTags.push(baseInfo.value.labelName);
            // tempTags.push(baseInfo.value.labelValue1);
            // tempTags.push(baseInfo.value.labelValue2);

            // currentLinkardInfo.value.tags = tempTags;

            Loading.show('正在保存...');
            linkardNet.saveLinkard(currentLinkardInfo.value).then(
                (response) => {
                    Loading.hideWithSuccess('保存成功');
                    linkardId.value = response;
                    getLinkardInfoRequest();
                    router.push({ path: NavigatorRoute.EDIT_CARD, query: { editType: 1, cardType: cardType.value, linkardId: linkardId.value } });
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                    Loading.hide();
                },
            );
        }

        const getDigitalRoleInfo = (characterIdStr: string, backgroundItem: BackGroundImgItem | null) => {
            if (characterIdStr) {
                currentLinkardInfo.value.npc.characterId = characterIdStr;
                const character = linkardConfigInfo.value?.characters.find(item => item.characterId === characterIdStr);
                if (character) {
                    currentLinkardInfo.value.npc.filepath = character.filepath;
                }
            }

            if (backgroundItem) {
                currentLinkardInfo.value.npc.background = backgroundItem.url;
                currentLinkardInfo.value.npc.backgroundId = backgroundItem.id;
            }

            // const tempData = JSON.parse(JSON.stringify(currentLinkardInfo.value));
            // if (!currentLinkardInfo.value.npc.characterShow) {
            //     tempData.npc.filepath = "";
            // }

            // if (!currentLinkardInfo.value.npc.backgroundShow) {
            //     tempData.npc.background = "";
            // }

            handlePreview(currentLinkardInfo.value);
        };
        const soundSettingUpdate = (item: any) => {
            if (currentLinkardInfo.value.npc.voice) {
                currentLinkardInfo.value.npc.voice.voiceId = item.id;
            }
        };

        const largeModelUpdate = (item: any) => {
            currentLinkardInfo.value.npc.largeModel = item.id;
        };

        const characterAndBgShowChange = (characterShow: boolean, bgShow: boolean) => {
            currentLinkardInfo.value.npc.characterShow = characterShow;
            currentLinkardInfo.value.npc.backgroundShow = bgShow;

            // const tempData = JSON.parse(JSON.stringify(currentLinkardInfo.value));
            // if (!currentLinkardInfo.value.npc.characterShow) {
            //     tempData.npc.filepath = "";
            // }

            // if (!currentLinkardInfo.value.npc.backgroundShow) {
            //     tempData.npc.background = "";
            // }
            handlePreview(currentLinkardInfo.value);
        };

        // 监视整个对象的变化
        // watch(currentLinkardInfo, (newValue, oldValue) => {
        //     if (oldValue.id != null) {
        //         hasDataChange.value = true;
        //     }
        // }, { deep: true });

        // watch(baseInfo, (newValue, oldValue) => {
        //     if (oldValue.name != "") {
        //         hasDataChange.value = true;
        //     }
        // }, { deep: true });

        // watch(selectSocialLinkNormalList, (newValue, oldValue) => {
        //     if (oldValue.length > 0) {
        //         hasDataChange.value = true;
        //     }
        // }, { deep: true });

        // watch(selectSocialLinkTop, (newValue, oldValue) => {
        //     if (oldValue.url.length > 0) {
        //         hasDataChange.value = true;
        //     }
        // }, { deep: true });

        const updateBaseInfoPreviewInfo = () => {

            currentLinkardInfo.value.icon = baseInfo.value.icon;
            currentLinkardInfo.value.name = baseInfo.value.name;
            currentLinkardInfo.value.style = baseInfo.value.style;

            const tempTags = [];
            tempTags.push(baseInfo.value.labelName);

            if (baseInfo.value.labelValue1 == "(空)") {
                tempTags.push("");
            } else {
                tempTags.push(baseInfo.value.labelValue1);
            }

            if (baseInfo.value.labelValue2 == "(空)") {
                tempTags.push("");
            } else {
                tempTags.push(baseInfo.value.labelValue2);
            }

            currentLinkardInfo.value.tags = tempTags;

            handlePreview(currentLinkardInfo.value);
        };
        onMounted(() => {
            if (sessionStorage.getItem(ACCESS_TOKEN)) {
                getMyLinkardConfigRequest();
                getTagsListRequest();
                getBackGroundImgListRequest();
                if (linkardId.value != 'undefined') {
                    getLinkardInfoRequest();
                }
            } else {
                router.replace({ path: NavigatorRoute.LOGIN_PAGE });
            }
        });

        return {
            linkardConfigInfo,
            currentLinkardInfo,
            mobileH5Ref,
            mobileH5Url,
            refreshIframe,
            selectSocialLinkTop,
            tabIndex,
            tabList,
            isSubmit,
            linkardId,
            isOwnLinakrd,
            labelList,
            baseInfo,
            selectSocialLinkNormalList,
            cardType,
            digitalChatSetting,
            hasDataChange,
            changeTab,
            toSpace,
            handleBack,
            saveEdit,
            deleteTopLink,
            getDigitalRoleInfo,
            soundSettingUpdate,
            largeModelUpdate,
            onPreviewLoad,
            characterAndBgShowChange,
            updateBaseInfoPreviewInfo,
        };
    },
});

</script>

<style lang="less" scoped>
.home-wrapper {
    width: 100%;
    height: calc(100vh - 168px);
    padding: 0px 80px 0 80px;

    .edit-content-wrapper {
        top: 40px;
        left: 0;
        right: 0;
        bottom: 0px;
        display: flex;
        position: relative;
        overflow: hidden;

        .left_content_bg {
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: start;


            .left {
                height: 700px;
                width: 366px;
                margin-left: auto;
                background: #f0f0f0;
                border-radius: 40px;
                overflow: hidden;
                border: 8px solid var(--White, #F0F0F0);
                background: lightgray 0.492px -85.197px / 99.731% 112.102% no-repeat, var(--White, #F0F0F0);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .right {

                padding: 18px 0 0px 0;

                .action_btn_bg {
                    width: 380px;
                    height: 514px;
                    flex-direction: column;
                    justify-content: flex-start;
                    display: flex;
                    align-items: center;
                    padding-top: 36px;
                    border-radius: 0 40px 40px 0;
                    border: 4px solid #242424;
                    border-left: none;
                    margin-right: 60px;

                    .left-btn {
                        width: 280px;
                        height: 64px;
                        border-radius: 32px;
                        border: 2px solid #242424;
                        background: #f0f0f0;
                        display: flex;
                        align-items: center;
                        padding-left: 36px;
                        font-weight: 600;
                        font-size: 16px;
                        cursor: pointer;
                        margin-bottom: 20px;
                        position: relative;
                        color: #242424;

                        .require-tips {
                            position: absolute;
                            right: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 108px;
                            text-align: center;
                            height: 24px;
                            line-height: 24px;
                            border-radius: 36px;
                            background: #e70000;
                            color: #f0f0f0;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }

                    .left-btn:active {
                        color: #f0f0f0;
                        background: #242424;
                        opacity: 1;
                    }

                    .left-btn-active {
                        color: #f0f0f0;
                        background: #242424 !important;
                    }

                    .left-btn-hover-style {
                        transition: color 0.3s, background 0.3s;
                    }

                    .left-btn-hover-style:hover {
                        color: #f0f0f0;
                        background: #242424;
                    }

                    .left-btn-3d-disabled {
                        opacity: 0.4;
                    }

                    .left-btn-3d {
                        margin-top: 38px;
                        border-radius: 36px;
                        border: 2px solid #242424;
                        background: #f3ec3f;
                        justify-content: space-between;
                        padding: 0 24px 0 36px;

                        img {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }

                .require {
                    border: 1px solid #e70000;
                }

                .left-save-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    margin-right: 60px;
                    position: relative;

                    .line_div_bg {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;

                        .line_div {
                            width: 323px;
                            height: 4px;
                            background-color: #242424;
                            flex-grow: 1;
                        }
                    }

                    button {
                        width: 280px;
                        height: 64px;
                        border-radius: 32px;
                        font-weight: 600;
                        font-size: 18px;
                    }

                    .save-btn {
                        margin: 24px 0 16px 0;
                        background-color: #F3EC3F;
                        border: 4px solid #242424;
                        cursor: pointer;
                    }

                    .save-btn:hover {
                        background-color: #242424;
                        color: #F0F0F0;
                    }

                    .back-btn {
                        margin-left: 0;
                        background-color: transparent;
                        border: 2px solid #242424;
                        cursor: pointer;
                    }

                    .has_change_tips {
                        width: 280px;
                        height: 30px;
                        border-radius: 40px;
                        background-color: #F3EC3F;
                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                        color: #242424;
                        text-align: center;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 30px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .right_content_bg {
            flex-grow: 1;
            width: 805px;
            height: 724px;
            flex-shrink: 0;
            border-radius: 40px;
            // background: #f0f0f0;
            // box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            // padding-top: 50px;
            text-align: center;
        }
    }
}
</style>