<template>
    <div class="dialog_bg_view">
        <transition name="dialog">
            <div v-if="showDialog" class="dialog_content_view">
                <span class="dialog_text">{{ dialogText }}</span>
                <div class="dialog_action_bg">
                    <button class="dialog_button dialog_sure" @click="sureButtonClick">{{ sureButton }}</button>
                    <button class="dialog_button dialog_cancel" @click="cancelButtonClick">{{ cancelButton }}</button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'DialogView',
    props: {
        dialogText: {
            type: String,
            default: '',
        },
        sureButton: {
            type: String,
            default: '确认',
        },
        cancelButton: {
            type: String,
            default: '取消',
        },
        showDialog: {
            type: Boolean,
            default: false,
        },
    },
    components: {

    },
    setup(props, { emit }) {

        const sureButtonClick = () => {
            emit("sureBtnClick");
        };

        const cancelButtonClick = () => {
            emit("cancelBtnClick");
        };

        onMounted(() => {

        });

        return {
            sureButtonClick,
            cancelButtonClick
        };
    },
});

</script>

<style lang="less" scoped>
.dialog_bg_view {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: rgba(240, 240, 240, 0.95);
    backdrop-filter: blur(8px);
    z-index: 2001;

    .dialog_content_view {
        width: 580px;
        border-radius: 40px;
        background: #F0F0F0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        padding: 110px 80px 64px 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog_text {
            color: #242424;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 0 30px;
        }

        .dialog_action_bg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 52px;

            .dialog_button {
                border-width: 0;
                width: 320px;
                height: 64px;
                border-radius: 36px;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                line-height: normal;
            }

            .dialog_button:active {
                opacity: 0.7;
            }

            .dialog_sure {
                background: #242424;
                color: #F0F0F0;
            }

            .dialog_cancel {
                margin-top: 24px;
                background: #F0F0F0;
                border: 2px solid #242424;
                color: #242424;

            }
        }
    }
}

.dialog-enter-active,
.dialog-leave-active {
    transition: transform 0.2s;
}

.dialog-enter,
.dialog-leave-to {
    transform: scale(0.8);
    opacity: 0;
}
</style>
