import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from 'vue-router';
import { useCachedViewStoreHook } from '@/stores/cachedView';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export interface toRouteType extends RouteLocationNormalized {
  meta: {
    title?: string;
    noCache?: boolean;
  };
}

router.beforeEach((to: toRouteType, from, next) => {
  // 路由缓存
  useCachedViewStoreHook().addCachedView(to);
  next();
});

router.afterEach(() => {
});

export default router;
