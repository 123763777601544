import { http } from "@/net/http";
import { AxiosHeaders, InternalAxiosRequestConfig } from "axios";

/**
 * 联卡个人能力、语气、职业 Item
 */
export type LKPersonalityItem = {
  npcName: string;
  career: string | null;
  tone: string | null;
  ability: string | null;
  introduction: string;
};

/**
 * 联卡声音信息
 */
export type LKVoiceItem = {
  voiceId: string | null;
};

/**
 * 联卡知识库
 */
export type LKKnowledgeItem = {
  id: string;
  filename: string;
  url: string;
  words: number;
  wordsStr: string;
  status: number;
  createTime: number;
  createDate?: string;
  statusTips?: string;
  statusColor?: string;
  statusImage?: string;
};

/**
 * 联卡知识库
 */
export type TriggerWordListItem = {
  type: number; //1:文本，2:图片，3:视频，4:链接
  message: string;
  extraData: string;
  fileName: string;
};

/**
 * 触发词Item
 */
export type TriggerWordV2Item = {
  keyword: string;
  list: TriggerWordListItem[];
};

/**
 *NPC Info
 */
export type LKNPCInfoItem = {
  characterId: string;
  filepath: string;
  avatar: string;
  backgroundId: string | null;
  background: string;
  personality: LKPersonalityItem;
  voice: LKVoiceItem | null;
  welcomeMsg: string;
  largeModel: string;
  characterShow: boolean;
  backgroundShow: boolean;
};

/**
 * 联卡配置数据
 */
export type LKConfigResponse = {
  characters: CharacterItem[];
  careers: CareersItem[];
  tones: TonesItem[];
  abilities: AbilityItem[];
  voices: VoiceItem[];
  availableModels: AvailableModelItem[];
  mediaCount: number;
};

/**
 * 用户信息数据
 */
export type UserLoginInfo = {
  id: string;
  headImg: string;
  name: string;
  linkardId: string;
  path: string;
  phone: string;
  pathAllowChanges: boolean;
  usedCnt: number;
  remainingCnt: number;
  totalCnt: number;
};

/**
 * 登录请求参数
 */
export type LoginCodeArgs = {
  phone: string;
};

/**
 * 保存联卡请求参数
 */
export type CurrentLinkardInfo = {
  id: string | null;
  type: number;
  path: string;
  style: string;
  pathAllowChanges: boolean;
  icon: string;
  name: string;
  tags: string[];
  operation: LinkardOperation;
  socialLinks: SocialLinkItem[];
  npc: LKNPCInfoItem;
  snapshot: string;
  isOwn: boolean;
  spaceValid: boolean;
};

/**
 * FileUpload
 */
export type FileUploadResponse = {
  id: string;
  filename: string;
  url: string;
};

/**
 * FileUpload
 */
export type KeyWorldUploadResponse = {
  id: string;
  mediaUrl: string;
  thumbnailUrl: string;
  fileName: string;
};

/**
 * 问答库 Item
 */
export type QATriggerWordItem = {
  keywordNo: string;
  triggerRule: number;
  keyword: string;
  text: string;
  linkUrl: string;
  imgUrl: string;
  videoUrl: string;
  imgExtraData: string;
  videoExtraData: string;
  imgFileName: string;
  videoFileName: string;
  id: string;
  isNew: boolean;
  index: number;
};

/**
 * 触发词Item
 */
export type TriggerWordItem = {
  keyword: string;
  keywordNo: string;
  fileName: string;
  triggerRule: number;
  createDate: string;
  triggerRuleText: string;
  hasImg: boolean;
  hasVideo: boolean;
  hasLink: boolean;
  message: string;
  list: TriggerWordListItem[];
};

/**
 * 使用数据返回值
 */
export type CollectListResponse = {
  pageSize: number;
  pageNum: number;
  total: number;
  list: LinkardItemInfo[];
};

/**
 *联卡Item
 */
export type LinkardItemInfo = {
  id: string;
  url: string;
  name: string;
  showName: string;
  avatar: string;
  npcAvatar: string;
  tags: string[];
};

/**
 *专题Item
 */
export type SpecialTopicItem = {
  url: string;
  name: string;
  linkardList: LinkardItemInfo[];
};

/**
 * 卡柜基本信息
 */
export type CabinetInfoResponse = {
  cabinetName: string;
  specialTopicList: SpecialTopicItem[];
};

/**
 *专题配置Item
 */
export type SpecialTopicConfigItem = {
  url: string;
  name: string;
  id: string;
};

/**
 * 专题配置信息
 */
export type CabinetConfigInfoResponse = {
  cabinetName: string;
  isOpen: boolean;
  specialTopicList: SpecialTopicConfigItem[];
};

/**
 * 专题详情
 */
export type SpecialTopicDetailResponse = {
  id: string;
  url: string;
  name: string;
  linkardList: LinkardItemInfo[];
};

/**
 * 卡柜专题模版列表
 */
export type TemplateListResponse = {
  isPlaceHoder: boolean;
  url: string;
};

/**
 * 联卡专题
 */
export type SpecialTopicLinkardItem = {
  linkardId: string;
  linkardName: string;
};

/**
 * 添加专题参数
 */
export type SpecialTopicAddArgs = {
  cabinetId: string;
  name: string;
  url: string;
  linkardList: SpecialTopicLinkardItem[];
};

/**
 * 更新专题
 */
export type SpecialTopicUpdateArgs = {
  id: string;
  name: string;
  url: string;
  linkardList: SpecialTopicLinkardItem[];
};

/**
 *企业版联卡Item
 */
export type EnterpriseCardItem = {
  id: string;
  url: string;
  name: string;
  avatar: string;
  npcAvatar: string;
  tags: string[];
  usedCnt: number;
  remainingCnt: number;
  totalCnt: number;
  adminName: string;
  adminPhone: string;

  userPhone: string;
};

/**
 *企业版联卡列表返回数据
 */
export type EnterpriseResponse = {
  type: number;
  linkardTotal: number;
  linkardNum: number;
  full: boolean;
  linkardList: EnterpriseCardItem[];
};

/**
 * 联卡基本信息
 */
export type LinkardBaseInfo = {
  name: string;
  labelName: string;
  labelValue1: string;
  labelValue2: string;
  icon: string;
  style: string;
};

/**
 * 联卡基本信息
 */
export type LinkardOperation = {
  phoneOpen: boolean;
  phone: string;
  messageOpen: boolean;
};

/**
 * 社媒链接
 */
export type SocialLinkItem = {
  icon: string;
  name: string;
  url: string;
  isTop: boolean;
};

/**
 * NPC Item
 */
export type CharacterItem = {
  characterId: string;
  name: string;
  avatar: string;
  filepath: string;
  intro: string;
  voice: string;
  voiceId: string;
  mouthShape: number;
  level: number;
  allow: boolean;
};

/**
 * 背景图Item
 */
export type BackGroundImgItem = {
  id: string;
  url: string;
  name: string;
  haveUsed?: boolean;
};

/**
 * 背景图 Response
 */
export type BackGroundImgResponse = {
  pageSize: number;
  pageNum: number;
  list: BackGroundImgItem[];
};

/**
 * 角色NPC
 */
export type DigitalItem = {
  id: string;
  url: string;
  avatar: string;
};

/**
 * 声音Voice
 */
export type VoiceItem = {
  id: string;
  name: string;
  level: number;
  url: string;
  allow: boolean;
  type: number;
};

/**
 * 职业 Item
 */
export type CareersItem = {
  id: string | null;
  name: string;
  level: number;
  allow: boolean;
};

/**
 * 语气 Item
 */
export type TonesItem = {
  id: string | null;
  name: string;
  level: number;
  allow: boolean;
};

/**
 * 能力 Item
 */
export type AbilityItem = {
  id: string | null;
  name: string;
  level: number;
  allow: boolean;
};

/**
 * 大模型 Item
 */
export type AvailableModelItem = {
  id: string;
  name: string;
  level: number;
  allow: boolean;
};

/**
 * 社媒icon
 */
export type SocialLinkIcon = {
  description: string;
  icon: string;
};

/**
 * 用户权益
 */
export type PrivilegeLevelInfo = {
  level: number;
  enterpriseName: string;
  isEnterpriseAdmin: boolean;
  haveCabinet: boolean;
};

/**
 * 触发词Item
 */
export type SaveFAQArgs = {
  linkardId: string;
  triggerWord: TriggerWordItem[];
};

/**
 * 查询问答库
 */
export type GetFAQListArgs = {
  linkardId: string;
  content: string;
  fileName: string;
  startDate: string;
  endDate: string;
  pageNum: number;
  pageSize: number;
};

/**
 * 触发词列表返回
 */
export type TriggerWordListResponse = {
  pageSize: number;
  pageNum: number;
  total: number;
  triggerRule: number;
  list: TriggerWordItem[];
};

class LinkardNet {
  ///////////  ///////////  ///////////  ///////////  ///////////  ///////////

  /**
   * 获取背景图列表
   * @returns
   */
  getBackGroundImgList = async (
    pageNum: number,
    pageSize: number,
    type: number
  ): Promise<BackGroundImgResponse> => {
    return http.get<BackGroundImgResponse>(
      `/v4/npc/backgroundList?pageNum=${pageNum}&pageSize=${pageSize}&type=${type}`
    );
  };

  /**
   * 获取连卡配置信息
   * @returns
   */
  getLinkardConfigInfo = async (type: number): Promise<LKConfigResponse> => {
    return http.get<LKConfigResponse>(
      `/v4/home/linkard/configInfo?linkardType=${type}`
    );
  };
  /**
   * 联卡保存
   * @param arg
   * @returns
   */
  saveLinkard = async (arg: CurrentLinkardInfo): Promise<string> => {
    return http.post("/v4/home/linkard/saveLinkard", arg);
  };

  /**
   * 首页卡片列表
   * @returns
   */
  getCollectList = async (
    pageNum: number,
    pageSize: number
  ): Promise<CollectListResponse> => {
    return http.get<CollectListResponse>(
      `/v4/home/favorites/list?pageNum=${pageNum}&pageSize=${pageSize}&search=&type=1`
    );
  };

  /**
   * 下发验证码
   * @returns
   */

  sendCheckCode = async (region: number, phone: string): Promise<any> => {
    return http.get<any>(
      `/v2/user/sendCheckCode?region=${region}&phone=${phone}`
    );
  };

  /**
   * 绑定手机号
   * @param {*} params
   * @returns
   */
  bindPhone = async (phone: string, code: string): Promise<string> => {
    return http.post(`/v2/user/bindPhone?phone=${phone}&code=${code}`, null);
  };

  /**
   * 获取卡柜展示信息
   * @returns
   */

  getCabinetInfoByUser = async (
    lookedUserId: string
  ): Promise<CabinetInfoResponse> => {
    return http.get<CabinetInfoResponse>(
      `/v4/home/linkard/cabinet/infoByUser?lookedUserId=${lookedUserId}`
    );
  };

  /**
   * 卡柜是否公开展示
   * @returns
   */
  cabinetIsOpen = async (openType: number): Promise<string> => {
    return http.post(
      `/v4/home/linkard/cabinet/isOpen?openType=${openType}`,
      null
    );
  };

  /**
   * 卡柜删除
   * @returns
   */

  cabinetDelete = async (id: string): Promise<any> => {
    return http.post<any>(`/v4/home/linkard/cabinet/delete?id=${id}`);
  };

  /**
   * 卡柜保存
   * @param arg
   * @returns
   */
  cabinetSave = async (arg: any): Promise<any> => {
    return http.post("/v4/home/linkard/cabinet/save", arg);
  };

  /**
   * 卡柜删除专题
   * @returns
   */
  specialTopicDelete = async (topicId: string): Promise<string> => {
    return http.post(
      `/v4/home/linkard/cabinet/specialTopic/delete?id=${topicId}`,
      null
    );
  };

  /**
   * 专题保存名称
   * @returns
   */
  specialTopicSaveName = async (args: any): Promise<any> => {
    return http.post(`/v4/home/linkard/cabinet/specialTopic/saveName`, args);
  };

  /**
   * 卡柜展示信息
   * @returns
   */

  specialTopicDetail = async (
    id: string
  ): Promise<SpecialTopicDetailResponse> => {
    return http.get<SpecialTopicDetailResponse>(
      `/v4/home/linkard/cabinet/specialTopic/detail?id=${id}`
    );
  };

  /**
   * 卡柜专题模版列表
   * @returns
   */

  specialTopicTemplateList = async (
    id: string
  ): Promise<TemplateListResponse[]> => {
    return http.get<TemplateListResponse[]>(
      `/v4/home/linkard/cabinet/specialTopic/templateList?id=${id}`
    );
  };

  /**
   * 有效联卡列表
   * @returns
   */

  specialTopicLinkardList = async (): Promise<LinkardItemInfo[]> => {
    return http.get<LinkardItemInfo[]>(
      `/v4/home/linkard/cabinet/specialTopic/linkardList`
    );
  };

  /**
   * 卡柜添加专题
   * @returns
   */
  specialTopicAdd = async (args: SpecialTopicAddArgs): Promise<any> => {
    return http.post(`/v4/home/linkard/cabinet/specialTopic/add`, args);
  };

  /**
   * 卡柜编辑专题
   * @returns
   */
  specialTopicUpdate = async (args: SpecialTopicUpdateArgs): Promise<any> => {
    return http.post(`/v4/home/linkard/cabinet/specialTopic/update`, args);
  };

  /**
   * 有效联卡列表
   * @returns
   */

  enterpriseMyLinkardList = async (
    type: string
  ): Promise<EnterpriseResponse[]> => {
    return http.get<EnterpriseResponse[]>(
      `/v4/home/linkard/enterprise/myLinkardList?type=${type}`
    );
  };

  /**
   * 上传图片
   * @param arg
   * @returns
   */
  uploadImageMedia = async (arg: any): Promise<any> => {
    const headers = new AxiosHeaders();
    headers.set("Content-Type", "multipart/form-data");
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post("/v2/resource/images/uploadMedia", arg, config);
  };

  /**
   * 上传图片
   * @param arg
   * @returns
   */
  uploadBackground = async (arg: any): Promise<any> => {
    const headers = new AxiosHeaders();
    headers.set("Content-Type", "multipart/form-data");
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post("/v4/npc/uploadBackground", arg, config);
  };

  /**
   * 联卡背景删除
   * @param arg
   * @returns
   */
  deleteBackground = async (id: string): Promise<void> => {
    return http.post(`/v4/npc/deleteBackground?id=${id}`);
  };

  /**
   * 上传头像
   * @param arg
   * @returns
   */
  uploadHeaderImageMedia = async (arg: any): Promise<string> => {
    const headers = new AxiosHeaders();
    headers.set("Content-Type", "multipart/form-data");
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post("/v2/resource/images/upload", arg, config);
  };

  /**
   * 获取联卡信息
   * @returns
   */
  getLinkardInfo = async (linkardId: string): Promise<CurrentLinkardInfo> => {
    return http.get<CurrentLinkardInfo>(
      `/v4/home/linkard/getCurrent?linkardId=${linkardId}`
    );
  };

  /**
   * 获取联卡标签列表
   * @returns
   */
  getTagsList = async (): Promise<string[]> => {
    return http.get<string[]>(`/v2/home/tags/list`);
  };

  /**
   * 社媒icon 列表
   * @returns
   */
  socialLinkIcons = async (): Promise<SocialLinkIcon[]> => {
    return http.get<SocialLinkIcon[]>(`/v2/home/socialLinkIcons/list`);
  };

  /**
   * 知识语料上传
   * @param arg
   * @returns
   */
  uploadKnowledge = async (arg: any): Promise<FileUploadResponse> => {
    const headers = new AxiosHeaders();
    headers.set("Content-Type", "multipart/form-data");
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post("/v4/home/linkard/uploadKnowledge", arg, config);
  };

  /**
   * 视频上传
   * @param arg
   * @returns
   */
  uploadVideoMedia = async (arg: any): Promise<any> => {
    const headers = new AxiosHeaders();
    headers.set("Content-Type", "multipart/form-data");
    const config: InternalAxiosRequestConfig = {
      headers: headers,
    };
    return http.post("/v2/resource/videos/uploadMedia", arg, config);
  };

  /**
   * 获取场景模板
   */
  getSceneList = async (
    pageNum: number,
    pageSize: number,
    linkardType: number
  ): Promise<any> => {
    return http.get<any>(
      `/v3/space/sceneList?pageNum=${pageNum}&pageSize=${pageSize}&linkardType=${linkardType}`
    );
  };

  /**
   * 创建空间
   * @returns
   */
  createSpace = async (args: any): Promise<any> => {
    return http.post(`/v3/space/create`, args);
  };

  /**
   * 获取场景模板
   */
  checkLinkardSpace = async (linkardId: string): Promise<any> => {
    return http.get<any>(`/v3/space/linkardSpace?linkardId=${linkardId}`);
  };

  /**
   *  空间删除
   * @returns
   */
  deleteSpace = async (args: any): Promise<any> => {
    return http.post(`/v3/space/delete`, args);
  };

  /**
   *  绑定空间
   * @returns
   */
  bindSpace = async (args: any): Promise<any> => {
    return http.post(`/v3/space/bind`, args);
  };

  /**
   *  绑定空间校验
   * @returns
   */
  bindCheck = async (args: any): Promise<any> => {
    return http.post(`/v3/space/bindCheck`, args);
  };

  /**
   * 用户权益等级查询（卡柜权益接入）
   */
  linkardPrivilegeLevel = async (): Promise<PrivilegeLevelInfo> => {
    return http.get<PrivilegeLevelInfo>(`/v4/home/linkard/privilegeLevel`);
  };

  /**
   * 登录
   * @param arg
   * @returns
   */
  login = async (code: string, type: number): Promise<string> => {
    return http.post(`/v2/user/login?code=${code}&type=${type}`, null);
  };

  /**
   * 获取用户信息
   * @returns
   */
  getUserInfo = async (): Promise<UserLoginInfo> => {
    return http.get<UserLoginInfo>(`/v4/user/getUserDetail`);
  };

  /**
   * 联卡链接保存
   * @param arg
   * @returns
   */
  linkardSavePath = async (linkardId: string, path: string): Promise<any> => {
    return http.post(
      `/v4/home/linkard/pathSave?linkardId=${linkardId}&path=${path}`
    );
  };

  /**
   * 退出登录
   * @param arg
   * @returns
   */
  userLoginOut = async (): Promise<void> => {
    return http.post("/v2/user/logout");
  };

  /**
   * 编辑用户信息
   * @param arg
   * @returns
   */
  userUpdate = async (args: any): Promise<any> => {
    return http.post(`/v2/user/update`, args);
  };

  /**
   * 联卡消耗情况查询
   * @returns
   */
  enterpriseConsumptionList = async (): Promise<any> => {
    return http.get<any>(`/v4/home/linkard/enterprise/consumptionList`);
  };

  /**
   * 企业联卡-管理人员查询
   * @returns
   */
  enterpriseAdminList = async (): Promise<any> => {
    return http.get<any>(`/v4/home/linkard/enterprise/adminList`);
  };

  /**
   * 企业联卡-普通管理员新增
   * @param arg
   * @returns
   */
  enterpriseOrdAdminAdd = async (args: any): Promise<any> => {
    return http.post(`/v4/home/linkard/enterprise/subAdmin/add`, args);
  };

  /**
   * 企业联卡-普通管理员删除
   * @param arg
   * @returns
   */
  enterpriseOrdAdminDelete = async (args: any): Promise<any> => {
    return http.post(`/v4/home/linkard/enterprise/subAdmin/delete`, args);
  };

  /**
   * 企业联卡-删除联卡
   * @param arg
   * @returns
   */
  enterpriseDeleteLinkard = async (linkardId: string): Promise<any> => {
    return http.post(
      `/v4/home/linkard/enterprise/deleteLinkard?linkardId=${linkardId}`
    );
  };

  /**
   * 企业联卡-激活联卡
   * @param arg
   * @returns
   */
  enterpriseActivateLinkard = async (linkardId: string): Promise<any> => {
    return http.post(
      `/v4/home/linkard/enterprise/deleteLinkard?linkardId=${linkardId}`
    );
  };

  /**
   * 企业联卡-可转移用户列表
   * @returns
   */
  enterpriseTransferableUserList = async (linkardId: string): Promise<any> => {
    return http.get<any>(
      `/v4/home/linkard/enterprise/transferableUserList?linkardId=${linkardId}`
    );
  };

  /**
   * 企业联卡-转移联卡
   * @param arg
   * @returns
   */
  enterpriseTransferLinkard = async (args: any): Promise<any> => {
    return http.post(`/v4/home/linkard/enterprise/transferLinkard`, args);
  };

  /**
   * 企业联卡-转移联卡校验
   * @param arg
   * @returns
   */
  enterpriseTransferLinkardCheck = async (args: any): Promise<any> => {
    return http.post(`/v4/home/linkard/enterprise/transferLinkardCheck`, args);
  };

  /**
   * 企业联卡-解绑空间
   * @param arg
   * @returns
   */
  enterpriseUnbindSpace = async (args: any): Promise<any> => {
    return http.post(`/v4/home/linkard/enterprise/unbindSpace`, args);
  };

  /**
   * 查询知识库
   * @returns
   */
  getKnowledgeList = async (linkardId: string): Promise<any> => {
    return http.get<any>(
      `/v4/home/linkard/getKnowledgeList?linkardId=${linkardId}`
    );
  };

  /**
   * 删除知识
   * @returns
   */
  deleteKnowledge = async (linkardId: string, fileId: string): Promise<any> => {
    return http.get<any>(
      `/v4/home/linkard/deleteKnowledge?linkardId=${linkardId}&fileId=${fileId}`
    );
  };

  /**
   * 新建编辑问答库
   * @param arg
   * @returns
   */
  saveFAQ = async (args: SaveFAQArgs): Promise<any> => {
    return http.post(`/v4/home/linkard/saveFAQ`, args);
  };

  /**
   *查询问答库
   * @returns
   */
  getFAQList = async (
    args: GetFAQListArgs
  ): Promise<TriggerWordListResponse> => {
    return http.get(
      `/v4/home/linkard/getFAQList?linkardId=${args.linkardId}&content=${args.content}&fileName=${args.fileName}&startDate=${args.startDate}&endDate=${args.endDate}&pageNum=${args.pageNum}&pageSize=${args.pageSize}`
    );
  };

  /**
   *删除问答
   * @returns
   */
  deleteFAQ = async (
    linkardId: string,
    keywordNoList: string[]
  ): Promise<any> => {
    return http.post(`/v4/home/linkard/deleteFAQ`, {
      linkardId: linkardId,
      keywordNoList: keywordNoList,
    });
  };

  /**
   * 卡柜创建
   * @returns
   */

  createCabinet = async (): Promise<any> => {
    return http.post<any>(`/v4/home/linkard/cabinet/create`);
  };

  /**
   * 卡柜列表
   * @returns
   */

  cabinetInfoList = async (): Promise<any> => {
    return http.get<any>(`/v4/home/linkard/cabinet/infoList`);
  };

  /**
   * 专题列表
   * @returns
   */

  specialTopicInfoList = async (cabinetId: string): Promise<any> => {
    return http.get<any>(
      `/v4/home/linkard/cabinet/specialTopic/infoList?cabinetId=${cabinetId}`
    );
  };
}

export const linkardNet = new LinkardNet();
