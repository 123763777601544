<template>
    <div :class="['space_model_item_bg', specialHeight ? 'space_model_item_bg_special' : '']" @click="itemClick"
        @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" :style="boxStyle">
        <div :class="['content_view', isSelected ? 'content_view_selected' : '']">
            <img class="space_model_item" :src="coverImg" />
        </div>
        <span class="item_name" v-text="itemInfo.name"></span>
        <div class="theme_style_bg">
            <van-button :class="['theme_style_button', styleTabIndex === index ? 'theme_style_button_selected' : '']"
                v-for="item, index in styleList" :key="index" @click.stop="themeStyleButtonClick(item, index)">
                <img class="theme_style_img" :src="item.button" />
            </van-button>
        </div>
    </div>
</template>

<script lang="ts">

import { computed, defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    name: 'SpaceModeItem',
    props: {
        itemInfo: {
            type: Object as () => any,
            default: {},
        },

        isSelected: {
            type: Boolean,
            default: false,
        },

        itemIndex: {
            type: Number,
            default: -1,
        },

        specialHeight: {
            type: Boolean,
            default: false,
        },
    },
    components: {

    },
    setup(props, { emit }) {

        let styleTabIndex = ref(0);
        let coverImg = ref('');
        let sceneStyle = ref('');
        let styleList: any[] = [];
        const scale = ref(1);

        const handleMouseEnter = () => {
            scale.value = 1.02;
        };

        const handleMouseLeave = () => {
            scale.value = 1;
        };

        const boxStyle = computed(() => ({
            transform: `scale(${scale.value})`,
            transition: 'transform 0.3s ease',
        }));


        const itemClick = () => {
            emit("spaceModelItemClick", props.itemIndex, sceneStyle.value, coverImg.value);
        };

        const themeStyleButtonClick = (item: any, index: number) => {
            styleTabIndex.value = index;
            coverImg.value = item["thumbnail"];
            sceneStyle.value = item["styleId"];
            emit("spaceModelItemClick", props.itemIndex, sceneStyle.value, coverImg.value);
        };

        onMounted(() => {
            if (props.itemInfo.Versions[0].ExConfig.styleList) {
                styleList = JSON.parse(props.itemInfo.Versions[0].ExConfig.styleList);
                styleList.sort((dict1: any, dict2: any) => {
                    // 获取排序字段的值，并比较
                    let sort1 = dict1["sort"];
                    let sort2 = dict2["sort"];
                    return sort1 - sort2;
                });
            }
            if (styleList && styleList.length > 0) {
                coverImg.value = styleList[0]["thumbnail"];
                sceneStyle.value = styleList[0]["styleId"];
            } else {
                coverImg.value = props.itemInfo.Versions[0].thumbnail;
            }
        });

        return {
            coverImg,
            styleTabIndex,
            styleList,
            themeStyleButtonClick,
            itemClick,
            handleMouseEnter,
            handleMouseLeave,
            boxStyle,
        };
    },
});

</script>

<style lang="less" scoped>
.space_model_item_bg {
    width: 360px;
    height: 230px;
    flex-shrink: 0;
    margin-left: 38px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

    .content_view {
        width: 100%;
        height: 180px;
        border-radius: 26px;

        .space_model_item {
            height: 100%;
            width: 100%;
            border-radius: 20px;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        }
    }

    .content_view_selected {
        border: 8px solid #F3EC3F;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        img {
            border: 4px solid #242424;
        }
    }

    .item_name {
        color: #242424;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 4px 0 0 10px;
    }

    .theme_style_bg {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 40px;

        .theme_style_button {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: 3.5px solid transparent;
            margin: 6px 0 0 6px;
            background: transparent;

            .theme_style_img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }
        }

        .theme_style_button_selected {
            border: 3.5px solid #242424;
            background: #242424;
        }
    }
}

.space_model_item_bg_special {
    height: 270px !important;
}
</style>
