import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d98b274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog_content_view" }
const _hoisted_2 = { class: "dialog_text" }
const _hoisted_3 = { class: "dialog_action_bg" }
const _hoisted_4 = { class: "dialog_acttion" }
const _hoisted_5 = {
  key: 0,
  class: "warning_tips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.msg.showDialog)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['dialog_bg_view', _ctx.msg.blackBg ? 'dialog_bg_view_black' : 'dialog_bg_view_normal'])
      }, [
        _createElementVNode("div", {
          class: "dialog_layer_view",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dismissOverAllDialog && _ctx.dismissOverAllDialog(...args)))
        }),
        _createVNode(_Transition, { name: "dialog" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.msg.dialogText), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.msg.cancelButton.length > 0)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "dialog_button dialog_cancel",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelButtonClick && _ctx.cancelButtonClick(...args)))
                      }, _toDisplayString(_ctx.msg.cancelButton), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: "dialog_button dialog_sure",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sureButtonClick && _ctx.sureButtonClick(...args)))
                  }, _toDisplayString(_ctx.msg.sureButton), 1)
                ]),
                (_ctx.msg.warningTips)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.msg.warningTips), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        })
      ], 2))
    : _createCommentVNode("", true)
}