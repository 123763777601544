import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e0a167e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "linkard_name" }
const _hoisted_3 = { class: "linkard_tags" }
const _hoisted_4 = { class: "linkard_tags_bg" }
const _hoisted_5 = { class: "linkard_avatar" }
const _hoisted_6 = { class: "linkard_path" }
const _hoisted_7 = { class: "qr_code_image_bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_vue_qrcode = _resolveComponent("vue-qrcode")!

  return (_openBlock(), _createElementBlock("div", {
    class: "linkard_item_bg",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
    style: _normalizeStyle(_ctx.boxStyle)
  }, [
    _createElementVNode("img", {
      src: _ctx.linkardInfo.avatar,
      alt: "",
      class: "linkard_logo"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.linkardInfo.name), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkardInfo.tags, (item, index) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_4, [
          item
            ? (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(item), 1))
            : _createCommentVNode("", true)
        ]))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_image, {
        src: _ctx.linkardInfo.npcAvatar,
        fit: "cover"
      }, null, 8, ["src"])
    ]),
    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.linkardQRImgUrl), 1),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_vue_qrcode, {
        value: _ctx.linkardQRImgUrl,
        color: {},
        type: "image/png",
        width: 78,
        height: 78,
        margin: 2,
        errorCorrectionLevel: "L"
      }, null, 8, ["value"])
    ])
  ], 36))
}