<template>
    <div class="dialog_content_view">
        <span class="dialog_text">删除后，该账号将不再拥有企业权限和卡片剪辑权限，请谨慎操作</span>
        <span class="dialog_text_tips">注：该账号创建的卡片依然有效，超级管理员和其他管理员可继续编辑</span>
        <div class="dialog_action_bg">
            <button class="dialog_button dialog_sure" @click="sureButtonClick">确认删除</button>
            <button class="dialog_button dialog_cancel" @click="cancelButtonClick">返回</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'DeleteManagerView',
    components: {

    },
    setup(props, { emit }) {

        const sureButtonClick = () => {
            emit("dismissDeleteManagerView", 1);
        };

        const cancelButtonClick = () => {
            emit("dismissDeleteManagerView", 0);
        }

        onMounted(() => {

        });

        return {
            sureButtonClick,
            cancelButtonClick
        };
    },
});

</script>

<style lang="less" scoped>
.dialog_content_view {
    width: 580px;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 110px 80px 64px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog_text {
        color: #242424;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0;
    }

    .dialog_text_tips {
        color: rgba(36, 36, 36, 0.60);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 18px;
    }

    .dialog_action_bg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 36px;

        .dialog_button {
            border-width: 0;
            width: 320px;
            height: 64px;
            border-radius: 36px;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            line-height: normal;
        }

        .dialog_button:active {
            opacity: 0.7;
        }

        .dialog_sure {
            background: #242424;
            color: #F0F0F0;
        }

        .dialog_cancel {
            margin-top: 24px;
            background: #F0F0F0;
            border: 2px solid #242424;
            color: #242424;

        }
    }
}
</style>
