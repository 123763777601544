<template>
    <div class="base_info_content_bg">
        <div class="upload-wrapper">
            <label class="require">*</label>
            <el-upload action="" class="uploader" drag accept="image/png,image/jpeg" :on-error="handleError"
                :on-success="handleSuccess" :http-request="(item: any) => uploadImg(item.file)"
                :before-upload="beforeUPload" :show-file-list="false">
                <img v-if="baseInfo.icon" :src="baseInfo.icon" alt="" />
                <img v-else src="../assets/images/upload.png" alt="" />
            </el-upload>

            <div class="tips">*支持图片格式文件</div>
        </div>
        <div class="base_info_content">
            <div class="input-wrapper">
                <label class="require">名称</label>
                <div class="input-content">
                    <el-input v-model="baseInfo.name" @input="checkNameInputLength"
                        @change="checkNameInputChange"></el-input>
                    <div class="tips">*2-8个中文字符或4-16个英文字符</div>
                </div>
            </div>
            <div class="phone_number_message_bg">
                <label>电话</label>
                <el-switch width="80" class="phone_number_witch" v-model="operation.phoneOpen"
                    @change="phoneOpenSwitchClick" active-color="rgb(0,0,0,0)" inactive-color="rgb(0,0,0,0)">
                </el-switch>
                <div>
                    <el-input :class="[!operation.phoneOpen ? 'disable_input_bg' : '']" v-model="tempPhone"
                        maxlength="11" :disabled="!operation.phoneOpen" @change="phoneInputChange"></el-input>
                </div>
                <span class="leave_message_tips">留言</span>
                <el-switch width="80" class="message_witch" v-model="operation.messageOpen" active-color="rgb(0,0,0,0)"
                    inactive-color="rgb(0,0,0,0)">
                </el-switch>
            </div>
            <div class="input-wrapper input_wrapper_tag">
                <label>标签</label>
                <div class="input-content label_info_content">
                    <div class="label_input_bg">
                        <el-input placeholder="请输入标签" v-model="baseInfo.labelName" @input="checkLabelInputLength"
                            @change="checkLabelInputChange"></el-input>
                        <div class="tips">*4个中文字符或8个英文字符</div>
                    </div>
                    <el-select class="el-select-option-info" v-model="baseInfo.labelValue1" placeholder="请选择"
                        @change="tagsSelectChange">
                        <el-option v-for="item, index in labelList" :key="index" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    <el-select class="el-select-option-info" v-model="baseInfo.labelValue2" placeholder="请选择"
                        @change="tagsSelectChange">
                        <el-option v-for="item, index in labelList" :key="index" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="input-wrapper">
                <label>主题</label>
                <div class="subject_selection_bg">
                    <el-button
                        :class="['tab_button', baseInfo.style == 'card' ? 'tab_button_selected' : 'tab_button_normal']"
                        @click="subjectTabClick(0)">名片主题</el-button>
                    <el-button
                        :class="['tab_button', baseInfo.style == 'chat' ? 'tab_button_selected' : 'tab_button_normal']"
                        @click="subjectTabClick(1)">对话主题</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { LinkardBaseInfo, LinkardOperation, linkardNet } from '@/net/linkardNet';
import { computeChineseCount, computeEnglishCount } from '@/utils/serviceUtils';
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, onMounted, ref, watch, watchEffect } from 'vue';
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';

export default defineComponent({
    name: 'BaseInfoView',
    props: {
        baseInfo: {
            type: Object as () => LinkardBaseInfo,
            default: {
                name: '',
                labelName: '',
                labelValue1: '',
                labelValue2: '',
                icon: '',
                style: ''
            }
        },
        labelList: {
            type: Array as () => Array<String>,
            default: () => []
        },
        operation: {
            type: Object as () => LinkardOperation,
            default: {
                phoneOpen: false,
                phone: "",
                messageOpen: false,
            }
        },
    },
    components: {

    },
    setup(props, { emit }) {

        let phoneNumberSwitch = ref(false);
        let leaveMessageSwitch = ref(false);
        const tempPhone = ref('');

        const beforeUPload = (file: any) => {
            const isImage =
                file.type === 'image/png' ||
                file.type ===
                'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isImage)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件只能是 png / jpg 格式！',
                //     type: 'warning',
                // });
                OverAllDialogView.showOverAllDialogView("上传文件只能是 png / jpg 格式！", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });
            if (!isLt2M)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件大小不能超过 20MB!',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件大小不能超过 20MB!", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });

            return isImage && isLt2M;
        };

        // 上传错误提示
        const handleError = () => {
            ElMessage.error('上传失败，请您重新上传！');
        };

        //上传成功提示
        const handleSuccess = () => {

        };
        // 图片上传
        const uploadImg = async (file: any) => {
            linkardNet.uploadHeaderImageMedia({ file }).then(
                (response) => {
                    ElMessage.success('图片上传成功！');
                    props.baseInfo.icon = response;
                    emit("updatePreview");
                },
                (ex) => {
                    ElMessage.error('图片上传失败，请重试！');
                },
            );
        }

        const phoneOpenSwitchClick = (open: boolean) => {
            if (open) {
                tempPhone.value = props.operation.phone;
            } else {
                tempPhone.value = "";
            }
        };

        const phoneInputChange = (text: string) => {
            props.operation.phone = tempPhone.value;
        };
        const checkNameInputLength = (text: string) => {
            const chineseCount = computeChineseCount(text);
            const englishCount = computeEnglishCount(text);
            let maxLength = 16;
            switch (true) {
                case (chineseCount > 8 && englishCount <= 0):
                    maxLength = 8;
                    break;
                case (chineseCount <= 0 && englishCount > 16):
                    maxLength = 16;
                    break;
                case ((chineseCount > 0 && englishCount > 0) && (chineseCount + englishCount > 12)):
                    maxLength = 12;
                    break;
                default:
                    maxLength = 16;
                    break;
            }
            props.baseInfo.name = text.substr(0, maxLength);
        };

        const checkNameInputChange = () => {
            emit("updatePreview");
        };

        const checkLabelInputLength = (text: string) => {
            const chineseCount = computeChineseCount(text);
            const englishCount = computeEnglishCount(text);
            let maxLength = 8;
            switch (true) {
                case (chineseCount > 4 && englishCount <= 0):
                    maxLength = 4;
                    break;
                case (chineseCount <= 0 && englishCount > 8):
                    maxLength = 8;
                    break;
                case ((chineseCount > 0 && englishCount > 0) && (chineseCount + englishCount > 6)):
                    maxLength = 6;
                    break;
                default:
                    maxLength = 8;
                    break;
            }
            props.baseInfo.labelName = text.substr(0, maxLength);
        };

        const checkLabelInputChange = () => {
            emit("updatePreview");
        };

        const subjectTabClick = (index: number) => {
            if (index == 0) {
                props.baseInfo.style = 'card';
            } else {
                props.baseInfo.style = 'chat';
            }
            emit("updatePreview");
        };

        watchEffect(() => {
            tempPhone.value = props.operation.phone;
        });

        const tagsSelectChange = () => {
            emit("updatePreview");
        };

        onMounted(() => {
            // if (props.baseInfo.style == 'card') {
            //     subjectTab.value == 0;
            // } else {
            //     subjectTab.value == 1;
            // }
            tempPhone.value = props.operation.phone;
        });

        return {
            phoneNumberSwitch,
            leaveMessageSwitch,
            tempPhone,
            subjectTabClick,
            beforeUPload,
            handleError,
            handleSuccess,
            uploadImg,
            phoneOpenSwitchClick,
            checkNameInputLength,
            checkNameInputChange,
            checkLabelInputLength,
            checkLabelInputChange,
            tagsSelectChange,
            phoneInputChange
        };
    },
});

</script>

<style lang="less" scoped>
.base_info_content_bg {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: 40px;
    background-color: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .upload-wrapper {
        position: relative;
        display: inline-block;
        margin-top: 64px;

        .require {
            position: absolute;
            right: -16px;
            top: 2px;
            color: #e70000;
            font-weight: 600;
        }

        .uploader {
            width: 140px;
            height: 140px;
            border-radius: 22px;
            overflow: hidden;
            margin: 0 auto;
            border: 6px solid #f3ec3f;

            :deep(.el-upload-dragger) {
                padding: 0;
                border-width: 0;
                background-color: transparent;

                .el-upload {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 128px;
                    height: 128px;
                }

                img:first-child {
                    object-fit: cover;
                }
            }
        }

        .tips {
            height: 20px;
            color: rgba(36, 36, 36, 0.6);
            text-align: center;
            font-size: 12px;
            margin-top: 16px;
            font-weight: 600;
        }
    }

    .base_info_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .input-wrapper {
            display: flex;
            padding: 0;
            margin: 40px 0 0 0;

            label {
                color: #242424;
                font-weight: 600;
                line-height: 48px;
                width: 80px;
                text-align: left;
                font-size: 18px;
                font-weight: 600;
            }

            .require::after {
                content: "*";
                color: #e70000;
                font-weight: 600;
            }

            .input-content {
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: start;

                .label_input_bg {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: start;
                }

                :deep(.el-input__wrapper) {
                    background: transparent;
                    border: 2px solid #242424;
                    width: 476px;
                    height: 48px;
                    border-radius: 24px;

                    .el-input__inner {
                        font-size: 18px;
                        font-weight: 600;
                        color: #242424;
                        margin: 0 16px 0 16px;
                        background: transparent;
                    }
                }

                .tips {
                    color: rgba(36, 36, 36, 0.6);
                    font-size: 12px;
                    font-weight: 600;
                    margin: 10px 0 0 0;
                }
            }

            .label_info_content {
                flex-direction: row;

                :deep(.el-select-option-info) {
                    margin-left: 8px;

                    .el-tooltip__trigger {
                        border-radius: 36px;
                        border: 2px solid #242424;
                        background: #f0f0f0;
                        width: 152px;
                        height: 48px;
                        border-radius: 24px;
                        margin: 0;


                        .el-select__selected-item span {
                            font-size: 12px;
                            color: #242424;
                        }
                    }
                }

                :deep(.el-input__wrapper) {
                    background: transparent;
                    border: 2px solid #242424;
                    width: 160px;
                    height: 48px;
                    border-radius: 24px;

                    .el-input__inner {
                        font-size: 12px;
                        font-weight: 500;
                        color: #242424;
                        margin: 0 16px 0 16px;
                        background: transparent;
                    }
                }
            }
        }

        .phone_number_message_bg {
            display: flex;
            padding: 0;
            margin: 24px 0 48px 0px;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            label {
                color: #242424;
                font-weight: 600;
                flex-shrink: 0;
                line-height: 48px;
                width: 80px;
                text-align: left;
                font-size: 18px;
                font-weight: 600;
            }

            .phone_number_witch {
                margin: 0 12px 0 0px;
            }

            .message_witch {
                margin-left: 24px;
            }

            :deep(.el-input__wrapper) {
                background: transparent;
                border: 2px solid #242424;
                width: 214px;
                height: 48px;
                border-radius: 24px;

                .el-input__inner {
                    font-size: 12px;
                    font-weight: 500;
                    color: #242424;
                    margin: 0 16px 0 16px;
                    background: transparent;
                }
            }

            :deep(.el-input.is-disabled) {
                .el-input__wrapper {
                    cursor: not-allowed;
                    background-color: rgba(36, 36, 36, 0.20);

                    .el-input__inner {
                        cursor: not-allowed;
                    }
                }
            }

            .leave_message_tips {
                color: #242424;
                font-weight: 600;
                flex-shrink: 0;
                line-height: 48px;
                text-align: left;
                font-size: 18px;
                font-weight: 600;
                margin-left: 32px;
            }

            :deep(.el-switch) {
                .el-switch__core {
                    width: 80px !important;
                    height: 48px;
                    border-radius: 24px;
                    border: 2px solid #242424 !important;
                    background-color: transparent;
                }

                .el-switch__action {
                    width: 32px;
                    height: 32px;
                    margin-left: 6px;
                    background: #242424;
                    border: 2px solid transparent
                }
            }

            :deep(.el-switch.is-checked .el-switch__action) {
                margin-left: -24px;
                background: #F3EC3F;
                border: 2px solid #242424
            }
        }

        .input_wrapper_tag {
            margin: 12px 0 0 0px;
            flex-direction: row;
            justify-content: flex-start;
        }

        :deep(.el-input__wrapper.is-focus) {
            box-shadow: none;
        }

        .subject_selection_bg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .tab_button {
                width: 120px;
                height: 48px;
                border-radius: 24px;
                border: 2px solid #242424;
                color: #242424;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-left: 0;
            }

            .tab_button:hover {
                opacity: 0.8;
            }

            .tab_button:active {
                opacity: 0.6;
            }

            .tab_button:last-child {
                margin-left: 24px;
            }

            .tab_button_selected {
                background-color: #F3EC3F;
            }

            .tab_button_normal {
                background-color: #F0F0F0;
            }
        }
    }
}
</style>