import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f71866b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "space_model_list_bg" }
const _hoisted_2 = { class: "top_view" }
const _hoisted_3 = {
  class: "list_content",
  style: {"overflow":"auto"}
}
const _hoisted_4 = ["infinite-scroll-disabled"]
const _hoisted_5 = { class: "list-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButtonItem = _resolveComponent("BackButtonItem")!
  const _component_SpaceModeItem = _resolveComponent("SpaceModeItem")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BackButtonItem, {
        onBackBtnClick: _ctx.backButtonClick,
        class: "back_button"
      }, null, 8, ["onBackBtnClick"]),
      _createElementVNode("button", {
        class: "submit_button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitClick && _ctx.submitClick(...args)))
      }, "确认")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives((_openBlock(), _createElementBlock("ul", {
        class: "list",
        "infinite-scroll-disabled": _ctx.noMoreData
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemList, (item, index) => {
          return (_openBlock(), _createElementBlock("li", _hoisted_5, [
            _createVNode(_component_SpaceModeItem, {
              itemInfo: item,
              specialHeight: _ctx.specailIndexList.includes(index),
              isSelected: index == _ctx.selectedIndex,
              itemIndex: index,
              onSpaceModelItemClick: _ctx.spaceModeItemClick
            }, null, 8, ["itemInfo", "specialHeight", "isSelected", "itemIndex", "onSpaceModelItemClick"])
          ]))
        }), 256))
      ], 8, _hoisted_4)), [
        [_directive_infinite_scroll, _ctx.loadMoreInfo]
      ])
    ])
  ]))
}