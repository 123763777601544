<template>
    <div class="bind_space_bg">
        <div class="top_view">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
        </div>
        <div class="bind_content_bg">
            <div class="bind_space_id_bg">
                <span>空间ID</span>
                <el-input v-model="bindId" type="text" @blur="bindIdblur" placeholder="请输入空间ID"></el-input>
                <button :class="[!bindCheckError ? 'sure_bind_btn' : 'sure_bind_btn_disable']"
                    @click="sureBindButtonClick">确认</button>
            </div>
            <div class="bind_result_tips">
                <span v-if="errorTips && (bindCheckError || bindError)" class="bind_error">*{{ errorTips }}</span>
                <div v-if="bindCheckInfo && !bindCheckError && !bindError" class="bind_success">
                    <span>空间名称：{{ bindCheckInfo.spaceName }}</span>
                    <span>空间主播：{{ bindCheckInfo.spaceCreator }}</span>
                    <span v-if="endTime">有效期至：{{ endTime }}</span>
                </div>
            </div>
        </div>
        <div class="application_download_bg">
            <div class="install_tips_bg">
                <img class="left_tips" :src="require('../assets/images/install_tips_icon.svg')" alt="">
                <span class="right_tips_text">可前往 [天海现场] 体验更多空间功能（若已安装可<a @click="joinActivity">点击此处打开</a>）</span>
            </div>
            <div class="download_action_bg">
                <div class="download_action_content">
                    <button class="download_action pc_download_img" @click="windowsDownLoadClick">
                        <img class="application_img" :src="require('../assets/images/install_windows_icon.svg')" alt="">
                        <img class="download_img" :src="require('../assets/images/install_download_icon.svg')" alt="">
                    </button>
                    <span class="platform_text">Windows</span>
                </div>

                <div class="download_action_content">
                    <button class="download_action pc_download_img" @click="macDownLoadClick">
                        <img class="application_img" :src="require('../assets/images/install_mac_icon.svg')" alt="">
                        <img class="download_img" :src="require('../assets/images/install_download_icon.svg')" alt="">
                    </button>
                    <span class="platform_text">MacOS</span>
                </div>

                <div class="download_action_content">
                    <button class="download_action">
                        <img class="app_qr_img" :src="require('../assets/images/android_qr_img.png')" alt="">
                    </button>
                    <span class="platform_text">Android</span>
                </div>


                <div class="download_action_content">
                    <button class="download_action">
                        <img class="app_qr_img" :src="require('../assets/images/ios_qr_img.png')" alt="">
                    </button>
                    <span class="platform_text">iOS</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { linkardNet } from '@/net/linkardNet';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import BackButtonItem from "./backButtonItem.vue";
import { HttpResponse } from '@/net/http';
import { Loading } from '../components/loadingView/customLoading';

export default defineComponent({
    name: 'BindSpaceView',
    components: {
        BackButtonItem
    },
    props: {
        linkardId: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {

        let bindId = ref('');
        let bindCheckError = ref(true);
        let bindError = ref(true);
        let errorTips = ref('');
        let bindCheckInfo: Ref<any> = ref();
        let endTime = ref('');

        const backButtonClick = () => {
            emit("dismissBindView");
        };

        const sureBindButtonClick = () => {
            if (!bindCheckError.value && !bindError.value) {
                bindSpace(1);
            }
        };

        const bindIdblur = (event: any) => {
            if (bindId.value) {
                bindCheckRequst(1);
            } else {
                bindCheckError.value = true;
                errorTips.value = "空间ID不能为空";
            }
        };

        const joinActivity = () => {
            var loadDateTime = new Date().getMilliseconds();
            window.location.href = "dese://share?activityId=";
            window.setTimeout(function () { //如果没有安装app,便会执行setTimeout跳转下载页
                var timeOutDateTime = new Date().getMilliseconds();
                if (timeOutDateTime - loadDateTime < 5000) {
                } else {
                }
            }, 500);
        };

        const bindSpace = async (bindType: number) => {
            Loading.show('正在绑定...');

            linkardNet.bindSpace({
                linkardId: props.linkardId,
                spaceId: bindId.value,
                bindType: bindType
            }).then(
                (response) => {
                    Loading.hide();
                    backButtonClick();
                    bindError.value = false;
                },
                (ex) => {
                    Loading.hide();
                    bindError.value = true;
                    const error = ex as HttpResponse<any>;
                    errorTips.value = error.message;
                },
            );
        };

        const bindCheckRequst = async (bindType: number) => {

            linkardNet.bindCheck({
                linkardId: props.linkardId,
                spaceId: bindId.value,
                bindType: bindType
            }).then(
                (response) => {
                    bindCheckError.value = false;
                    bindCheckInfo.value = response;
                    bindError.value = false;
                    endTime.value = response.endTime ? changeTime(response.endTime) : '';
                },
                (ex) => {
                    bindCheckError.value = true;
                    const error = ex as HttpResponse<any>;
                    errorTips.value = error.message;
                },
            );
        };

        const changeTime = (timestamp: string | number | Date) => {
            // 创建一个Date对象
            var date = new Date(timestamp);
            // 提取年、月、日、小时和分钟
            var year = date.getFullYear();
            var month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以需要加1
            var day = date.getDate().toString().padStart(2, '0');
            var hour = date.getHours().toString().padStart(2, '0');
            var minute = date.getMinutes().toString().padStart(2, '0');
            // 格式化为"yyyy-mm-dd hh:mm"的字符串
            return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
        };
        const windowsDownLoadClick = () => {
            window.location.href = 'https://resource.dese.cn/app/release/win/SkyseaLive_latest_win_release.exe';
        };
        const macDownLoadClick = () => {
            window.location.href = 'https://resource.dese.cn/app/release/osx/SkyseaLive_latest_osx_release.pkg';
        };

        onMounted(() => {

        });

        return {
            bindId,
            bindCheckError,
            bindError,
            errorTips,
            bindCheckInfo,
            endTime,
            bindIdblur,
            sureBindButtonClick,
            joinActivity,
            windowsDownLoadClick,
            macDownLoadClick,
            backButtonClick,
        };
    },
});

</script>

<style lang="less" scoped>
.bind_space_bg {
    width: 100%;
    height: 100%;
    top: 24px;
    left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {

        }

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
        }

        .submit_button {
            width: 180px;
            height: 64px;
            border-radius: 36px;
            background: #242424;
            color: #F0F0F0;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            border-width: 0;
        }

        .submit_button:active {
            opacity: 0.7;
        }
    }

    .bind_content_bg {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;

        .bind_space_id_bg {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            :deep(.el-input__wrapper) {
                background-color: transparent;
                border-width: 0;
                box-shadow: none;
                input {
                    background: transparent;
                    width: 424px;
                    height: 48px;
                    border-radius: 36px;
                    border: 2px solid #242424;
                    padding: 0 32px;
                    color: #242424;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                }
            }

            span {
                width: 80px;
                color: #242424;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
            }

            .sure_bind_btn {
                color: #F0F0F0;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                width: 140px;
                height: 48px;
                border-radius: 30px;
                background: #242424;
                border-width: 0px;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            }

            .sure_bind_btn_disable {
                width: 140px;
                height: 48px;
                font-size: 12px;
                font-style: normal;
                border-radius: 30px;
                border: 2px solid rgb(36, 36, 36);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                opacity: 0.6;
                color: rgb(36, 36, 36);

            }

            .sure_bind_btn:active {
                opacity: 0.7;
            }
        }

        .bind_result_tips {
            width: 100%;
            padding-left: 120px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 10px;

            .bind_error {
                color: #E70000;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
            }

            .bind_success {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                color: #242424;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                text-align: left;
            }
        }
    }

    .application_download_bg {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        position: absolute;
        bottom: 56px;

        .install_tips_bg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .left_tips {
                width: 20px;
                height: 20px;
            }

            .right_tips_text {
                margin-left: 20px;
                color: #242424;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;

                a {
                    color: #0040FF;
                }

                a:hover {
                    cursor: pointer;
                }
            }
        }

        .download_action_bg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: start;
            margin-top: 38px;

            .download_action_content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin-right: 32px;

                .download_action {
                    width: 140px;
                    height: 140px;
                    border-radius: 20px;
                    border: 2px solid #242424;
                    background: transparent;
                }

                .pc_download_img {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .application_img {
                    width: 64px;
                    height: 64px;
                    margin-top: 6px;
                }

                .download_img {
                    margin-top: 6px;
                    width: 22px;
                    height: 22px;
                }

                .app_qr_img {
                    width: 103px;
                    height: 103px;
                    border-radius: 10px;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                }

                .download_action:active {
                    opacity: 0.7;
                }

                .platform_text {
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>
./loadingView/customLoading