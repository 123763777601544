<template>
    <div class="add_link_show_view_bg">
        <div class="top_view">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
            <button class="submit_button" @click="submitClick">确认</button>
        </div>
        <span v-if="tipsText" class="tips_bg">*{{ tipsText }}</span>
        <div class="center_content_view">
            <div class="link_info_bg">
                <div class="link_head">
                    <img class="link_img" :src="selectSocialLink.icon" alt="">
                </div>
                <div class="link_info_describe">
                    <div class="input-wrapper">
                        <label>标题：</label>
                        <div class="input-content">
                            <el-input v-model="selectSocialLink.name" placeholder="请输入标题"></el-input>
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <label>链接：</label>
                        <div class="input-content">
                            <el-input v-model="selectSocialLink.url" placeholder="请输入链接"></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="link_icon_list" style="overflow:auto">
                <ul class="search-icon-content">
                    <li :class="item.icon === selectSocialLink.icon ? 'link-active' : ''
                " v-for="(item, index) in socialIconList" :key="item.icon" @click="selectLink(item)"
                        @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)"
                        :style="itemStyle(index)">
                        <img :src="item.icon" alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { SocialLinkIcon, SocialLinkItem, linkardNet } from '@/net/linkardNet';
import { isValidURL } from '@/utils/commonUtils';
import { ElMessage } from 'element-plus';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import BackButtonItem from "./backButtonItem.vue";
export default defineComponent({
    name: 'AddLinkShow',
    components: {
        BackButtonItem
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        tempSocialLink: {
            type: Object as () => SocialLinkItem,
            default: {
                icon: "",
                name: "",
                url: "",
                isTop: false,
            }
        },
    },
    setup(props, { emit }) {

        let selectSocialLink: Ref<SocialLinkItem> = ref({
            icon: "",
            name: "",
            url: "",
            isTop: false,
        });
        let socialIconList: Ref<SocialLinkIcon[]> = ref([]);
        let tipsText = ref('');

        const scales = ref(new Array(socialIconList.value.length).fill(1));

        const handleMouseEnter = (index: number) => {
            scales.value[index] = 1.1;
        };

        const handleMouseLeave = (index: number) => {
            scales.value[index] = 1;
        };

        const itemStyle = (index: number) => {
            return {
                transform: `scale(${scales.value[index]})`,
                transition: 'transform 0.3s ease',
            };
        };

        const backButtonClick = () => {
            emit("dismissAddLinkShow");
        };

        const submitClick = () => {
            if (!selectSocialLink.value.name || !selectSocialLink.value.url) {
                tipsText.value = "请填写完整信息";
                setTimeout(() => {
                    tipsText.value = "";
                }, 3000);
                return;
            } else if (!isValidURL(selectSocialLink.value.url)) {
                tipsText.value = "请填写有效链接";
                setTimeout(() => {
                    tipsText.value = "";
                }, 3000);
                return;
            }

            emit("sureAddLinkShow", selectSocialLink.value);
        };

        const selectLink = (link: SocialLinkIcon) => {
            if (selectSocialLink.value.icon === link.icon) {
                selectSocialLink.value.icon = "";
                return false;
            }
            selectSocialLink.value.icon = link.icon;
        };

        const socialLinkIconsRequest = async () => {
            let result = {};

            linkardNet.socialLinkIcons().then(
                (response) => {
                    socialIconList.value = [...(response || [])];
                    scales.value = new Array(socialIconList.value.length).fill(1);
                },
                (ex) => {
                    ElMessage.error('获取社媒图标失败');
                },
            );
        };

        onMounted(() => {
            if (props.tempSocialLink) {
                selectSocialLink.value = props.tempSocialLink;
            }
            socialLinkIconsRequest();
        });

        return {
            backButtonClick,
            submitClick,
            selectLink,
            handleMouseEnter,
            handleMouseLeave,
            itemStyle,
            selectSocialLink,
            socialIconList,
            tipsText
        };
    },
});

</script>

<style lang="less" scoped>
.add_link_show_view_bg {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {}

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
            margin-left: 60px;
        }
    }

    .tips_bg {
        position: absolute;
        padding: 0 30px;
        top: 100px;
        height: 28px;
        border-radius: 40px;
        background: #F3EC3F;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        color: #242424;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }

    .center_content_view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;
        margin-top: 30px;
        width: 664px;

        .link_info_bg {
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: center;

            .link_head {
                width: 140px;
                height: 140px;
                background-color: #D9D9D9;
                border-radius: 70px;
                margin-right: 30px;

                .link_img {
                    width: 140px;
                    height: 140px;
                    border-radius: 70px;
                }
            }

            .link_info_describe {
                flex-grow: 1;

                .input-wrapper {
                    display: flex;

                    label {
                        color: #242424;
                        font-weight: 600;
                        flex-shrink: 0;
                        line-height: 48px;
                        width: 80px;
                        margin-right: 20px;
                    }

                    .input-content {
                        :deep(.el-input__wrapper) {
                            background: transparent;
                            border: 2px solid #242424;
                            width: 336px;
                            height: 48px;
                            border-radius: 24px;

                            .el-input__inner {
                                font-size: 16px;
                                font-weight: 500;
                                color: #242424;
                                margin: 0 16px 0 16px;
                                background: transparent;
                            }
                        }

                        :deep(.el-input__wrapper.is-focus) {
                            box-shadow: none;
                        }

                        .tips {
                            color: rgba(36, 36, 36, 0.6);
                            font-size: 12px;
                            font-weight: 600;
                            margin-top: 10px;
                        }
                    }
                }

                .input-wrapper:first-child {
                    margin-bottom: 40px;
                }
            }
        }

        .link_icon_list {
            height: 290px;
            margin-top: 46px;
            border-radius: 40px;
            border: 2px solid #242424;
            background: #f0f0f0;
            padding: 14px 10px 10px 20px;
            width: 100%;

            .search-icon-content {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                .link-active {
                    border: 3px solid #f3ec3f;
                }

                li {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-bottom: 20px;
                    margin: 20px 20px 20px 20px;
                    cursor:pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        vertical-align: top;
                    }
                }
            }
        }

        .link_icon_list::-webkit-scrollbar-track {
            background-color: transparent;
            margin: 30px 0;
        }

        .link_icon_list::-webkit-scrollbar {
            width: 4px;
        }

        .link_icon_list::-webkit-scrollbar-thumb {
            width: 4px;
            background-color: #d3d4d6;
            border-radius: 4px;
            margin: 30px 0;
        }
    }
}
</style>
