<template>
    <div class="home-wrapper">
        <div class="edit-content-wrapper">
            <div class="left_content_bg">
                <div class="left">
                    <div class="linkard_cabinet_list_bg" :style="{ backgroundImage: `url(${linkardCabinetBgImg})` }">
                        <div class="special_subject_list_bg">
                            <div class="cabinet_name">{{ cabinetInfo.cabinetName }}</div>
                            <div class="special_subject_item_bg"
                                v-for="(specialTopItem, index) in cabinetInfo.specialTopicList" :key="index">
                                <img class="special_subject_img" :src="specialTopItem.url" alt="" />
                                <div class="special_subject_name">{{ specialTopItem.name }}</div>
                                <div class="post-wrapper" v-for="(linkardInfo, index) in specialTopItem.linkardList"
                                    :key="'item_' + index">
                                    <div id="posterHtml">
                                        <img :src="linkardInfo.avatar" alt="" class="post-link-logo">
                                        <div class="post-link-name">{{ linkardInfo.name }}</div>
                                    </div>
                                    <div class="custom_linkard_name">{{ linkardInfo.showName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right_content_bg">
                <CabinetBaseView @refreshSpecialSubjectList="refreshSpecialSubjectList"></CabinetBaseView>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref, watch } from 'vue';
import { CabinetInfoResponse, linkardNet } from '@/net/linkardNet';
import { ElMessage } from 'element-plus';
import CabinetBaseView from "../components/cabinetBaseView.vue";
import { useUserStore } from '@/stores/user';
import { ACCESS_TOKEN, NavigatorRoute } from '@/utils/const';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'Home',
    components: {
        CabinetBaseView
    },
    setup(props, { emit }) {
        const userStore = useUserStore();
        const router = useRouter();
        
        let cabinetInfo: Ref<CabinetInfoResponse> = ref(
            {
                cabinetName: '',
                specialTopicList: [],
            }
        );
        const refreshSpecialSubjectList = () => {
            getCabinetInfoRequest();
        };

        const getCabinetInfoRequest = async () => {
            if (!userStore.userInfo.id) return;
            linkardNet.getCabinetInfoByUser(userStore.userInfo.id).then(
                (response) => {
                    if (response) {
                        cabinetInfo.value = response;
                    } else {
                        cabinetInfo.value = {
                            cabinetName: '',
                            specialTopicList: [],
                        }
                    }
                },
                (ex) => {
                    ElMessage.error('获取卡柜列表失败');
                },
            );
        };

        watch(() => userStore.userInfo.id, (newValue, oldValue) => {
            refreshSpecialSubjectList();
        });

        onMounted(() => {
            if (sessionStorage.getItem(ACCESS_TOKEN)) {
                getCabinetInfoRequest();
            } else {
                router.replace({ path: NavigatorRoute.LOGIN_PAGE });
            }
        });
        return {
            cabinetInfo,
            refreshSpecialSubjectList,
            linkardCabinetBgImg: require('../assets/images/linkard_cabinet_bg.svg'),
        };
    },
});

</script>

<style lang="less" scoped>
.home-wrapper {
    width: calc(100% - 160px);
    height: calc(100vh - 168px);
    padding: 0px 80px 0 80px;

    .edit-content-wrapper {
        // margin: 40px 0 60px 80px;
        top: 40px;
        left: 0;
        right: 0;
        bottom: 60px;
        display: flex;
        position: relative;
        overflow: hidden;

        .left_content_bg {
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: start;


            .left {
                height: 710px;
                width: 386px;
                margin-left: auto;
                background: #f0f0f0;
                border-radius: 40px;
                overflow: hidden;
                border: 8px solid var(--White, #F0F0F0);
                background: lightgray 0.492px -85.197px / 99.731% 112.102% no-repeat, var(--White, #F0F0F0);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                .linkard_cabinet_list_bg {
                    background-repeat: no-repeat;
                    height: 100%;
                    padding-top: 0;
                    background-size: cover;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    overflow: auto;

                    .special_subject_list_bg {
                        height: calc(100% - 90px);
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;

                        .cabinet_name {
                            width: 231px;
                            height: 24px;
                            line-height: 24px;
                            color: #F0F0F0;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                            margin: 10px 0 20px 0;
                        }

                        .special_subject_item_bg {
                            width: 355px;
                            height: auto;
                            border-radius: 28px;
                            border: 2px solid #242424;
                            flex-wrap: wrap;
                            display: flex;
                            flex-direction: row;
                            padding-left: 16px;
                            margin-bottom: 20px;
                            position: relative;

                            .special_subject_img {
                                width: 100%;
                                height: 100%;
                                border-radius: 20px;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }

                            .special_subject_name {
                                width: 279px;
                                height: 40px;
                                line-height: 40px;
                                border-radius: 36px;
                                background: #F0F0F0;

                                color: #242424;
                                text-align: center;
                                font-size: 18px;
                                font-weight: 600;
                                margin: 24px 0 24px 22px;
                                z-index: 1;
                            }

                            .post-wrapper {
                                position: relative;
                                width: 100px;
                                height: auto;
                                margin: 0 3px 20px 3px;
                                box-sizing: border-box;

                                #posterHtml {
                                    position: relative;
                                    background: url('../assets/images/share-my.svg') no-repeat;
                                    width: 100%;
                                    height: 132px;
                                    background-size: cover;
                                    border-radius: 8px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;

                                }

                                .post-link-logo {
                                    width: 48px;
                                    height: 48px;
                                    border-radius: 24px;
                                    margin-left: 16px;
                                    object-fit: cover;
                                }

                                .post-link-name {
                                    font-size: 8px;
                                    color: #fff;
                                    margin-left: 16px;
                                    font-weight: 600;
                                    margin-top: 8px;
                                    text-align: center;
                                }

                                .custom_linkard_name {
                                    width: 100%;
                                    height: 20px;
                                    margin-top: 4px;
                                    border-radius: 10px;
                                    background: #F0F0F0;
                                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                                    color: #242424;
                                    text-align: center;
                                    font-size: 10px;
                                    font-weight: 600;
                                    line-height: 20px;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }

                .linkard_cabinet_list_bg::-webkit-scrollbar-track {
                    display: none;
                }

                .linkard_cabinet_list_bg::-webkit-scrollbar {
                    display: none;
                }

                .linkard_cabinet_list_bg::-webkit-scrollbar-thumb {
                    display: none;
                }

            }
        }

        .right_content_bg {
            width: 1034px;
            height: 724px;
            flex-shrink: 0;
            margin-left: 170px;
            border-radius: 40px;
            text-align: center;
        }
    }
}
</style>
