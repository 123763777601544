export function GenerateQRCodeLink(path: string): string {
  return `${process.env.VUE_APP_MOBILE_URL}/${path}`;
}

export function computeChineseCount(text: string): number {
  // 计算中文字符数量
  const chineseCount = text.replace(/[^\u4e00-\u9fa5]/g, "").length;
  return chineseCount;
}
export function computeEnglishCount(text: string): number {
  // 计算英文字符数量
  const englishCount = text.replace(/[^\x00-\x7F]/g, "").length;
  return englishCount;
}

export const hasDuplicateKey = (array: { keyword: string }[]) => {
  let keySet: Record<string, boolean> = {}; // 用于存储已经出现的键
  for (let i = 0; i < array.length; i++) {
    let currentItem = array[i];
    if (
      currentItem &&
      Object.prototype.hasOwnProperty.call(currentItem, "keyword")
    ) {
      let currentKey = currentItem.keyword;

      // 检查当前键是否已经存在于 keySet 中
      if (keySet[currentKey]) {
        return true; // 存在相同的键
      } else {
        keySet[currentKey] = true; // 将当前键添加到 keySet 中
      }
    }
  }

  return false; // 不存在相同的键
};
