<template>
    <div v-if="msg.showDialog"
        :class="['dialog_bg_view', msg.blackBg ? 'dialog_bg_view_black' : 'dialog_bg_view_normal']">
        <div class="dialog_layer_view" @click="dismissOverAllDialog">
        </div>
        <transition name="dialog">
            <div class="dialog_content_view">
                <span class="dialog_text">{{ msg.dialogText }}</span>
                <div class="dialog_action_bg">
                    <div class="dialog_acttion">
                        <button v-if="msg.cancelButton.length > 0" class="dialog_button dialog_cancel"
                            @click="cancelButtonClick">{{ msg.cancelButton
                            }}</button>
                        <button class="dialog_button dialog_sure" @click="sureButtonClick">{{ msg.sureButton }}</button>
                    </div>
                    <span v-if="msg.warningTips" class="warning_tips">{{ msg.warningTips }}</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'OverAllDialogView',
    props: {
        msg: {
            type: Object as () => any,
            default: {
                dialogText: '',
                sureButton: '确认',
                cancelButton: '取消',
                warningTips: '',
                showDialog: false,
                blackBg: false,
                callBackAction: (type: number) => { }
            }
        }
    },
    components: {

    },
    setup(props, { emit }) {

        const sureButtonClick = () => {
            if (props.msg.callBackAction) {
                props.msg.callBackAction(1);
            }
        };
        const cancelButtonClick = () => {
            if (props.msg.callBackAction) {
                props.msg.callBackAction(0);
            }
        }

        const dismissOverAllDialog = () => {
            props.msg.showDialog = false;
        };

        onMounted(() => {
        });

        return {
            sureButtonClick,
            cancelButtonClick,
            dismissOverAllDialog,
        };
    },
});

</script>

<style scoped lang="less">
.dialog_bg_view {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .dialog_layer_view {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .dialog_content_view {
        width: 580px;
        border-radius: 40px;
        padding: 110px 50px 64px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        background-color: #f0f0f0;
        z-index: 100;

        .dialog_text {
            color: #242424;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 0 30px;
        }

        .dialog_action_bg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 52px;

            .dialog_acttion {
                width: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .dialog_button {
                    border-width: 0;
                    width: 180px;
                    height: 64px;
                    border-radius: 36px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: normal;
                    cursor: pointer;
                }

                .dialog_button:active {
                    opacity: 0.7;
                }

                .dialog_sure {
                    background: #242424;
                    color: #F0F0F0;
                }

                .dialog_cancel {
                    background: #F0F0F0;
                    border: 2px solid #242424;
                    color: #242424;
                    margin-right: 30px;

                }
            }

            .warning_tips {
                margin: 30px 0;
                color: #E70000;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}

.dialog_bg_view_normal {
    background: rgba(230, 230, 230, 0.95);
}

.dialog_bg_view_black {
    background: rgba(36, 36, 36, 0.90);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}

.dialog-enter-active,
.dialog-leave-active {
    transition: transform 0.2s;
}

.dialog-enter,
.dialog-leave-to {
    transform: scale(0.8);
    opacity: 0;
}
</style>