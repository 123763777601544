import { createApp, reactive } from "vue";

import myLoad from "./LoadingView.vue";

const msg = reactive({
  isShow: false,
  text: "",
  isShowSuccess: false,
});

const $loading = createApp(myLoad, { msg }).mount(
  document.createElement("div")
);
const Loading = {
  show(title: any = msg.text) {
    // 控制显示loading的方法
    msg.isShowSuccess = false;
    msg.isShow = true;
    msg.text = title;
    document.body.appendChild($loading.$el);
  },

  hide() {
    // 控制loading隐藏的方法
    msg.isShow = false;
  },
  hideWithSuccess(textTips: string) {
    msg.isShowSuccess = true;
    msg.text = textTips ? textTips : "";
    setTimeout(() => {
      msg.isShow = false;
    }, 2000);
  },
};
export { Loading };
