<template>
    <div class="header-wrapper">
        <div class="header-container">
            <div class="logo-wrapper" @click="gotoHome">
                <img src="../assets/images/linkard_logo.png" alt="" />
                <span class="linkard_name linkard_en_name">LINKARD</span>
                <span class="linkard_name">联卡</span>
            </div>
            <div class="label-wrapper">
                <div v-if="showTopRoutTab" class="card_jump_action">
                    <div :class="['f-btn', (currentRoutePath == '/editCard' || currentRoutePath == '/enterpriseEdit') ? 'f-btn-active' : '']"
                        @click="toLinkard">
                        <img class="card_img" src="../assets/images/my-link-default.svg" alt="" />
                        <span>联卡</span>
                    </div>
                    <div :class="['f-btn', currentRoutePath == '/collect' ? 'f-btn-active' : '']"
                        @click="toCardCollect">
                        <img class="card_img" src="../assets/images/my-card-default.svg" alt="" />
                        <span>卡包</span>
                    </div>
                    <div v-if="haveCabinet" :class="['f-btn', currentRoutePath == '/cabinet' ? 'f-btn-active' : '']"
                        @click="toCabinet">
                        <img class="card_img" src="../assets/images/my-cabinet.svg" alt="" />
                        <span>卡柜</span>
                    </div>
                    <div v-if="currentRoutePath == '/editCard'" :class="['linkard_link_site_bg']">
                        <div class="top-site-info">
                            <span>{{ baseLinkUrl + '/' + splitLinkardPath }}</span>
                            <el-input v-model="linkardPath" :disabled="!pathAllowChanges" @blur="linkInputBlurChange"
                                @focus="linkInputFocusChange"></el-input>
                            <span v-if="!pathAllowChanges" class="cannot_input_tips">* 暂不可修改</span>
                            <button v-if="pathAllowChanges && linkInputFocus" class="change_btn"
                                @click="linkardSavePathRequest">
                                修改
                            </button>
                        </div>
                        <el-popover placement="bottom" trigger="hover">
                            <template #default>
                                <div class="pop-wrapper">
                                    <div class="pop-next-btn" @click="toShowPost">我的海报</div>
                                    <div class="pop-next-btn" @click="toShowErweima">我的二维码</div>
                                    <div class="link-address-wrapper">
                                        <div class="text">{{ baseLinkUrl + '/' + splitLinkardPath + linkardPath }}
                                        </div>
                                        <div class="copy-btn" @click="copyLink">点击复制</div>
                                    </div>
                                </div>
                            </template>
                            <template #reference>
                                <button slot="reference" class="share-btn">
                                    <img class="share_linkard_icon" src="../assets/images/share_linkard_icon.svg"
                                        alt="">
                                </button>
                            </template>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
        <el-button v-if="currentRoutePath == '/login'" class="login_btn" @click="loginButtonClick">登录</el-button>
        <el-popover v-if="currentRoutePath != '/login' && currentRoutePath != '/managementCenterView'"
            placement="bottom-end" trigger="hover">
            <template #default>
                <div class="pop-wrapper mine-pop-wrapper">
                    <div v-if="hasUserPhone" class="pop-next-btn" @click="toManagerLinkardView">
                        管理中心
                    </div>
                    <div class="pop-next-btn" @click="showUserManual">使用手册</div>
                    <div class="pop-next-btn" @click="logOutButtonClick">立即登出</div>
                </div>
            </template>
            <template #reference>
                <div class="avatar" slot="reference">
                    <img :src="currentUserHeader ? currentUserHeader : userDefaultHeader" alt="" />
                </div>
            </template>
        </el-popover>
        <el-dialog v-model="loginQRVisible" :show-close="false" align-center center :before-close="handleBeforeClose">
            <template #header>
                <div class="login-wrapper">
                    <div id="qrcode-container" class="erweima"></div>
                    <img src="../assets/images/close.png" alt="" class="close_qr" @click="closeQRView" />
                    <div class="privacy_agreement">
                        <span>扫码即代表您同意</span>
                        <span>
                            <a href="https://o4ernlf37o.feishu.cn/file/Zk3JbG6N5oUEfExRWwjchdqcnUg?from=from_copylink"
                                target="_blank">《用户协议》</a>
                        </span>
                        <span>与</span>
                        <span>
                            <a href="https://o4ernlf37o.feishu.cn/file/EW6Lb8Iu4o6LHpx7PDYc6R5SnQb?from=from_copylink"
                                target="_blank">《隐私政策》</a>
                        </span>
                    </div>
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="logoutShow" :show-close="false" align-center center>
            <template #header>
                <div class="logout-wrapper">
                    <div class="tips">是否确认登出？</div>
                    <div class="btn btn-confirm" @click="handleLogout">登出</div>
                    <div class="btn" @click="closeLogout">返回</div>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="postShow" :show-close="false" align-center center>
            <template #header>
                <div class="poster_bg">
                    <div ref="canvasRef" class="linkard_item_bg">
                        <img :src="currentLinkInfo.logo" alt="" class="linkard_logo">
                        <div class="linkard_name">{{ currentLinkInfo.name }}</div>
                        <div class="linkard_tags">
                            <div class="linkard_tags_bg" v-for="(item, index) in currentLinkInfo.tags">
                                <span v-if="item" :key="index">{{ item }}</span>
                            </div>
                        </div>
                        <div class="linkard_avatar">
                            <el-image :src="currentLinkInfo.avatar" fit="cover" />
                        </div>
                        <span class="linkard_path">{{ baseLinkUrl + '/' + splitLinkardPath + linkardPath }}</span>
                        <div class="qr_code_image_bg">
                            <vue-qrcode :value="linkardQRImgUrl" :color="{}" type="image/png" :width="106" :height="106"
                                :margin="2" errorCorrectionLevel="L"></vue-qrcode>
                        </div>
                    </div>
                    <div class="btn btn-confirm btn-post" @click="createPoster">
                        下载PNG
                    </div>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="qRCodeShow" :show-close="false" align-center center>
            <template #header>
                <div class="download_qr_code_image_bg">
                    <div ref="qrcodeGeneratorDownloadRef" class="download_qr_code_image">
                        <vue-qrcode :value="linkardQRImgUrl" :color="{}" type="image/png" :width="306" :height="306"
                            :margin="2" errorCorrectionLevel="L"></vue-qrcode>
                    </div>
                    <div class="btn btn-confirm btn-post" @click="downLoadQRImage">
                        下载PNG
                    </div>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="showUserInfoDialog" :show-close="false" align-center center>
            <template #header>
                <UserinfoView @closeUserView="closeUserInfoDialog"></UserinfoView>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts">
import { HttpResponse } from '@/net/http';
import { UserLoginInfo, linkardNet } from '@/net/linkardNet';
import { useUserStore } from '@/stores/user';
import { ACCESS_TOKEN, LK_PATH_PERSONAL, LK_PATH_PRO, NavigatorRoute, UserLevelType } from '@/utils/const';
import { WxLogin } from '@/utils/weixinLogin';
import { ElMessage } from 'element-plus';
import useClipboard from 'vue-clipboard3';
import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UserinfoView from "./userinfoView.vue";
import html2canvas from "html2canvas";
import VueQrcode from 'vue-qrcode';

const screenWidth = window.screen.width;
let wxQrCodeStyle = ''
if (screenWidth > 1280 && screenWidth <= 1440) {//240
    wxQrCodeStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI0MHB4O2hlaWdodDoyNDBweDttYXJnaW4tdG9wOjIwcHg7Ym9yZGVyLXJhZGl1czoyOHB4fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTAwJTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7bWFyZ2luOiAwIDAgNHB4IDA7fQ0KLnN0YXR1c19mYWlsIHtkaXNwbGF5OiBmbGV4O2ZsZXgtZGlyZWN0aW9uOiBjb2x1bW47YWxpZ24taXRlbXM6IGNlbnRlcjt9DQouc3RhdHVzX3N1Y2Mge2Rpc3BsYXk6IGZsZXg7ZmxleC1kaXJlY3Rpb246IGNvbHVtbjthbGlnbi1pdGVtczogY2VudGVyO30NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'
} else if (screenWidth > 1440 && screenWidth <= 1920) {//250
    wxQrCodeStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI1MHB4O2hlaWdodDoyNTBweDttYXJnaW4tdG9wOjIwcHg7Ym9yZGVyLXJhZGl1czoyOHB4fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTAwJTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7bWFyZ2luOiAwIDAgNHB4IDA7fQ0KLnN0YXR1c19mYWlsIHtkaXNwbGF5OiBmbGV4O2ZsZXgtZGlyZWN0aW9uOiBjb2x1bW47YWxpZ24taXRlbXM6IGNlbnRlcjt9DQouc3RhdHVzX3N1Y2Mge2Rpc3BsYXk6IGZsZXg7ZmxleC1kaXJlY3Rpb246IGNvbHVtbjthbGlnbi1pdGVtczogY2VudGVyO30NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'
} else if (screenWidth > 1920) {//260
    wxQrCodeStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI2MHB4O2hlaWdodDoyNjBweDttYXJnaW4tdG9wOjQwcHg7Ym9yZGVyLXJhZGl1czoyOHB4fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTAwJTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmU7bWFyZ2luOiAwIDAgNHB4IDA7fQ0KLnN0YXR1c19mYWlsIHtkaXNwbGF5OiBmbGV4O2ZsZXgtZGlyZWN0aW9uOiBjb2x1bW47YWxpZ24taXRlbXM6IGNlbnRlcjt9DQouc3RhdHVzX3N1Y2Mge2Rpc3BsYXk6IGZsZXg7ZmxleC1kaXJlY3Rpb246IGNvbHVtbjthbGlnbi1pdGVtczogY2VudGVyO30NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'
}

export default defineComponent({
    name: 'HeaderView',
    components: {
        UserinfoView,
        VueQrcode
    },
    computed: {
        NavigatorRoute() {
            return NavigatorRoute;
        }
    },
    setup(props, { emit }) {

        let userLevel = 0;
        const router = useRouter();
        const route = useRoute();
        const userStore = useUserStore();
        let baseLinkUrl = process.env.VUE_APP_MOBILE_URL;
        let pathAllowChanges = ref(false);
        let hasUserPhone = ref(false);

        let splitLinkardPath = ref('');
        // 访问当前路由的路径
        let currentRoutePath = ref(route.path);
        let currentUserHeader = ref('');

        let linkInputFocus = ref(false);

        let loginQRVisible = ref(false);

        let haveCabinet = ref(false);

        let showUserInfoDialog = ref(false);

        let logoutShow = ref(false);
        let postShow = ref(false);
        let qRCodeShow = ref(false);

        let linkardType = 0;
        let linkardPath = ref('');
        let linkardId = '';
        let linkardQRImgUrl = ref('');
        let currentLinkInfo: Ref<any> = ref({
            logo: '',
            name: '',
            tags: [],
            avatar: ''
        });
        const canvasRef = ref();
        const qrcodeGeneratorDownloadRef = ref();

        const { toClipboard } = useClipboard();

        const handleRouteChange = () => {
            currentRoutePath.value = route.path;
            getLinkardEditInfo();
        };

        const getLinkardEditInfo = () => {
            const tempLinkardId = String(route.query.linkardId);
            if (tempLinkardId && tempLinkardId != 'undefined') {
                linkardId = tempLinkardId;
            }

            linkardPath.value = userStore.currentPath;
            // const tempLinkardPath = String(route.query.cardPath);
            // if (tempLinkardPath && tempLinkardPath != 'undefined') {
            //     linkardPath.value = tempLinkardPath;
            // }

            const tempLinkardType = Number(route.query.cardType);
            if (!Number.isNaN(tempLinkardType)) {
                linkardType = tempLinkardType;
            }

            calculateSplitLinkardPath();
        };

        router.afterEach(handleRouteChange);

        const showTopRoutTab = computed(() => {
            return currentRoutePath.value == '/editCard' || currentRoutePath.value == '/enterpriseEdit' || currentRoutePath.value == '/cabinet' || currentRoutePath.value == '/collect';
        });

        const closeUserInfoDialog = () => {
            showUserInfoDialog.value = false;
        };

        const toManagerLinkardView = () => {
            if (userStore.privilegeLevel.level == UserLevelType.PROFESSIONAL || userStore.privilegeLevel.level == UserLevelType.ENT) {
                router.push({ path: NavigatorRoute.MANAGER_CENTER });
            } else {
                showUserInfoDialog.value = true;
            }
        };

        const logOutButtonClick = () => {
            logoutShow.value = true;
        };

        const showUserManual = () => {
            const url = "https://o4ernlf37o.feishu.cn/docx/LgCGd430eocuFQxZnu0cYivYnfc";
            window.open(url, '_blank');
        };

        const handleLogout = () => {
            linkardNet.userLoginOut().then(
                (response) => {
                    closeLogout();
                    sessionStorage.removeItem(ACCESS_TOKEN);
                    router.replace({ path: NavigatorRoute.LOGIN_PAGE });
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const closeLogout = () => {
            const empty: UserLoginInfo = {
                id: '',
                headImg: '',
                name: '',
                linkardId: '',
                path: '',
                phone: '',
                pathAllowChanges: false,
                usedCnt: 0,
                remainingCnt: 0,
                totalCnt: 0,
            };
            userStore.updateCurrentUserInfo(empty);
            logoutShow.value = false;
        };

        const showUserInfoMethod = (value: boolean) => {
            if (showUserInfoDialog.value == value) return;
            showUserInfoDialog.value = value;
        };

        const toLinkard = () => {
            if (userStore.privilegeLevel.level == UserLevelType.PROFESSIONAL || userStore.privilegeLevel.level == UserLevelType.ENT) {
                router.push({ path: NavigatorRoute.ENTER_PRISE_EDIT });
            } else {
                userStore.currentPath = userStore.userInfo.path;
                router.push({ path: NavigatorRoute.EDIT_CARD, query: { cardType: 0, linkardId: userStore.userInfo.linkardId } });
            }
        };

        const toCardCollect = () => {
            router.push({ path: NavigatorRoute.COLLECT });
        };

        const toCabinet = () => {
            router.push({ path: NavigatorRoute.CABINET });
        };

        const linkInputBlurChange = () => {
            setTimeout(() => {
                linkInputFocus.value = false;
            }, 200);
        };

        const linkInputFocusChange = () => {
            linkInputFocus.value = true;
        };

        const initWxLogin = (): void => {
            const obj: WxLogin = new WxLogin({
                id: 'qrcode-container',// 需要显示的容器id
                appid: process.env.VUE_APP_WX_APPID,
                scope: 'snsapi_login',
                redirect_uri: encodeURIComponent(process.env.VUE_APP_WX_URL),
                state: new Date().getTime() + '' + Math.ceil(Math.random() * 1000), //建议使用时间戳+随机数，具体看你
                href: wxQrCodeStyle
            });
        };

        const gotoHome = () => {
            if (!userStore.userInfo.phone || userStore.userInfo.phone == 'undefined') {
                return;
            }
            router.push({ path: NavigatorRoute.HOME_PAGE });
        };

        const loginButtonClick = () => {
            loginQRVisible.value = true;
            setTimeout(() => {
                initWxLogin();
            }, 50);
        };

        const handleBeforeClose = (done: () => void) => {
            // 在这里可以添加关闭前的逻辑，例如确认操作等
            done();
        };

        const closeQRView = () => {
            loginQRVisible.value = false;
        };

        const linkardSavePathRequest = () => {
            linkardNet.linkardSavePath(linkardId, linkardPath.value).then(
                (response) => {
                    ElMessage.success('修改成功');
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const privilegeLevelRequest = () => {
            linkardNet.linkardPrivilegeLevel().then(
                (response) => {
                    haveCabinet.value = response.haveCabinet;
                    userStore.updatePrivilegeLevelInfo(response);
                    getUserInfoRequest();
                },
                (ex) => {

                },
            );
        };

        const loginRequest = (code: string, type: number) => {
            linkardNet.login(code, type).then(
                (response) => {
                    sessionStorage.setItem(ACCESS_TOKEN, response);
                    privilegeLevelRequest();
                    router.replace({ path: NavigatorRoute.HOME_PAGE });
                },
                (ex) => {

                },
            );
        };

        const getUserInfoRequest = () => {
            linkardNet.getUserInfo().then(
                (response) => {
                    currentUserHeader.value = response.headImg;
                    userStore.updateCurrentUserInfo(response);
                },
                (ex) => {

                },
            );
        };

        const calculateSplitLinkardPath = () => {
            if (linkardType == 0) {
                splitLinkardPath.value = LK_PATH_PERSONAL;
            } else {
                if (userLevel == 3) {
                    splitLinkardPath.value = LK_PATH_PRO;
                } else if (userLevel == 4) {
                    splitLinkardPath.value = "";
                }
            }
        };

        const copyLink = async () => {
            try {
                await toClipboard(baseLinkUrl + '/' + splitLinkardPath.value + linkardPath.value);
                ElMessage.info('复制成功');
            } catch (e) {
                ElMessage.error('复制失败');
                console.error(e);
            }
        }

        const toShowPost = async () => {
            postShow.value = true;
            getLinkardInfoRequest();
        };

        const toShowErweima = async () => {
            qRCodeShow.value = true;
            linkardQRImgUrl.value = baseLinkUrl + '/' + splitLinkardPath.value + linkardPath.value;
        };

        const createPoster = async () => {
            html2canvas(canvasRef.value, {
                useCORS: true,
                allowTaint: false,
                logging: false,
                backgroundColor: 'transparent', // 设置背景为透明
                scale: 3,
            }).then(canvas => {
                // canvas 其实就是我们所讲的res 的意思 返回报文的意思
                let baseImg = canvas.toDataURL("image/png");
                let save = document.createElement('a');
                save.href = baseImg;
                // 下载的名字
                save.download = 'linkard'
                // 直接回调a的点击事件
                save.click()

            });
        };

        const downLoadQRImage = () => {
            html2canvas(qrcodeGeneratorDownloadRef.value, {
                useCORS: true,
                allowTaint: false,
                logging: false,
                backgroundColor: 'transparent', // 设置背景为透明
                scale: 2,
            }).then(canvas => {
                // canvas 其实就是我们所讲的res 的意思 返回报文的意思
                let baseImg = canvas.toDataURL("image/png");
                let save = document.createElement('a');
                save.href = baseImg;
                // 下载的名字
                save.download = 'linkard_qr'
                // 直接回调a的点击事件
                save.click()
            });
        };

        const getLinkardInfoRequest = () => {
            linkardNet.getLinkardInfo(linkardId).then(
                (response) => {
                    currentLinkInfo.value.logo = response.icon;
                    currentLinkInfo.value.name = response.name;
                    currentLinkInfo.value.tags = response.tags;
                    currentLinkInfo.value.avatar = response.npc && response.npc.avatar;
                    linkardQRImgUrl.value = baseLinkUrl + '/' + splitLinkardPath.value + linkardPath.value;
                },
                (ex) => {

                },
            );
        }

        watch(() => userStore.privilegeLevel, (newValue, oldValue) => {
            userLevel = newValue.level;
            calculateSplitLinkardPath();
        });

        watch(() => userStore.currentPath, (newValue, oldValue) => {
            linkardPath.value = newValue;
            calculateSplitLinkardPath();
        });

        watch(() => userStore.userInfo.pathAllowChanges, (newValue, oldValue) => {
            pathAllowChanges.value = newValue;
        });

        watch(() => userStore.userInfo.headImg, (newValue, oldValue) => {
            currentUserHeader.value = newValue;
        });

        watch(() => userStore.userInfo, (newValue, oldValue) => {
            pathAllowChanges.value = newValue.pathAllowChanges;
            if (newValue.phone && newValue.phone != 'undefined') {
                hasUserPhone.value = true;
            } else {
                hasUserPhone.value = false;
            }
        });

        onMounted(() => {
            const wxCode = String(route.query.code);
            if (wxCode != 'undefined' && !sessionStorage.getItem(ACCESS_TOKEN)) {
                loginRequest(wxCode, 2);
            } else if (sessionStorage.getItem(ACCESS_TOKEN)) {
                privilegeLevelRequest();
            }
            getLinkardEditInfo();
        });

        return {
            currentRoutePath,
            loginQRVisible,
            splitLinkardPath,
            showTopRoutTab,
            pathAllowChanges,
            linkInputFocus,
            baseLinkUrl,
            linkardPath,
            currentUserHeader,
            haveCabinet,
            showUserInfoDialog,
            hasUserPhone,
            logoutShow,
            postShow,
            currentLinkInfo,
            linkardQRImgUrl,
            canvasRef,
            qRCodeShow,
            qrcodeGeneratorDownloadRef,
            toShowPost,
            toShowErweima,
            logOutButtonClick,
            toManagerLinkardView,
            closeUserInfoDialog,
            loginButtonClick,
            handleBeforeClose,
            closeQRView,
            toLinkard,
            toCardCollect,
            toCabinet,
            linkInputBlurChange,
            linkInputFocusChange,
            linkardSavePathRequest,
            gotoHome,
            showUserInfoMethod,
            handleLogout,
            closeLogout,
            copyLink,
            createPoster,
            downLoadQRImage,
            showUserManual,
            userDefaultHeader: require('../assets/images/user_default_header.svg'),
        };
    },
});

</script>

<style lang="less" scoped>
.header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px 80px 0 80px;

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;


        .logo-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                width: 88px;
                height: 88px;
            }

            .linkard_en_name {
                margin: 0 20px 0 48px;
            }

            .linkard_name {
                min-width: 50px;
                color: #242424;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }


        .label-wrapper {
            margin-left: 140px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;

            .card_jump_action {
                display: flex;
                align-items: center;
                width: auto;
                z-index: 10;

                .f-btn {
                    width: 118px;
                    height: 48px;
                    font-size: 12px;
                    margin-right: 8px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 30px;
                    border: 2px solid #242424;
                    background: #f0f0f0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                    color: #242424;
                    cursor: pointer;

                    .card_img {
                        width: 10px;
                        height: 10px;
                        margin-right: 10px;
                    }
                }

                .seize_a_seat {
                    width: 368px;
                }

                .f-card-btn {
                    img {
                        height: 12px;
                    }
                }

                .f-btn-active {
                    background: #F3EC3F;
                    color: #242424;
                    cursor: auto;
                }
            }

            .linkard_link_site_bg {
                width: 810px;
                height: 64px;
                border-radius: 40px;
                background: #F0F0F0;
                margin-left: 8px;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: relative;

                .change_linkard_site {
                    position: absolute;
                    bottom: -30px;
                    left: 36px;
                    color: rgba(36, 36, 36, 0.60);
                    font-size: 12px;
                    font-weight: 600;
                }

                .share-btn {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    width: 142px;
                    height: 64px;
                    font-weight: 600;
                    border-radius: 36px;
                    background: #f0f0f0;
                    border: 4px solid #242424;
                    position: absolute;
                    right: 0;
                    top: 0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                    .share_linkard_icon {
                        margin-right: 28px;
                        width: 36px;
                        height: 36px;
                    }
                }

                .top-site-info {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 700;
                    margin-left: 36px;
                    align-items: center;

                    .el-input {
                        width: auto;
                    }

                    .cannot_input_tips {
                        color: rgba(36, 36, 36, 0.60);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        width: 140px;
                        text-align: center;
                    }

                    .change_btn {
                        width: 88px;
                        height: 36px;
                        border-radius: 40px;
                        background: #F3EC3F;
                        border-width: 0;
                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                        color: #242424;
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin-left: 30px;
                    }

                    .change_btn:hover {
                        opacity: 0.7;
                    }

                    .change_btn:active {
                        opacity: 0.5;
                    }

                    :deep(.el-input__wrapper) {
                        background-color: transparent;
                        box-shadow: none;
                        padding: 0;

                        input {
                            max-width: 190px;
                            height: 48px;
                            flex-shrink: 0;
                            padding: 0;
                            border-width: 0;
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }
                }
            }

            .linkard_link_site_none {
                background: transparent;
                box-shadow: none;
            }
        }
    }

    .login_btn {
        width: 200px;
        height: 64px;
        font-weight: 600;
        border-radius: 36px;
        font-size: 18px;
        border: 4px solid #242424;
        background-color: #F3EC3F;
        color: #242424;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    }

    .login_btn:hover {
        opacity: 0.7;
    }

    .login_btn:active {
        opacity: 0.5;
    }

    .avatar {
        width: 64px;
        height: 64px;

        img {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 4px solid #242424;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        }
    }

    :deep(.el-overlay) {
        background: rgba(230, 230, 230, 0.95);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        .el-dialog {
            width: auto;
            border-radius: 8px;
            border-width: 0px !important;
            background-color: transparent !important;
            box-shadow: none;

            .el-dialog__header.show-close {
                padding: 0;
            }

            .el-dialog__headerbtn {
                top: 6px;
                right: 6px;

                .el-dialog__close {
                    font-size: 24px;
                }
            }
        }
    }
}
</style>

<style>
.login-wrapper {
    position: relative;
    border-radius: 40px;
    background: #f0f0f0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    width: 400px;
    height: 550px;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-duration: 2s;
    animation-fill-mode: forwards;

    div {
        font-weight: 600;
    }

    .erweima {
        margin-bottom: 28px;
    }

    .close_qr {
        margin-top: 64px;
        width: 48px;
        height: 48px;
        cursor: pointer;
        position: absolute;
        bottom: 45px;
    }

    .privacy_agreement {
        position: absolute;
        bottom: 12px;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        color: #242424;
        font-size: 12px;
        font-weight: 500;

        a {
            color: #242424;
            font-size: 12px;
        }
    }
}

#qrcode-container iframe {
    width: 300px !important;
    height: 480px !important;
    border-width: 0;
}

.el-popover {
    border-radius: 16px !important;
    background: transparent !important;
    border: none !important;
    padding: 0px !important;
    min-width: 300px !important;
}

.pop-wrapper {
    width: auto !important;
    border-radius: 28px;
    background: #f0f0f0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;

    .pop-next-btn {
        width: 220px;
        height: 48px;
        border-radius: 36px;
        border: 2px solid #242424;
        background: #f0f0f0;
        color: #242424;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        cursor: pointer;
    }

    .pop-next-btn:last-child {
        margin-bottom: 0;
    }

    .link-address-wrapper {
        display: -webkit-box;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        word-wrap: break-word;
        max-width: 280px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 60px;
        text-align: center;
        font-weight: 700;

        .text {
            margin-bottom: 14px;
            text-decoration-line: underline;
        }

        .copy-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: 20px;
            border-radius: 12px;
            border: 2px solid #242424;
            background: #f0f0f0;
            margin: 0 auto;
            cursor: pointer;
        }
    }
}

.logout-wrapper {
    width: 580px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 40px;
    background: #f0f0f0;
    box-shadow: 0 0 .41667vw 0 rgba(0, 0, 0, .08);

    .tips {
        margin-top: 124px;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 70px;
    }
}

.poster_bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 80px 40px 80px;
    border-radius: 40px;
    background: #F0F0F0;
    /* BtnShadow */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .linkard_item_bg {
        position: relative;
        background: url('../assets/images/share-my.jpg') no-repeat;
        width: 420px;
        height: 560px;
        background-size: cover;
        border-radius: 30px;

        .linkard_path {
            position: absolute;
            color: #f0f0f0;
            font-size: 12px;
            bottom: 57px;
            left: 144px;
        }

        .qr_code_image_bg {
            position: absolute;
            border-radius: 18px;
            left: 22px;
            bottom: 65px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                border-radius: 8px;
            }
        }

        .linkard_logo {
            width: 98px;
            height: 98px;
            border-radius: 98px;
            margin-top: 92px;
            margin-left: 40px;
            object-fit: cover;
        }

        .linkard_name {
            font-size: 28px;
            color: #fff;
            margin-left: 40px;
            font-weight: 600;
            margin-top: 20px;
        }

        .linkard_tags {
            margin-left: 40px;
            margin-top: 30px;
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .linkard_tags_bg {
                span {
                    color: #f0f0f0;
                    border-radius: 12px;
                    border: 1px solid #F0F0F0;
                    font-size: 10px;
                    padding: 2px 22px 2px 22px;
                    margin-left: 10px;
                    height: 22px;
                    line-height: 22px;
                }
            }
        }

        .linkard_avatar {
            width: 276px;
            height: 142px;
            border-radius: 30px 0px 0px 30px;
            background: #f0f0f0;
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            bottom: 82px;


            .el-image {
                height: 100%;
            }
        }
    }
}

.btn {
    width: 320px;
    height: 64px;
    border-radius: 36px;
    border: 2px solid #242424;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.btn-confirm {
    background: #242424;
    color: #f0f0f0;
    margin-bottom: 24px;
}

.btn-post {
    margin-top: 57px;
}

.download_qr_code_image_bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 80px 40px 80px;
    border-radius: 40px;
    background: #F0F0F0;
    /* BtnShadow */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}
</style>