import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      _createVNode(_component_HeaderView),
      (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.cachedViews }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
      ], 1032, ["include"]))
    ]),
    _: 1
  }))
}