import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-575cd696"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trigger_view_bg" }
const _hoisted_2 = { class: "trigger_view_content" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_video_player, {
        class: "video-js play_view_content",
        src: _ctx.showVideoUrl,
        onDispose: _ctx.closeVideoButtonClick,
        options: _ctx.playerOptions,
        volume: 0.6
      }, null, 8, ["src", "onDispose", "options"]),
      _createElementVNode("button", {
        class: "close_button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeVideoButtonClick && _ctx.closeVideoButtonClick(...args)))
      }, [
        _createElementVNode("img", {
          src: require('../assets/images/delete_button_icon.svg'),
          alt: ""
        }, null, 8, _hoisted_3)
      ])
    ])
  ]))
}