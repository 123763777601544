import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21747bc5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog_content_view" }
const _hoisted_2 = { class: "dialog_text" }
const _hoisted_3 = { class: "dialog_action_bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.dialogText), 1),
    _createVNode(_component_el_input, {
      modelValue: _ctx.userName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userName) = $event)),
      type: "text",
      placeholder: "请输入用户名"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_el_input, {
      modelValue: _ctx.userPhone,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userPhone) = $event)),
      type: "text",
      maxlength: "11",
      placeholder: "请输入手机号"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "dialog_button dialog_cancel",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancelButtonClick && _ctx.cancelButtonClick(...args)))
      }, _toDisplayString(_ctx.cancelButton), 1),
      _createElementVNode("button", {
        class: "dialog_button dialog_sure",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.sureButtonClick && _ctx.sureButtonClick(...args)))
      }, _toDisplayString(_ctx.sureButton), 1)
    ])
  ]))
}