<template>
    <button class="back_button" @click="backButtonClick">
        <img class="back_button_icon" :src="require('../assets/images/back_icon.svg')" alt="">
    </button>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'BackButtonItem',
    computed: {

    },
    setup(props, { emit }) {

        const backButtonClick = () => {
            emit("backBtnClick");
        }

        onMounted(() => {
        });

        return {
            backButtonClick
        };
    },
});

</script>

<style lang="less" scoped>
.back_button {

    width: 108px;
    height: 64px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    border-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;

    .back_button_icon {}
}

.back_button:hover {
    opacity: 0.9;
}

.back_button:active {
    opacity: 0.5;
}
</style>
