<!-- <script setup lang="ts">
import { useCachedViewStoreHook } from '@/stores/cachedView';
import { computed } from 'vue';

const cachedViews = computed(() => useCachedViewStoreHook().cachedViewList);
</script> -->

<template>
  <router-view v-slot="{ Component }">
    <HeaderView></HeaderView>
    <keep-alive :include="cachedViews">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useCachedViewStoreHook } from '@/stores/cachedView';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HeaderView from '../components/headerView.vue';

const cachedViews = computed(() => useCachedViewStoreHook().cachedViewList);
export default defineComponent({
  name: 'Login',
  components: {
    HeaderView
  },
  setup(props, { emit }) {

    const router = useRouter();
    const route = useRoute();
    // 访问当前路由的路径
    let currentPath = ref(route.fullPath);

    const handleRouteChange = () => {
    };

    router.afterEach(handleRouteChange);

    onMounted(() => {

    });
    return {
      cachedViews
    };
  },
});

</script>
<style lang="less" scoped>
@import '@/styles/mixin.less';

.app-wrapper {
  .clearfix();
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
</style>
