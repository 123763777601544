import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./stores";
import router from "./router";
import ElementPlus from "element-plus"; //全局引入
import "/node_modules/element-plus/dist/index.css";
// 全局样式
import VideoPlayer from "vue-video-player/src";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import "../src/assets/fonts/font.css";
import "./styles/index.less";
import "../rem";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VideoPlayer);
app.use(ElementPlus);
app.mount("#app");
