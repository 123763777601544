<template>
    <div class="social_media_content_bg">
        <div class="link-add-wrapper">
            <div class="link-number">
                {{ selectSocialLinkTop.name ? 1 : 0 }}/1
            </div>
            <div class="link-btn-wrapper">
                <div class="link-btn" @click="addTopLink" v-if="!selectSocialLinkTop.name">
                    点击添加
                </div>
                <div class="link-btn-content" v-else>
                    <div class="link-wrapper link-btn " @click="addTopLink">
                        <img :src="selectSocialLinkTop.icon" alt="" class="logo" v-if="selectSocialLinkTop.icon" />
                        <span>{{ selectSocialLinkTop.name }}</span>
                    </div>
                    <div class="link-btn-option">
                        <img src="../assets/images/brand-close.png" alt="" class="close" @click="deleteTopLink" />
                    </div>
                </div>
                <div class="tips">
                    *置顶链接：每次更新后您的联卡上将显示“NEW”字样以示提醒
                </div>
            </div>
        </div>
        <div class="link-add-wrapper">
            <div class="link-number">{{ selectSocialLinkNormalList.length }}/{{ mediaCount }}</div>
            <div class="link-btn-wrapper">
                <div id="dragcon" class="media_content">
                    <div class="link-btn-content" v-for="(item, index) in selectSocialLinkNormalList"
                        :key="item.name + index">
                        <div @click="editNormalLink(item, index)" class="link-wrapper link-btn link-btn-content-normal">
                            <img :src="item.icon" alt="" class="logo" v-if="item.icon" />
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="link-btn-option sort-btn">
                            <img src="../assets/images/brand-move.png" alt="" class="move" />
                            <img src="../assets/images/brand-close.png" alt="" class="close"
                                @click="deleteNormalLink(index)" />
                        </div>
                    </div>
                </div>
                <div class="link-btn link-btn-normal" @click="addNormalLink"
                    v-if="selectSocialLinkNormalList.length < mediaCount">
                    点击添加
                </div>
            </div>
        </div>
        <AddLinkShow v-if="addLinkShow" @dismissAddLinkShow="dismissAddLinkShow" @sureAddLinkShow="sureAddLinkShow"
            :tempSocialLink="selectSocialLink">
        </AddLinkShow>
    </div>
</template>

<script lang="ts">
import { SocialLinkItem } from '@/net/linkardNet';
import Sortable, { SortableEvent } from 'sortablejs';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import AddLinkShow from "./addLinkShow.vue";

export default defineComponent({
    name: 'BaseInfoView',
    props: {
        selectSocialLinkTop: {
            type: Object as () => SocialLinkItem,
            default: {
                icon: "",
                name: "",
                url: "",
                isTop: true,
            }
        },
        selectSocialLinkNormalList: {
            type: Array as () => Array<SocialLinkItem>,
            default: () => []
        },
        mediaCount: {
            default: 5
        }
    },
    components: {
        AddLinkShow
    },
    setup(props, { emit }) {
        const addLinkShow = ref(false);
        let editIndex = -1;

        const selectSocialLink: Ref<SocialLinkItem> = ref({
            icon: "",
            name: "",
            url: "",
            isTop: false,
        });
        // let selectSocialLinkTop: Ref<SocialLinkItem> = ref({
        //     icon: "",
        //     name: "",
        //     url: "",
        //     isTop: true,
        // });

        // let selectSocialLinkNormalList: Ref<SocialLinkItem[]> = ref([]);

        // let selectSocialLink: Ref<SocialLinkItem | null> = ref({
        //     icon: "",
        //     name: "",
        //     url: "",
        //     isTop: true,
        // });

        const addTopLink = () => {
            selectSocialLink.value.icon = props.selectSocialLinkTop.icon;
            selectSocialLink.value.name = props.selectSocialLinkTop.name;
            selectSocialLink.value.url = props.selectSocialLinkTop.url;
            selectSocialLink.value.isTop = true;
            addLinkShow.value = true;
        };

        const editNormalLink = (item: SocialLinkItem, index: number) => {
            editIndex = index;
            selectSocialLink.value.icon = item.icon;
            selectSocialLink.value.name = item.name;
            selectSocialLink.value.url = item.url;
            selectSocialLink.value.isTop = item.isTop;
            addLinkShow.value = true;
        };
        const deleteTopLink = () => {
            emit("deleteTopLink");
        };
        const addNormalLink = () => {
            // props.selectSocialLink = null;

            selectSocialLink.value.icon = '';
            selectSocialLink.value.name = '';
            selectSocialLink.value.url = '';
            selectSocialLink.value.isTop = false;
            addLinkShow.value = true;
        };
        const dismissAddLinkShow = () => {
            addLinkShow.value = false;
        };
        const deleteNormalLink = (i: number) => {
            props.selectSocialLinkNormalList.splice(i, 1);
        };
        const sureAddLinkShow = (socialLinkInfo: SocialLinkItem) => {
            if (socialLinkInfo.isTop) {
                props.selectSocialLinkTop.name = socialLinkInfo.name;
                props.selectSocialLinkTop.icon = socialLinkInfo.icon;
                props.selectSocialLinkTop.url = socialLinkInfo.url;
                props.selectSocialLinkTop.isTop = socialLinkInfo.isTop;
            } else {
                if (editIndex === -1) {
                    props.selectSocialLinkNormalList.push({
                        ...socialLinkInfo,
                        icon: socialLinkInfo.icon,
                        isTop: false,
                    });
                } else {
                    props.selectSocialLinkNormalList[editIndex] = {
                        ...socialLinkInfo,
                        icon: socialLinkInfo.icon,
                        isTop: false,
                    };
                }
            }
            addLinkShow.value = false;
        };

        const initSortable = async () => {
            const el = document.getElementById("dragcon");
            // 创建拖拽实例
            if (el) {
                const dragTable = new Sortable(el, {
                    animation: 150,
                    handle: ".sort-btn", // 触发拖动的按钮绑定类名sort-btn
                    // 结束拖动事件
                    onEnd: (e: SortableEvent) => {
                        if (e.newIndex !== e.oldIndex && e.newIndex != undefined && e.oldIndex != undefined) {
                            const ele = props.selectSocialLinkNormalList[e.oldIndex];
                            props.selectSocialLinkNormalList.splice(e.newIndex, 0, ele);
                            props.selectSocialLinkNormalList.splice(
                                e.newIndex > e.oldIndex ? e.oldIndex : e.oldIndex + 1,
                                1
                            );
                        }
                    },
                });
            }
        };

        onMounted(() => {
            initSortable();
            // selectSocialLinkTop.value = props.tempSelectSocialLinkTop;
            // selectSocialLinkNormalList.value = props.tempselectSocialLinkNormalList;
            // selectSocialLink.value = props.tempSelectSocialLink;
        });

        return {
            addLinkShow,
            selectSocialLink,
            addTopLink,
            deleteTopLink,
            editNormalLink,
            deleteNormalLink,
            addNormalLink,
            dismissAddLinkShow,
            sureAddLinkShow,
        };
    },
});

</script>

<style lang="less" scoped>
.social_media_content_bg {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    .link-add-wrapper {
        display: flex;
        padding-left: 150px;
        margin-bottom: 80px;

        .link-number {
            display: flex;
            width: 80px;
            line-height: 48px;
            color: #242424;
            font-size: 28px;
            font-weight: 700;
            margin-right: 43px;
        }

        .link-btn-wrapper {
            flex: 1;

            .media_content {
                height: auto;
                max-height: 340px;
                overflow: scroll;
                background-color: transparent;
            }

            .media_content::-webkit-scrollbar-track {
                background-color: transparent;
                margin: 30px 0;
            }

            .media_content::-webkit-scrollbar {
                width: 4px;
                background-color: transparent;
                height: 0px;
            }

            .media_content::-webkit-scrollbar-thumb {
                width: 4px;
                background-color: #d3d4d6;
                border-radius: 4px;
                margin: 30px 0;
            }

            .link-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 400px;
                height: 48px;
                border-radius: 36px;
                background: #f3ec3f;
                color: #242424;
                font-size: 18px;
                font-weight: 600;
                cursor: pointer;
            }

            .link-btn-normal {
                background: #242424;
                color: #f0f0f0;
            }

            .link-btn-content-normal {
                border-radius: 36px;
                border: 2px solid #242424;
                background: #f0f0f0;
                margin-bottom: 22px;
            }

            .link-btn-content {
                position: relative;
                display: flex;

                .link-wrapper {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    padding: 0 10px;
                    justify-content: flex-start;

                    span {
                        margin: 0 42px 0 10px;
                        display: inline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                        height: 48px;
                        line-height: 48px;
                        flex-grow: 1;
                    }
                }

                .logo {
                    width: 32px;
                    height: 32px;
                    top: 8px;
                }

                .link-btn-option {
                    // position: absolute;
                    // right: -162px;
                    height: 24px;
                    width: 122px;
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    margin-left: 40px;

                    .move {
                        width: 24px;
                        height: 24px;
                        margin-right: 40px;
                        cursor: pointer;
                    }

                    .close {
                        width: 18px;
                        height: 18px;
                        cursor: pointer;
                    }
                }
            }

            .tips {
                margin-top: 10px;
                color: rgba(36, 36, 36, 0.6);
                text-align: left;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
}
</style>