import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/images/brand-close.png'


const _withScopeId = n => (_pushScopeId("data-v-f6c80ce4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "digital_corpus_setting" }
const _hoisted_2 = { class: "top_view" }
const _hoisted_3 = { class: "yl-wrapper" }
const _hoisted_4 = { class: "yl-left" }
const _hoisted_5 = { class: "yl-right" }
const _hoisted_6 = { class: "input-content" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButtonItem = _resolveComponent("BackButtonItem")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BackButtonItem, {
        onBackBtnClick: _ctx.backButtonClick,
        class: "back_button"
      }, null, 8, ["onBackBtnClick"]),
      _createElementVNode("button", {
        class: "submit_button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitClick && _ctx.submitClick(...args)))
      }, "确认")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.realTimeTraining.length) + "/5", 1),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.realTimeTraining, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "yl-list-item",
            key: index
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_input, {
                modelValue: item.text,
                "onUpdate:modelValue": ($event: any) => ((item.text) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              onClick: ($event: any) => (_ctx.deleteYl(index))
            }, null, 8, _hoisted_7)
          ]))
        }), 128)),
        _createElementVNode("button", {
          class: "click_add_button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addRealTimeTrainingClick && _ctx.addRealTimeTrainingClick(...args)))
        }, " 点击添加 ")
      ])
    ])
  ]))
}