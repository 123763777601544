import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-653d74d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-wrapper" }
const _hoisted_2 = { class: "edit-content-wrapper" }
const _hoisted_3 = { class: "left_content_bg" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "special_subject_list_bg" }
const _hoisted_6 = { class: "cabinet_name" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "special_subject_name" }
const _hoisted_9 = { id: "posterHtml" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "post-link-name" }
const _hoisted_12 = { class: "custom_linkard_name" }
const _hoisted_13 = { class: "right_content_bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CabinetBaseView = _resolveComponent("CabinetBaseView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "linkard_cabinet_list_bg",
            style: _normalizeStyle({ backgroundImage: `url(${_ctx.linkardCabinetBgImg})` })
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.cabinetInfo.cabinetName), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cabinetInfo.specialTopicList, (specialTopItem, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "special_subject_item_bg",
                  key: index
                }, [
                  _createElementVNode("img", {
                    class: "special_subject_img",
                    src: specialTopItem.url,
                    alt: ""
                  }, null, 8, _hoisted_7),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(specialTopItem.name), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(specialTopItem.linkardList, (linkardInfo, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "post-wrapper",
                      key: 'item_' + index
                    }, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("img", {
                          src: linkardInfo.avatar,
                          alt: "",
                          class: "post-link-logo"
                        }, null, 8, _hoisted_10),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(linkardInfo.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(linkardInfo.showName), 1)
                    ]))
                  }), 128))
                ]))
              }), 128))
            ])
          ], 4)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_CabinetBaseView, { onRefreshSpecialSubjectList: _ctx.refreshSpecialSubjectList }, null, 8, ["onRefreshSpecialSubjectList"])
      ])
    ])
  ]))
}