<template>
    <div class="home_content_bg">
        <div class="mine-content-wrapper">
            <img class="home_linkard_logo" src="../assets/images/home_index_linkard_logo.svg" alt="" />
            <div class="card-item card-mine-item" @click="toLinkardPage" v-if="!bindPhoneVisible">
                <div class="mask">
                    <img src="../assets/images/mine-card-icon-hover.png" alt="" />
                </div>
                <img src="../assets/images/mine-card-icon.svg" alt="" />
                <span>联卡</span>
            </div>
            <div class="card-item card-collect-item" @click="toCollectPage" v-if="!bindPhoneVisible">
                <div class="mask">
                    <img src="../assets/images/collect-card-icon-hover.png" alt="" />
                </div>
                <img src="../assets/images/collect-card-icon.svg" alt="" />
                <span>卡包</span>
            </div>
            <div v-if="haveCabinet && !bindPhoneVisible" class="card-item card-collect-item" @click="toCabinet">
                <div class="mask">
                    <img src="../assets/images/linkard_box_icon_hover.png" alt="" />
                </div>
                <img src="../assets/images/linkard_box_icon.svg" alt="" />
                <span>卡柜</span>
            </div>
        </div>

        <div class="fotter_view">
            <div class="filing_information">
                <span class="icp">
                    <span>
                        <a href="https://beian.cac.gov.cn/" target="_blank">网信算备110105218498801240011号</a>
                    </span>
                    <span class="line">|</span>
                    <span>
                        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023018406号-2</a>
                    </span>
                    <span class="line">|</span>
                </span>
                <span>
                    <a class="police-regis-link"
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502055796"
                        target="_blank">
                        <img class="police-regis" src="../assets/images/police-regis.png" alt="police-regis">
                        <span>京公网安备11010502055796号</span>
                    </a>
                </span>
                <span class="line">|</span>
                <span>
                    <a href="https://o4ernlf37o.feishu.cn/file/Zk3JbG6N5oUEfExRWwjchdqcnUg?from=from_copylink" target="_blank">用户协议</a>
                </span>
                <span class="line">|</span>
                <span>
                    <a href="https://o4ernlf37o.feishu.cn/file/EW6Lb8Iu4o6LHpx7PDYc6R5SnQb?from=from_copylink" target="_blank">隐私政策</a>
                </span>
            </div>
        </div>
        <el-dialog v-model="bindPhoneVisible" :show-close="false" align-center center :before-close="handleBeforeClose">
            <template #header>
                <div class="bind-phone-container">
                    <div class="title">绑定手机号后即可创建</div>
                    <div class="main">
                        <div class="phone-box">
                            <el-input v-model="phone" placeholder="请输入手机号" maxlength="11"></el-input>
                        </div>
                        <div class="password-box">
                            <el-input v-model="code" placeholder="请输入验证码" maxlength="6"></el-input>
                            <div class="code" round @click="sendCode" :disabled="isSending || countdown > 0">
                                {{ isSending ? "" : countdown > 0
                ? `${countdown}秒后重试`
                : "获取验证码"
                                }}
                            </div>
                        </div>
                        <div class="submit-box">
                            <el-button round @click="bindPhoneRequest">确认</el-button>
                        </div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { linkardNet } from '@/net/linkardNet';
import { ElMessage } from 'element-plus';
import { HttpResponse } from '@/net/http';
import { useRouter } from 'vue-router';
import { ACCESS_TOKEN, NavigatorRoute, QueryParam, UserLevelType } from '@/utils/const';
import useStore from 'element-plus/es/components/table/src/store';
import { useUserStore } from '@/stores/user';
import { validatePhone } from '@/utils/commonUtils';


export default defineComponent({
    name: 'Home',
    components: {

    },
    setup(props, { emit }) {
        let pageNumber = 1;
        let pageSize = 12;
        let linkardUrl = ref('');
        let bindPhoneVisible = ref(false);
        let isSending = ref(false);
        let countdown = ref(0);
        let phone = ref('');
        let code = ref('');
        let userInfo = {};
        let path = ref('');
        let haveCabinet = ref(false);

        const userStore = useUserStore();

        haveCabinet.value = userStore.privilegeLevel.haveCabinet;

        const router = useRouter();

        const handleBeforeClose = (done: () => void) => {
            // 在这里可以添加关闭前的逻辑，例如确认操作等
            done();
        };

        watch(() => userStore.privilegeLevel, (newValue, oldValue) => {
            haveCabinet.value = newValue.haveCabinet;
        });

        const toLinkardPage = () => {

            if (!userStore.userInfo.phone || userStore.userInfo.phone == 'undefined') {
                bindPhoneVisible.value = true;
            } else {
                if (userStore.privilegeLevel.level == UserLevelType.PROFESSIONAL || userStore.privilegeLevel.level == UserLevelType.ENT) {
                    router.push({ path: NavigatorRoute.ENTER_PRISE_EDIT });
                } else {
                    userStore.currentPath = userStore.userInfo.path;
                    if (userStore.userInfo.linkardId && userStore.userInfo.linkardId != 'undefined') {
                        router.push({ path: NavigatorRoute.EDIT_CARD, query: { editType: 1, cardType: 0, linkardId: userStore.userInfo.linkardId } });
                    } else {
                        router.push({ path: NavigatorRoute.EDIT_CARD, query: { editType: 0, cardType: 0 } });
                    }
                }
            }
        };

        const toCollectPage = () => {
            if (!userStore.userInfo.phone || userStore.userInfo.phone == 'undefined') {
                bindPhoneVisible.value = true;
            } else {
                router.push({ path: NavigatorRoute.COLLECT });
            }
        };

        const toCabinet = () => {
            if (!userStore.userInfo.phone || userStore.userInfo.phone == 'undefined') {
                bindPhoneVisible.value = true;
            } else {
                router.push({ path: NavigatorRoute.CABINET });
            }
        };

        const bindPhoneRequest = async () => {
            if (!phone.value || !code.value) {
                ElMessage.error('请填写手机号和验证码');
                return;
            }
            if (!validatePhone(phone.value)) {
                ElMessage.error('请填写手机号和验证码');
                return;
            }

            linkardNet.bindPhone(phone.value, code.value).then(
                (response) => {
                    bindPhoneVisible.value = false;
                    ElMessage.success('已成功绑定手机号');
                    userStore.userInfo.phone = phone.value;
                    userStore.updateCurrentUserInfo(userStore.userInfo);
                    router.replace({ path: NavigatorRoute.HOME_PAGE });
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const sendCode = async () => {
            if (!phone.value) {
                ElMessage.error('请填写手机号');
                return;
            }
            if (!validatePhone(phone.value)) {
                ElMessage.error('请正确填写手机号码');
                return;
            }
            if (countdown.value > 0) {
                return; // 防止重复点击发送
            }

            linkardNet.sendCheckCode(0, phone.value).then(
                (response) => {
                    startCountdown();
                },
                (ex) => {
                    ElMessage.error('验证码发送失败，请稍后重试');
                },
            );
        };
        const startCountdown = () => {
            countdown.value = 60;
            const timer = setInterval(() => {
                countdown.value--;
                if (countdown.value <= 0) {
                    clearInterval(timer);
                }
            }, 1000);
        };

        const getUserInfoRequest = () => {
            linkardNet.getUserInfo().then(
                (response) => {
                    userStore.updateCurrentUserInfo(response);
                },
                (ex) => {

                },
            );
        };

        onMounted(() => {
            if (sessionStorage.getItem(ACCESS_TOKEN) && !userStore.userInfo.linkardId) {
                getUserInfoRequest();
            } else if (!sessionStorage.getItem(ACCESS_TOKEN)) {
                router.replace({ path: NavigatorRoute.LOGIN_PAGE });
            }
        });
        return {
            linkardUrl,
            path,
            bindPhoneVisible,
            haveCabinet,
            phone,
            code,
            isSending,
            countdown,
            handleBeforeClose,
            sendCode,
            bindPhoneRequest,
            toLinkardPage,
            toCollectPage,
            toCabinet,
            userDefaultHeader: require('../assets/images/user_default_header.svg'),
        };
    },
});

</script>

<style lang="less" scoped>
.home_content_bg {
    width: 100%;
    height: calc(100vh - 168px);
    padding: 0 80px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .header-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;


            .logo-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    width: 88px;
                    height: 88px;
                }

                .linkard_en_name {
                    margin: 0 20px 0 48px;
                }

                .linkard_name {
                    min-width: 50px;
                    color: #242424;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .line_div {
                width: 578px;
                height: 2px;
                margin-left: 136px;
                background-color: #242424;
            }
        }

        .avatar {
            img {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                border: 4px solid #242424;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            }
        }
    }

    .mine-content-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 110px;

        .home_linkard_logo {
            position: absolute;
            width: 420px;
            height: 480px;
            left: 60px;
            top: 400px;
        }

        .card-item {
            // padding: 44px 44px 48px 44px;
            width: 400px;
            height: 560px;
            margin-right: 88px;
            border-radius: 40px;
            background: var(--white, #f0f0f0);
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--black, #242424);
            font-size: 24px;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            span {
                position: absolute;
                text-align: center;
                bottom: 48px;
            }

            .mask {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #242424;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all 0.2s ease-in-out;

                img {
                    width: 129px;
                    height: 129px;
                }
            }
        }

        .card-item:hover .mask {
            opacity: 1;
        }

        .card-mine-item {
            img {
                width: 128px;
                height: 128px;
            }
        }

        .card-collect-item {
            img {
                width: 108.44px;
                height: 128.78px;
            }

            .mask {
                img {
                    width: 108px;
                }
            }
        }

        .card-item:last-child {
            margin-right: 0;
        }
    }

    :deep(.el-overlay) {
        background: rgba(230, 230, 230, 0.95);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        .el-dialog {
            border-radius: 8px;
            border-width: 0px !important;
            background-color: transparent !important;
            box-shadow: none;
            width: auto;

            .el-dialog__header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .el-dialog__header.show-close {
                padding: 0;
            }

            .el-dialog__headerbtn {
                top: 6px;
                right: 6px;

                .el-dialog__close {
                    font-size: 24px;
                }
            }
        }
    }

    .bind-phone-container {
        text-align: center;
        width: 580px;
        height: 450px;
        padding: 82px 100px 65px 100px;
        flex-shrink: 0;
        border-radius: 40px;
        background: #f0f0f0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        .title {
            color: #242424;
            font-size: 24px;
            font-weight: 600;
        }

        .main {
            margin-top: 31px;

            .phone-box {
                margin-bottom: 28px;
            }

            .password-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 48px;

                .code {
                    width: 200px;
                    height: 48px;
                    line-height: 48px;
                    border-radius: 48px;
                    margin-left: 20px;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    background: #242424;
                    color: #fff;
                    border-color: #242424;
                    cursor: pointer;
                }
            }

            :deep(.el-input__wrapper) {
                background: transparent;
                border: 2px solid #242424;
                height: 48px;
                border-radius: 24px;

                .el-input__inner {
                    font-size: 12px;
                    font-weight: 500;
                    color: #242424;
                    margin: 0 16px 0 16px;
                    background: transparent;
                }
            }

            :deep(.el-input__wrapper.is-focus) {
                box-shadow: none;
            }

            .submit-box {
                cursor: pointer;

                :deep(.el-button) {
                    height: 64px;
                    width: 100%;
                    padding: 10px 15px;
                    font-size: 18px;
                    background-color: #242424;
                    color: #fff;
                    font-weight: 600;
                    border-width: 0;
                    border-radius: 64px;
                    cursor: pointer !important;
                }
            }
        }
    }

    .fotter_view {
        position: absolute;
        bottom: 10px;
        width: calc(100% - 160px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-size: 12px;
        font-weight: 400;
        color: #242424;

        .line {
            margin: 0 15px;
        }


        .filing_information {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 6px;

            a {
                color: #242424;
            }
        }

        .police-regis-link {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .police-regis {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }
    }
}
</style>