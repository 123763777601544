<template>
    <div class="space_model_list_bg">
        <div class="top_view">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
            <!-- <span>选择数字空间模板</span> -->
            <button class="submit_button" @click="submitClick">确认</button>
        </div>
        <div class="list_content" style="overflow:auto">
            <ul class="list" v-infinite-scroll="loadMoreInfo" :infinite-scroll-disabled="noMoreData">
                <li v-for="(item, index) in itemList" class="list-item">
                    <SpaceModeItem :itemInfo="item" :specialHeight="specailIndexList.includes(index)"
                        :isSelected="index == selectedIndex" :itemIndex="index"
                        @spaceModelItemClick="spaceModeItemClick">
                    </SpaceModeItem>
                </li>
            </ul>
        </div>
        <!-- <span class="data_count_tips" v-if="loading">加载中...</span> -->
    </div>
</template>

<script lang="ts">
import { linkardNet } from '@/net/linkardNet';
import { ElMessage } from 'element-plus';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import SpaceModeItem from "./spaceModeItem.vue";
import BackButtonItem from "./backButtonItem.vue";
import { HttpResponse } from '@/net/http';
import { Loading } from '../components/loadingView/customLoading';

export default defineComponent({
    name: 'SpaceModelList',
    props: {
        linkardType: {
            type: Number,
            default: 0,
        },
        linkardId: {
            type: String,
            default: '',
        },
    },
    components: {
        SpaceModeItem,
        BackButtonItem
    },
    setup(props, { emit }) {

        let loading = ref(false);
        let pageNum = 1;
        let pageSize = 10;
        let noMoreData = ref(false);
        let itemList: Ref<any[]> = ref([]);
        let specailIndexList: Ref<any[]> = ref([]);
        let selectedIndex = ref(-1);
        let selectSceneStyle = "";
        let selectCoverImg = "";

        const backButtonClick = () => {
            emit("dismissModelList");
        };

        const loadMoreInfo = () => {
            getSceneList(pageNum, pageSize);
        };

        const spaceModeItemClick = (index: number, sceneStyle: string, coverImg: string) => {
            selectedIndex.value = index;
            selectSceneStyle = sceneStyle;
            selectCoverImg = coverImg;
        };

        const submitClick = () => {
            if (selectedIndex.value >= 0) {
                createSpace();
            } else {
                ElMessage.error("请选择场景");
            }
        };

        const getSceneList = async (pageNumber: number, pageSize: number) => {
            loading.value = true;
            linkardNet.getSceneList(pageNumber, pageSize,
                props.linkardType).then(
                    (response) => {
                        loading.value = false;
                        const tempList = JSON.parse(response.itemList).itemList;
                        if (tempList.length < 10) {
                            noMoreData.value = true;
                        } else {
                            pageNum += 1;
                        }
                        const listArray = itemList.value;
                        itemList.value = [...listArray, ...tempList];
                        computeSpecailIndex();
                    },
                    (ex) => {
                        loading.value = false;
                    },
                );
        };

        const computeSpecailIndex = () => {
            for (var i = 0; i < itemList.value.length; i++) {
                if (itemList.value[i].Versions[0].ExConfig.styleList) {
                    if (i > 0) {
                        specailIndexList.value.push(i);
                        if (i % 2 == 0) {
                            specailIndexList.value.push(i + 1);
                        }
                        else {
                            specailIndexList.value.push(i - 1);
                        }
                    }
                    else {
                        specailIndexList.value.push(i);
                        specailIndexList.value.push(i + 1);
                    }
                }
            }
        };

        const createSpace = async () => {
            Loading.show('正在创建...');
            const spaceItem = itemList.value[selectedIndex.value];
            var param = {
                linkardId: props.linkardId,
                sceneId: spaceItem.ID,
                coverImgUrl: selectCoverImg,
                sceneStyle: ''
            };
            if (selectSceneStyle) {
                param.sceneStyle = selectSceneStyle;
            }
            linkardNet.createSpace(param).then(
                (response) => {
                    Loading.hideWithSuccess('创建成功');
                    backButtonClick();
                },
                (ex) => {
                    Loading.hide();
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        onMounted(() => {

        });

        return {
            backButtonClick,
            submitClick,
            loadMoreInfo,
            spaceModeItemClick,
            noMoreData,
            itemList,
            specailIndexList,
            selectedIndex,
        };
    },
});

</script>

<style lang="less" scoped>
.space_model_list_bg {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {}

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
            margin-left: 50px;
        }
    }

    .list_content {
        width: 848px;
        height: 540px;
        padding: 10px 0;

        .list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }

    .data_count_tips {
        height: 60px;
        line-height: 60px;
        color: #242424;
        font-size: 16px;
        font-weight: 600;
    }

    .list_content::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .list_content::-webkit-scrollbar {
        width: 4px;
    }

    .list_content::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: #d3d4d6;
        border-radius: 10px;
    }
}
</style>
./loadingView/customLoading