<template>
    <div class="digital_role_content_bg">
        <div class="digital_role_content">
            <div class="role_background_select">
                <div class="role_background_content_bg">
                    <span class="title_name">智能体形象</span>
                    <button class="add_digial_bg_view" @click="addDigial">
                        <img v-if="!characterItem" src="../assets/images/add_digital_role_icon.svg" alt="" />
                        <img v-else class="character_back_img" :src="characterItem.avatar" alt="" />
                        <label class="require">*</label>
                        <span :class="[characterItem ? 'click_tips_img' : 'click_tips_none_img']">{{ characterItem ?
                        '使用中，点击更换'
                        :
                        '请选择角色' }}</span>
                    </button>
                    <div class="show_switch">
                        <span>是否在H5展示</span>
                        <el-switch width="80" @change="characterShowChange" class="public_switch"
                            v-model="tempCharacterShow" active-color="rgb(0,0,0,0)" inactive-color="rgb(0,0,0,0)">
                        </el-switch>
                    </div>
                </div>
                <div class="role_background_content_bg">
                    <span class="title_name">智能体背景</span>
                    <button class="add_digial_bg_view add_digial_background" @click="addBackGround">
                        <img v-if="!bgImageUrl" src="../assets/images/add_background_icon.svg" alt="" />
                        <img v-else class="character_back_img" :src="bgImageUrl" alt="" />
                        <span :class="[bgImageUrl ? 'click_tips_img' : 'click_tips_none_img']">{{ bgImageUrl ?
                        '使用中，点击更换' :
                        '请选择背景' }}</span>
                    </button>
                    <div class="show_switch">
                        <span>是否在H5展示</span>
                        <el-switch width="80" @change="backgroundShowChange" class="public_switch"
                            v-model="tempBackgroundShow" active-color="rgb(0,0,0,0)" inactive-color="rgb(0,0,0,0)">
                        </el-switch>
                    </div>
                </div>
            </div>
            <div class="kp-option-wrapper mt-40">
                <div class="option-item personality_setting">
                    <div class="title">
                        <label>人格</label>
                        <el-tooltip class="item" effect="dark" content="*影响您智能体的语气风格" placement="top-start">
                            <img src="../assets/images/brand-warning.png" alt="" />
                        </el-tooltip>
                    </div>
                    <div class="option-btn" @click="editRg">
                        点击配置
                        <img src="../assets/images/arrow-right.png" alt="" />
                    </div>
                </div>
                <div class="option-item sound_setting">
                    <div class="title">
                        <label>声音</label>
                        <el-tooltip class="item" effect="dark" content="*影响您智能体的语气风格" placement="top-start">
                            <img src="../assets/images/brand-warning.png" alt="" />
                        </el-tooltip>
                    </div>
                    <div class="option-btn" @click="soundSettingClick">
                        点击配置
                        <img src="../assets/images/arrow-right.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <AddRoleView v-if="showAddRoleView" @dismissAddRoleView="dismissAddRoleView"
            @setLinardInfoView="setLinardInfoView" :charactList="charactList" :tempCharacterId="characterId">
        </AddRoleView>
        <BackgroundSelView v-if="showBackgroundSelView" @dismissBackgroundSelView="dismissBackgroundSelView"
            @setLinardInfoView="setLinardInfoView" :tempBackgroundId="tempBackgroundId">
        </BackgroundSelView>
        <DigitalInfoSetting v-if="showDigitalInfoSetting" @dismissDigitalInfoSetting="dismissDigitalInfoSetting"
            :tempPersonality="tempPersonality" :careerList="careerList" :toneList="toneList"
            :initialCapabilityList="initialCapabilityList">
        </DigitalInfoSetting>
        <SoundSettingView v-if="showSoundSetting" @dismisSoundSetting="dismisSoundSetting" :voiceList="voices"
            :voiceId="voiceId">
        </SoundSettingView>
    </div>
</template>

<script lang="ts">
import { AbilityItem, BackGroundImgItem, CareersItem, CharacterItem, TonesItem, VoiceItem } from '@/net/linkardNet';
import { Ref, defineComponent, onMounted, ref, watch } from 'vue';
import AddRoleView from "./addRoleView.vue";
import BackgroundSelView from "./backgroundSelView.vue";
import DigitalInfoSetting from "./digitalInfoSetting.vue";
import SoundSettingView from "./soundSettingView.vue";

export default defineComponent({
    name: 'BaseInfoView',
    props: {
        tempCharacterId: {
            default: ""
        },
        tempBackground: {
            default: "" //数字人背景图片
        },
        tempBackgroundId: {
            default: "" //数字人背景图片Id
        },
        personality: {
            type: Object as () => any,
            default: {
                npcName: "",
                introduction: "",
                career: null,//职业
                tone: null,//语气
                ability: null,//能力
            }
        },

        careerList: {
            type: Array as () => Array<CareersItem>,
            default: () => []
        },//职业
        toneList: {
            type: Array as () => Array<TonesItem>,
            default: () => []
        },//语气
        initialCapabilityList: {
            type: Array as () => Array<AbilityItem>,
            default: () => []
        },//能力
        voices: {
            type: Array as () => Array<VoiceItem>,
            default: []
        },//声音
        voiceId: {
            type: String,
            default: ''
        },
        charactList: {
            type: Array as () => Array<CharacterItem>,
            default: []
        },
        // backList: {
        //     type: Array as () => Array<BackGroundImgItem>,
        //     default: []
        // },
        characterShow: {
            type: Boolean,
            default: true
        },
        backgroundShow: {
            type: Boolean,
            default: true
        },

    },
    components: {
        AddRoleView,
        BackgroundSelView,
        DigitalInfoSetting,
        SoundSettingView
    },
    setup(props, { emit }) {

        const characterId = ref('');
        // const background = ref('');
        const showAddRoleView = ref(false);
        const showBackgroundSelView = ref(false);
        const showDigitalInfoSetting = ref(false);
        const showSoundSetting = ref(false);
        const tempCharacterShow = ref(true);
        const tempBackgroundShow = ref(true);
        const characterItem: Ref<CharacterItem | undefined> = ref(undefined);
        // const backitem: Ref<BackGroundImgItem | undefined> = ref(undefined);
        const tempPersonality: Ref<any> = ref(null);
        const bgImageUrl = ref('');

        onMounted(() => {
            characterId.value = props.tempCharacterId;
            tempCharacterShow.value = props.characterShow;
            tempBackgroundShow.value = props.backgroundShow;
            // if (props.tempBackground && props.backList.length > 0) {
            //     backitem.value = props.backList.find(element => element.url === props.tempBackground);
            // }
            bgImageUrl.value = props.tempBackground;
        });

        const editRg = () => {
            showDigitalInfoSetting.value = !showDigitalInfoSetting.value;
            if (showDigitalInfoSetting.value) {
                tempPersonality.value = { ...props.personality };
            }
        };

        const addDigial = () => {
            showAddRoleView.value = true;
        };

        const addBackGround = () => {
            showBackgroundSelView.value = true;
        };

        const dismissAddRoleView = (characterIdStr: string) => {
            if (characterIdStr) {
                characterId.value = characterIdStr;
                characterItem.value = props.charactList.find(element => element.characterId === characterIdStr);
                emit("getDigitalRoleInfo", characterId.value, null);
            }
            showAddRoleView.value = false;
        };

        const setLinardInfoView = (characterId: string, backgroundItem: BackGroundImgItem | null) => {
            emit("getDigitalRoleInfo", characterId, backgroundItem);
        };

        const dismissBackgroundSelView = (item: BackGroundImgItem | null) => {
            if (item) {
                bgImageUrl.value = item.url;
                emit("getDigitalRoleInfo", characterId, item);
            }
            showBackgroundSelView.value = false;
        };

        const dismissDigitalInfoSetting = (tempPersonality: any) => {
            if (tempPersonality != null) {
                props.personality.npcName = tempPersonality.npcName;
                props.personality.introduction = tempPersonality.introduction;
                props.personality.career = tempPersonality.career;
                props.personality.tone = tempPersonality.tone;
                props.personality.ability = tempPersonality.ability;
            }
            showDigitalInfoSetting.value = false;
        };

        //sound setting
        const soundSettingClick = () => {
            showSoundSetting.value = !showSoundSetting.value;
        };

        const dismisSoundSetting = (item: any) => {
            showSoundSetting.value = !showSoundSetting.value;
            if (item) {
                emit("soundSettingUpdate", item);
            }
        };

        const characterShowChange = () => {
            emit("characterAndBgShowChange", tempCharacterShow.value, tempBackgroundShow.value);
        };

        const backgroundShowChange = () => {
            emit("characterAndBgShowChange", tempCharacterShow.value, tempBackgroundShow.value);
        };

        watch(() => props.tempCharacterId, (newValue, oldValue) => {
            if (newValue && !characterItem.value && props.charactList) {
                characterId.value = newValue;
                characterItem.value = props.charactList.find(element => element.characterId === characterId.value);
            }
        });

        watch(() => props.charactList, (newValue, oldValue) => {
            if (characterId.value && !characterItem.value && newValue && newValue.length > 0) {
                characterItem.value = newValue.find(element => element.characterId === characterId.value);
            }
        });

        watch(() => props.backgroundShow, (newValue, oldValue) => {
            tempBackgroundShow.value = newValue;
        });

        watch(() => props.characterShow, (newValue, oldValue) => {
            tempCharacterShow.value = newValue;
        });

        watch(() => props.tempBackground, (newValue, oldValue) => {
            if (newValue) {
                bgImageUrl.value = newValue;
            }
        });

        onMounted(() => {
            characterItem.value = props.charactList.find(element => element.characterId === characterId.value);
        });

        return {
            editRg,
            addDigial,
            addBackGround,
            dismissAddRoleView,
            setLinardInfoView,
            dismissBackgroundSelView,
            dismissDigitalInfoSetting,
            soundSettingClick,
            dismisSoundSetting,
            characterShowChange,
            backgroundShowChange,
            characterId,
            showAddRoleView,
            showBackgroundSelView,
            showDigitalInfoSetting,
            showSoundSetting,
            characterItem,
            bgImageUrl,
            tempPersonality,
            tempCharacterShow,
            tempBackgroundShow,
        };
    },
});

</script>

<style lang="less" scoped>
.digital_role_content_bg {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .digital_role_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .role_background_select {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .role_background_content_bg {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 256px;

                .title_name {
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 28px;
                }

                .show_switch {
                    width: 100%;
                    height: 48px;
                    border-radius: 30px;
                    background-color: #F0F0F0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                    // padding: 0 20px;

                    span {
                        color: #242424;
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .public_switch {}

                    :deep(.el-switch) {
                        .el-switch__core {
                            width: 80px !important;
                            height: 36px;
                            border-radius: 18px;
                            border: 2px solid #242424 !important;
                            background-color: transparent;
                        }

                        .el-switch__action {
                            width: 24px;
                            height: 24px;
                            margin-left: 4px;
                            background: #242424;
                            border: 2px solid transparent
                        }
                    }

                    :deep(.el-switch.is-checked .el-switch__action) {
                        margin-left: -14px;
                        background: #F3EC3F;
                        border: 2px solid #242424
                    }
                }

                .add_digial_bg_view {
                    position: relative;
                    width: 256px;
                    height: 256px;
                    border-radius: 24px;
                    background: #E6E6E6;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 6px solid #f3ec3f;
                    cursor: pointer;

                    .require {
                        font-size: 24px;
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        color: #e70000;
                        font-weight: 600;
                    }

                    .character_back_img {
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        object-fit: cover;
                    }

                    .click_tips_none_img {
                        position: absolute;
                        bottom: 16px;
                        width: 180px;
                        height: 32px;
                        border-radius: 16px;
                        background-color: #242424;

                        color: #F3EC3F;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 30px;
                    }

                    .click_tips_img {
                        position: absolute;
                        bottom: 16px;
                        width: 180px;
                        height: 32px;
                        border-radius: 16px;
                        border: 2px solid #242424;
                        background-color: #F3EC3F;

                        color: #242424;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 30px;
                    }
                }
            }

            .role_background_content_bg:last-child {
                margin-left: 88px;
            }
        }

        .kp-option-wrapper {
            padding: 0 152px;
            margin-top: 26px;
            display: flex;

            .option-item {
                margin-top: 48px;
                display: flex;
                align-items: center;

                .title {
                    color: #242424;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    margin-right: 48px;
                    // margin-bottom: 16px;

                    label {
                        width: 40px;
                        margin-right: 16px;
                        text-align: left;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                        margin-left: 8px;
                    }
                }

                .option-btn {
                    width: 130px;
                    height: 48px;
                    flex-shrink: 0;
                    border-radius: 30px;
                    border: 2px solid #242424;
                    background: #f0f0f0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #242424;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 0 22px;
                    cursor: pointer;

                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .personality_setting::after {
                margin-left: 8px;
                margin-bottom: 26px;
                content: "*";
                color: #e70000;
                font-weight: 600;
            }

            .sound_setting {
                margin-left: 40px;
            }
        }
    }
}
</style>
