<template>
    <div class="digital_info_setting">
        <div class="top_view">
            <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem>
            <button class="submit_button" @click="submitClick">确认</button>
        </div>
        <div class="yl-add-wrapper">
            <div class="yl-add-content">
                <div class="input-wrapper">
                    <span class="require">名称：</span>
                    <div class="input-content">
                        <el-input v-model="tempPersonality.npcName"></el-input>
                    </div>
                </div>
                <div class="career_and_tone_bg">
                    <span class="title_tips">职业：</span>
                    <el-select class="career_el_select" v-model="careerItem" placeholder="请选择">
                        <el-option v-for="item, index in careerList" :key="index" :label="item.name" :value="item.name"
                            :disabled="!item.allow">
                        </el-option>
                    </el-select>
                    <span class="title_tips">语气：</span>
                    <div class="tone_librarys">
                        <el-button
                            :class="['tone_library_item', selectedTone === item ? 'tone_library_item_selected' : '']"
                            v-for="(item, index) in toneList" @click="toneLibraryItemClick(item)"
                            :disabled="!item.allow && selectedTone != item">{{
                item.name
            }}</el-button>
                    </div>
                </div>
                <div class="initial_capability_bg">
                    <span class="initial_capability_title">初始能力：</span>
                    <div class="initial_capabilitys">
                        <el-button
                            :class="['initial_capability_item', selectedCapabilityItem === item ? 'initial_capability_item_selected' : '']"
                            v-for="(item, index) in initialCapabilityList" @click="initialCapabilityItemClick(item)"
                            :disabled="!item.allow && selectedCapabilityItem != item">{{
                item.name
            }}</el-button>
                    </div>
                </div>
                <div class="input-wrapper role_desc_bg">
                    <span class="role_desc">角色描述：</span>
                    <div class="input-content role_description">
                        <el-input type="textarea" v-model="tempPersonality.introduction"></el-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { CareersItem, TonesItem, AbilityItem } from '@/net/linkardNet';
import { Ref, defineComponent, onMounted, ref, watchEffect } from 'vue';
import BackButtonItem from "./backButtonItem.vue";

export default defineComponent({
    name: 'DigitalInfoSetting',

    components: {
        BackButtonItem
    },

    props: {
        tempPersonality: {
            type: Object as () => any,
            default: {
                npcName: "",
                introduction: "",
                career: null,//职业
                tone: null,//语气
                ability: null,//能力
            }
        },
        careerList: {
            type: Array as () => Array<CareersItem>,
            default: () => []
        },//职业
        toneList: {
            type: Array as () => Array<TonesItem>,
            default: () => []
        },//语气
        initialCapabilityList: {
            type: Array as () => Array<AbilityItem>,
            default: () => []
        },//能力
    },
    setup(props, { emit }) {

        let careerItem = ref('');
        let selectedTone: Ref<TonesItem> = ref({
            id: null,
            name: '',
            level: 0,
            allow: false
        });
        let selectedCapabilityItem: Ref<AbilityItem> = ref({
            id: null,
            name: '',
            level: 0,
            allow: false
        });

        const backButtonClick = () => {
            emit("dismissDigitalInfoSetting", null);
        };

        const submitClick = () => {

            for (var i = 0; i < props.careerList.length; i++) {
                let item = props.careerList[i];
                if (careerItem.value == item.name) {
                    props.tempPersonality.career = item.id;
                    break;
                }
            }

            if (selectedTone.value) {
                props.tempPersonality.tone = selectedTone.value.id;
            }

            if (selectedCapabilityItem.value) {
                props.tempPersonality.ability = selectedCapabilityItem.value.id;
            }
            emit("dismissDigitalInfoSetting", props.tempPersonality);
        };

        const toneLibraryItemClick = (item: TonesItem) => {
            if (selectedTone.value.id && selectedTone.value.id == item.id) {
                selectedTone.value = {
                    id: null,
                    name: '',
                    level: 0,
                    allow: false
                };
            } else {
                selectedTone.value = item;
            }
        };

        const initialCapabilityItemClick = (item: AbilityItem) => {
            if (selectedCapabilityItem.value.id && selectedCapabilityItem.value.id == item.id) {
                selectedCapabilityItem.value = {
                    id: null,
                    name: '',
                    level: 0,
                    allow: false
                };
            } else {
                selectedCapabilityItem.value = item;
            }
        };

        onMounted(() => {
            for (var i = 0; i < props.initialCapabilityList.length; i++) {
                const item = props.initialCapabilityList[i];
                if (item.id == props.tempPersonality.ability) {
                    selectedCapabilityItem.value = item;
                    break;
                }
            }

            for (var i = 0; i < props.toneList.length; i++) {
                const item = props.toneList[i];
                if (item.id == props.tempPersonality.tone) {
                    selectedTone.value = item;
                    break;
                }
            }

            for (var i = 0; i < props.careerList.length; i++) {
                const item = props.careerList[i];
                if (item.id == props.tempPersonality.career) {
                    careerItem.value = item.name;
                    break;
                }
            }
        });

        watchEffect(() => {

        });

        return {
            careerItem,
            selectedTone,
            selectedCapabilityItem,
            backButtonClick,
            submitClick,
            toneLibraryItemClick,
            initialCapabilityItemClick,
        };
    },
});

</script>

<style lang="less" scoped>
.digital_info_setting {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {}

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
            margin-left: 60px;
        }
    }

    .yl-add-wrapper {
        width: 100%;

        .yl-add-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 20px 0 0 104px;
        }

        .input-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 30px;


            .role_desc {
                color: #242424;
                font-weight: 600;
                flex-shrink: 0;
                line-height: 48px;
                width: 90px;
                margin-right: 4px;
                position: relative;
            }

            .require {
                color: #242424;
                font-size: 18px;
                font-weight: 600;
            }

            .require::after {
                content: "*";
                color: #e70000;
                font-weight: 600;
            }


            .input-content {
                margin-left: 30px;

                :deep(.el-input__wrapper) {
                    background: transparent;
                    border: 2px solid #242424;
                    width: 552px;
                    height: 48px;
                    border-radius: 24px;

                    .el-input__inner {
                        font-size: 15px;
                        font-weight: 500;
                        color: #242424;
                        margin: 0 16px 0 16px;
                        background: transparent;
                    }
                }

                :deep(.el-textarea) {
                    border-radius: 36px;
                    border: 2px solid #242424;
                    overflow: hidden;

                    textarea {
                        border: none;
                        background: #f0f0f0;
                        width: 556px;
                        height: 138px;
                        box-shadow: none;
                    }

                    // .el-textarea__inner {
                    //     bo
                    // }
                }
            }
        }

        .role_desc_bg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 40px 0 0 0;
        }

        .role_description {
            width: 552px;
            margin-left: 0px !important;
        }

        .career_and_tone_bg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .title_tips {
                color: #242424;
                font-size: 18px;
                font-weight: 600;
                margin-right: 10px;
            }

            :deep(.el-select) {
                margin: 0 10px 0 26px;
                width: 118px;
                height: 48px;

                .el-tooltip__trigger {
                    border-radius: 36px;
                    border: 2px solid #242424;
                    background: #f0f0f0;
                    border-radius: 24px;
                    margin: 0;
                    height: 48px;

                    .el-select__selected-item span {
                        font-size: 14px;
                        font-weight: 600;
                        color: #242424;
                    }
                }
            }

            .tone_librarys {
                flex-direction: row;
                justify-content: flex-start;
                align-items: start;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                overflow: auto;
                /* 你可以根据需要调整间距大小 */

                .tone_library_item {
                    margin: 0 0px 0 0;
                    width: 83px;
                    height: 48px;
                    font-size: 12px;
                    font-weight: 500px;
                    color: #242424;
                    background: transparent;
                    border: 2px solid #242424;
                    border-radius: 24px;
                    transition: color 0.3s, background 0.3s;

                }

                .tone_library_item:enabled:hover {
                    background-color: #242424;
                    color: #F0F0F0;
                    opacity: 0.8;
                }

                .tone_library_item_selected {
                    background: #242424;
                    color: #F0F0F0;
                }

                .tone_library_item_selected:enabled:hover {
                    opacity: 1;
                }
            }

        }

        .initial_capability_bg {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: start;
            width: 700px;
            height: auto;
            margin-top: 30px;

            .initial_capability_title {
                color: #242424;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 18px;
            }

            .initial_capabilitys {
                width: 100%;
                flex-direction: row;
                justify-content: flex-start;
                align-items: start;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                overflow: auto;
                /* 你可以根据需要调整间距大小 */

                .initial_capability_item {
                    margin: 0 4px 0 2px;
                    width: 116px;
                    height: 48px;
                    font-size: 12px;
                    font-weight: 500px;
                    color: #242424;
                    background: transparent;
                    border: 2px solid #242424;
                    border-radius: 24px;
                    transition: color 0.3s, background 0.3s;
                }

                .initial_capability_item:enabled:hover {
                    background-color: #242424;
                    color: #F0F0F0;
                    opacity: 0.8;
                }

                .initial_capability_item_selected {
                    background: #242424;
                    color: #F0F0F0;
                }

                .initial_capability_item_selected:enabled:hover {
                    opacity: 1;
                }
            }
        }
    }

    .el-button {
        padding: 0;
    }

    .el-button.is-disabled {
        color: #c0c4cc !important;
        border: 2px solid #c0c4cc !important;
    }

    :deep(.el-input__wrapper.is-focus) {
        box-shadow: none;
    }
}
</style>
