<template>
    <div class="digital_chat_setting_content_bg">
        <div class="kp-wrapper welcome_message_bg">
            <div class="title welcome_message_title">
                <span>欢迎语</span>
                <el-tooltip class="item" effect="dark" content="*影响用户访问您联卡时首次看到的内容" placement="top-start">
                    <img src="../assets/images/brand-warning.png" alt="" />
                </el-tooltip>
            </div>
            <div class="keyword-input-wrapper">
                <el-input class="keyword-input keyword-zh" placeholder="请输入" v-model="npcDetail.welcomeMsg"></el-input>
            </div>
        </div>
        <div class="kp-wrapper">
            <div class="title trigger_world_title">
                <div class="title_left">
                    <span>触发词</span>
                    <el-tooltip class="item" effect="dark" content="*当对话文本中包含触发词时将返回相应的内容" placement="top-start">
                        <img src="../assets/images/brand-warning.png" alt="" />
                    </el-tooltip>
                    <el-button class="add_button" @click="triggerWordAddButtonClick">
                        <div class="center_add_tips">
                            <img :src="addTriggerWordIcon" alt="">
                            <span class="add_title">添加</span>
                        </div>
                    </el-button>
                </div>
                <div class="page_bg">
                    <el-button :disabled="currentPage <= 1" class="page_button" @click="pageLeft"
                        @mouseenter="hoverLeft = true" @mouseleave="hoverLeft = false">
                        <img :src="leftButtonImage" alt="Left" />
                    </el-button>
                    <span class="page_number">{{ currentPage }}</span>
                    <el-button :disabled="!canPageRight" class="page_button" @click="pageRight"
                        @mouseenter="hoverRight = true" @mouseleave="hoverRight = false">
                        <img :src="rightButtonImage" alt="Right" />
                    </el-button>
                </div>
            </div>
            <div class="trigger_word_content_bg">
                <div class="trigger_word_bg">
                    <ul>
                        <li v-for="(item, index) in qaDataList">
                            <div :class="['trigger_word_label_bg', triggerWordHoverIndex == index ? 'trigger_word_label_bg_hover' : '']"
                                @click="triggerWordLabelClick(index)">
                                <span @mouseover="handleMouseOver(index)" @mouseleave="handleMouseLeave(index)"
                                    class="trigger_word_label">{{ triggerWordHoverIndex == index ? "前往编辑" : item.keyword
                                    }}</span>
                                <img class="delete" :src="circleGrayDelete" alt=""
                                    @click.stop="deleteTriggerWord(index)">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="model_library_selection_bg">
                <span class="model_tips_title">大模型库</span>
                <div class="model_librarys">
                    <el-button
                        :class="['model_library_item', selectedMobel === item ? 'model_library_item_selected' : '']"
                        v-for="(item, index) in modelLibraryList" @click="modelLibraryItemClick(item)"
                        :disabled="!item.allow">{{ item.name
                        }}</el-button>
                </div>
            </div>
        </div>
        <div class="kp-option-wrapper">
            <div class="option-item personality_setting">
                <div class="title">
                    <label>领域知识</label>
                    <el-tooltip class="item" effect="dark" content="*支持文本格式文件" placement="top-start">
                        <img src="../assets/images/brand-warning.png" alt="" />
                    </el-tooltip>
                </div>
                <div class="option-btn" @click="editYl">
                    点击配置
                    <img src="../assets/images/arrow-right.png" alt="" />
                </div>
            </div>
        </div>

        <div v-if="showKeyWordCreatView" class="trigger_word_creat_pop_bg">
            <div class="top_view">
                <BackButtonItem @backBtnClick="triggerWordCancelButtonClick" class="back_button"></BackButtonItem>
                <button class="submit_button" @click="triggerWordConfirmButtonClick">保存</button>
            </div>
            <!-- <div class="trigger_word_creat_pop"> -->
            <div class="trigger_word_text_link_content_bg">
                <div class="trigger_word_content_bg">
                    <span class="trigger_word_text_title">触发词</span>
                    <el-input placeholder="请输入触发词" maxlength="300" v-model="newAddTriggerWordItem.keyword"></el-input>
                </div>
                <div class="trigger_word_content_bg">
                    <span class="trigger_word_text_title">触发词内容</span>
                    <el-input placeholder="请输入触发的文本" maxlength="1000" v-model="newAddTriggerWordItem.text"></el-input>
                </div>
                <div class="trigger_word_content_bg">
                    <span class="trigger_word_link_title">链接</span>
                    <el-input placeholder="https://" maxlength="200" v-model="newAddTriggerWordItem.linkUrl"></el-input>
                </div>
            </div>
            <div class="media_content_bg">
                <div class="image_content">
                    <el-upload action="" class="upload_el_view" drag accept="image/png,image/jpeg"
                        :on-error="handleImgError" :on-success="handleImgSuccess"
                        :http-request="(item: any) => uploadImg(item.file)" :before-upload="beforeImgUPload"
                        :show-file-list="false">
                        <div class="add_media_button">
                            <span v-if="!newAddTriggerWordItem.imgUrl" class="media_title">上传图片</span>
                            <div v-if="newAddTriggerWordItem.imgUrl" class="media_center_add_tips">
                                <img class="media_icon" :src="newAddTriggerWordItem.imgUrl" alt="">
                                <!-- <span class="media_name">{{ newAddTriggerWordItem.imgFileName }}</span> -->
                            </div>
                            <img v-if="!newAddTriggerWordItem.imgUrl" class="add_img" :src="addTriggerWordMedia" alt="">
                            <span v-if="!newAddTriggerWordItem.imgUrl" class="add_media_tips">JPG/PNG(2M以内)</span>
                        </div>
                        <img v-if="newAddTriggerWordItem.imgUrl" class="delete_upload" :src="circleGrayDelete" alt=""
                            @click.stop="deleteUploadImage" />
                    </el-upload>
                </div>
                <div class="video_content">
                    <el-upload action="" class="upload_el_view" drag accept="video/*" :on-error="handleVideoError"
                        :on-success="handleVideoSuccess" :http-request="(item: any) => uploadVideo(item.file)"
                        :before-upload="beforeVideoUPload" :show-file-list="false">
                        <div class="add_media_button">
                            <span v-if="!newAddTriggerWordItem.videoUrl" class="media_title">上传视频</span>
                            <div v-if="newAddTriggerWordItem.videoUrl" class="media_center_add_tips">
                                <img class="media_icon" :src="newAddTriggerWordItem.videoUrl" alt="">
                                <div class="play_icon_bg"
                                    @click.stop="showPlayButtonClick(newAddTriggerWordItem.videoExtraData)">
                                    <img :src="require('../assets/images/trigger_play.svg')" class="trigger_play_icon"
                                        alt="">
                                </div>
                            </div>
                            <img v-if="!newAddTriggerWordItem.videoUrl" class="add_img" :src="addTriggerWordMedia"
                                alt="">
                            <span v-if="!newAddTriggerWordItem.videoUrl" class="add_media_tips">MP4(10M以内)</span>
                        </div>
                        <img v-if="newAddTriggerWordItem.videoUrl" class="delete_upload" :src="circleGrayDelete" alt=""
                            @click.stop="deleteUploadVideo" />
                    </el-upload>
                </div>
            </div>

            <div class="trigger_model_radio_bg">
                <span class="trigger_model_title">触发词类型</span>
                <div class="trigger_model_radio">
                    <el-radio v-model="newAddTriggerWordItem.triggerRule" :label="0"
                        @change="radioChange">智能匹配（通过语义理解命中某问题则触发对应内容）</el-radio>
                    <el-radio v-model="newAddTriggerWordItem.triggerRule" :label="1"
                        @change="radioChange">精准匹配（完全包含问题则触发对应内容）</el-radio>
                </div>
            </div>
        </div>
        <KnowledgeListView v-if="showKnowledgeListView" :linkardId="linkardId" :linkardType="linkardType"
            @dismissKnowledgeListView="dismissKnowledgeListView">
        </KnowledgeListView>

        <VideoTriggerPlayView v-if="showVideoPlayTriggerView" @dismissVideoTriggerPlay="dismissVideoTriggerPlayView"
            :showVideoUrl="showVideoUrl">
        </VideoTriggerPlayView>
        <SubViewLoadingView :msg="subLoadingInfo"></SubViewLoadingView>
    </div>
</template>

<script lang="ts">
import { LKNPCInfoItem, AvailableModelItem, TriggerWordItem, linkardNet, TriggerWordListItem, SaveFAQArgs, GetFAQListArgs, QATriggerWordItem } from '@/net/linkardNet';
import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
import VideoTriggerPlayView from "./videoTriggerPlayView.vue";
import KnowledgeListView from "./knowledgeListView.vue";
import RealTimeTrainingView from "./realTimeTrainingView.vue";
import SubViewLoadingView from "./loadingView/subViewLoadingView.vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { isValidURL } from '@/utils/commonUtils';
import BackButtonItem from "./backButtonItem.vue";
import { HttpResponse } from '@/net/http';
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';

export default defineComponent({
    name: 'DigitalChatSetting',
    props: {
        npcDetail: {
            type: Object as () => LKNPCInfoItem,
            default: {
                characterId: '',
                filepath: '',
                avatar: '',
                welcomeMsg: '',
                backgroundId: null,
                background: null,
                personality: {
                    npcName: '',
                    career: null,
                    tone: null,
                    ability: null,
                    introduction: ''
                },
                voice: {
                    voiceId: null
                },
                largeModel: ''
            }
        },
        modelLibraryList: {
            type: Array as () => Array<AvailableModelItem>,
            default: () => []
        },
        linkardId: {
            type: String,
            default: '',
        },
        linkardType: {
            type: Number,
            default: 0
        }
    },
    components: {
        RealTimeTrainingView,
        KnowledgeListView,
        BackButtonItem,
        VideoTriggerPlayView,
        SubViewLoadingView
    },
    setup(props, { emit }) {

        const showKnowledgeListView = ref(false);
        const selectedMobel: Ref<AvailableModelItem | null> = ref(null);
        const showRealTimeTrainingView = ref(false);
        const realTimeTraining: Ref<any[]> = ref([]);
        const showTextTriggerView = ref(false);
        const showPicktureTriggerView = ref(false);
        const showVideoTriggerView = ref(false);
        const showLinkTriggerView = ref(false);

        const currentPage = ref(1);
        const pageSize = 4;
        const qaDataTotal = ref(0);
        const canPageRight = ref(false);
        const triggerWordHoverIndex = ref(-1);
        const showVideoPlayTriggerView = ref(false);
        const showVideoUrl = ref('');

        const subLoadingInfo = ref({
            show: false,
            text: '',
            isShowSuccess: false
        });

        const qaDataList: Ref<TriggerWordItem[]> = ref([]);
        // let triggerWordList: Ref<QATriggerWordItem[]> = ref([]);
        const newAddTriggerWordItem: Ref<QATriggerWordItem> = ref({
            keywordNo: '0',
            triggerRule: 0,
            keyword: '',
            text: '',
            linkUrl: '',
            imgUrl: '',
            videoUrl: '',
            imgExtraData: '',
            videoExtraData: '',
            imgFileName: '',
            videoFileName: '',
            id: '',
            isNew: false,
            index: 0
        });

        const showKeyWordCreatView = ref(false);

        const hoverLeft = ref(false);
        const hoverRight = ref(false);

        const leftButtonImage = computed(() =>
            !hoverLeft.value || currentPage.value <= 1
                ? require('../assets/images/page_turning_left.svg') : require('../assets/images/page_turning_left_white.svg')
        );

        const rightButtonImage = computed(() =>
            !hoverRight.value || !canPageRight.value
                ? require('../assets/images/page_turning_right.svg') : require('../assets/images/page_turning_right_white.svg')
        );

        const editYl = () => {
            showKnowledgeListView.value = true;
        };

        const dismissKnowledgeListView = () => {
            showKnowledgeListView.value = false;
        };

        const modelLibraryItemClick = (item: AvailableModelItem) => {
            selectedMobel.value = item;
            emit("largeModelUpdate", item);
        };

        const realTimeTrainingClick = () => {
            showRealTimeTrainingView.value = true;
        };

        const dismissRealTimeTrainingView = () => {
            showRealTimeTrainingView.value = false;
        };

        const textTriggerViewClick = () => {
            showTextTriggerView.value = true;
        };

        const picktureTriggerViewClick = () => {
            showPicktureTriggerView.value = true;
        };

        const videoTriggerViewClick = () => {
            showVideoTriggerView.value = true;
        };

        const linkTriggerViewClick = () => {
            showLinkTriggerView.value = true;
        };
        const dismissTextTriggerView = () => {
            showTextTriggerView.value = false;
        };

        const dismissPicktureTriggerView = () => {
            showPicktureTriggerView.value = false;
        };

        const dismissVideoTriggerView = () => {
            showVideoTriggerView.value = false;
        };

        const dismissLinkTriggerView = () => {
            showLinkTriggerView.value = false;
        };

        const pageLeft = () => {
            if (currentPage.value > 0) {
                currentPage.value = 1;
                getFAQListRequest();
            }
        };

        const pageRight = () => {
            currentPage.value += 1;
            getFAQListRequest();
        };

        watch(() => props.modelLibraryList, (newValue, oldValue) => {
            if (newValue && newValue != oldValue) {
                for (var i = 0; i < props.modelLibraryList.length; i++) {
                    const item = props.modelLibraryList[i];
                    if (item.id == props.npcDetail.largeModel) {
                        selectedMobel.value = item;
                        break;
                    }
                }
                if (!selectedMobel.value && props.modelLibraryList && props.modelLibraryList.length > 0) {
                    selectedMobel.value = props.modelLibraryList[0];
                    emit("largeModelUpdate", selectedMobel.value);
                }
            }
        });

        const triggerWordAddButtonClick = () => {

            if (!props.linkardId || props.linkardId == 'undefined') {
                ElMessage.error('请保存联卡后，再进行创建');
                return;
            }
            showKeyWordCreatView.value = true;
        };

        const triggerWordLabelClick = (index: number) => {
            const tempItem = qaDataList.value[index];
            newAddTriggerWordItem.value.keyword = tempItem.keyword;
            newAddTriggerWordItem.value.keywordNo = tempItem.keywordNo;
            newAddTriggerWordItem.value.triggerRule = tempItem.triggerRule;
            newAddTriggerWordItem.value.isNew = false;

            tempItem.list.forEach(item => {
                switch (item.type) {
                    case 1:
                        newAddTriggerWordItem.value.text = item.message;
                        break;

                    case 2:
                        newAddTriggerWordItem.value.imgExtraData = item.extraData;
                        newAddTriggerWordItem.value.imgFileName = item.fileName;
                        newAddTriggerWordItem.value.imgUrl = item.message;
                        break;

                    case 3:
                        newAddTriggerWordItem.value.videoExtraData = item.extraData;
                        newAddTriggerWordItem.value.videoFileName = item.fileName;
                        newAddTriggerWordItem.value.videoUrl = item.message;
                        break;

                    case 4:
                        newAddTriggerWordItem.value.linkUrl = item.message;
                        break;
                }
            });

            showKeyWordCreatView.value = true;
        };

        const deleteTriggerWord = (index: number) => {
            deleteFAQRequestWithIndex(index);
        };

        const triggerWordCancelButtonClick = () => {
            showKeyWordCreatView.value = false;
            const triggerWordItem: QATriggerWordItem = {
                keywordNo: '0',
                triggerRule: 0,
                keyword: '',
                text: '',
                linkUrl: '',
                imgUrl: '',
                videoUrl: '',
                imgExtraData: '',
                videoExtraData: '',
                imgFileName: '',
                videoFileName: '',
                id: '',
                isNew: true,
                index: 0
            };
            newAddTriggerWordItem.value = triggerWordItem;
        };

        const triggerWordConfirmButtonClick = () => {
            if (!newAddTriggerWordItem.value.keyword) {
                ElMessage.error("请输入触发词");
                return;
            }

            if (!newAddTriggerWordItem.value.text) {
                ElMessage.error("请输入触发词内容");
                return;
            }

            if (newAddTriggerWordItem.value.linkUrl && !isValidURL(newAddTriggerWordItem.value.linkUrl)) {
                ElMessage.error("请填写有效链接");
                return;
            }

            let saveTriggerWordList: any[] = [];
            let item: any = {
                keyword: '',
                list: [],
                keywordNo: newAddTriggerWordItem.value.keywordNo,
                fileName: '',
                triggerRule: newAddTriggerWordItem.value.triggerRule,
                createDate: ''
            };

            if (newAddTriggerWordItem.value.isNew) {
                const { keywordNo, ...newItem } = item;
                item = newItem;
            }

            item.keyword = newAddTriggerWordItem.value.keyword;

            if (newAddTriggerWordItem.value.text) {
                let keyItem1: TriggerWordListItem = {
                    type: 1,
                    message: '',
                    extraData: '',
                    fileName: ''
                };
                keyItem1.message = newAddTriggerWordItem.value.text;
                item.list.push(keyItem1);
            }

            if (newAddTriggerWordItem.value.imgUrl) {
                let keyItem2: TriggerWordListItem = {
                    type: 2,
                    message: '',
                    extraData: '',
                    fileName: ''
                };
                keyItem2.message = newAddTriggerWordItem.value.imgUrl;
                keyItem2.extraData = newAddTriggerWordItem.value.imgExtraData;
                keyItem2.fileName = newAddTriggerWordItem.value.imgFileName;
                item.list.push(keyItem2);
            }

            if (newAddTriggerWordItem.value.videoUrl) {
                let keyItem3: TriggerWordListItem = {
                    type: 3,
                    message: '',
                    extraData: '',
                    fileName: ''
                };
                keyItem3.message = newAddTriggerWordItem.value.videoUrl;
                keyItem3.extraData = newAddTriggerWordItem.value.videoExtraData;
                keyItem3.fileName = newAddTriggerWordItem.value.videoFileName;
                item.list.push(keyItem3);
            }

            if (newAddTriggerWordItem.value.linkUrl) {
                let keyItem4: TriggerWordListItem = {
                    type: 4,
                    message: '',
                    extraData: '',
                    fileName: ''
                };
                keyItem4.message = newAddTriggerWordItem.value.linkUrl;
                item.list.push(keyItem4);
            }
            saveTriggerWordList.push(item);
            saveFAQArgsRequest(saveTriggerWordList);
        };

        // 删除问答库
        const deleteFAQRequestWithIndex = (index: number) => {
            linkardNet.deleteFAQ(props.linkardId, [qaDataList.value[index].keywordNo]).then(
                (response) => {
                    ElMessage.success('已删除');
                    qaDataList.value.splice(index, 1);
                    currentPage.value = 1;
                    getFAQListRequest();
                },
                (ex) => {
                    ElMessage.error('删除失败，请重试');
                },
            );
        };

        const beforeImgUPload = (file: any) => {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件大小不能超过 2MB!',
                //     type: 'warning',
                //     confirmButtonText: '确定'
                // });
                OverAllDialogView.showOverAllDialogView("上传文件大小不能超过 2MB!", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });
            return isLt2M;
        };

        // 上传错误提示
        const handleImgError = () => {
            ElMessage.error('上传失败，请您重新上传！');
        };

        //上传成功提示
        const handleImgSuccess = () => {
            // ElMessage.success('图片上传成功！');
        };
        // 图片上传
        const uploadImg = async (file: any) => {
            subLoadingInfo.value.show = true;
            linkardNet.uploadImageMedia({ file }).then(
                (response) => {
                    newAddTriggerWordItem.value.imgUrl = response.thumbnailUrl;
                    newAddTriggerWordItem.value.imgExtraData = response.mediaUrl;
                    newAddTriggerWordItem.value.imgFileName = response.fileName;

                    subLoadingInfo.value.isShowSuccess = true;
                    subLoadingInfo.value.text = '上传完成';
                    setTimeout(() => {
                        subLoadingInfo.value.show = false;
                        subLoadingInfo.value.isShowSuccess = false;
                        subLoadingInfo.value.text = '';
                    }, 2000);
                },
                (ex) => {
                    subLoadingInfo.value.show = false;
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        }

        // Video
        const beforeVideoUPload = (file: any) => {
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isLt10M)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件大小不能超过 10MB!',
                //     type: 'warning',
                //     confirmButtonText: '确定'
                // });
                OverAllDialogView.showOverAllDialogView("上传文件大小不能超过 10MB!", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });
            return isLt10M;
        };

        // 上传错误提示
        const handleVideoError = () => {
            ElMessage.error('上传失败，请您重新上传！');
        };

        //上传成功提示
        const handleVideoSuccess = () => {
            // ElMessage.success('视频上传成功！');
        };
        // 视频上传
        const uploadVideo = async (file: any) => {
            subLoadingInfo.value.show = true;
            linkardNet.uploadVideoMedia({ file }).then(
                (response) => {
                    newAddTriggerWordItem.value.videoUrl = response.thumbnailUrl;
                    newAddTriggerWordItem.value.videoExtraData = response.mediaUrl;
                    newAddTriggerWordItem.value.videoFileName = response.fileName;

                    subLoadingInfo.value.isShowSuccess = true;
                    subLoadingInfo.value.text = '上传完成';
                    setTimeout(() => {
                        subLoadingInfo.value.show = false;
                        subLoadingInfo.value.isShowSuccess = false;
                        subLoadingInfo.value.text = '';
                    }, 2000);
                },
                (ex) => {
                    subLoadingInfo.value.show = false;
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        }

        const deleteUploadVideo = () => {
            newAddTriggerWordItem.value.videoExtraData = '';
            newAddTriggerWordItem.value.videoFileName = '';
            newAddTriggerWordItem.value.videoUrl = '';
        };

        const deleteUploadImage = () => {
            newAddTriggerWordItem.value.imgExtraData = '';
            newAddTriggerWordItem.value.imgFileName = '';
            newAddTriggerWordItem.value.imgUrl = '';
        };

        const getFAQListRequest = () => {
            if (!props.linkardId || props.linkardId == 'undefined') return;
            subLoadingInfo.value.show = true;
            const args: GetFAQListArgs = {
                linkardId: props.linkardId,
                content: '',
                fileName: '',
                startDate: '',
                endDate: '',
                pageNum: currentPage.value,
                pageSize: pageSize
            };
            linkardNet.getFAQList(args).then(
                (response) => {
                    response.list.forEach(item => {
                        if (item.triggerRule == 0) {
                            item.triggerRuleText = "智能匹配";
                        } else {
                            item.triggerRuleText = "精准匹配";
                        }

                        if (!item.fileName) {
                            item.fileName = '/';
                        }

                        item.list.forEach(element => {
                            switch (element.type) {
                                case 1:
                                    item.message = element.message;
                                    break;
                                case 2:
                                    item.hasImg = true;
                                    break;
                                case 3:
                                    item.hasVideo = true;
                                    break;
                                case 4:
                                    item.hasLink = true;
                                    break;
                            }
                        });
                    });
                    qaDataList.value = response.list;
                    qaDataTotal.value = response.total;
                    if (response.total / 4.0 > currentPage.value) {
                        canPageRight.value = true;
                    } else {
                        canPageRight.value = false;
                    }

                    showKeyWordCreatView.value = false;

                    const triggerWordItem: QATriggerWordItem = {
                        keywordNo: '0',
                        triggerRule: 0,
                        keyword: '',
                        text: '',
                        linkUrl: '',
                        imgUrl: '',
                        videoUrl: '',
                        imgExtraData: '',
                        videoExtraData: '',
                        imgFileName: '',
                        videoFileName: '',
                        id: '',
                        isNew: true,
                        index: 0
                    };
                    newAddTriggerWordItem.value = triggerWordItem;
                    subLoadingInfo.value.show = false;
                },
                (ex) => {
                    subLoadingInfo.value.show = false;
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const saveFAQArgsRequest = async (list: TriggerWordItem[]) => {
            const args: SaveFAQArgs = {
                linkardId: props.linkardId,
                triggerWord: list
            };
            linkardNet.saveFAQ(args).then(
                (response) => {
                    getFAQListRequest();
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };

        const radioChange = (index: number) => {
        };

        const handleMouseOver = (index: number) => {
            triggerWordHoverIndex.value = index;
        };

        const handleMouseLeave = (index: number) => {
            triggerWordHoverIndex.value = -1;
        };

        const dismissVideoTriggerPlayView = () => {
            showVideoPlayTriggerView.value = false;
        };

        const showPlayButtonClick = (videoUrl: string) => {
            showVideoUrl.value = videoUrl;
            showVideoPlayTriggerView.value = true;
        };

        onMounted(() => {
            getFAQListRequest();
        });

        return {
            dismissKnowledgeListView,
            modelLibraryItemClick,
            realTimeTrainingClick,
            dismissRealTimeTrainingView,
            textTriggerViewClick,
            picktureTriggerViewClick,
            videoTriggerViewClick,
            linkTriggerViewClick,
            dismissTextTriggerView,
            dismissPicktureTriggerView,
            dismissVideoTriggerView,
            dismissLinkTriggerView,
            editYl,
            triggerWordAddButtonClick,
            triggerWordLabelClick,
            deleteTriggerWord,
            triggerWordCancelButtonClick,
            triggerWordConfirmButtonClick,
            beforeImgUPload,
            handleImgError,
            handleImgSuccess,
            uploadImg,
            beforeVideoUPload,
            handleVideoError,
            handleVideoSuccess,
            uploadVideo,
            deleteUploadVideo,
            deleteUploadImage,
            radioChange,
            pageLeft,
            pageRight,
            handleMouseOver,
            handleMouseLeave,
            dismissVideoTriggerPlayView,
            showPlayButtonClick,
            canPageRight,
            currentPage,
            pageSize,
            selectedMobel,
            showKnowledgeListView,
            showRealTimeTrainingView,
            realTimeTraining,
            showTextTriggerView,
            showPicktureTriggerView,
            showVideoTriggerView,
            showLinkTriggerView,
            qaDataList,
            newAddTriggerWordItem,
            showKeyWordCreatView,
            hoverLeft,
            hoverRight,
            leftButtonImage,
            rightButtonImage,
            triggerWordHoverIndex,
            showVideoPlayTriggerView,
            showVideoUrl,
            subLoadingInfo,
            addTriggerWordMedia: require('../assets/images/add_trigger_word_media.svg'),
            addTriggerWordIcon: require('../assets/images/add_trigger_word_icon.svg'),
            circleGrayDelete: require('../assets/images/delete_trigger_word_icon.svg'),
            mediaImg: require('../assets/images/key_word_img_icon.svg'),
            mediaVideo: require('../assets/images/key_word_video_icon.svg'),
        };
    },
});

</script>

<style lang="less" scoped>
.digital_chat_setting_content_bg {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    .kp-wrapper {
        padding: 0 152px;
        width: 100%;
        margin-bottom: 48px;

        .title {
            color: #242424;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-bottom: 32px;

            span {
                width: auto;
            }

            img {
                width: 20px;
                height: 20px;
                margin-left: 8px;
            }

            .add_button {
                width: 80px;
                height: 32px;
                border-radius: 16px;
                margin-left: 40px;

                background-color: #242424;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;

                .center_add_tips {
                    width: auto;
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 12px;
                        height: 16px;
                        margin: 0 8px 0 0;
                    }

                    .add_title {
                        width: auto;
                        height: 20px;
                        font-weight: 500;
                        font-size: 12px;
                        color: #F0F0F0;
                        line-height: 20px;
                        text-align: justify;
                        font-style: normal;
                        opacity: 0.8;
                    }
                }
            }

            .add_button:hover {
                opacity: 0.8;
            }

            .add_button:active {
                opacity: 0.6;
            }

            .title_left {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

            .page_bg {
                height: auto;
                width: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .page_button {
                    width: 64px;
                    height: 32px;
                    border-width: 0;
                    border-radius: 22px;
                    background-color: #F0F0F0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

                    img {
                        width: 18px;
                        height: 18px;
                        margin: 0;
                    }
                }

                .page_button:enabled:hover {
                    background-color: #242424;
                }

                .page_button:active {
                    opacity: 0.6;
                }

                .el-button.is-disabled {
                    opacity: 0.3;
                    border: none !important;
                }

                .page_number {
                    color: #242424;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    width: 60px;
                }
            }
        }

        .trigger_world_title {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .keyword-zh {
            width: 645px;
            height: 48px;
        }

        .keyword-input {
            background: #f0f0f0;
            color: rgba(36, 36, 36, 0.6);
            font-size: 12px;
            font-weight: 500;
            width: 100%;

            :deep(.el-input__wrapper) {
                background: transparent;
                border: 2px solid #242424;
                width: 100%;
                height: 48px;
                border-radius: 24px;

                .el-input__inner {
                    color: #242424;
                    margin: 0 16px 0 16px;
                    background: transparent;
                }
            }

            :deep(.el-input__wrapper.is-focus) {
                box-shadow: none;
            }
        }

        .keyword-input-wrapper {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            margin: 0 0 22px 40px;

            .keyword-input-left {
                width: 160px;
                height: 48px;
                flex-shrink: 0;
                margin-right: 20px;
            }
        }

        .keyword-input-wrapper:last-child {
            margin-bottom: 0px;
        }

        .trigger_word_content_bg {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            .trigger_word_bg {
                height: 64px;
                width: 626px;

                ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    margin: 0;

                    li {
                        margin: 0 16px 0 0;

                        .trigger_word_label_bg {
                            background-color: transparent;
                            width: 140px;
                            height: 48px;
                            border: 2px solid #242424;
                            border-radius: 32px;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            position: relative;

                            transition: color 0.2s, background 0.2s;


                            font-weight: 500;
                            font-size: 12px;
                            color: #242424;
                            text-align: center;
                            font-style: normal;

                            .trigger_word_label {
                                width: 100px;
                                height: 46px;
                                line-height: 46px;
                                padding: 0 10px;

                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .delete {
                                width: 40px;
                                height: 40px;
                                position: absolute;
                                top: -12px;
                                right: -14px;
                            }

                            .delete:hover {
                                opacity: 0.8;
                            }

                            .delete:active {
                                opacity: 0.6;
                            }
                        }

                        .trigger_word_label_bg_hover {
                            color: #f0f0f0;
                            background-color: #242424;
                        }

                        .current_index {
                            background: #ECF0F4;

                            .trigger_word_label {
                                color: #3790FE;
                            }
                        }
                    }
                }
            }
        }

        .trigger_word_content_bg::-webkit-scrollbar-track {
            background-color: transparent;
        }

        .trigger_word_content_bg::-webkit-scrollbar {
            width: 4px;
        }

        .trigger_word_content_bg::-webkit-scrollbar-thumb {
            width: 4px;
            background-color: #3790FE;
            border-radius: 2px;
        }

        .model_library_selection_bg {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: start;
            width: 676px;
            height: auto;
            margin-top: 30px;

            .model_tips_title {
                color: #242424;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 18px;
            }

            .model_librarys {
                width: 100%;
                flex-direction: row;
                justify-content: flex-start;
                align-items: start;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                overflow: auto;
                /* 你可以根据需要调整间距大小 */

                .model_library_item {
                    margin: 0 0px 0 0;
                    width: 114px;
                    height: 48px;
                    font-size: 12px;
                    font-weight: 500px;
                    color: #242424;
                    background: transparent;
                    border: 2px solid #242424;
                    border-radius: 24px;
                    transition: color 0.3s, background 0.3s;
                }

                .model_library_item:enabled:hover {
                    background-color: #242424;
                    color: #F0F0F0;
                }

                .model_library_item_selected {
                    background: #242424;
                    color: #F0F0F0;
                }
            }
        }

    }

    .welcome_message_bg {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .welcome_message_title {
            margin-bottom: 0;
        }

    }

    .mt-40 {
        margin-top: 40px !important;
    }

    .kp-option-wrapper {
        padding: 0 152px;
        margin-top: 10px;
        display: flex;

        .option-item {
            display: flex;
            align-items: center;

            .title {
                color: #242424;
                font-weight: 600;
                display: flex;
                align-items: center;
                margin-right: 40px;
                // margin-bottom: 16px;

                label {
                    width: 80px;
                    // margin-right: 16px;
                    text-align: left;
                }

                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 8px;
                }
            }

            .option-btn {
                width: 130px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 30px;
                border: 2px solid #242424;
                background: #f0f0f0;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #242424;
                font-size: 12px;
                font-weight: 500;
                padding: 0 22px;
                cursor: pointer;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        // .personality_setting::after {
        //     margin-left: 8px;
        //     margin-bottom: 26px;
        //     content: "*";
        //     color: #e70000;
        //     font-weight: 600;
        // }

        .sound_setting {
            margin-left: 40px;
        }

        .sound_setting::after {
            margin-left: 8px;
            margin-bottom: 26px;
            content: "*";
            color: #e70000;
            font-weight: 600;
        }
    }

    .el-button.is-disabled {
        color: #c0c4cc !important;
        border: 2px solid #c0c4cc !important;
    }

    .trigger_word_creat_pop_bg {
        z-index: 1001;
        width: 100%;
        height: 100%;
        display: flex;
        top: 24px;
        left: 24px;
        flex-direction: column;
        align-items: center;
        position: absolute;
        border-radius: 40px;
        background: #F0F0F0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        .top_view {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 32px;
            position: relative;

            .back_button {}

            span {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                color: #242424;
                margin-left: 60px;
            }

            .submit_button {
                width: 180px;
                height: 64px;
                border-radius: 36px;
                background: #242424;
                color: #F0F0F0;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                border-width: 0;
            }

            .submit_button:active {
                opacity: 0.7;
            }
        }

        // .trigger_word_creat_pop {
        //     width: 810px;
        //     height: 710px;
        //     background: #FAFBFC;
        //     border-radius: 16px;

        //     display: flex;
        //     flex-direction: column;
        //     justify-content: flex-start;
        //     align-items: flex-start;
        //     padding: 30px 0 0 60px;

        .trigger_word_text_link_content_bg {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 16px;
            color: #596480;
            font-weight: 400;
            text-align: left;
            font-style: normal;

            .trigger_word_content_bg {
                width: 560px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;

                .trigger_word_text_title {
                    width: auto;
                    height: 22px;
                    line-height: 22px;
                    margin: 24px 0 12px 0;

                    color: #242424;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .trigger_word_text_title::after {
                    margin-right: 2px;
                    margin-bottom: 10px;
                    content: "*";
                    color: #FF4D4D;
                    font-weight: 400;

                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .trigger_word_link_title {
                    width: auto;
                    height: 22px;
                    line-height: 22px;
                    margin: 24px 0 12px 0;

                    color: #242424;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                :deep(.el-input) {
                    width: auto;
                    border: none;

                    .el-input__wrapper {
                        background: transparent;
                        border: 1px solid #DAE2EB;
                        box-shadow: none;
                        flex-grow: 0;
                        padding: 0;
                        border: none;

                        input {
                            width: 428px;
                            height: 48px;
                            background: #EDF1F5;
                            border-radius: 6px;
                            padding: 0 20px;
                            font-weight: 400;
                            font-size: 12px;
                            color: #242424;
                            line-height: 22px;
                            text-align: left;
                            font-style: normal;
                            border: 2px solid #242424;
                            background: transparent;
                            border-radius: 24px;
                        }
                    }
                }
            }
        }

        .media_content_bg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 24px;

            .image_content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .video_content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-left: 40px;
            }

            .upload_el_view {
                :deep(.el-upload-dragger) {
                    padding: 0;
                    border-width: 0;
                    background-color: transparent;

                    .add_media_button {
                        width: 258px;
                        height: 160px;
                        border-radius: 6px;
                        background: #eef1f5;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        border-width: 0px;

                        border-radius: 24px;
                        background-color: rgba(36, 36, 36, 0.20);

                        .media_title {
                            width: auto;
                            height: 22px;
                            line-height: 22px;
                            margin: 8px 0 12px 0;

                            color: rgba(36, 36, 36, 0.90);
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .media_center_add_tips {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .media_icon {
                                width: 40px;
                                height: 40px;
                                width: 100%;
                                height: 100%;
                                border-radius: 24px;
                                object-fit: cover;
                            }

                            .play_icon_bg {
                                position: absolute;
                                top: 48px;
                                left: 100px;
                                width: 64px;
                                height: 64px;
                                background: #F0F0F0;
                                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                border-radius: 32px;

                                .trigger_play_icon {
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 4px;
                                }
                            }

                            .media_name {
                                width: 150px;
                                height: 22px;
                                margin-top: 12px;
                                font-weight: 400;
                                font-size: 14px;
                                color: #596480;
                                line-height: 22px;
                                text-align: center;
                                font-style: normal;

                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                        }


                        .add_img {
                            width: 64px;
                            height: 64px;
                            opacity: 0.8;
                        }

                        .add_media_tips {
                            position: absolute;
                            bottom: 16px;
                            height: 20px;
                            font-weight: 500;
                            font-size: 12px;
                            color: rgba(36, 36, 36, 0.60);
                            line-height: 20px;
                            text-align: justify;
                            font-style: normal;
                            opacity: 0.8;
                        }
                    }

                    .add_media_button:hover {
                        opacity: 0.8;
                    }

                    .add_media_button:active {
                        opacity: 0.6;
                    }
                }

                .delete_upload {
                    width: 46px;
                    height: 46px;
                    position: absolute;
                    top: 0px;
                    right: 2px;
                }

                .delete_upload:hover {
                    opacity: 0.8;
                }

                .delete_upload:active {
                    opacity: 0.6;
                }
            }
        }

        .trigger_model_radio_bg {
            margin-top: 24px;
            width: 560px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .trigger_model_title {
                font-family: Alibaba-PuHuiTi-Regular;
                font-weight: 600;
                font-size: 18px;
                color: #242424;
                font-style: normal;
            }

            .trigger_model_title::after {
                margin-right: 2px;
                margin-bottom: 10px;
                content: "*";
                color: #FF4D4D;
                font-weight: 400;

                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }

            .trigger_model_radio {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin: 12px 0 0px 0;

                :deep(.el-radio) {
                    .el-radio__label {
                        font-family: Alibaba-PuHuiTi-Regular;
                        font-weight: 500;
                        font-size: 12px;
                        color: #242424;
                        font-style: normal;
                    }

                    .el-radio__input {
                        .el-radio__inner {
                            width: 16px;
                            height: 16px;
                            border-radius: 12px;
                            border: 1px solid #596480;
                        }
                    }

                    .el-radio__input.is-disabled {
                        .el-radio__inner {
                            width: 16px;
                            height: 16px;
                            border-radius: 12px;
                            border: 1px solid #c0c4cc;
                        }
                    }

                    .el-radio__input.is-checked {
                        .el-radio__inner {
                            width: 16px;
                            height: 16px;
                            border-radius: 12px;
                            background-color: transparent;
                            border: 2px solid #242424;
                        }

                        .el-radio__inner::after {
                            width: 12px;
                            height: 12px;
                            background: #242424;
                            border-radius: 10px;
                        }
                    }
                }

                :deep(.el-radio.is-disabled) {
                    .el-radio__label {
                        color: #c0c4cc;
                    }
                }
            }
        }

        // }
    }
}
</style>
