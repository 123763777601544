<template>

    <div class="account-wrapper">
        <span class="title_tips">
            编辑账号信息
        </span>
        <div class="account-base">
            <el-upload class="upload_bg" accept="image/png,image/jpeg" :on-error="handleError"
                :on-success="handleSuccess" :http-request="(item: any) => uploadImg(item.file)"
                :before-upload="beforeUPload" :show-file-list="false">
                <img v-if="userInfo.headImg" :src="userInfo.headImg" alt="" />
                <img v-else class="upload-ava" src="../assets/images/upload-ava.png" alt="" />
            </el-upload>

            <div class="info_bg">
                <div class="line">
                    <label>姓名</label>
                    <div class="user_name_bg">
                        <el-input v-model="userInfo.name" type="text" placeholder="请输入姓名"></el-input>
                        <div v-if="userLevel == 4"
                            :class="['user_name_type', isEnterpriseAdmin ? 'user_name_type_admin' : '']">{{
                isEnterpriseAdmin ? '超管' : '普管' }} </div>
                    </div>
                </div>
                <div class="line phone_number_company_info_bg">
                    <label>电话</label>
                    <el-input v-model="userShowPhone" placeholder="请输入手机号" disabled="true"></el-input>
                </div>
                <div class="line phone_number_company_info_bg">
                    <label>公司</label>
                    <el-input v-model="userCompany" placeholder="请输入公司名称" disabled="true"></el-input>
                </div>
            </div>
        </div>
        <div class="action_button_bg">
            <div class="btn" @click="cancelButtonClick">取消</div>
            <div class="btn btn-confirm" @click="sureButtonClick">确认更改</div>
        </div>
    </div>
</template>

<script lang="ts">
import { HttpResponse } from '@/net/http';
import { UserLoginInfo, linkardNet } from '@/net/linkardNet';
import { useUserStore } from '@/stores/user';
import { phoneWithOutReginPrefix } from '@/utils/commonUtils';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';

export default defineComponent({
    name: 'EnterpriseEditUserInfoView',
    components: {

    },
    setup(props, { emit }) {

        const userStore = useUserStore();
        let userInfo: Ref<UserLoginInfo> = ref(userStore.userInfo);
        let isEnterpriseAdmin = userStore.privilegeLevel.isEnterpriseAdmin;
        let userLevel = userStore.privilegeLevel.level;
        let userShowPhone = phoneWithOutReginPrefix(userInfo.value.phone);
        let userCompany = userStore.privilegeLevel.enterpriseName;

        const sureButtonClick = () => {
            updateUserInfo();
        };

        const cancelButtonClick = () => {
            emit("dismissEnterpriseEditUserInfoView");
        };

        const beforeUPload = (file: any) => {
            const isImage =
                file.type === 'image/png' ||
                file.type ===
                'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isImage)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件只能是 png / jpg 格式！',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件只能是 png / jpg 格式！", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });

            if (!isLt2M)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件大小不能超过 20MB!',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件大小不能超过 20MB!", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });
            return isImage && isLt2M;
        };

        // 上传错误提示
        const handleError = () => {
            ElMessage.error('上传失败，请您重新上传！');
        };

        //上传成功提示
        const handleSuccess = () => {

        };
        // 图片上传
        const uploadImg = async (file: any) => {
            linkardNet.uploadHeaderImageMedia({ file }).then(
                (response) => {
                    ElMessage.success('上传成功！');
                    userInfo.value.headImg = response;
                },
                (ex) => {
                    ElMessage.error('图片上传失败，请重试！');
                },
            );
        };

        const updateUserInfo = async () => {
            linkardNet.userUpdate(userInfo.value).then(
                (response) => {
                    // TODO 自定义
                    //     this.$message.success({
                    //     message: '修改成功',
                    //     customClass: "custom_message",
                    // });
                    ElMessage.success('修改成功');
                    userStore.updateCurrentUserInfo(userInfo.value);
                    emit("dismissEnterpriseEditUserInfoView");
                },
                (ex) => {
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        };


        onMounted(() => {

        });

        return {
            userInfo,
            isEnterpriseAdmin,
            userLevel,
            userShowPhone,
            userCompany,
            beforeUPload,
            handleError,
            uploadImg,
            handleSuccess,
            cancelButtonClick,
            sureButtonClick
        };
    },
});

</script>

<style lang="less" scoped>
.account-wrapper {
    width: 600px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 58px 38px 48px 38px;
    background: #f0f0f0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .title_tips {
        color: #242424;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .account-base {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        .upload_bg {
            width: 164px;
            height: 164px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            .upload-ava {
                border: 1px solid #000;
            }
        }

        .info_bg {
            margin-top: 40px;

            .line {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                label {
                    width: 54px;
                    margin-right: 6px;
                    flex-shrink: 0;
                    color: #242424;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .phone_number_company_info_bg {
                :deep(.el-input__wrapper) {
                    background: transparent;
                    border: none;
                    width: 220px;
                    height: 48px;
                    border-radius: 24px;
                    border: 2px solid #242424;
                    box-shadow: none;

                    .el-input__inner {
                        font-size: 16px;
                        font-weight: 600;
                        color: #242424;
                        margin: 0 16px 0 16px;
                        background: transparent;
                    }
                }
            }

            .user_name_bg {
                width: 300px;
                border-radius: 36px;
                display: flex;
                align-items: center;
                border: 2px solid #242424;
                background: #f0f0f0;

                :deep(.el-input) {
                    width: 218px;

                    .el-input__wrapper {
                        background: transparent;
                        border: none;
                        height: 48px;
                        border-radius: 24px;
                        box-shadow: none;

                        .el-input__inner {
                            font-size: 16px;
                            font-weight: 600;
                            color: #242424;
                            margin: 0 16px 0 16px;
                            background: transparent;
                        }
                    }
                }

                .user_name_type {
                    width: 60px;
                    height: 24px;
                    font-size: 12px;
                    color: #f0f0f0;
                    background-color: #FF900D;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 20px;
                    margin-right: 18px;
                }

                .user_name_type_admin {
                    background-color: #0D55FF;
                }
            }
        }
    }

    .action_button_bg {
        width: 420px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .btn {
            width: 180px;
            height: 64px;
            border-radius: 36px;
            border: 2px solid #242424;
            background: #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 18px;
            cursor: pointer;
        }

        .btn-confirm {
            background: #242424;
            color: #f0f0f0;
            margin-bottom: 0;
        }
    }
}
</style>
