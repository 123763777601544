import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dbcb047"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sound_info_setting" }
const _hoisted_2 = { class: "top_view" }
const _hoisted_3 = { class: "sound-add-wrapper" }
const _hoisted_4 = { class: "default_sound_setting_bg" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "sound_list_bg" }
const _hoisted_7 = { class: "sound_list" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "sound_name" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButtonItem = _resolveComponent("BackButtonItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BackButtonItem, {
        onBackBtnClick: _ctx.backButtonClick,
        class: "back_button"
      }, null, 8, ["onBackBtnClick"]),
      _createElementVNode("button", {
        class: "submit_button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitClick && _ctx.submitClick(...args)))
      }, "确认")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voiceTypeList, (item, index) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(['default_sound_button', _ctx.currentSelVoiceLevel == item.type ? 'selected_sound_button' : '']),
            key: index,
            onClick: ($event: any) => (_ctx.voiceTypeButtonClick(item))
          }, _toDisplayString(item.name), 11, _hoisted_5))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedVoiceList, (soundItem, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("div", {
                class: _normalizeClass(['sound_item', _ctx.selectedItem == soundItem ? 'sound_item_selected' : 'sound_item_normal', !soundItem.allow ? 'sound_item_disable' : '']),
                onClick: ($event: any) => (_ctx.soundItemClick(soundItem))
              }, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(soundItem.name), 1),
                _createElementVNode("button", {
                  onClick: _withModifiers(($event: any) => (_ctx.soundPlayingClick(soundItem)), ["stop"]),
                  class: "play_icon_button"
                }, [
                  _createElementVNode("img", {
                    src: _ctx.currentPlayingItem == soundItem ? require('../assets/images/sound_setting_item_pause.svg') : require('../assets/images/sound_setting_item_play.svg'),
                    alt: ""
                  }, null, 8, _hoisted_11)
                ], 8, _hoisted_10)
              ], 10, _hoisted_8)
            ]))
          }), 128))
        ]),
        _createElementVNode("audio", {
          ref: "audioPlayer",
          onEnded: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.audioEnded && _ctx.audioEnded(...args)))
        }, null, 544)
      ])
    ])
  ]))
}