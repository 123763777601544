import { createApp, reactive } from "vue";

import myOverAllDialogView from "./overAllDialogView.vue";

const msg = reactive({
  dialogText: "",
  sureButton: "确认",
  cancelButton: "取消",
  warningTips: "",
  showDialog: false,
  blackBg: false,
  callBackAction: (type: number) => {},
});

const $overAllDialogView = createApp(myOverAllDialogView, { msg }).mount(
  document.createElement("div")
);
const OverAllDialogView = {
  showOverAllDialogView(
    content: string,
    sureText: string,
    cancelText: string,
    warningTipStr: string,
    blackBgStr: boolean,
    action: (type: number) => void
  ) {
    // 控制显示loading的方法
    msg.showDialog = true;
    msg.dialogText = content;
    msg.sureButton = sureText;
    msg.cancelButton = cancelText;
    msg.warningTips = warningTipStr;
    msg.blackBg = blackBgStr;
    msg.callBackAction = action;

    document.body.appendChild($overAllDialogView.$el);
  },

  hideOverAllDialogView() {
    // 控制loading隐藏的方法
    msg.showDialog = false;
  },
};
export { OverAllDialogView };
