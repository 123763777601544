<template>
    <div class="dialog_content_view">
        <span class="dialog_text">{{ dialogText }}</span>
        <el-input v-model="userName" type="text" placeholder="请输入用户名"></el-input>
        <el-input v-model="userPhone" type="text" maxlength="11" placeholder="请输入手机号"></el-input>
        <div class="dialog_action_bg">
            <button class="dialog_button dialog_cancel" @click="cancelButtonClick">{{ cancelButton }}</button>
            <button class="dialog_button dialog_sure" @click="sureButtonClick">{{ sureButton }}</button>
        </div>
    </div>
</template>

<script lang="ts">
import { validatePhone } from '@/utils/commonUtils';
import { ElMessage } from 'element-plus';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    name: 'AddManagerView',
    props: {
        dialogText: {
            type: String,
            default: ""
        },
        sureButton: {
            type: String,
            default: "确认"
        },
        cancelButton: {
            type: String,
            default: "取消"
        },
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    components: {

    },
    setup(props, { emit }) {

        let userName = ref('');
        let userPhone = ref('');

        const sureButtonClick = () => {
            if (!userName) {
                ElMessage.error("请输入用户名");
                return;
            }
            if (!validatePhone(userPhone.value)) {
                ElMessage.error("请正确填写手机号码");
                return;
            }
            emit("dismissAddManagerView", 1, userName, userPhone);
        };

        const cancelButtonClick = () => {
            emit("dismissAddManagerView", 0);
        };

        onMounted(() => {

        });

        return {
            userName,
            userPhone,
            sureButtonClick,
            cancelButtonClick
        };
    },
});

</script>

<style lang="less" scoped>
.dialog_content_view {
    width: 580px;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 110px 80px 64px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog_text {
        color: #242424;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0 30px;
        margin-bottom: 6px;
    }

    :deep(.el-input__wrapper) {
        background: transparent;
        border: 2px solid #242424;
        width: 420px;
        height: 48px;
        border-radius: 24px;
        margin-top: 36px;

        .el-input__inner {
            font-size: 18px;
            font-weight: 600;
            color: #242424;
            margin: 0 16px 0 16px;
            background: #f0f0f0;
            text-align: center;
        }
    }

    :deep(.el-input__wrapper.is-focus) {
        box-shadow: none;
    }

    .dialog_action_bg {
        width: 420px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 42px;

        .dialog_button {
            border-width: 0;
            width: 180px;
            height: 64px;
            border-radius: 36px;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
        }

        .dialog_button:active {
            opacity: 0.7;
        }

        .dialog_sure {
            background: #242424;
            color: #F0F0F0;
        }

        .dialog_cancel {
            background: #F0F0F0;
            border: 2px solid #242424;
            color: #242424;

        }
    }
}
</style>
