export interface WxLoginOptions {
  id: string;
  appid: string;
  scope: string;
  redirect_uri: string;
  state: string;
  style?: string;
  href?: string;
  self_redirect?: boolean;
  styletype?: string;
  sizetype?: string;
  bgcolor?: string;
  rst?: string;
  lang?: string;
}

export class WxLogin {
  constructor(private options: WxLoginOptions) {
    const r: string = options.self_redirect ? "true" : "false";
    const i: string =
      "https://open.weixin.qq.com/connect/qrconnect?appid=" +
      options.appid +
      "&scope=" +
      options.scope +
      "&redirect_uri=" +
      options.redirect_uri +
      "&state=" +
      options.state +
      "&login_type=jssdk&self_redirect=" +
      r +
      "&styletype=" +
      (options.styletype || "") +
      "&sizetype=" +
      (options.sizetype || "") +
      "&bgcolor=" +
      (options.bgcolor || "") +
      "&rst=" +
      (options.rst || "");
      
    const langParam: string = options.lang === "en" ? "&lang=en" : "";
    const styleParam: string = options.style ? "&style=" + options.style : "";
    const hrefParam: string = options.href ? "&href=" + options.href : "";

    const n: HTMLIFrameElement = document.createElement("iframe");
    n.src = i + styleParam + hrefParam + langParam;
    // n.frameBorder = '0';
    // n.allowTransparency = 'true';
    // n.scrolling = 'no';
    n.width = "300px";
    n.height = "400px";

    const s: HTMLElement | null = document.getElementById(options.id);
    if (s) {
      s.innerHTML = "";
      s.appendChild(n);
    }
  }
}
