<template>
    <div class="add_role_view_bg">
        <div class="top_view">
            <!-- <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem> -->
            <button class="submit_button" @click="submitClick">确认</button>
        </div>
        <div class="background_img_content">
            <div class="tab_action_bg">
                <el-button :class="['tab_button', selectedTab == 0 ? 'tab_button_selected' : 'tab_button_normal']"
                    @click="tabActionClick(0)">发现</el-button>
                <el-button :class="['tab_button', selectedTab == 1 ? 'tab_button_selected' : 'tab_button_normal']"
                    @click="tabActionClick(1)">我的</el-button>
            </div>
            <div class="model-wrapper-bg">
                <div class="model-wrapper" style="overflow:auto">
                    <ul v-if="selectedTab == 0" class="ava-wrapper" v-infinite-scroll="getBackGroundImgListRequest"
                        :infinite-scroll-disabled="noMoreBackListData">
                        <li v-for="(item, index) in backList" :key="item.url" @click="selectBack(item)"
                            @mouseenter="handleBgMouseEnter(index)" @mouseleave="handleBgMouseLeave(index)"
                            :style="itemBgStyle(index)">
                            <div :class="['back_img_bg', item.url === backgroundItem.url ? 'active-border' : '']">
                                <img class="bg_item" :src="item.url" alt="" />
                            </div>
                        </li>
                    </ul>
                    <ul v-else class="ava-wrapper" v-infinite-scroll="getMyBackGroundImgListRequest"
                        :infinite-scroll-disabled="noMoreMyBackListData">
                        <li v-for="(item, index) in myBackGroundList" :key="item.url" @click="selectBack(item)"
                            @mouseenter="handleMyBgMouseEnter(index)" @mouseleave="handleMyBgMouseLeave(index)"
                            :style="itemMyBgStyle(index)">
                            <div :class="['back_img_bg', item.url === backgroundItem.url ? 'active-border' : '']">
                                <img class="bg_item" :src="item.url" alt="" />
                                <button class="delete_button" @click.stop="deleteItemButtonClick(index)">
                                    <img :src="require('../assets/images/delete_button_icon.svg')" alt="">
                                </button>
                                <span v-if="item.haveUsed" class="useing_tips">使用中</span>
                            </div>
                        </li>
                        <li>
                            <el-upload action="" class="upload_el_view" drag accept="image/png,image/jpeg"
                                :on-error="handleError" :on-success="handleSuccess"
                                :http-request="(item: any) => uploadImg(item.file)" :before-upload="beforeUPload"
                                :show-file-list="false">
                                <button class="add_button">
                                    <img src="../assets/images/special_subject_add_icon.svg" alt="" />
                                    <span class="creat_tips_text">点击上传</span>
                                </button>
                            </el-upload>
                        </li>
                    </ul>
                </div>
                <span v-if="selectedTab == 1" class="delete_tips">*注意：删除后将恢复为默认背景</span>
            </div>
        </div>
        <SubViewLoadingView :msg="subLoadingInfo"></SubViewLoadingView>
    </div>
</template>

<script lang="ts">
import { BackGroundImgItem, linkardNet } from '@/net/linkardNet';;
import { Ref, defineComponent, onMounted, ref } from 'vue';
import BackButtonItem from "./backButtonItem.vue";
import SubViewLoadingView from "./loadingView/subViewLoadingView.vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { HttpResponse } from '@/net/http';
import { OverAllDialogView } from './overAllDialogView/overAllDialogView';
import { BG_DELETE_ALERT } from '@/utils/const';

export default defineComponent({
    name: 'BaseInfoView',
    props: {
        tempBackgroundId: {
            default: "" //数字人背景图片
        }
    },
    components: {
        BackButtonItem,
        SubViewLoadingView
    },
    setup(props, { emit }) {

        let selectedTab = ref(0);
        const backgroundItem: Ref<BackGroundImgItem> = ref({
            id: '',
            url: '',
            name: '',
        });

        const backList: Ref<BackGroundImgItem[]> = ref([]);
        const myBackGroundList: Ref<BackGroundImgItem[]> = ref([]);

        let pageNumber = 1;
        let myBgPageNumber = 1;
        let pageSize = 12;
        const noMoreBackListData = ref(false);
        const noMoreMyBackListData = ref(false);

        let isLoading = false;
        let isMyImageLoading = false;
        const subLoadingInfo = ref({
            show: false,
            text: '',
            isShowSuccess: false
        });

        const scales = ref(new Array(backList.value.length).fill(1));
        const myBgscales = ref(new Array(myBackGroundList.value.length).fill(1));

        const handleBgMouseEnter = (index: number) => {
            scales.value[index] = 1.02;
        };

        const handleBgMouseLeave = (index: number) => {
            scales.value[index] = 1;
        };

        const itemBgStyle = (index: number) => {
            return {
                transform: `scale(${scales.value[index]})`,
                transition: 'transform 0.3s ease',
            };
        };


        const handleMyBgMouseEnter = (index: number) => {
            myBgscales.value[index] = 1.02;
        };

        const handleMyBgMouseLeave = (index: number) => {
            myBgscales.value[index] = 1;
        };

        const itemMyBgStyle = (index: number) => {
            return {
                transform: `scale(${myBgscales.value[index]})`,
                transition: 'transform 0.3s ease',
            };
        };


        const backButtonClick = () => {
            emit("dismissBackgroundSelView", null);
        };
        const submitClick = () => {
            emit("dismissBackgroundSelView", backgroundItem.value);
        };
        const selectBack = (item: BackGroundImgItem) => {
            backgroundItem.value = item;
            emit("setLinardInfoView", "", item);
        };

        const tabActionClick = (index: number) => {
            selectedTab.value = index;
            if (selectedTab.value == 0) {
                pageNumber = 1;
                backList.value = [];
                getBackGroundImgListRequest();
            } else {
                myBgPageNumber = 1;
                myBackGroundList.value = [];
                getMyBackGroundImgListRequest();
            }
        };

        const getBackGroundImgListRequest = () => {
            if (isLoading) return;

            isLoading = true;

            linkardNet.getBackGroundImgList(pageNumber, pageSize, 0).then(
                (response) => {
                    if (response.list.length < pageSize) {
                        noMoreBackListData.value = true;
                    } else {
                        pageNumber += 1;
                    }

                    backList.value = [...backList.value, ...response.list];
                    if (props.tempBackgroundId) {
                        const foundItem = backList.value.find(item => props.tempBackgroundId === item.id);
                        if (foundItem) {
                            backgroundItem.value = foundItem as BackGroundImgItem;
                        } else {
                            // 处理找不到匹配项的情况，比如抛出错误或赋予默认值
                        }
                    }
                    isLoading = false;
                },
                (ex) => {
                    isLoading = false;
                },
            );
        }

        const getMyBackGroundImgListRequest = () => {
            if (isMyImageLoading) return;

            isMyImageLoading = true;
            linkardNet.getBackGroundImgList(myBgPageNumber, pageSize, 1).then(
                (response) => {
                    if (response.list.length < pageSize) {
                        noMoreMyBackListData.value = true;
                    } else {
                        myBgPageNumber += 1;
                    }

                    myBackGroundList.value = [...myBackGroundList.value, ...response.list];

                    if (props.tempBackgroundId) {
                        const foundItem = myBackGroundList.value.find(item => props.tempBackgroundId === item.id);
                        if (foundItem) {
                            backgroundItem.value = foundItem as BackGroundImgItem;
                        } else {
                            // 处理找不到匹配项的情况，比如抛出错误或赋予默认值
                        }
                    }

                    isMyImageLoading = false;
                },
                (ex) => {
                    isMyImageLoading = false;
                },
            );
        }

        const beforeUPload = (file: any) => {
            const isImage =
                file.type === 'image/png' ||
                file.type ===
                'image/jpeg';
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isImage)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件只能是 png / jpg 格式！',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件只能是 png / jpg 格式！", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });

            if (!isLt5M)
                // ElMessageBox({
                //     title: '温馨提示',
                //     message: '上传文件大小不能超过 5MB!',
                //     type: 'warning',
                // });

                OverAllDialogView.showOverAllDialogView("上传文件大小不能超过 5MB!", "确定", "", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {

                    }
                    OverAllDialogView.hideOverAllDialogView();
                });

            return isImage && isLt5M;
        };

        // 上传错误提示
        const handleError = () => {
            ElMessage.error('上传失败，请您重新上传！');
        };

        //上传成功提示
        const handleSuccess = () => {
        };
        // 图片上传
        const uploadImg = async (file: any) => {
            subLoadingInfo.value.show = true;
            linkardNet.uploadBackground({ file }).then(
                (response) => {
                    ElMessage.success('图片上传成功！');
                    const item: BackGroundImgItem = {
                        id: response.id,
                        url: response.url,
                        name: response.filename,
                    };
                    myBackGroundList.value.unshift(item);
                    subLoadingInfo.value.isShowSuccess = true;
                    subLoadingInfo.value.text = '上传完成';
                    setTimeout(() => {
                        subLoadingInfo.value.show = false;
                        subLoadingInfo.value.isShowSuccess = false;
                        subLoadingInfo.value.text = '';
                    }, 2000);
                },
                (ex) => {
                    subLoadingInfo.value.show = false;
                    const error = ex as HttpResponse<any>;
                    ElMessage.error(error.message);
                },
            );
        }

        const deleteItemButtonClick = (index: number) => {

            const item = myBackGroundList.value[index];
            let deleteMessage = "删除后不可恢复，请谨慎操作！";
            if (item.haveUsed) {
                deleteMessage = "删除后使用该背景的卡片将恢复为默认背景，请谨慎操作！";
            }

            if (localStorage.getItem(BG_DELETE_ALERT) != "1") {
                OverAllDialogView.showOverAllDialogView(deleteMessage, "确认删除", "取消", "", false, (dialogIndex: number) => {
                    if (dialogIndex == 1) {
                        linkardNet.deleteBackground(item.id).then(
                            (response) => {
                                myBackGroundList.value.splice(index, 1);
                                localStorage.setItem(BG_DELETE_ALERT, "1");

                                if (backgroundItem.value.id == item.id && backList.value.length > 0) {
                                    selectBack(backList.value[0]);
                                }
                            },
                            (ex) => {
                                ElMessage.error('删除失败，请稍后重试');
                            },
                        );
                    }
                    OverAllDialogView.hideOverAllDialogView();
                });
            } else {
                linkardNet.deleteBackground(item.id).then(
                    (response) => {
                        myBackGroundList.value.splice(index, 1);
                        if (backgroundItem.value.id == item.id && backList.value.length > 0) {
                            selectBack(backList.value[0]);
                        }
                    },
                    (ex) => {
                        ElMessage.error('删除失败，请稍后重试');
                    },
                );
            }
        }

        onMounted(() => {
            // background.value = props.tempBackground;
        });

        return {
            backgroundItem,
            selectedTab,
            backList,
            myBackGroundList,
            noMoreBackListData,
            noMoreMyBackListData,
            subLoadingInfo,
            handleError,
            beforeUPload,
            handleSuccess,
            uploadImg,
            backButtonClick,
            submitClick,
            selectBack,
            tabActionClick,
            deleteItemButtonClick,
            handleBgMouseEnter,
            handleBgMouseLeave,
            itemBgStyle,
            handleMyBgMouseEnter,
            handleMyBgMouseLeave,
            itemMyBgStyle,
            getMyBackGroundImgListRequest,
            getBackGroundImgListRequest,
        };
    },
});

</script>

<style lang="less" scoped>
.add_role_view_bg {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {}

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
            margin-left: 60px;
        }
    }

    .background_img_content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;

        .tab_action_bg {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 36px;

            .tab_button {
                width: 120px;
                height: 64px;
                border-radius: 36px;
                border: 2px solid #242424;
                color: #242424;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-left: 0;
            }

            .tab_button:hover {
                opacity: 0.8;
            }

            .tab_button:active {
                opacity: 0.6;
            }

            .tab_button:last-child {
                margin-top: 20px;
            }

            .tab_button_selected {
                background-color: #F3EC3F;
            }

            .tab_button_normal {
                background-color: #F0F0F0;
            }
        }

        .model-wrapper-bg {
            flex-grow: 1;
            width: 720px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0 30px 70px 20px;

            .model-wrapper {

                height: 500px;
                width: 100%;
                padding-left: 10px;
                border-radius: 40px;
                border: 2px solid #242424;

                .title {
                    font-weight: 600;
                    color: #242424;
                    display: flex;
                    align-items: center;
                }

                .active-border {
                    border: 6px solid #f3ec3f !important;
                }

                .ava-wrapper {
                    margin: 10px 0 0 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    cursor: pointer;

                    li {
                        margin-right: 8px;
                        border-radius: 20px;
                        position: relative;

                        .back_img_bg {
                            border: 6px solid transparent;
                            border-radius: 20px;
                            position: relative;
                        }

                        .bg_item {
                            width: 155px;
                            height: 191px;
                            border-radius: 20px;
                            vertical-align: top;
                            object-fit: cover;
                        }

                        span {
                            color: #242424;
                            text-align: center;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            margin-top: 10px;
                        }

                        .upload_el_view {
                            :deep(.el-upload-dragger) {
                                padding: 0;
                                border-width: 0;
                                background-color: transparent;

                                .add_button {
                                    width: 155px;
                                    height: 191px;
                                    border-radius: 20px;
                                    background: rgba(36, 36, 36, 0.20);
                                    border-width: 0;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    margin: 4px 0 0 4px;
                                    cursor: pointer;

                                    img {
                                        width: 64px;
                                        height: 64px;
                                    }

                                    .creat_tips_text {
                                        color: #242424;
                                        font-size: 18px;
                                        font-weight: 600;
                                        margin-top: 8px;
                                    }
                                }

                                .add_button:hover {
                                    opacity: 0.8;
                                }

                                .add_button:active {
                                    opacity: 0.6;
                                }

                            }
                        }

                        .delete_button {
                            position: absolute;
                            top: 12px;
                            right: 12px;
                            width: 36px;
                            height: 36px;
                            border-radius: 18px;
                            border-width: 0;
                            background: rgba(240, 240, 240, 1);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            cursor: pointer;

                            img {
                                width: 14px;
                                height: 14px;
                            }
                        }

                        .delete_button:hover {
                            opacity: 0.8;
                        }

                        .delete_button:active {
                            opacity: 0.6;
                        }

                        .useing_tips {
                            position: absolute;
                            bottom: 16px;
                            left: 20px;
                            width: 120px;
                            height: 32px;
                            border-radius: 16px;
                            border: 2px solid #242424;
                            background-color: #F3EC3F;

                            color: #242424;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 30px;
                            z-index: 1;
                        }
                    }
                }
            }

            .model-wrapper::-webkit-scrollbar-track {
                background-color: transparent;
                margin: 30px 0;
            }

            .model-wrapper::-webkit-scrollbar {
                width: 4px;
            }

            .model-wrapper::-webkit-scrollbar-thumb {
                width: 4px;
                background-color: #d3d4d6;
                border-radius: 4px;
                margin: 30px 0;
            }

            .delete_tips {
                color: #e70000;
                font-weight: 600;
                font-size: 14px;
                text-align: left;
                margin-top: 10px;
            }
        }
    }
}
</style>
