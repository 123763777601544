<template>
    <div class="add_role_view_bg">
        <div class="top_view">
            <!-- <BackButtonItem @backBtnClick="backButtonClick" class="back_button"></BackButtonItem> -->
            <button class="submit_button" @click="submitClick">确认</button>
        </div>
        <div class="model-wrapper" style="overflow:auto">
            <ul class="ava-wrapper">
                <li v-for="(item,index) in charactList" :key="item.avatar" @click="selectCharact(item)"
                    @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)"
                    :style="itemStyle(index)">
                    <div :class="[
                item.characterId === characterId ? 'active-border' : '',
                !item.allow ? 'disable_character' : '']">
                        <img class="avatar_img" :src="item.avatar" alt="" />
                        <div :class="['level_style']" :style="{ background: item.levelBackground }">
                            {{ item.userLevelTips }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bottom_view">
            <div class="main-name">默认人格</div>
            <div class="main-intro">{{ digitalIntroduction }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { CharacterItem } from '@/net/linkardNet';
import { defineComponent, onMounted, ref } from 'vue';
import BackButtonItem from "./backButtonItem.vue";

export default defineComponent({
    name: 'BaseInfoView',
    props: {
        charactList: {
            type: Array as () => Array<any>,
            default: []
        },
        tempCharacterId: {
            default: ""
        }
    },
    components: {
        BackButtonItem
    },
    setup(props, { emit }) {

        let characterId = ref('');
        let digitalIntroduction = ref('');
        let usingCharacterId = ref('');
        let userLevel = ref(-1);

        const scales = ref(new Array(props.charactList.length).fill(1));

        const handleMouseEnter = (index: number) => {
            scales.value[index] = 1.05;
        };

        const handleMouseLeave = (index: number) => {
            scales.value[index] = 1;
        };

        const itemStyle = (index: number) => {
            return {
                transform: `scale(${scales.value[index]})`,
                transition: 'transform 0.3s ease',
            };
        };

        const backButtonClick = () => {
            emit("dismissAddRoleView", null);
        };

        const submitClick = () => {
            emit("dismissAddRoleView", characterId.value);
        };

        const selectCharact = (item: CharacterItem) => {
            if (!item.allow) return;

            characterId.value = item.characterId;
            digitalIntroduction.value = item.intro;
            emit("setLinardInfoView", item.characterId, null);
        };

        onMounted(() => {
            characterId.value = props.tempCharacterId;
            if (props.charactList.length > 0) {

                if (characterId.value) {
                    usingCharacterId.value = characterId.value;
                }
                props.charactList.forEach(element => {
                    if (element.characterId == usingCharacterId.value) {
                        element.userLevelTips = "使用中";
                        element.levelBackground = "#242424";
                        digitalIntroduction.value = element.intro;
                    } else {
                        switch (element.level) {
                            case 1:
                                element.userLevelTips = "体验版";
                                element.levelBackground = "#39D92B";
                                break;
                            case 2:
                                element.userLevelTips = "进阶版";
                                element.levelBackground = "#FF900D";
                                break;
                            case 3:
                                element.userLevelTips = "专业版";
                                element.levelBackground = "#242424";
                                break;
                            case 4:
                                element.userLevelTips = "企业版";
                                element.levelBackground = "#0D55FF";
                                break;
                        }
                    }
                });

                // this.$forceUpdate();
            }
        });

        return {
            characterId,
            digitalIntroduction,
            usingCharacterId,
            userLevel,
            backButtonClick,
            submitClick,
            selectCharact,
            handleMouseEnter,
            handleMouseLeave,
            itemStyle,
        };
    },
});

</script>

<style lang="less" scoped>
.add_role_view_bg {
    width: 100%;
    height: 100%;
    display: flex;
    top: 24px;
    left: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

    .top_view {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 32px;
        position: relative;

        .back_button {}

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            color: #242424;
            margin-left: 60px;
        }
    }

    .model-wrapper {
        height: 358px;
        width: calc(100% - 88px);
        margin: 0 40px 0 48px;
        padding-left: 32px;
        border-radius: 40px;
        border: 2px solid #242424;

        .title {
            font-weight: 600;
            color: #242424;
            display: flex;
            align-items: center;
        }

        .active-border {
            border: 6px solid #f3ec3f !important;

            .img {
                border: 4px solid #242424;
            }
        }

        .disable_character {
            opacity: 0.4;
        }

        .ava-wrapper {
            margin: 20px 0 24px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            li {
                margin-right: 22px;
                border-radius: 50%;
                margin-top: 10px;

                div {
                    border: 6px solid transparent;
                    border-radius: 50%;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor:pointer;
                }

                .avatar_img {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    vertical-align: top;
                    object-fit: cover;
                }

                span {
                    color: #242424;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-top: 10px;
                }

                .level_style {
                    position: absolute;
                    bottom: 14px;
                    width: 76px;
                    height: 16px;
                    border-radius: 8px;
                    text-align: center;
                    font-family: LXGW 975 HazyGo SC;
                    font-size: 12px;
                    font-weight: 600;
                    color: #F0F0F0;
                }
            }
        }

        .bg-wrapper {
            margin: 20px 0 18px 0;
            display: flex;
            justify-content: space-between;

            li {
                border: 6px solid transparent;
                border-radius: 24px;

                div {
                    border: 6px solid transparent;
                    border-radius: 24px;
                }

                img {
                    width: 120px;
                    // height: 180px;
                    flex-shrink: 0;
                    vertical-align: top;
                    border-radius: 24px;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
                }
            }

            span {
                color: #242424;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-top: 10px;
            }
        }
    }

    .model-wrapper::-webkit-scrollbar-track {
        background-color: transparent;
        margin: 30px 0;
    }

    .model-wrapper::-webkit-scrollbar {
        width: 4px;
    }

    .model-wrapper::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: #d3d4d6;
        border-radius: 4px;
        margin: 30px 0;
    }

    .bottom_view {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        margin-bottom: 20px;

        .main-name {
            margin-top: 24px;
            font-size: 18px;
            color: #242424;
            font-weight: 600;
            text-align: center;
            margin-bottom: 24px;
        }

        .main-intro {
            text-align: left;
            font-size: 14px;
            color: #242424;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-left: 120px;
            margin-right: 120px;
        }
    }
}
</style>
